import React from 'react'

import { Flex } from '@chakra-ui/react'

import { useDivergenceStore } from 'modules/map/store/divergence-store'

import useCommentsStore from '../../store/use-comments-store'
import { Chat } from '../chat/chat'
import { AboutToolRightView } from './about-tool-right-view'
import { QuestionAnalysis } from './summary/question-analysis-view'

export const RightDivergenceView: React.FC<{
	linksOrRefs: string
	journeyId: string
}> = ({ linksOrRefs, journeyId }) => {
	const { questionOpenedIndex, summaryQuestionIndex, questions, point } =
		useCommentsStore()
	const { isAnonym } = useDivergenceStore()
	const bg = isAnonym ? 'gray.200' : 'blue.50'

	let countQuestions = [...questions]

	if (point?.introduction) {
		countQuestions = [{ id: '0', comments: [], count: 0 }, ...questions]
	}

	return (
		<Flex flexDirection='column' backgroundColor={bg} h='full' w='full'>
			{questionOpenedIndex !== -1 && questionOpenedIndex !== -2 && point && (
				<Chat
					point={point}
					questions={countQuestions[questionOpenedIndex]}
					questionOpenedIndex={questionOpenedIndex}
					journeyId={journeyId}
				/>
			)}
			{questionOpenedIndex === -1 && (
				<AboutToolRightView
					linksOrRefs={linksOrRefs}
					divergencePointId={point?.id || ''}
				/>
			)}
			{questionOpenedIndex === -2 && point && (
				<QuestionAnalysis
					point={point}
					questionOpenedIndex={questionOpenedIndex}
					questions={questions[summaryQuestionIndex]}
					analysisQuestionIndex={summaryQuestionIndex}
				/>
			)}
		</Flex>
	)
}
