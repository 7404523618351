import i18n from 'i18n/i18n'
import { string, object, array } from 'yup'

import { testHourFromNow } from 'modules/map/validators'

const option = () =>
	object({
		text: string().required(i18n.t('errors:requiredField'))
	})

const question = () =>
	object({
		text: string().required(i18n.t('errors:requiredField')),
		options: array().of(option())
	})

export const CreateConvergencePointValidation = () =>
	object({
		date: string().required(i18n.t('errors:requiredField')),
		hour: string()
			.required(i18n.t('errors:requiredField'))
			.test({
				test: testHourFromNow,
				message: i18n.t('errors:hourFromNow')
			}),
		questions: array().of(question())
	})
