type Keys<T> = T[]
type Data<T> = {
	[Property in keyof T]: T[Property] | null
}

export function fixNullOrNotBlank<T>(data: Data<T>, keys?: Keys<keyof T>) {
	const keysToFix = keys || (Object.keys(data) as Keys<keyof T>)
	keysToFix.forEach((key) => (data[key] = data[key] || null))
	return data
}
