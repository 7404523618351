import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded, BiInfoCircle } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import {
	Box,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'

import { useJourney } from 'modules/journeys/hooks'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { useConvergenceStore } from '../../../store'
import { ConvergencePoint } from '../../../types'
import { ClosePoint } from './close-point'
import { DeletePoint } from './delete-point'
import { UpdateCloseDate } from './update-close-date'

export const ConfigPoint = ({
	point,
	handleToggleVisibility
}: {
	point: ConvergencePoint
	handleToggleVisibility: () => void
}) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { user: currUser } = useMe()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const { data: users = [] } = useJourney(projectId, {
		select: (journey) => journey.users
	})

	const {
		state: { selectedPoint }
	} = useMapStore()
	const { date, hour, formatHours } = useConvergenceStore()

	const [isEditing, setIsEditing] = useState(false)

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) =>
			Role(project_roles).can('convergence_point.update')
		)

	const translateParam = { date, hour, formatHours }

	const isClosed = point.status === 'CLOSED'

	const Info = () => {
		const count = point.answer_count
		const answerCount = count ? (
			<Trans
				i18nKey='map:points.convergence.countAnswer'
				count={count}
				components={[<strong />]}
			/>
		) : (
			` ${t('map:points.convergence.countAnswer_0')}`
		)

		return (
			<>
				<HStack>
					<Icon as={BiInfoCircle} />
					<Text fontSize='sm'>
						{isClosed ? (
							<Trans
								i18nKey='map:points.convergence.pointWasCompleted'
								values={translateParam}
								components={[<strong />]}
							/>
						) : (
							<Trans
								i18nKey='map:points.convergence.pointWillCompleted'
								values={translateParam}
								components={[<strong />]}
							/>
						)}
						{answerCount}
					</Text>
				</HStack>

				<Box>
					{hasPrivileges && (
						<Menu placement='bottom-end'>
							<MenuButton
								as={IconButton}
								aria-label='More'
								variant='ghost'
								icon={<BiDotsVerticalRounded />}
							></MenuButton>
							<MenuList>
								{isMobile && (
									<MenuItem onClick={handleToggleVisibility} borderRadius='md'>
										{selectedPoint?.visible
											? t('map:points.action.hidden.title')
											: t('map:points.action.visible.title')}
									</MenuItem>
								)}
								{!isClosed && (
									<MenuItem onClick={() => setIsEditing(true)}>
										{t('buttons:edit')}
									</MenuItem>
								)}
								<DeletePoint
									point={point}
									projectId={projectId}
									mapId={mapId}
								/>
								{!isClosed && <ClosePoint point={point} />}
							</MenuList>
						</Menu>
					)}
				</Box>
			</>
		)
	}

	const Content = () => {
		if (isEditing)
			return (
				<UpdateCloseDate
					setIsEditing={setIsEditing}
					mapId={mapId}
					point={point}
					projectId={projectId}
				/>
			)
		return <Info />
	}

	return (
		<Stack
			justify='space-between'
			align='center'
			direction='row'
			bg='gray.100'
			py={4}
			px={isMobile ? 4 : 8}
		>
			<Content />
		</Stack>
	)
}
