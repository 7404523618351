import { useTranslation } from 'react-i18next'

import { Flex, Icon, Text } from '@chakra-ui/react'

import { IconStAnonym } from 'modules/common/components/icons/st-anonym'
import { useDivergenceStore } from 'modules/map/store/divergence-store'

export function AnonymInfo() {
	const { t } = useTranslation()
	const { isAnonym } = useDivergenceStore()

	if (isAnonym) {
		return (
			<Flex py={2}>
				<Icon as={IconStAnonym} fontSize='xl' mr={2} mt={1} />
				<Text fontSize='xs'>{t('anonym:incognito.divergence.message')}</Text>
			</Flex>
		)
	}

	return null
}
