import React from 'react'

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { MonitorPointForm } from './components/monitor-point-form'
import { MonitorPointView } from './components/monitor-point-view'
import { MonitorPointStatusStoreProvider } from './store/use-monitor-point-status-store'

export type Mode = 'create' | 'view'

interface MonitorPointProps {
	isOpen: boolean
	onClose: () => void
	mode: Mode
}

const modeComponents: Record<Mode, React.ComponentType> = {
	create: MonitorPointForm,
	view: MonitorPointView
}

export const MonitorPointModal = ({
	isOpen,
	onClose,
	mode = 'create'
}: MonitorPointProps) => {
	const ModeComponent = modeComponents[mode]

	return (
		<MonitorPointStatusStoreProvider>
			<Modal
				scrollBehavior='outside'
				isOpen={isOpen}
				onClose={onClose}
				closeOnOverlayClick={false}
				size={mode === 'view' ? ['full', '4xl'] : ['full', '2xl']}
				isCentered
			>
				<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
				<ModalContent
					minH={mode === 'view' ? 'xs' : 'lg'}
					h={['full', 'fit-content']}
					transition='max-width 0.3s ease'
					overflowY='auto'
				>
					<ModeComponent />
				</ModalContent>
			</Modal>
		</MonitorPointStatusStoreProvider>
	)
}
