import create from 'zustand'

import { ProjectRole, User } from 'modules/journeys/types'
import ProjectsApi from 'modules/journeys/usecases'
import { ROLES } from 'modules/user/constants'

import {
	USER_ACTIVE,
	USER_PENDING_APPROVAL,
	USER_PENDING_NDA
} from '../constants'

interface JourneyState {
	users: User[]
	setUsers: (users: User[]) => void
	getActiveUsers: () => User[]
	getWaitingForApprovalUsers: () => User[]
	getPedingApprovalUsers: () => User[]
	disapproveMembers: (projectId: string, userIdList: string[]) => void
	approveMembers: (projectId: string, userIdList: string[]) => void
	removeMembers: (projectId: string, userIdList: string[]) => Promise<boolean>
	inviteUsers: (projectId: string, userIdList: string[]) => Promise<boolean>
	removeAsMentor: (projectId: string, userIdList: string[]) => void
	promoteToMentor: (projectId: string, userIdList: string[]) => void
	getRoles: (userId: string) => ProjectRole[] | undefined
}

export const JourneyStore = create<JourneyState>((set, get) => ({
	users: [],
	notificationCount: null,
	setUsers: (users: User[]) => {
		set(() => ({
			users: [...users]
		}))
	},

	getActiveUsers: () => {
		const { users } = get()
		const userRolePriority = new Map<string, number>()

		userRolePriority.set(ROLES.INNOVATOR, 0)
		userRolePriority.set(ROLES.INVESTOR, 0)
		userRolePriority.set(ROLES.MENTOR, 1)
		userRolePriority.set(ROLES.ADMIN, 2)

		const highestRole = (roles: ProjectRole[]) => {
			return roles.reduce((prev, curr) => {
				const currPriority = userRolePriority.get(curr) || 0
				if (currPriority > prev) {
					return currPriority
				}
				return prev
			}, 0)
		}

		const filtredMemberList = users.filter(
			({ status }) => status === USER_ACTIVE
		)
		filtredMemberList.sort((currUser, nextUser) => {
			const currUserHighestRole = highestRole(currUser.project_roles)
			const nextUserHighestRole = highestRole(nextUser.project_roles)
			if (currUserHighestRole === nextUserHighestRole) {
				return currUser.name.localeCompare(nextUser.name, undefined, {
					sensitivity: 'accent'
				})
			}
			return currUserHighestRole <= nextUserHighestRole ? 1 : -1
		})
		return filtredMemberList
	},

	getWaitingForApprovalUsers: () => {
		const { users } = get()
		const filtredMemberList = users.filter(
			({ status }) => status === USER_PENDING_APPROVAL
		)
		filtredMemberList.sort((currUser, nextUser) => {
			return currUser.name.localeCompare(nextUser.name, undefined, {
				sensitivity: 'accent'
			})
		})
		return filtredMemberList
	},

	getPedingApprovalUsers: () => {
		const { users } = get()
		const filtredMemberList = users.filter(
			({ status }) => status === USER_PENDING_NDA
		)
		filtredMemberList.sort((currUser, nextUser) => {
			return currUser.name.localeCompare(nextUser.name, undefined, {
				sensitivity: 'accent'
			})
		})
		return filtredMemberList
	},

	disapproveMembers: async (projectId: string, userIdList: string[]) => {
		const { users } = await ProjectsApi.disapproveMembers(projectId, userIdList)
		set(() => ({
			users: [...users]
		}))
	},

	approveMembers: async (projectId: string, userIdList: string[]) => {
		const { users } = await ProjectsApi.approveMembers(projectId, userIdList)
		set(() => ({
			users: [...users]
		}))
	},

	removeMembers: async (projectId: string, userIdList: string[]) => {
		const { users } = await ProjectsApi.removeMember(projectId, userIdList)
		set(() => ({
			users: [...users]
		}))
		return true
	},

	inviteUsers: async (projectId: string, userIdList: string[]) => {
		try {
			const { users } = await ProjectsApi.inviteUsers(projectId, userIdList)
			set(() => ({
				users: [...users]
			}))
			return true
		} catch (e) {
			return false
		}
	},

	removeAsMentor: async (projectId: string, userIdList: string[]) => {
		const { users } = await ProjectsApi.removeAsMentor(projectId, userIdList)
		set(() => ({
			users: [...users]
		}))
	},

	promoteToMentor: async (projectId: string, userIdList: string[]) => {
		const { users } = await ProjectsApi.promoteToMentor(projectId, userIdList)
		set(() => ({
			users: [...users]
		}))
	},

	getRoles(userId) {
		const { users } = get()
		return users.find((u) => u.id === userId)?.project_roles
	}
}))

export default JourneyStore
