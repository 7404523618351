import { useContext } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SignUpContext } from '..'

import {
	Box,
	Checkbox,
	Text,
	VStack,
	FormControl,
	FormErrorMessage,
	useTheme
} from '@chakra-ui/react'

import { SelectField } from 'modules/common/components/forms/hook-form-fields'
import { ButtonsRadio } from 'modules/common/components/forms/radio-card'

export const GetToKnow = () => {
	const { t } = useTranslation()

	const { control, setValue } = useFormContext()
	const {
		field,
		fieldState: { error }
	} = useController({
		control,
		name: 'strateegia_usage'
	})

	const handleChange = (name: string, value: string) => {
		setValue(name, value)
	}

	const theme = useTheme() as { colors: any }
	return (
		<Box>
			<VStack spacing={4}>
				<FormControl isInvalid={!!error}>
					<ButtonsRadio
						stackProps={{ mb: 4, mt: '-4' }}
						options={[
							{ text: t('signup:usage.options.work'), value: 'WORK' },
							{ text: t('signup:usage.options.personal'), value: 'PERSONAL' },
							{ text: t('signup:usage.options.education'), value: 'EDUCATION' }
						]}
						onChange={(e) => {
							handleChange('strateegia_usage', e)
							field.onChange(e)
						}}
					/>
					{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
				</FormControl>

				<SelectField
					fieldName='acting'
					label={t('signup:usage.ask2')}
					labelProps={{
						mb: 2,
						fontSize: 'md',
						fontWeight: 'medium',
						color: 'gray.400'
					}}
					_focus={{
						borderColor: theme.colors.input_border,
						boxShadow: `0 0 0 1px ${theme.colors.input_border}`
					}}
					placeholder={t('signup:usage.chooseAnOption')}
				>
					<option value='BUSINESS_OWNER'>
						{t('signup:usage.options2.owner')}
					</option>
					<option value='PRINCIPAL'>
						{t('signup:usage.options2.director')}
					</option>
					<option value='TEAM_LEADER'>{t('signup:usage.options2.lead')}</option>
					<option value='TEAM_MEMBER'>
						{t('signup:usage.options2.member')}
					</option>
					<option value='FREELANCER'>
						{t('signup:usage.options2.freelancer')}
					</option>
					<option value='TEACHER'>{t('signup:usage.options2.teacher')}</option>
					<option value='STUDENT'>{t('signup:usage.options2.student')}</option>
				</SelectField>

				<SelectField
					fieldName='discover_strateegia'
					label={t('signup:usage.ask3')}
					_focus={{
						borderColor: theme.colors.input_border,
						boxShadow: `0 0 0 1px ${theme.colors.input_border}`
					}}
					labelProps={{
						mb: 2,
						fontSize: 'md',
						fontWeight: 'medium',
						color: 'gray.400'
					}}
					placeholder={t('signup:usage.chooseAnOption')}
				>
					<option value='TOLD_ME'>{t('signup:usage.options3.someone')}</option>
					<option value='JOB'>{t('signup:usage.options3.work')}</option>
					<option value='FACULTY'>{t('signup:usage.options3.school')}</option>
					<option value='GOOGLE'>{t('signup:usage.options3.google')}</option>
					<option value='LINKEDIN'>
						{t('signup:usage.options3.linkedIn')}
					</option>
					<option value='YOUTUBE'>{t('signup:usage.options3.youtube')}</option>
					<option value='TWITTER'>{t('signup:usage.options3.twitter')}</option>
					<option value='OTHER'>{t('signup:usage.options3.other')}</option>
				</SelectField>
			</VStack>
			<NewsletterField />
		</Box>
	)
}

const NewsletterField = () => {
	const { t } = useTranslation()
	const { newsChecked, setNewsChecked } = useContext(SignUpContext)

	const { control, setValue } = useFormContext()
	const {
		field,
		fieldState: { error }
	} = useController({
		control,
		name: 'newsletter_accepted'
	})

	const handleChange = (name: string, value: string) => {
		setValue(name, value)
	}

	return (
		<VStack spacing={4} mt={8} alignItems='flex-start'>
			<FormControl isInvalid={!!error}>
				<Checkbox
					isChecked={newsChecked}
					onChange={(e) => {
						setNewsChecked(!newsChecked)
						handleChange('newsletter_accepted', e.target.value)
						field.onChange(e)
					}}
				>
					<Text fontSize={['xs', 'md']} fontWeight='medium' color='gray.400'>
						{t('signup:newsletter')}
					</Text>
				</Checkbox>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</VStack>
	)
}
