import { useTranslation } from 'react-i18next'

import {
	Button,
	ListItem,
	MenuItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	UnorderedList,
	UseDisclosureReturn,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'

import { ConvergencePoint } from '../../../types'
import { closeConvergencePoint } from '../../../usecases/close-convergence-point'

export const ClosePoint = ({ point }: { point: ConvergencePoint }) => {
	const disclosure = useDisclosure()
	const { t } = useTranslation()
	const toast = useToast()

	async function onClosePoint() {
		try {
			await closeConvergencePoint(point.id)
			queryClient.invalidateQueries([point.id])
		} catch (err) {
			toast({
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error',
				position: 'bottom'
			})
		}
	}

	return (
		<>
			<MenuItem onClick={disclosure.onOpen} borderRadius='md'>
				{t('buttons:closePoint')}
			</MenuItem>
			<CloseConfirmation {...disclosure} onClosePoint={onClosePoint} />
		</>
	)
}

type CloseConfirmationProps = UseDisclosureReturn & {
	onClosePoint: () => void
}

const CloseConfirmation = ({
	onClose,
	isOpen,
	onClosePoint
}: CloseConfirmationProps) => {
	const { t } = useTranslation()

	function handleDelete() {
		onClosePoint()
		onClose()
	}

	return (
		<Modal isCentered size='xl' isOpen={isOpen} onClose={onClose}>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent>
				<ModalHeader>
					{t('map:points.convergence.closeDialog.title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						{t('map:points.convergence.closeDialog.description:start')}
					</Text>
					<UnorderedList spacing={1}>
						<ListItem>
							{t('map:points.convergence.closeDialog.description:bullet1')}
						</ListItem>
						<ListItem>
							{t('map:points.convergence.closeDialog.description:bullet2')}
						</ListItem>
					</UnorderedList>
					<Text>{t('map:points.convergence.closeDialog.description:end')}</Text>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={onClose}>
						{t('buttons:cancel')}
					</Button>
					<Button
						colorScheme='custom_scheme_color_button'
						mr={3}
						onClick={handleDelete}
					>
						{t('buttons:confirm')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
