import { fetch } from 'config'

import { GenerateResponse } from '../types'

export const generateStartingPoint = (prompt: string) => {
	return fetch<GenerateResponse>({
		url: 'projects/gpt/learn',
		method: 'POST',
		data: { query: prompt }
	}).then(({ data }) => data)
}
