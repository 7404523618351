import i18n from 'i18n/i18n'
import { object, string, boolean } from 'yup'

export const getToKnowValidation = () =>
	object({
		newsletter_accepted: boolean(),
		strateegia_usage: string().required(i18n.t('errors:requiredField')),
		acting: string().required(i18n.t('errors:requiredField')),
		discover_strateegia: string().required(i18n.t('errors:requiredField'))
	})
