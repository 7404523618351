import { TenantColors } from '../types'
import mitsloanreviewCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const mitsloanreviewColors: TenantColors = {
	...mitsloanreviewCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default mitsloanreviewColors
