import defaultCustomColors from '../default/colors'

const sinergiaCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#B0027F'
	},

	secondary_color_scheme_button: {
		500: '#B0027F',
		600: '#7B1C60'
	},

	radio_color: {
		500: '#232735'
	},

	switch_scheme_color_checked: {
		500: '#232735'
	}
}

export default sinergiaCustomColors
