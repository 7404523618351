import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#0055BF',
		light: '#7EA2E3'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FEEBC8'
	}
}

export default themeColors
