import { useQuery } from 'react-query'

import Api from 'data/api'

export function useDivergencePoint(pointId: string) {
	return useQuery(
		['divergencePoint', pointId],
		() => Api.Projects.getDivergencePointById(pointId),
		{ cacheTime: 0 }
	)
}
