import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	Button,
	HStack,
	Icon,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Stack,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'
import { format } from 'date-fns'

import { IconMonitorPoint } from 'modules/common/components/icons/st-monitor-point'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Position } from 'modules/map/modules/map-canvas/types'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { useCreateMonitorPoint } from '../../hooks/use-create-monitor-point'
import { FormDatePicker } from '../../shared/components/form-date-picker'
import { FormInput } from '../../shared/components/form-input'
import { FormRadioGroup } from '../../shared/components/form-radio-group'
import { FormSelect } from '../../shared/components/form-select'
import { FormTextarea } from '../../shared/components/form-textarea'
import {
	MonitorPoint,
	MonitorType,
	QuantitativeMonitorPoint,
	QuantitativeMonitorPointFlow,
	QuantitativeMonitorPointType
} from '../../types/monitor-point'
import { getMonitorPointSchema } from '../../validators/monitor-point'

type MonitorPointFormValues = QuantitativeMonitorPoint & Position

export const MonitorPointForm = () => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { id: journeyId = '', mapId = '' } = useParams()
	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))
	const { mutate: createMonitorPoint, isLoading: isCreatingMonitorPoint } =
		useCreateMonitorPoint()
	const {
		state: { selectedPoint }
	} = useMapStore()
	const navigate = useNavigate()
	const toast = useToast()
	const { t } = useTranslation()

	const monitorPointSchema = getMonitorPointSchema()
	const todayDate = format(new Date(), 'yyyy-MM-dd')

	const {
		handleSubmit,
		register,
		watch,
		formState: { errors }
	} = useForm<MonitorPointFormValues>({
		defaultValues: {
			position: selectedPoint?.position,
			name: '',
			description: '',
			monitor_type: MonitorType.QUANTITATIVE,
			conclusion_date: todayDate
		},
		resolver: yupResolver(monitorPointSchema)
	})

	const indicatorType = watch('monitor_type')
	const isQuantitativeMode = indicatorType === MonitorType.QUANTITATIVE

	const onSubmit = (data: MonitorPointFormValues) => {
		createMonitorPoint(
			{ mapId: mapId as string, data },
			{
				onSuccess: (monitorPoint: MonitorPoint) => {
					const socketPayload = {
						id: monitorPoint.id,
						position: monitorPoint.position,
						point_type: 'MONITOR'
					}
					emit('ADD', socketPayload)

					navigate(`point/${monitorPoint.id}`)
					toast({ title: t('divergencePoint:createdPoint'), status: 'success' })
				},
				onError: (error) => {
					console.log('error on createMonitorPoint', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)
	}

	return (
		<>
			<ModalCloseButton color='gray.400' mt={['9px', 4]} mr={[2, 4]} />
			<ModalHeader px={[3, 7]} pt={[3, 7]}>
				<HStack alignItems='flex-start'>
					<Icon
						mt={[2, 0]}
						as={IconMonitorPoint}
						color='green.400'
						fontSize='5xl'
					/>
					<Box>
						<HStack>
							<Text mt={[2, 0]} fontWeight='semibold' fontSize={['lg', 'xl']}>
								{t('map:points:monitor:title')}
							</Text>
						</HStack>
						<Text color='gray.400' fontWeight='medium' fontSize='md'>
							{t('map:points:monitor:description')}
						</Text>
					</Box>
				</HStack>
			</ModalHeader>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<ModalBody px={isMobile ? 4 : 8}>
					<Stack gap={4}>
						<FormInput
							label={t('map:points:monitor:create:name:label')}
							name='name'
							placeholder={t('map:points:monitor:create:name:placeholder')}
							maxLength={100}
							register={register}
							errors={errors}
							isRequired
						/>
						<FormTextarea
							label={t('map:points:monitor:create:description:label')}
							name='description'
							maxLength={1000}
							errors={errors}
							placeholder={t(
								'map:points:monitor:create:description:placeholder'
							)}
							register={register}
							isRequired
						/>
						<FormRadioGroup
							label={t('map:points:monitor:create:type:label')}
							name='monitor_type'
							isRequired
							options={[
								{
									value: MonitorType.QUANTITATIVE,
									label: t(
										'map:points:monitor:create:type:options:quantitative:label'
									),
									description: t(
										'map:points:monitor:create:type:options:quantitative:description'
									)
								},
								{
									value: MonitorType.QUALITATIVE,
									label: t(
										'map:points:monitor:create:type:options:qualitative:label'
									),
									description: t(
										'map:points:monitor:create:type:options:qualitative:description'
									)
								}
							]}
							register={register}
						/>

						{isQuantitativeMode && (
							<Stack
								direction={isMobile ? 'column' : 'row'}
								spacing={4}
								width='100%'
							>
								<FormInput
									label={t(
										'map:points:monitor:create:type:options:quantitative:goal:label'
									)}
									name='goal'
									type='number'
									errors={errors}
									placeholder={t(
										'map:points:monitor:create:type:options:quantitative:goal:placeholder'
									)}
									register={register}
									isRequired
								/>
								<FormSelect
									label={t(
										'map:points:monitor:create:type:options:quantitative:flow:label'
									)}
									withTip
									tip={t(
										'map:points:monitor:create:type:options:quantitative:flow:tip'
									)}
									name='flow'
									placeholder={t(
										'map:points:monitor:create:type:options:quantitative:flow:placeholder'
									)}
									options={[
										{
											value: QuantitativeMonitorPointFlow.INCREASING,
											label: t(
												'map:points:monitor:create:type:options:quantitative:flow:options:increasing'
											)
										},
										{
											value: QuantitativeMonitorPointFlow.DECREASING,
											label: t(
												'map:points:monitor:create:type:options:quantitative:flow:options:decreasing'
											)
										}
									]}
									register={register}
									errors={errors}
									isRequired
								/>
								<FormSelect
									label={t(
										'map:points:monitor:create:type:options:quantitative:type:label'
									)}
									withTip
									tip={t(
										'map:points:monitor:create:type:options:quantitative:type:tip'
									)}
									placeholder={t(
										'map:points:monitor:create:type:options:quantitative:type:placeholder'
									)}
									name='type'
									options={[
										{
											value: QuantitativeMonitorPointType.CUMULATIVE,
											label: t(
												'map:points:monitor:create:type:options:quantitative:type:options:cumulative'
											)
										},
										{
											value: QuantitativeMonitorPointType.RECURRING,
											label: t(
												'map:points:monitor:create:type:options:quantitative:type:options:recurring'
											)
										}
									]}
									register={register}
									errors={errors}
									isRequired
								/>
							</Stack>
						)}

						<FormDatePicker
							label={t('map:points:monitor:create:date:label')}
							name='conclusion_date'
							errors={errors}
							minDate={todayDate}
							register={register}
						/>
					</Stack>
				</ModalBody>
				<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<Button
						type='submit'
						colorScheme='pink'
						fontSize='sm'
						fontWeight='semibold'
						isLoading={isCreatingMonitorPoint}
					>
						{t('map:points:monitor:create:actions:submit')}
					</Button>
				</ModalFooter>
			</form>
		</>
	)
}
