import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useToast,
	VStack
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import loading from 'modules/common/assets/img/loading.svg'
import { ENCOURAGE_DEBATE_RESPONSE_CREATION_COST } from 'modules/constants'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { generateStartingPoint } from 'modules/map/modules/starting-point/usecases/generate-class'
import UseCommentsStore from 'modules/map/store/use-comments-store'
import { User } from 'modules/user/types'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import SendIcon from '../../../chat/send.svg'

type NewType = {
	isOpen: boolean
	onClose: () => void
	divergencePointId: string
	questionId: string
	summary: string
	journeyId: string
	user: User
}

export const EncourageDebate = ({
	isOpen,
	onClose,
	divergencePointId,
	questionId,
	summary,
	journeyId,
	user
}: NewType) => {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(false)
	const [comment, setComment] = useState('')
	const { addComment, addCommentState } = UseCommentsStore()
	const toast = useToast()
	const { emit } = useSocket(divergencyPointChannel({ projectId: journeyId }))
	const { setQuestionOpenedIndex } = UseCommentsStore()

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const createResponse = async () => {
		setIsLoading(true)
		const gptResult = await generateStartingPoint(
			`a partir do resumo abaixo, referente a um debate em grupo, escreva, como se você fosse um professor dando aula, um comentário de até 100 palavras sobre o que você acha que falta ser discutido sobre o tema, resumo: ${summary}`
		)
		setIsLoading(false)
		setComment(gptResult.choices[0].message.content)
		await consumeCredits(ENCOURAGE_DEBATE_RESPONSE_CREATION_COST)
		updateUserCredits(
			(user?.credits || 0) - ENCOURAGE_DEBATE_RESPONSE_CREATION_COST
		)
	}

	useEffect(() => {
		createResponse()
	}, [])

	const handleComment = async () => {
		const savedComment = await addComment(
			divergencePointId,
			questionId,
			comment
		)
		addCommentState(savedComment)
		const commentInfo = {
			divergence_point_id: savedComment.divergence_point_id,
			question_id: savedComment.question_id,
			parent_id: savedComment.parent_id,
			id: savedComment.id
		}
		emit('ADD', commentInfo)
		toast({
			title: t('divergencePoint:reply_created'),
			isClosable: true,
			status: 'success'
		})
		onClose()
		setQuestionOpenedIndex(0)
	}

	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
		}
	}, [comment])

	function handleKeyDown(e: any) {
		e.target.style.height = 'inherit'
		e.target.style.height = `${e.target.scrollHeight}px`
		setComment(e.target.value)
	}

	const handleClose = () => {
		onClose()
		setComment('')
	}

	return (
		<Modal
			closeOnOverlayClick={false}
			size='xl'
			isCentered
			isOpen={isOpen}
			onClose={handleClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>
					<Text>{t('maturity:encourage.title')}</Text>
				</ModalHeader>
				<ModalBody>
					<VStack w='full'>
						{isLoading && !comment ? (
							<Box py={20} textAlign='center' w='full'>
								<Flex placeContent='center'>
									<Image
										css={{
											animationFillMode: 'both'
										}}
										w={12}
										animation='loading 1.5s linear infinite alternate'
										src={loading}
									/>
								</Flex>
							</Box>
						) : (
							<Textarea
								p={8}
								ref={textareaRef}
								onChange={handleKeyDown}
								defaultValue={comment}
							/>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter mb={2}>
					<VStack alignItems='flex-end' w='full'>
						<Button
							onClick={handleComment}
							mt={4}
							colorScheme='custom_scheme_color_button'
							leftIcon={<Image src={SendIcon} />}
							isDisabled={comment.length >= 3000 || !comment.length}
							// isLoading={isLoading}
						>
							{t('divergencePoint:send_answer')}
						</Button>
					</VStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
