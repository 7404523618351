import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from '@chakra-ui/react'

import { ANALYSIS_CREATION_COST } from 'modules/constants'
import { QuestionAnalysis } from 'modules/map/types'
import { createQuestionAnalysis } from 'modules/map/usecases/create-question-analysis'
import { useMe } from 'modules/user/hooks'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import { questionKeys } from '../../../query/index'

type Props = {
	questionId: string
	setButton?: Dispatch<SetStateAction<boolean>>
}

export const useAddQuestionAnalysisMutation = ({
	setButton,
	questionId
}: Props) => {
	const queryClient = useQueryClient()
	const { user } = useMe()
	const { t } = useTranslation()
	const toast = useToast({
		position: 'bottom',
		isClosable: true,
		variant: 'solid'
	})

	const addToList = (questionAnalysis: QuestionAnalysis) => {
		const [Query] = queryClient
			.getQueryCache()
			.findAll(questionKeys.questionSummary(questionId))
		if (!Query) return

		Query.setData(() => questionAnalysis)
	}

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const mutation = useMutation(createQuestionAnalysis, {
		onSuccess: async (analysis) => {
			addToList(analysis)
			await consumeCredits(ANALYSIS_CREATION_COST)
			updateUserCredits((user?.credits || 0) - ANALYSIS_CREATION_COST)
		},
		onError: () => {
			toast({ title: t('errors:summaryGPT.error'), status: 'info' })
			if (setButton) setButton(false)
		}
	})

	return mutation
}
