import React from 'react'

import Api from 'data/api'

import { FullPageLayout } from 'modules/common/templates'
import { Login } from 'modules/login'

export const MakeLogin: React.FC<{ previousURL: string }> = ({
	previousURL
}) => {
	return (
		<FullPageLayout>
			<Login previousURL={previousURL} authentication={Api.Authentication} />
		</FullPageLayout>
	)
}
