import { TenantColors } from '../types'
import bateriasmouraCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const bateriasmouraColors: TenantColors = {
	...bateriasmouraCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default bateriasmouraColors
