import { useTranslation } from 'react-i18next'

import * as yup from 'yup'

export const assistantForm = {
	SPECIALIZED_KNOWLEDGE_MAX_LENGTH: 6000,
	FORMATION_MAX_LENGTH: 255,
	NAME_MAX_LENGTH: 100,
	ETHNICITY_MAX_LENGTH: 100,
	GENDER_MAX_LENGTH: 100
} as const

export const personalAssistantSchema = (step: number, editMode: boolean) => {
	const { t } = useTranslation('assistants')

	return yup.object().shape({
		name: yup
			.string()
			.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 }))
			.max(assistantForm.NAME_MAX_LENGTH)
			.required(t('errors:assistants.form.requiredField')),
		years_old: yup.string().required(t('errors:assistants.form.requiredField')),
		behavioral_profile_id: yup
			.string()
			.required(t('errors:assistants.form.requiredField')),
		narrative_style_id: yup
			.string()
			.required(t('errors:assistants.form.requiredField')),
		gender: yup.string().max(assistantForm.GENDER_MAX_LENGTH),
		ethnicity: yup.string().max(assistantForm.ETHNICITY_MAX_LENGTH),
		formation:
			step === 2 || editMode
				? yup
						.string()
						.required(t('errors:assistants.form.requiredField'))
						.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 }))
						.max(assistantForm.FORMATION_MAX_LENGTH)
				: yup.string(),
		specialized_knowledge: yup
			.string()
			.max(assistantForm.SPECIALIZED_KNOWLEDGE_MAX_LENGTH)
	})
}
