import { ComponentStyleConfig } from '@chakra-ui/react'

const Accordion: ComponentStyleConfig = {
	baseStyle: {
		container: {
			borderTopWidth: 0,
			border: 0
		}
	}
}

export default Accordion
