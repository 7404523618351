import { QueryFilter } from 'modules/common/types'
import { makeKey } from 'modules/common/utils/make-key'

export const journeyKeys = {
	all: ['journey'],
	lists: () => [...journeyKeys.all, 'lists'],
	list: () => [...journeyKeys.lists(), 'list'],

	my: (filters?: QueryFilter) =>
		makeKey([...journeyKeys.lists(), 'my', filters]),
	explore: (filters?: QueryFilter) =>
		makeKey([...journeyKeys.list(), 'explore', filters]),
	recent: () => [...journeyKeys.list(), 'DESC'],
	labs: () => [...journeyKeys.list(), 'labs', 'with-projects'],
	details: () => [...journeyKeys.all, 'detail'],
	detail: (id: string) => [...journeyKeys.details(), id],
	map: (id: string) => [...journeyKeys.all, id]
} as const
