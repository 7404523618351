import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	Button,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import freeFirstAssistantChatImageEn from 'modules/assistants/assets/images/free-assistant-chat-en.png'
import freeFirstAssistantChatImageEs from 'modules/assistants/assets/images/free-assistant-chat-es.png'
import freeFirstAssistantChatImagePt from 'modules/assistants/assets/images/free-assistant-chat-pt.png'
import { useFreeChatStore } from 'modules/assistants/store/free-chat-store'
import { MyAssistants } from 'modules/home/components/my-assistants'

import { AssistantStartConfirmButton } from '../assistant-start-confirm-modal'

export const AssistantChatFreeModal = ({
	isOpen,
	onClose,
	assistantId,
	handleCreateChatRoom
}: {
	isOpen: boolean
	onClose: () => void
	assistantId: string | null
	handleCreateChatRoom?: (assistantId: string) => void
}) => {
	const { t } = useTranslation()
	const [mode, setMode] = useState<'view' | 'list'>('view')
	const { setOnExperimentNow, isLoading, setOnClose } = useFreeChatStore()
	const lang = localStorage.getItem('strateegiaLang')
	const location = useLocation()
	const navigate = useNavigate()

	const freeChatImages = {
		en: freeFirstAssistantChatImageEn,
		pt: freeFirstAssistantChatImagePt,
		es: freeFirstAssistantChatImageEs
	} as const

	const toggleMode = () => {
		setMode(mode === 'view' ? 'list' : 'view')
	}

	const handleModalClose = () => {
		setMode('view')
		onClose()
		setOnExperimentNow(false)
		sendGAEvent('assistant_chat_free_close')
		sessionStorage.removeItem('free_chat_is_open')

		if (location.state.assistant) {
			navigate('/dashboard', { state: { assistant: null } })
		}
	}

	return (
		<Modal
			size={mode === 'view' ? '3xl' : ['full', '4xl']}
			isOpen={isOpen}
			onClose={handleModalClose}
			isCentered
			closeOnOverlayClick={false}
		>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent
				pt={2}
				borderRadius='xl'
				transition='max-width 0.3s'
				maxWidth={mode === 'view' ? '3xl' : '4xl'}
			>
				<ModalHeader fontWeight='semibold' fontSize='lg'>
					{mode === 'view' ? (
						t('assistants:modal.free-chat.view.title')
					) : (
						<>
							{t('assistants:modal.free-chat.list.title')}
							<Text as='span' display={['block', 'inline']} ml={[0, 1]}>
								{t('assistants:modal.free-chat.list.start-chat')}
							</Text>
						</>
					)}
				</ModalHeader>
				<ModalCloseButton mt={[mode === 'view' ? 3 : 4, 3]} mr={3} />
				<ModalBody
					overflow='auto'
					maxHeight={['calc(100vh - 127px)', '470px']}
					pr={6}
				>
					{mode === 'view' ? (
						<>
							<Image
								src={freeChatImages[lang as keyof typeof freeChatImages]}
							/>
							<Text mt={3} fontWeight='500' mb={0.5}>
								{t('assistants:modal.free-chat.view.description')}
							</Text>
						</>
					) : (
						<MyAssistants columnMode={true} />
					)}
				</ModalBody>
				<ModalFooter>
					{mode === 'view' && !location.state.assistant && (
						<Button
							w='100%'
							fontWeight='semibold'
							colorScheme='pink'
							isLoading={isLoading[assistantId ?? '']}
							onClick={() => {
								sendGAEvent('assistant_chat_free_experiment_button')
								sessionStorage.removeItem('free_chat_is_open')

								if (assistantId) {
									setOnClose(onClose)
									handleCreateChatRoom?.(assistantId)
								} else {
									toggleMode()
									setOnExperimentNow(true)
								}
							}}
						>
							{t('assistants:modal.free-chat.view.experiment')}
						</Button>
					)}
					{mode === 'view' && location.state.assistant && (
						<AssistantStartConfirmButton
							assistantId={location.state.assistant.id}
							callback={() => {
								setOnClose(onClose)
								sessionStorage.removeItem('free_chat_is_open')
							}}
						/>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
