import { useTranslation } from 'react-i18next'
import { RiRobot2Line } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'

import { Accordion, Box, Center, Fade, Icon } from '@chakra-ui/react'

import { internationalization } from 'config'

import { IconStApplets, IconStHome } from 'modules/common/components/icons'
import { NavBarLink } from 'modules/common/components/nav-bar-link'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { useTypeUser } from 'modules/common/contexts/user-type-context'
import { hasPermissionInList } from 'modules/common/utils/has-permission-in-list'

import { NavItem } from './nav-item'
import { StrateegiaBlog } from './strateegia-blog'

type Props = {
	isIconMode?: boolean
	handleLinkClick: () => void
	showContent: boolean
	handleClick?: () => void
	currentIndex: number
	expandedIndex: number
	itemsShrink: any[]
	items: any[]
	handleAccordionChange: (index: number) => void
}

export const StrateegiaOptions = ({
	isIconMode,
	handleLinkClick,
	showContent,
	handleClick,
	currentIndex,
	expandedIndex,
	handleAccordionChange,
	itemsShrink,
	items
}: Props) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const { tenant } = useTenant()
	const { userType } = useTypeUser()

	const lang = internationalization.get()

	if (isIconMode) {
		return (
			<Box flex={1} pt={8}>
				<Accordion allowToggle defaultIndex={currentIndex}>
					<Center p={2} flexDirection='column' pl={4} pr={3.5}>
						<NavBarLink
							leftIcon={
								<Icon
									as={IconStHome}
									strokeWidth={0.6}
									fontSize='large'
									mr={2}
								/>
							}
							to='/dashboard'
							onClick={handleLinkClick}
							isIconMode={isIconMode}
							buttonName={t('common:navbar.home')}
						></NavBarLink>
					</Center>
					{itemsShrink.map((item, index) => (
						<Center
							key={`shrink-${item.path}`}
							p={2}
							flexDirection='column'
							pl={4}
							pr={3.5}
						>
							<NavBarLink
								leftIcon={
									<Icon
										as={item.icon}
										strokeWidth={0.6}
										fontSize='large'
										mr={2}
									/>
								}
								to={item.path}
								onClick={handleClick}
								isActive={`${pathname}`.includes(item.path) || item?.match?.()}
								isIconMode={isIconMode}
								buttonName={item.name}
							></NavBarLink>
						</Center>
					))}
				</Accordion>

				<Center p={2} flexDirection='column' pl={4} pr={3.5}>
					<NavBarLink
						leftIcon={
							<Icon
								as={RiRobot2Line}
								strokeWidth={0.6}
								fontSize='large'
								mr={2}
							/>
						}
						to='/dashboard/assistants'
						onClick={handleLinkClick}
						isIconMode={isIconMode}
						buttonName={t('assistants:page.title')}
					></NavBarLink>
				</Center>

				{hasPermissionInList('navbar_options', 'applets', userType, tenant) && (
					<Center p={2} flexDirection='column' pl={4} pr={3.5}>
						<NavBarLink
							leftIcon={
								<Icon
									as={IconStApplets}
									strokeWidth={0.6}
									fontSize='large'
									mr={2}
								/>
							}
							to='/dashboard/applets'
							onClick={handleLinkClick}
							isIconMode={isIconMode}
							buttonName='applets'
						></NavBarLink>
					</Center>
				)}

				{hasPermissionInList('navbar_options', 'blog', userType, tenant) && (
					<StrateegiaBlog
						isIconMode={isIconMode}
						lang={lang}
						handleLinkClick={handleLinkClick}
					/>
				)}
			</Box>
		)
	} else
		return (
			<Fade in={showContent} unmountOnExit>
				<Box flex={1} pt={8}>
					<Accordion
						allowToggle
						defaultIndex={currentIndex}
						index={expandedIndex}
						onChange={handleAccordionChange}
					>
						<Center p={2} flexDirection='column' pl={8} pr={10}>
							<NavBarLink
								leftIcon={
									<Icon
										as={IconStHome}
										strokeWidth={0.6}
										fontSize='large'
										mr={2}
									/>
								}
								to='/dashboard'
								onClick={handleLinkClick}
							>
								{t('common:navbar.home')}
							</NavBarLink>
						</Center>
						{items.map((item) => (
							<NavItem
								key={item.path}
								icon={item.icon}
								text={item.text}
								handleClick={handleClick}
								subItems={item.subItems}
							/>
						))}
					</Accordion>

					<Center p={2} flexDirection='column' pl={8} pr={10}>
						<NavBarLink
							leftIcon={
								<Icon
									as={RiRobot2Line}
									strokeWidth={0.6}
									fontSize='large'
									mr={2}
								/>
							}
							to='/dashboard/assistants'
							onClick={handleLinkClick}
						>
							{!isIconMode && t('assistants:page.title')}
						</NavBarLink>
					</Center>

					{hasPermissionInList(
						'navbar_options',
						'applets',
						userType,
						tenant
					) && (
							<Center p={2} flexDirection='column' pl={8} pr={10}>
								<NavBarLink
									leftIcon={
										<Icon
											as={IconStApplets}
											strokeWidth={0.6}
											fontSize='large'
											mr={2}
										/>
									}
									to='/dashboard/applets'
									onClick={handleLinkClick}
								>
									{!isIconMode && 'applets'}
								</NavBarLink>
							</Center>
						)}

					{hasPermissionInList('navbar_options', 'blog', userType, tenant) && (
						<StrateegiaBlog
							isIconMode={isIconMode}
							lang={lang}
							handleLinkClick={handleLinkClick}
						/>
					)}
				</Box>
			</Fade>
		)
}
