import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiCaretRight, BiLink } from 'react-icons/bi'

import { Flex, Text, Button, Icon } from '@chakra-ui/react'

import { PointInfos } from 'modules/map/store/use-comments-store'
import { Tool } from 'modules/tools/types/tool-model'

export const AboutToolMobile: React.FC<{
	tool: Tool
	setNotIsSideBar: () => void
	setRefs: () => void
	setLinks: () => void
	pointInfo: PointInfos
}> = ({ tool, setNotIsSideBar, setRefs, setLinks, pointInfo }) => {
	const { t } = useTranslation()

	return (
		<Flex flexDirection='column' h='xl' overflow='hidden'>
			<Flex
				flexDirection='column'
				overflowY='auto'
				minH='38%'
				maxH='38%'
				px={8}
				py={8}
			>
				<Text fontSize='md' color='gray.300' fontWeight='600'>
					{t('divergencePoint:about_kit:description')}
				</Text>
				<Text fontSize='md' mt={2}>
					{tool.description || pointInfo.introduction}
				</Text>
			</Flex>
			<Button
				onClick={() => {
					setNotIsSideBar()
					setRefs()
				}}
				background={'transparent'}
				minH='15%'
				maxH='15%'
				paddingX={10}
				paddingY={12}
				display='flex'
				justifyContent='space-between'
				boxShadow='xs'
			>
				<Flex alignItems='center'>
					<Icon as={BiLink} fontSize='2xl' />
					<Text fontWeight='500' color='gray.1' ml={2}>
						{t('divergencePoint:about_kit:references')}
					</Text>
				</Flex>
				<Icon as={BiCaretRight} fontSize='xl' />
			</Button>
			<Button
				onClick={() => {
					setNotIsSideBar()
					setLinks()
				}}
				background={'transparent'}
				paddingX={10}
				paddingY={12}
				display='flex'
				justifyContent='space-between'
				boxShadow='sm'
				minH='15%'
				maxH='15%'
			>
				<Flex alignItems='center'>
					<Icon as={BiLink} fontSize='2xl' />
					<Text fontWeight='500' color='gray.1' ml={2}>
						{t('divergencePoint:about_kit:links')}
					</Text>
				</Flex>
				<Icon as={BiCaretRight} fontSize='xl' />
			</Button>
		</Flex>
	)
}
