import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Flex,
	HStack,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack
} from '@chakra-ui/react'

import { format } from 'date-fns'

import { createId } from 'modules/common/utils/create-id'
import { formatToLocalDateTime } from 'modules/common/utils/format-to-local-date-time'
import { CreateConvergencePoint } from 'modules/map/modules/convergence-point/components/create'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { QuestionAnalysis } from 'modules/map/types'

import { HighMaturity, LowMaturity } from './components/maturity'
import { Options } from './components/question-analysis-options'

type CardProps = {
	hasPrivileges: boolean
	pointId: string
	questionId: string
	userLanguage?: string
	questionAnalysisData: QuestionAnalysis
	journeyId: string
}

export const QuestionAnalysisCard = (props: CardProps) => {
	const {
		questionId,
		userLanguage,
		hasPrivileges,
		questionAnalysisData,
		pointId,
		journeyId
	} = props
	const { t } = useTranslation()

	const [showConvergence, setShowConvergence] = useState(false)
	const [showMotivate, setShowMotivate] = useState(false)
	const convergenceDisclosure = useDisclosure()

	const isMobile = useBreakpointValue({ base: true, md: false })
	const createdInfos = new Date(
		formatToLocalDateTime(questionAnalysisData.created_at)
	)

	const languageHourFormat = userLanguage === 'EN_US' ? 'H:mm a' : 'HH:mm'
	const languageDateFormat = userLanguage === 'EN_US' ? 'MM/dd/yy' : 'dd/MM/yy'
	const time = format(createdInfos, languageHourFormat)
	const dateAnalysis = format(createdInfos, languageDateFormat)

	const ref = useRef<HTMLTextAreaElement | null>(null)
	const color = useMapStore(({ state }) => state.selectedPoint?.color)

	const todayDate = format(new Date(), 'yyyy-MM-dd')

	const highMaturityValues = {
		date: todayDate,
		hour: '',
		questions: questionAnalysisData.propositions.map((proposition) => ({
			id: createId(),
			text: t('maturity:automaticConvergence.alternativeQuestions'),
			options: [{ id: createId(), text: proposition }]
		}))
	}

	useEffect(() => {
		ref.current?.focus()
	}, [])

	useEffect(() => {
		setShowConvergence(questionAnalysisData.maturity.rating >= 50)
		setShowMotivate(questionAnalysisData.maturity.rating < 50)
	}, [questionAnalysisData.summary])

	return (
		<Box px={4} pt={2} overflowY='scroll'>
			{showConvergence && hasPrivileges && (
				<HighMaturity openConvergence={convergenceDisclosure.onOpen} />
			)}
			<Flex
				w='full'
				mt='2'
				px='1.125rem'
				pt='1.125rem'
				flexDirection='column'
				borderRadius='8'
				border='1px solid'
				borderColor={color}
				backgroundColor='#fff'
			>
				<HStack mb={4} justify='space-between'>
					<Text fontSize='sm' fontWeight='semibold'>
						{t('summary:card.title')}
					</Text>
					<HStack>
						<Text
							fontSize={isMobile ? '10px' : 'xs'}
							fontWeight='normal'
							color='gray.300'
						>
							{`${dateAnalysis} - ${time}`}
						</Text>
						{hasPrivileges && (
							<Options
								questionId={questionId}
								analysisId={questionAnalysisData.id}
								pointId={pointId}
							/>
						)}
					</HStack>
				</HStack>
				<VStack mb={6} alignItems={'flex-start'}>
					<Text fontWeight={'semibold'}>
						{t('divergencePoint:resume.summary')}
					</Text>
					<Text>{questionAnalysisData.summary}</Text>
					<Text fontWeight={'semibold'}>
						{t('divergencePoint:resume.roles')}
					</Text>
					<Text whiteSpace={'pre-line'}>
						{t('divergencePoint:resume.motivators')}:{'\n'}
						{questionAnalysisData.roles.motivators.map(
							(motivador: string) => `- ${motivador}\n`
						)}
						{'\n'}
						{t('divergencePoint:resume.critics')}:{'\n'}
						{questionAnalysisData.roles.critics.map(
							(critico: string) => `- ${critico}\n`
						)}
					</Text>
					<Text fontWeight={'semibold'}>
						{t('divergencePoint:resume.propositions')}
					</Text>
					<Text whiteSpace={'pre-line'}>
						{questionAnalysisData.propositions.map(
							(proposta: string) => `- ${proposta}\n`
						)}
					</Text>
					<Text fontWeight={'semibold'}>
						{t('divergencePoint:resume.debate_maturity')}
					</Text>
					<Text whiteSpace={'pre-line'}>
						{t('divergencePoint:resume.rating')}:{' '}
						{questionAnalysisData.maturity.rating}
						{'\n'}
						{t('divergencePoint:resume.quality_of_discussion')}:{' '}
						{questionAnalysisData.maturity.quality_of_discussion}
						{'\n'}
						{t('divergencePoint:resume.mutual_respect')}:{' '}
						{questionAnalysisData?.maturity.mutual_respect}
						{'\n'}
						{t('divergencePoint:resume.flexibility_and_openness')}:{' '}
						{questionAnalysisData?.maturity.flexibility_and_openness}
						{'\n'}
						{t('divergencePoint:resume.diversity_of_perspectives')}:{' '}
						{questionAnalysisData?.maturity.diversity_of_perspectives}
						{'\n'}
						{t('divergencePoint:resume.focus_on_topic')}:{' '}
						{questionAnalysisData?.maturity.focus_on_topic}
					</Text>
				</VStack>
			</Flex>
			<CreateConvergencePoint
				disclosure={convergenceDisclosure}
				highMaturityValues={highMaturityValues}
			/>
			{questionAnalysisData && showMotivate && hasPrivileges && (
				<LowMaturity
					journeyId={journeyId}
					summary={questionAnalysisData.summary}
					pointId={pointId}
					questionId={questionId}
				/>
			)}
		</Box>
	)
}
