import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#131D4C',
		light: '#BDD9FF'
	},

	secondary: {
		dark: '#5FC2E4',
		light: '#AFE1F2'
	}
}

export default themeColors
