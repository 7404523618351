import React from 'react'

import { HStack, useRadio, Box, useRadioGroup } from '@chakra-ui/react'

const CreditsOptions = ['50', '100', '500', '1000'] // default options

type InputProps = {
	name: string
	value: string
	isDisabled?: boolean
	options?: typeof CreditsOptions // question mark indicates this prop is optional
	radioSize?: 'sm' | 'md' | 'lg'
	onChange: (credits: string) => void
}

export const InputCredits = ({
	isDisabled,
	options = CreditsOptions,
	radioSize,
	...props
}: InputProps) => {
	// if options prop is not provided, default CreditsOptions will be used
	const { getRootProps, getRadioProps } = useRadioGroup(props)
	const group = getRootProps()

	return (
		<HStack {...group} wrap='wrap'>
			{options.map((value) => {
				const radio = getRadioProps({ value, disabled: isDisabled })
				return <RadioCredits key={value} radioSize={radioSize} {...radio} />
			})}
		</HStack>
	)
}

type Props = ReturnType<typeof useRadioGroup>
type RadioPropsReturn = ReturnType<Props['getRadioProps']>

const RadioCredits: React.FC<
	RadioPropsReturn & { radioSize?: 'sm' | 'md' | 'lg' }
> = ({ radioSize, ...props }) => {
	const { getInputProps, getCheckboxProps } = useRadio(props)
	const input = getInputProps()
	const checkbox = getCheckboxProps()

	let paddingY, paddingX
	switch (radioSize) {
		case 'sm':
			paddingY = 1
			paddingX = 2
			break
		case 'lg':
			paddingY = 3
			paddingX = 12
			break
		default: // medium size
			paddingY = 2
			paddingX = 4
			break
	}

	return (
		<Box as='label' cursor='pointer'>
			<Box as='input' {...input} />
			<Box
				{...checkbox}
				bg='none'
				py={paddingY}
				px={paddingX}
				borderRadius='full'
				bgColor='gray.180'
				_checked={{
					borderWidth: '2px',
					borderColor: 'gray.300',
					shadow: 'base',
					backgroundColor: 'blue.50'
				}}
			>
				{props.value}
			</Box>
		</Box>
	)
}
