import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useDisclosure } from '@chakra-ui/react'

import { sessionManager } from 'config/session-manager'

import { useCheckFreeFirstChatRoom } from 'modules/assistants/hooks/chat-room/use-check-free-first-chat-room'

export const useAssistantFreeChat = () => {
	const location = useLocation()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const isDashboardRoute = location.pathname.startsWith('/dashboard')
	const hasSession = sessionManager.hasSession()

	const { data: hasFreeAssistantChat } = useCheckFreeFirstChatRoom(
		isDashboardRoute && hasSession
	)

	location.state = { ...location.state, hasFreeAssistantChat }

	const hasAnActiveAssistantLink = !!location.state?.assistant?.id

	const checkFirstLoginOfDay = () => {
		const today = new Date().toDateString()
		const lastLoginDate = localStorage.getItem('lastLoginDate')

		if (lastLoginDate !== today) {
			localStorage.setItem('lastLoginDate', today)
			return true
		}
		return false
	}

	useEffect(() => {
		if (
			hasFreeAssistantChat &&
			checkFirstLoginOfDay() &&
			!hasAnActiveAssistantLink
		) {
			onOpen()
			sessionStorage.setItem('free_chat_is_open', 'true')
		}
	}, [hasAnActiveAssistantLink, hasFreeAssistantChat, onOpen])

	return {
		isOpenAssistantChatFree: isOpen,
		onCloseAssistantChatFree: onClose,
		hasFreeAssistantChat
	}
}
