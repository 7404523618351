import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChat, BiComment } from 'react-icons/bi'
import { Link } from 'react-router-dom'

import {
	Box,
	Button,
	Collapse,
	Flex,
	Icon,
	Skeleton,
	Text,
	useBreakpointValue,
	useToast,
	VStack
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import useSocket from 'config/socket/useSocket'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import Hyperlink from 'modules/common/components/hyperlink'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import useJourneyStore from 'modules/journeys/store/use-journey-store'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { useDivergenceStore } from 'modules/map/store/divergence-store'

import useCommentsStore, { Comments } from '../../store/use-comments-store'
import { AnonymAvatar } from '../view-divergence-point/anonym-mode/anonym-avatar'
import { InputComment } from './input-comment'
import { LikeButton } from './like-button'
import { ReplyCard } from './reply-card'

type Props = {
	comments: Comments
	hasDeletePrivilege: boolean
	userName: string
	userId: string
	userLanguage: string
	onCollapseLastComment: (height: number, collapsed: boolean) => void
	journeyId: string
	username: string
	userAvatarUrl?: string
	hasIntro?: boolean
}

export const CommentCard: React.FC<Props> = ({
	comments,
	hasDeletePrivilege,
	userName,
	userId,
	userLanguage,
	onCollapseLastComment,
	journeyId,
	username,
	userAvatarUrl,
	hasIntro = false
}) => {
	const { isAnonym } = useDivergenceStore()
	const commentsCount = comments.replies.length | 0
	const authorName = comments.author.name
	const tone = isAnonym ? 800 : 500
	const useString = useStringToColor({ text: authorName }, [tone])[1]
	const { deleteComment, deleteCommentState } = useCommentsStore()
	const { questions, questionOpenedIndex } = useCommentsStore()
	const toast = useToast()
	const { t } = useTranslation()
	const boxCommentRef = useRef<HTMLDivElement | null>(null)
	const [isOpen, setIsOpen] = useState(false)
	const [dataVisible, setDataVisible] = useState(true)
	const { emit } = useSocket(divergencyPointChannel({ projectId: journeyId }))
	const anonymName = useRef('anonym'.padStart(6 + Math.random() * 10, '0'))
	const profilePath = `/dashboard/profile/${username}`
	const authorAvatarUrl =
		comments.author.avatar_url || comments.author.avatarUrl
	const isAssistant = comments.author.is_assistant
	const isOwner = comments.created_by === userId
	const { users } = useJourneyStore()
	const userRevisor = users.find((user) => user.id === comments.created_by)
	const { tenant, isTenantDefault } = useTenant()

	const makeDuration: () => number = () => {
		if (commentsCount === 0) return 0.2
		if (commentsCount < 4) return 0.4
		if (commentsCount < 10) return 0.8
		return 1
	}

	const duration = makeDuration()
	const name = isAnonym ? anonymName.current : authorName

	const handleClickToReply = () => {
		let countQuestions = [...questions]

		if (hasIntro) {
			countQuestions = [{ id: '0', comments: [], count: 0 }, ...questions]
		}

		const index = countQuestions[questionOpenedIndex].comments.findIndex(
			(c) => c.id === comments.id
		)
		if (index === countQuestions[questionOpenedIndex].comments.length - 1) {
			if (boxCommentRef) {
				setTimeout(
					() =>
						onCollapseLastComment(
							boxCommentRef.current ? boxCommentRef.current.clientHeight : 0,
							!isOpen
						),
					800
				)
			}
		}
		setIsOpen(!isOpen)
	}

	const deleteCommentHandler = async () => {
		await deleteComment(comments.id)
		deleteCommentState(comments)
		const commentInfo = {
			divergence_point_id: comments.divergence_point_id,
			question_id: comments.question_id,
			parent_id: comments.parent_id,
			id: comments.id
		}
		emit('DELETE', commentInfo)
		toast({
			title: t('divergencePoint:reply_deleted'),
			isClosable: true,
			status: 'success'
		})
		setDataVisible(false)
	}

	const AuthorName = () => {
		return (
			<Skeleton isLoaded={!isAnonym} animation='none' endColor='#EDEDED'>
				<Text color='gray.1' fontWeight='600' fontSize='sm'>
					{name}
				</Text>
			</Skeleton>
		)
	}

	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	const borderColor = !isTenantDefault(tenant)
		? 'evaluation_answer_comment_card_border'
		: useString

	return (
		<Flex
			w='full'
			mt='2'
			px='1.125rem'
			pt='1.125rem'
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor={borderColor}
			backgroundColor='#fff'
			ref={boxCommentRef}
			mx={isMobile ? 2 : 3.5}
			maxW={'96%'}
		>
			<Flex w='full' justifyContent='space-between'>
				{isAnonym || isAssistant ? (
					<Flex alignItems='center'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={authorAvatarUrl}
							name={authorName}
							backgroundColor={useString}
						/>
						<VStack ml={2} alignItems='flex-start'>
							<AuthorName />
							{isAssistant && !isAnonym && (
								<Text
									mt='-1 !important'
									color='gray.300'
									fontWeight='semibold'
									fontSize='xs'
								>
									{t('divergencePoint:assistants.cardLabel', {
										author: userRevisor?.name
									})}
								</Text>
							)}
						</VStack>
					</Flex>
				) : (
					<Flex as={Link} to={profilePath} alignItems='center'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={authorAvatarUrl}
							name={authorName}
							backgroundColor={useString}
						/>
						<VStack ml={2} alignItems='flex-start'>
							<AuthorName />
							{isAssistant && !isAnonym && (
								<Text
									mt='0 !important'
									color='gray.300'
									fontWeight='semibold'
									fontSize='xs'
								>
									{t('divergencePoint:assistants.cardLabel', {
										author: userRevisor?.name
									})}
								</Text>
							)}
						</VStack>
					</Flex>
				)}
				<Flex alignItems='center'>
					{dataVisible && (
						<Text color='#8B90A0' fontWeight='500' fontSize='xs' mr='2'>
							{handleDateFormat(
								comments.created_at,
								userLanguage === 'PT_BR' ? 'ptBR' : ''
							)}
						</Text>
					)}
					{(hasDeletePrivilege || isOwner) && (
						<DeleteConfirmButton
							onConfirm={deleteCommentHandler}
							iconButtonProps={{
								fontSize: 'xl',
								color: 'pink.500',
								background: 'transparent'
							}}
							setDataVisible={() => setDataVisible(true)}
							setNotDataVisible={() => setDataVisible(false)}
						/>
					)}
				</Flex>
			</Flex>
			<Flex mt='2'>
				<Text
					fontSize='sm'
					color='#505565'
					fontWeight='500'
					w='100%'
					variant='breakLines'
				>
					<Hyperlink>{comments.text.trim()}</Hyperlink>
				</Text>
			</Flex>
			<Flex
				mt='4'
				alignItems='center'
				w='full'
				h='3rem'
				justifyContent='space-around'
				borderTop='1px solid #DFE0E3'
				borderBottom={isOpen ? '1px solid #DFE0E3' : 'none'}
			>
				<LikeButton
					commentsAgreements={comments.agreements}
					commentsId={comments.id}
					commentsQuestionId={comments.question_id}
					journeyId={journeyId}
					userId={userId}
					isReply={false}
					userName={userName}
				/>
				<Box w='1px' h='60%' backgroundColor='#DFE0E3' />
				{commentsCount === 0 ? (
					<Button background='transparent' onClick={handleClickToReply}>
						<Icon
							as={BiComment}
							w='1.500rem'
							h='1.500rem'
							color='evaluation_answer_comment_button'
						/>

						<Text
							ml='2'
							color='evaluation_answer_comment_button'
							fontWeight='600'
							fontSize='xs'
						>
							{t('divergencePoint:Comment')}
						</Text>
					</Button>
				) : (
					<Button background='transparent' onClick={handleClickToReply}>
						<Icon
							as={BiChat}
							w='1.500rem'
							h='1.500rem'
							color='evaluation_answer_comment_button'
						/>
						<Text
							ml='2'
							color='evaluation_answer_comment_button'
							fontWeight='600'
							fontSize='xs'
						>
							{t('divergencePoint:comments', { count: commentsCount })}
						</Text>
						{isOpen && <ChevronUpIcon boxSize='1.563rem' color='gray.400' />}
						{!isOpen && <ChevronDownIcon boxSize='1.563rem' color='gray.400' />}
					</Button>
				)}
			</Flex>
			<Collapse transition={{ enter: { duration } }} in={isOpen} animateOpacity>
				<Box pb='2'>
					{comments.replies.map((reply) => (
						<ReplyCard
							key={reply.id}
							reply={reply}
							userId={userId}
							userName={userName}
							userLanguage={userLanguage}
							journeyId={journeyId}
							username={reply.author.username}
							divergencePointId={comments.divergence_point_id}
						/>
					))}
					<InputComment
						commentsId={comments.id}
						commentsQuestionId={comments.question_id}
						userName={userName}
						userId={userId}
						journeyId={journeyId}
						userUrl={userAvatarUrl}
					/>
				</Box>
			</Collapse>
		</Flex>
	)
}
