import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiBookOpen, BiChat } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStResume } from 'modules/common/components/icons'
import { useJourney } from 'modules/journeys/hooks'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import useCommentsStore from '../../store/use-comments-store'
import { ButtonSharePoint } from '../button-share-point'
import { AboutToolMobile } from './about-tool-mobile'
import { AnonymControl } from './anonym-mode/anonym-control'
import DivergenceOptions from './divergence-options'
import { StructQuestionMobile } from './struct-question-mobile'
import { SummaryQuestionsMobile } from './summary/components/analysis-questions-mobile'

export const LeftDivergenceViewMobile: React.FC<{
	isAbout: boolean
	isSummary?: boolean
	setIsSideBar: () => void
	setNotIsSideBar: () => void
	setRefs: () => void
	setLinks: () => void
}> = ({
	isAbout,
	setIsSideBar,
	setNotIsSideBar,
	setRefs,
	setLinks,
	isSummary = false
}) => {
		const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
		const { title = '', color = '' } = selectedPoint || {}
		const { t } = useTranslation()
		const { setQuestionOpenedIndex, point, setQuestionSummaryIndex } =
			useCommentsStore()
		const { user: currUser } = useMe()
		const { id: projectId = '', mapId } = useParams<{
			id: string
			mapId: string
		}>()
		const { data: users = [] } = useJourney(projectId, {
			select: (journey) => journey.users
		})
		const hasPrivileges = !!users
			.filter((user) => user.id === currUser?.id)
			.find(({ project_roles }) => Role(project_roles).can('divergence.delete'))

		const handleClickAboutKit = () => {
			setIsSideBar()
			setQuestionOpenedIndex(-1)
			sendGAEvent('about_kit')
		}

		const handleClickSummary = () => {
			setQuestionSummaryIndex(0)
			setQuestionOpenedIndex(-2)
			sendGAEvent('summary_tab')
			setIsSideBar()
		}

		const HandleClickQuestions = () => {
			setQuestionOpenedIndex(0)
			setIsSideBar()
		}
		return (
			<Flex flexDirection='column' w='full' h='full' bg='white'>
				<Flex
					mt={3}
					alignItems='center'
					pl='10'
					justifyContent='space-between'
					h='6.5rem'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				>
					<Flex alignItems='center' w='full' minH='6.5rem'>
						{selectedPoint && (
							<SelectColor
								color={color.toLowerCase()}
								visible={selectedPoint.visible}
							/>
						)}
						<Text
							fontSize='lg'
							px='3'
							fontWeight='600'
							maxW='90%'
							wordBreak='break-all'
							display='-webkit-box'
							sx={{ WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}
							overflow='hidden'
						>
							{title}
						</Text>
					</Flex>
					{selectedPoint && (
						<ButtonSharePoint
							pointType='divergence'
							mr={!hasPrivileges ? 2 : 0}
						/>
					)}
					{hasPrivileges && mapId && (
						<DivergenceOptions
							divergencePoint={selectedPoint}
							projectId={projectId}
							mapId={mapId}
						/>
					)}
				</Flex>
				<Flex
					py={2}
					justifyContent='space-evenly'
					align='center'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				>
					<Tooltip>
						<Flex flexDirection='column'>
							<Button
								onClick={HandleClickQuestions}
								background={!isAbout && !isSummary ? 'gray.100' : 'transparent'}
								p={2}
								display='flex'
								h='auto'
								alignItems='center'
								justifyContent='center'
								flexDirection='column'
								bg='gray.100'
							>
								<Icon
									as={BiChat}
									fontSize='2xl'
									color={
										!isAbout && !isSummary
											? 'tabs_aria_selected_color'
											: 'gray.300'
									}
								/>
								<Text
									fontWeight='600'
									fontSize='xs'
									color={
										!isAbout && !isSummary
											? 'tabs_aria_selected_color'
											: 'gray.300'
									}
									p={2}
								>
									{t('tools:edit.questions.questions')}
								</Text>
							</Button>
						</Flex>
					</Tooltip>

					<Tooltip>
						<Flex flexDirection='column'>
							<Button
								onClick={handleClickAboutKit}
								background={isAbout ? 'gray.100' : 'transparent'}
								p={2}
								display='flex'
								h='auto'
								alignItems='center'
								justifyContent='center'
								flexDirection='column'
								bg='gray.100'
							>
								<Icon
									as={BiBookOpen}
									fontSize='2xl'
									color={isAbout ? 'tabs_aria_selected_color' : 'gray.300'}
								/>
								<Text
									fontWeight='600'
									fontSize='xs'
									color={isAbout ? 'tabs_aria_selected_color' : 'gray.300'}
									p={2}
								>
									{t('divergencePoint:about_kit.references')}
								</Text>
							</Button>
						</Flex>
					</Tooltip>

					<Tooltip>
						<Flex flexDirection='column'>
							<Button
								onClick={handleClickSummary}
								background={isSummary ? 'gray.100' : 'transparent'}
								p={2}
								display='flex'
								h='auto'
								alignItems='center'
								justifyContent='center'
								flexDirection='column'
								bg='gray.100'
							>
								<Icon
									as={IconStResume}
									fontSize='26px'
									color={isSummary ? 'tabs_aria_selected_color' : 'gray.300'}
								/>
								<Text
									fontWeight='600'
									fontSize='xs'
									color={isSummary ? 'tabs_aria_selected_color' : 'gray.300'}
									p={2}
								>
									{t('divergencePoint:resume.title')}
								</Text>
							</Button>
						</Flex>
					</Tooltip>
				</Flex>

				{point && !isSummary && !isAbout && <AnonymControl pointId={point.id} />}
				{point && isAbout && (
					<AboutToolMobile
						tool={point.tool}
						setNotIsSideBar={setNotIsSideBar}
						setRefs={setRefs}
						setLinks={setLinks}
						pointInfo={point}
					/>
				)}
				{point && !isAbout && !isSummary && (
					<StructQuestionMobile
						questionsWithText={point.tool.questions}
						setNotIsSideBar={setNotIsSideBar}
						intro={point.introduction}
					/>
				)}
				{point && isSummary && (
					<SummaryQuestionsMobile
						questionsWithText={point.tool.questions}
						setNotIsSideBar={setNotIsSideBar}
					/>
				)}
			</Flex>
		)
	}
