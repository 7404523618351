import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#007AFF',
		light: '#8EC2ED'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FFEBDB'
	}
}

export default themeColors
