import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

import { AssistantManager } from '../pages/assistant-manager'

const AssistantsPage = lazy(() => import('modules/assistants/pages/assistants'))

const AssistantsRoutes = () => {
	return (
		<ApmRoutes>
			<Route
				path='/'
				element={
					<Loading>
						<AssistantsPage />
					</Loading>
				}
			/>
			<Route
				path='/create'
				element={
					<Loading>
						<AssistantManager mode='create' />
					</Loading>
				}
			/>
			<Route
				path='/edit/:id'
				element={
					<Loading>
						<AssistantManager mode='edit' />
					</Loading>
				}
			/>
			<Route
				path='/start/:id'
				element={
					<Loading>
						<AssistantManager mode='start' />
					</Loading>
				}
			/>
		</ApmRoutes>
	)
}

export default AssistantsRoutes
