import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'

import {
	Badge,
	Box,
	BoxProps,
	Button,
	HStack,
	Heading,
	Icon,
	Text
} from '@chakra-ui/react'

import { DocHeader } from 'modules/common/components/layout'

interface Props extends BoxProps {
	section?: boolean
	title: string
	subtitle?: string
	template?: string
	mb?: string | number
	betaBadge?: boolean
}

export const PageTitle: React.FC<Props> = React.memo(
	({ title, subtitle, template, mb, section, betaBadge = false, ...props }) => {
		return (
			<Box mb={mb || 8} {...props}>
				{!section && <DocHeader title={title} />}
				<HStack>
					<Heading fontSize='lg'>{title}</Heading>
					{betaBadge && (
						<Badge
							px={1}
							py='px'
							colorScheme='blue'
							variant='solid'
							textTransform='none'
							letterSpacing={1}
						>
							beta
						</Badge>
					)}
				</HStack>
				{subtitle && (
					<Text color='gray.300' mt={4}>
						{subtitle}
					</Text>
				)}
				{template && (
					<Text color='gray.300' mt={4}>
						template: {template}
					</Text>
				)}
			</Box>
		)
	}
)

export const BackButton = ({
	to,
	onClick
}: {
	to?: string
	onClick?: () => void
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const handleClick = () => {
		const action = onClick || (() => navigate(-1))
		action()
	}
	const btnProps = {
		variant: 'link',
		color: 'go_back_link',
		leftIcon: <Icon as={BiArrowBack} />
	}
	if (typeof to !== 'string') {
		return (
			<Button onClick={handleClick} {...btnProps}>
				{t('buttons:back')}
			</Button>
		)
	}
	return (
		<Button as={Link} to={to} {...btnProps}>
			{t('buttons:back')}
		</Button>
	)
}
