import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillSendFill } from 'react-icons/bs'
import { MdOutlineOpenInFull } from 'react-icons/md'

import {
	Button,
	Flex,
	Icon,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Progress,
	Text,
	Textarea,
	useBreakpointValue,
	useDisclosure
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

interface Props {
	message: string
	setMessage: (message: string) => void
	handleKeyPress: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
	textareaRef: React.RefObject<HTMLTextAreaElement>
	tokens: number
	tokensInitialValue: number
	getProgressTokensColor: () => string
	handleSendMessage: () => void
	isLoading: boolean
}

const SendButton: React.FC<{
	onClick: () => void
	isLoading: boolean
	isMobile: boolean
	absolute?: boolean
}> = ({ onClick, isLoading, isMobile, absolute }) => {
	const { t } = useTranslation()

	return (
		<Button
			alignSelf='flex-end'
			colorScheme='blue'
			onClick={() => {
				sendGAEvent('assistant_chat_send_message_button_clicked')
				onClick()
			}}
			isLoading={isLoading}
			borderRadius={isMobile ? 'full' : undefined}
			ml={isMobile ? 3 : undefined}
			h={isMobile ? '40px' : undefined}
			pos={absolute ? 'absolute' : undefined}
			bottom={absolute ? 4 : undefined}
			right={absolute ? 6 : undefined}
			zIndex={2}
			tabIndex={1}
		>
			<Icon
				transform={`rotate(-44.5deg) translateY(3px) ${isMobile ? 'translateX(-3px)' : ''}`}
				as={BsFillSendFill}
				mr={isMobile ? 0 : 2}
			/>
			{!isMobile && t('assistants:modal.chat.action.send')}
		</Button>
	)
}

export const MessageInputWithTokenCounter = ({
	message,
	setMessage,
	handleKeyPress,
	textareaRef,
	tokens,
	tokensInitialValue,
	getProgressTokensColor,
	handleSendMessage,
	isLoading
}: Props) => {
	const isMobileDevice = useBreakpointValue({ base: true, md: false })
	const { isOpen, onOpen, onClose } = useDisclosure()
	const modalTextareaRef = useRef<HTMLTextAreaElement>(null)
	const { t } = useTranslation()

	const [isLandscape, setIsLandscape] = useState(
		window.innerWidth > window.innerHeight
	)

	const isMobile = isMobileDevice && !isLandscape

	const handleOrientationChange = () => {
		setIsLandscape(window.innerWidth > window.innerHeight)
	}

	useEffect(() => {
		window.addEventListener('resize', handleOrientationChange)
		return () => {
			window.removeEventListener('resize', handleOrientationChange)
		}
	}, [])

	useEffect(() => {
		if (isOpen && isMobile) {
			setTimeout(() => {
				modalTextareaRef.current?.focus()
			}, 100)
		}

		if (!isMobile && textareaRef.current) {
			textareaRef.current.focus()
		}
	}, [isOpen, isMobile])

	const tokensFormatted = (tokens / 1000).toFixed(2)

	return (
		<Flex
			direction={['column', 'row']}
			w='full'
			justifyContent='center'
			gap={[0, 2]}
		>
			<Flex
				direction={['row', 'column']}
				gap={2}
				alignItems='center'
				mt={[0, '-6px']}
				mb={[4, 0]}
				order={[1, 2]}
			>
				<Text
					as='span'
					fontWeight='semibold'
					w={['50%', '100%']}
					color='gray.400'
					fontSize={['md', '2xl']}
					alignSelf='flex-start'
				>
					{tokensFormatted}K
					<Text
						as={isMobile ? 'span' : 'p'}
						color='gray.400'
						fontWeight='normal'
						alignSelf='flex-start'
						mt={['1px', '-6px']}
						fontSize={['12px', 'xs']}
						w='100%'
						ml={['4px', 0]}
						whiteSpace={['nowrap', 'normal']}
					>
						{t('assistants:modal.chat.tokens')}
					</Text>
				</Text>

				<Progress
					value={(tokens / tokensInitialValue) * 100}
					size='xs'
					colorScheme={getProgressTokensColor()}
					w={['45%', '100%']}
					h='8px'
					borderRadius='full'
					mt={['1px', '-8px']}
					bgColor='gray.190'
				/>
				{!isMobile && (
					<SendButton
						onClick={handleSendMessage}
						isLoading={isLoading}
						isMobile={false}
					/>
				)}
			</Flex>
			<Flex direction={['row', 'column']} w={['100%', '84%']} order={[2, 1]}>
				<Textarea
					placeholder={
						isMobile
							? t('assistants:modal.chat.placeholder-mobile')
							: t('assistants:modal.chat.placeholder')
					}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					onKeyUp={(e) => {
						if (!isMobile) {
							handleKeyPress(e)
						}

						const textarea = e.currentTarget
						const maxScrollHeight = 0.2 * window.innerHeight

						textarea.style.height = 'auto'

						const currentScrollHeight = textarea.scrollHeight

						if (currentScrollHeight > maxScrollHeight) {
							textarea.style.overflowY = 'scroll'
							textarea.style.height = `${maxScrollHeight}px`
						} else {
							textarea.style.height = `${currentScrollHeight}px`
						}
					}}
					pt={2}
					minH={['40px', '100px']}
					maxH='80px'
					flex={2}
					ref={textareaRef}
					position='relative'
					rows={1}
					tabIndex={0}
					resize='none'
				/>
				{isMobile && (
					<>
						<IconButton
							position='absolute'
							bottom={5}
							right={20}
							zIndex={2}
							color='gray.400'
							variant='ghost'
							icon={<MdOutlineOpenInFull />}
							aria-label={t('assistants:modal.chat.action.expand')}
							onClick={() => {
								sendGAEvent('assistant_chat_expand_textarea_button_clicked')
								onOpen()
							}}
							size='sm'
							mr={2}
						/>
						<SendButton
							onClick={handleSendMessage}
							isLoading={isLoading}
							isMobile={true}
						/>
					</>
				)}
			</Flex>
			{isMobile && isOpen && (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						sendGAEvent('assistant_chat_close_expand_textarea_button_clicked')
						onClose()
					}}
				>
					<ModalOverlay />
					<ModalContent
						position='fixed'
						bottom={0}
						mb={0}
						w='100%'
						h='50vh'
						borderTopRadius='md'
						borderBottomRadius={0}
					>
						<ModalCloseButton zIndex={2} mt={1} />
						<ModalBody mt={2} p={0}>
							<Textarea
								ref={modalTextareaRef}
								border='none'
								p={8}
								mr={1}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								placeholder={t('assistants:modal.chat.placeholder-mobile')}
								mt={2}
								h='100%'
								resize='none'
								_focus={{ boxShadow: 'none', border: 'none' }}
								pos='relative'
								onFocus={() => {
									modalTextareaRef.current?.setSelectionRange(
										modalTextareaRef.current.value.length,
										modalTextareaRef.current.value.length
									)
								}}
							/>
							<SendButton
								onClick={() => {
									if (message.length > 0) {
										handleSendMessage()
										onClose()
									}
								}}
								isLoading={isLoading}
								isMobile={true}
								absolute
							/>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</Flex>
	)
}
