import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

import { useTypeUser } from 'modules/common/contexts/user-type-context'

const MyLab = lazy(() => import('modules/labs/pages/my-lab'))
const SharedLabs = lazy(() => import('modules/labs/pages/shared-labs'))
const SelectPlan = lazy(() => import('modules/labs/pages/select-plan'))
const PlanSubscription = lazy(
	() => import('modules/labs/pages/plan-subscription')
)
const Lab = lazy(() => import('modules/labs/pages/lab'))

const LabsRoutes = () => {
	const { hasPermission } = useTypeUser()
	return (
		<ApmRoutes>
			<Route
				path=':labId'
				element={
					<Loading>
						<Lab />
					</Loading>
				}
			>
				<Route index element={<Navigate to='./journeys' />} />
			</Route>
			<Route path='my'>
				<Route
					index
					element={
						<Navigate to={hasPermission('plan_tab') ? './plan' : './credits'} />
					}
				/>
				<Route
					path=':tab'
					element={
						<Loading>
							<MyLab />
						</Loading>
					}
				/>
			</Route>
			<Route
				path='participation'
				element={
					<Loading>
						<SharedLabs />
					</Loading>
				}
			/>
			<Route path='select-plan'>
				<Route
					index
					element={
						<Loading>
							<SelectPlan />
						</Loading>
					}
				/>
				<Route
					path='subscribe'
					element={
						<Loading>
							<PlanSubscription />
						</Loading>
					}
				/>
			</Route>
		</ApmRoutes>
	)
}

export default LabsRoutes
