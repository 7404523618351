import React from 'react'
import { NavLink } from 'react-router-dom'

import { AccordionButton } from '@chakra-ui/react'

type NavButtonProps = {
	children: React.ReactNode
	to: string
	asNav: boolean
	handleClick?: () => void
}

export const NavButton: React.FC<NavButtonProps> = ({
	children,
	asNav,
	to,
	handleClick,
	...props
}: NavButtonProps) => {
	if (asNav) {
		return (
			<AccordionButton
				py={4}
				pr={8}
				as={NavLink}
				to={to}
				{...props}
				onClick={handleClick}
			>
				{children}
			</AccordionButton>
		)
	}

	return (
		<AccordionButton py={4} pr={8} {...props}>
			{children}
		</AccordionButton>
	)
}
