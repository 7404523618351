import { useTranslation } from 'react-i18next'

import {
	FormControl,
	FormErrorMessage,
	InputGroup,
	InputRightElement,
	Text,
	Textarea,
	TextareaProps,
	useBreakpointValue
} from '@chakra-ui/react'
import { FieldError, UseFormRegister } from 'react-hook-form'

type EssayProps = TextareaProps & {
	register: UseFormRegister<{
		title:string,
		response:string
	}>
	errors?: FieldError,
	text:string
}

const InputResponse = ({register, errors, text}: EssayProps) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	return (
		<FormControl isInvalid={!!errors} h='full'>
			<InputGroup h='full'>
				<Textarea
					{...register('response')}
					_placeholder={{ color: 'gray.300', fontSize: isMobile ? 'sm' : 'md' }}
					maxLength={3000}
					h='full'
					placeholder={t('evaluationPoint:essay.text')}
					pr={75}
				/>
				<InputRightElement mr={5}>
					<Text fontSize={['sm', 'md']} color='gray.300'>
						{text?.length}/{3000}
					</Text>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage>{errors?.message}</FormErrorMessage>
		</FormControl>
	)
}

export default InputResponse
