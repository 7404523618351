import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiEdit } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import {
	Stack,
	HStack,
	Checkbox,
	Button,
	Text,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'

import { useMapStore } from 'modules/map/modules/map-canvas/store'

import { noticePointKeys } from '../../query'
import { updateNoticePoint } from '../../usecases'

type Props = {
	sentEmail: boolean
	pointId: string
	newNotice: string
}

export const EditNoticePointFooter: FC<Props> = ({
	sentEmail,
	pointId,
	newNotice
}) => {
	const [sendEmail, setSendEmail] = useState(sentEmail)
	const { t } = useTranslation()
	const toast = useToast()
	const navigate = useNavigate()
	const toastMessage = sendEmail
		? t('map:points:notice:edit:sendEmailSucess')
		: t('map:points:notice:edit:sucess')
	const { clearSelected } = useMapStore(({ actions }) => actions)
	const handleEditPoint = () => {
		try {
			updateNoticePoint(pointId, newNotice, sendEmail)
			queryClient.setQueryData(noticePointKeys.noticePoint(pointId), newNotice)
			clearSelected()
			toast({
				title: toastMessage,
				status: 'success',
				isClosable: true
			})
			navigate('../')
		} catch (e) {
			toast({
				title: t('errors:editNoticePoint'),
				status: 'error',
				isClosable: true
			})
		}
	}
	const isEmpty = newNotice.length === 0
	return (
		<>
			<Stack direction={['column', 'row']} w='full' justify='space-between'>
				<HStack>
					<Checkbox
						defaultChecked={sentEmail}
						onChange={() => setSendEmail(!sendEmail)}
					/>
					<Text fontSize='sm' color='black'>
						{t('map:points:notice:edit:sendEmail')}
					</Text>
				</HStack>
				<Button
					isDisabled={isEmpty}
					onClick={handleEditPoint}
					colorScheme='custom_scheme_color_button'
					leftIcon={<BiEdit />}
				>
					{t('buttons:saveEdition')}
				</Button>
			</Stack>
		</>
	)
}
