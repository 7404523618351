import { useLocation } from 'react-router-dom'

import {
	Box,
	BoxProps,
	Button,
	Flex,
	HStack,
	Icon,
	Text,
	useDisclosure,
	useTheme
} from '@chakra-ui/react'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { IconStCoin } from 'modules/common/components/icons'
import { User } from 'modules/user/types'

interface Props extends BoxProps {
	coins: number | null
	nexStep: () => void
	isDisabled: boolean
	user?: User
	text: string
	icon?: any
	isLoading?: boolean
}

export const CreateContentBtn = ({
	isLoading,
	coins,
	nexStep,
	isDisabled,
	user,
	text,
	icon,
	...props
}: Props) => {
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()
	const hasCoins = coins && coins > 0
	const hasSufficientCredits = user?.credits && user.credits >= (coins ?? 0)
	const { state } = useLocation()
	const hasFreeAssistantChat = state?.hasFreeAssistantChat

	const handleCreate = () => {
		if (hasFreeAssistantChat || hasSufficientCredits) {
			nexStep()
			return
		}
		emptyCreditsDisclosure.onOpen()
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	const theme = useTheme() as { colors: any }
	return (
		<Flex
			border='1px'
			borderRadius='lg'
			borderColor={theme.colors.evaluation_box_border_color}
			bg={theme.colors.evaluation_box_bg_color}
			opacity={isDisabled ? '50%' : '100%'}
			justifyContent='center'
			alignItems='center'
			_hover={{
				bg: theme.colors.evaluation_box_bg_color,
				color: theme.colors.evaluation_box_border_color
			}}
			role='group'
			{...props}
		>
			<Button
				p={2.5}
				color='white'
				fontSize='xs'
				fontWeight='bold'
				bgColor='transparent'
				cursor='pointer'
				w='100%'
				textAlign='center'
				_groupHover={{ bg: 'none' }}
				onClick={handleCreate}
				isDisabled={isDisabled}
				isLoading={isLoading}
			>
				{icon && <Icon color='white' mr='5px' fontSize='15px' as={icon} />}

				{text}
				{hasCoins && (
					<Box alignSelf='center'>
						<HStack px={2}>
							<Icon w={5} h={5} as={IconStCoin} />
							<Text ml='-4px' fontSize='sm' fontWeight='bold' color='white'>
								{coins}
							</Text>
						</HStack>
					</Box>
				)}
			</Button>
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={user?.credits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Flex>
	)
}
