import { fetch } from 'config'

import { CommentResponse } from '../types'

export async function fetchConversationComments(conversationPointId: string) {
	const {
		data: { content }
	} = await fetch<CommentResponse>({
		url: `/projects/v1/checkpoint/${conversationPointId}/comment`,
		method: 'GET'
	})

	return content
}
