import { fetch } from 'config'

import { MonitorPointStatusCommentResponse } from '../types/monitor-point'

export const addMonitorPointStatusComment = async (
	monitorPointId: string,
	statusId: string,
	text: string
) => {
	return fetch<MonitorPointStatusCommentResponse>({
		url: `projects/v1/monitor-point/${monitorPointId}/status/${statusId}/comments`,
		method: 'POST',
		data: { text }
	})
}
