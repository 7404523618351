import React from 'react'

import {
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberInputProps
} from '@chakra-ui/react'

type Props = NumberInputProps & {
	isReadOnly?: boolean
	availableTokens: number
	placeholder?: string
}

export const InputNumber: React.FC<Props> = ({
	isReadOnly = false,
	availableTokens,
	children,
	placeholder,
	...inputProps
}) => {
	return (
		<NumberInput
			min={0}
			size='sm'
			borderRadius='md'
			isReadOnly={isReadOnly}
			borderColor='#E2E8F0'
			borderWidth='1px'
			{...inputProps}
		>
			<NumberInputField
				fontSize='md'
				fontWeight='semibold'
				placeholder={placeholder}
				_placeholder={{ textColor: '#8B90A0', fontWeight: 400 }}
			/>
			{!isReadOnly && (
				<NumberInputStepper borderLeftColor={'#E2E8F0'} borderLeftWidth='1px'>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			)}
		</NumberInput>
	)
}
