import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiCalendar, BiEdit, BiTimeFive } from 'react-icons/bi'

import {
	Box,
	Button,
	Icon,
	Input,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'

import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import {
	addLeadingZero,
	changeDateOrder,
	correctHourAmPm,
	todayDate
} from '../../../helpers'
import { useConvergenceStore } from '../../../store'
import { ConvergencePoint } from '../../../types'
import { updateConvergencePoint } from '../../../usecases'
import { CreateConvergencePointValidation } from '../../../validators'

export const UpdateCloseDate = ({
	point,
	setIsEditing,
	projectId,
	mapId
}: {
	point: ConvergencePoint
	setIsEditing: Dispatch<SetStateAction<boolean>>
	projectId: string
	mapId: string
}) => {
	const toast = useToast({
		status: 'error',
		position: 'bottom',
		variant: 'solid'
	})

	const {
		actions: { addPoint }
	} = useMapStore()

	const {
		t,
		i18n: { language }
	} = useTranslation()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))

	const { date, hour, formatHours, updateTime } = useConvergenceStore()
	const [isLoading, setIsLoading] = useState(false)

	const validation = CreateConvergencePointValidation().pick(['date', 'hour'])

	const { register, formState, handleSubmit } = useForm<SubmitData>({
		mode: 'onChange',
		resolver: yupResolver(validation),
		defaultValues: {
			hour: addLeadingZero(correctHourAmPm(hour, formatHours, language), 4),
			date: changeDateOrder(date, language)
		}
	})

	type SubmitData = {
		hour: string
		date: string
	}

	const onSubmit = async (data: SubmitData) => {
		const { date, hour } = data
		const fullDate = new Date(`${date}T${hour}`)
		setIsLoading(true)

		try {
			const updatedPoint = await updateConvergencePoint(point.id, {
				closing_date: fullDate.toISOString()
			})
			const pointType = 'CONVERGENCE'
			updateTime(updatedPoint.closing_date, language)
			addPoint({ ...updatedPoint, point_type: pointType })
			setIsEditing(false)
			const pointInfo = {
				id: point.id,
				position: point.position,
				point_type: pointType
			}
			setIsLoading(false)
			emit('ADD', pointInfo)
		} catch (err) {
			setIsLoading(false)
			toast({
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error'
			})
		}
	}

	const { isValid } = formState

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack
				direction={{ base: 'column', lg: 'row' }}
				spacing={4}
				justify='space-between'
				w='full'
			>
				<Stack direction='row' alignItems='center' w='full'>
					<Icon as={BiCalendar} />
					<Text fontWeight='medium'>
						{t('map:points.conversation.create.date.label')}
					</Text>
					<Input
						variant='filled'
						type='date'
						min={todayDate}
						{...register('date')}
					/>
				</Stack>
				<Stack direction='row' alignItems='center'>
					<Icon as={BiTimeFive} />
					<Text fontWeight='medium'>
						{t('map:points.conversation.create.hour.label')}
					</Text>
					<Input
						variant='filled'
						type='time'
						pattern='[0-9]{2}:[0-9]{2}'
						placeholder='17:30'
						{...register('hour')}
					/>
				</Stack>
				<Box>
					<Button
						fontSize={['xs', 'md']}
						colorScheme='custom_scheme_color_button'
						type='submit'
						isDisabled={!isValid}
						leftIcon={<Icon as={BiEdit} />}
						isLoading={isLoading}
					>
						{t('settings:username.button')}
					</Button>
				</Box>
			</Stack>
		</form>
	)
}
