import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import {
	Button,
	Icon,
	MenuItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	UseDisclosureReturn,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { ConvergencePoint } from '../../../types'
import { deleteConvergencePoint } from '../../../usecases'

export const DeletePoint = ({
	point,
	projectId,
	mapId
}: {
	point: ConvergencePoint
	projectId: string
	mapId: string
}) => {
	const disclosure = useDisclosure()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const toast = useToast({
		status: 'error',
		position: 'bottom',
		variant: 'solid'
	})
	const {
		actions: { removePoint, clearSelected }
	} = useMapStore()

	async function deletePoint() {
		try {
			await deleteConvergencePoint(point.id)
			removePoint(point)
			clearSelected()
			toast({
				status: 'success',
				title: t('map:points.convergence.deleteSuccess')
			})
			const pointType = 'CONVERGENCE'
			const pointInfo = {
				id: point.id,
				position: point.position,
				point_type: pointType
			}
			emit('DELETE', pointInfo)
			navigate('../')
		} catch (err) {
			toast({
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error'
			})
		}
	}

	return (
		<>
			<MenuItem onClick={disclosure.onOpen}>{t('buttons:delete')}</MenuItem>
			<DeleteConfirmation {...disclosure} onDelete={deletePoint} />
		</>
	)
}

const DeleteConfirmation = ({
	onClose,
	isOpen,
	onDelete
}: { onDelete: () => void } & UseDisclosureReturn) => {
	const { t } = useTranslation()

	function handleDelete() {
		onDelete()
		onClose()
	}

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent>
				<ModalHeader>
					{t('map:points.convergence.deleteDialog.title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>{t('map:points.convergence.deleteDialog.text')}</Text>
					<Text as='strong'>{t('journeys:dialogs.delete.alert')}</Text>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={onClose}>
						{t('buttons:cancel')}
					</Button>
					<Button
						colorScheme='custom_scheme_color_button'
						mr={3}
						onClick={handleDelete}
						leftIcon={<Icon as={BiTrashAlt} />}
					>
						{t('buttons:delete')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
