import { fetch } from 'config'

import { BillingAccount } from '../types'

export const getBillingAccount = () => {
	return fetch<BillingAccount>({
		url: 'billing/v1/billing/account',
		method: 'GET'
	}).then(({ data }) => data)
}
