/* global __APP_VERSION__:readonly */

import { init as initApm } from '@elastic/apm-rum'

export const setupAPM = () =>
	initApm({
		// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
		serviceName: 'sp-web',

		// Set custom APM Server URL (default: http://localhost:8200)
		serverUrl:
			import.meta.env.VITE_ELASTIC_APM_SERVER_URL_FRONTEND ||
			import.meta.env.VITE_ELASTIC_APM_SERVER_URL,

		// Set service version (required for sourcemap feature)
		serviceVersion: __APP_VERSION__,

		environment: import.meta.env.VITE_ELASTIC_APM_ENVIRONMENT,

		transactionSampleRate: parseFloat(
			import.meta.env.VITE_ELASTIC_APM_TRANSACTION_SAMPLE_RATE
		),

		active: true
	})
