import { useTranslation } from 'react-i18next'

import { Flex, Icon, Switch, Text, VStack } from '@chakra-ui/react'

import { IconStAnonym } from 'modules/common/components/icons/st-anonym'
import useJourneyStore from 'modules/journeys/store/use-journey-store'
import { useDivergenceStore } from 'modules/map/store/divergence-store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { useAnonymMutation } from '../hooks/use-anonym-mutation'

type Props = {
	pointId: string
}

export function AnonymControl({ pointId }: Props) {
	const { t } = useTranslation()
	const { user } = useMe()
	const mutation = useAnonymMutation(pointId)
	const { isAnonym } = useDivergenceStore()

	const roles = useJourneyStore((state) => {
		if (!user) return []
		return state.getRoles(user.id)
	})

	const can = Role(roles).can('anonym_mode.toggle')

	if (!can) return null

	const handleChange = () => {
		mutation.mutate({ pointId, isAnonym: !isAnonym })
	}

	return (
		<VStack py={5} pl={8} pr={4} gap={1} alignItems='flex-start'>
			<Flex gap={2} alignItems='center'>
				<Icon as={IconStAnonym} fontSize='xl' />
				<Text fontWeight='bold'>{t('anonym:incognito.enable')}</Text>
				<Switch
					colorScheme='switch_scheme_color_checked'
					isChecked={isAnonym}
					onChange={handleChange}
				/>
			</Flex>
			<Text fontSize='xs'>{t('anonym:incognito.divergence.description')}</Text>
		</VStack>
	)
}
