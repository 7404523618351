import create from 'zustand'

export const EVENT_SOCKET_FAIL = 'socket:fail'
export const EVENT_SESSION_EXPIRED = 'session:expired'

interface Connectivity {
	eventType: undefined | string
	setEventType: (
		eventType: typeof EVENT_SOCKET_FAIL | typeof EVENT_SESSION_EXPIRED
	) => void
}

const useConnectivityStore = create<Connectivity>((set, get) => ({
	eventType: undefined,
	setEventType: (
		eventType: typeof EVENT_SOCKET_FAIL | typeof EVENT_SESSION_EXPIRED
	) => {
		set({
			eventType
		})
	}
}))

export default useConnectivityStore
