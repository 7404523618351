import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Icon, Text, Box, useDisclosure, Image } from '@chakra-ui/react'

import loading from 'modules/common/assets/img/loading.svg'
import { IconStResume } from 'modules/common/components/icons'
import { WarningModal } from 'modules/journeys/components/limited-features/warning-modal'
import { useMe } from 'modules/user/hooks'

import { CreateQuestionAnalysis } from './components/create-question-analysis-button'

type Props = {
	pointId: string
	questionId: string
	isBasicJourney: boolean
	hasPrivileges: boolean
}

export const EmptyQuestionAnalysis = ({
	pointId,
	questionId,
	hasPrivileges
}: Props) => {
	const { t } = useTranslation()
	const disclosure = useDisclosure()
	const { user } = useMe()
	const [disabledButton, setDisabledButton] = useState(false)

	return (
		<Flex
			h='lg'
			w='full'
			alignItems='center'
			flexDirection='column'
			justifyContent='center'
		>
			<Icon as={IconStResume} fontSize='4.375rem' color='gray.400' mb='1rem' />
			{hasPrivileges ? (
				<Text
					textAlign='center'
					px={6}
					fontSize='sm'
					fontWeight='600'
					color='gray.400'
				>
					{t('divergencePoint:resume.empty')}
				</Text>
			) : (
				<Text
					textAlign='center'
					px={6}
					fontSize='sm'
					fontWeight='600'
					color='gray.400'
				>
					{t('divergencePoint:resume.noSummary')}
				</Text>
			)}
			{hasPrivileges && (
				<Box mt={6}>
					{disabledButton ? (
						<Box textAlign='center'>
							<Flex placeContent='center'>
								<Image
									css={{
										animationFillMode: 'both'
									}}
									w={12}
									animation='loading 1.5s linear infinite alternate'
									src={loading}
								/>
							</Flex>
						</Box>
					) : (
						<CreateQuestionAnalysis
							user={user}
							pointId={pointId}
							questionId={questionId}
							setDisabledButton={setDisabledButton}
						/>
					)}
				</Box>
			)}
			<WarningModal
				title={t('journeys:limitedFeatures.proFeatures.title')}
				description='journeys:limitedFeatures.proFeatures.description'
				showIcon
				{...disclosure}
			/>
		</Flex>
	)
}
