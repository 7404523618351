import { fetch } from 'config'

type Params = {
	pointId: string,
	questionId: string,
	assistantId: string
	until?: string
	force?: boolean
}

export async function commentAll({ assistantId, questionId, pointId, until, force }: Params) {
	const response = await fetch({
		url: `/projects/v1/divergence-point/${pointId}/question/${questionId}/comment/assisted/${assistantId}/all`,
		method: 'POST',
		params: { until, force },
	})
	return response.data
}
