import { useTheme } from '@chakra-ui/react'

export const useColorWithOpacity = (colorString: string, opacity: number) => {
	const theme = useTheme()

	const getColorWithOpacity = (colorString: string, opacity: number) => {
		const [color, shade] = colorString.split('.')
		const hexColor = theme.colors[color][shade]
		return `${hexColor}${Math.round(opacity * 255)
			.toString(16)
			.padStart(2, '0')}`
	}

	return getColorWithOpacity(colorString, opacity)
}
