import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#006F3B',
		light: '#79BF9E'
	},

	secondary: {
		dark: '#F68E1E',
		light: '#FDBE7A'
	}
}

export default themeColors
