import { useTranslation } from 'react-i18next'
import { BiUser } from 'react-icons/bi'

import { Flex, Icon, Text } from '@chakra-ui/react'

import { IconStAnonym } from 'modules/common/components/icons/st-anonym'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'

export const PointBar = () => {
	const { t } = useTranslation()
	const { isAnonymous, isIndividual, hasAnswers } = useEvaluationStore()

	if (isAnonymous && hasAnswers) {
		return (
			<Flex bg='gray.400' color='white' py={1} align='center' justify='center'>
				<Icon as={IconStAnonym} fontSize='xl' mr={1} />
				<Text fontSize='xs'>{t('evaluationPoint:settings.anonymous.bar')}</Text>
			</Flex>
		)
	}

	if (isIndividual && hasAnswers) {
		return (
			<Flex
				bg='gray.200'
				color='gray.400'
				py={1}
				align='center'
				justify='center'
			>
				<Icon as={BiUser} fontSize='xl' mr={1} />
				<Text fontSize='xs'>
					{t('evaluationPoint:settings.individual.bar')}
				</Text>
			</Flex>
		)
	}

	return <Flex display='none' />
}
