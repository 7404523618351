import { useQuery, useQueryClient } from 'react-query'

import { sessionManager } from 'config/session-manager'
import Api from 'data/api'

import { User } from 'modules/user/types'

import { userKeys } from '../query'

export const useMe = () => {
	const queryClient = useQueryClient()
	const hasSession = sessionManager.hasSession()

	const { data, isLoading } = useQuery(userKeys.me(), () => Api.Users.getMe(), {
		enabled: hasSession
	})

	return {
		user: data,
		isLoading,
		mutateUser: (user: User | Partial<User>) =>
			queryClient.setQueryData('me', user)
	}
}
