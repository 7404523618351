import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiArrowBack } from 'react-icons/bi'

import { Flex, Icon, Text } from '@chakra-ui/react'

export const BackButtonMobile: React.FC<{
	onClickFunc: () => void
}> = ({ onClickFunc }) => {
	const { t } = useTranslation()

	return (
		<Flex
			alignItems='center'
			onClick={onClickFunc}
			maxW='8rem'
			cursor='pointer'
			mb={2}
		>
			<Icon as={BiArrowBack} fontSize='md' color='go_back_link' />
			<Text fontSize='md' color='go_back_link' fontWeight='600'>
				{t('buttons:back')}
			</Text>
		</Flex>
	)
}
