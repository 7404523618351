import { fetch } from 'config'

import { BehavioralProfile } from '../types/index'

export const getBehavioralProfile = async () => {
	const response = await fetch<BehavioralProfile[]>({
		url: 'projects/v1/assistants/behavioral-profile',
		method: 'GET'
	})

	return response.data
}
