import { fetch } from 'config'
import create from 'zustand'

import { Tool, Reference } from 'modules/tools/types/tool-model'

export type Author = {
	avatar_url: string
	id: string
	name: string
	username: string
}

export type Link = {
	author: Author
	id: string
	title: string
	url: string
}

export type DivergenceInfos = {
	attached_links: Link[]
	tool: Tool
}

export type ReferencesAndLinks = {
	references: Reference[]
	attached_links: Link[]
}

type ReferenceStore = {
	referencesAndLinks: ReferencesAndLinks
	fetchReferencecsAndLinks: (divergencePointId: string) => void
	addAttachedLink: (
		divergencePointId: string,
		description: string,
		urlSend: string
	) => void
	removeAttachedLink: (
		divergencePointId: string,
		attachedLinkId: string
	) => void
}

const ReferencesStore = create<ReferenceStore>((set, get) => ({
	referencesAndLinks: {
		references: [],
		attached_links: []
	},

	fetchReferencecsAndLinks: async (divergencePointId: string) => {
		const { data: divergencePointInfos } = await fetch<DivergenceInfos>({
			url: `/projects/v1/divergence-point/${divergencePointId}`,
			method: 'GET'
		})

		set(() => ({
			referencesAndLinks: {
				references: divergencePointInfos.tool.references,
				attached_links: divergencePointInfos.attached_links
			}
		}))
	},

	addAttachedLink: async (
		divergencePointId: string,
		description: string,
		urlSend: string
	) => {
		const { data: link } = await fetch<Link>({
			url: `/projects/v1/divergence-point/${divergencePointId}/attached-link`,
			method: 'POST',
			data: {
				title: description,
				url: urlSend
			}
		})

		const { referencesAndLinks } = get()

		referencesAndLinks.attached_links.push(link)

		set(() => ({
			referencesAndLinks
		}))
	},

	removeAttachedLink: async (
		divergencePointId: string,
		attachedLinkId: string
	) => {
		await fetch<Link>({
			url: `/projects/v1/divergence-point/${divergencePointId}/attached-link/${attachedLinkId}`,
			method: 'DELETE'
		})

		const { referencesAndLinks } = get()

		const index = referencesAndLinks.attached_links.findIndex(
			(link) => link.id === attachedLinkId
		)
		referencesAndLinks.attached_links.splice(index, 1)

		set(() => ({
			referencesAndLinks
		}))
	}
}))

export default ReferencesStore
