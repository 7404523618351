import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const updateAssistant = async (id: string, data: Assistant) => {
	return await fetch<Assistant>({
		url: `projects/v1/assistants/${id}`,
		method: 'PUT',
		data
	})
}
