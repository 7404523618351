import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#232324',
		light: '#BBBBC5'
	},

	secondary: {
		dark: '#333FE1',
		light: '#BBC9F7'
	}
}

export default themeColors
