import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#050353',
		light: '#9AA3C3'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FEE199'
	}
}

export default themeColors
