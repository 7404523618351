export function useBrowser() {
	let browser

	const agent = navigator.userAgent

	switch (true) {
		case agent.indexOf('Firefox') > -1:
			browser = 'Mozilla Firefox'
			break

		case agent.indexOf('SamsungBrowser') > -1:
			browser = 'Samsung Internet'
			break

		case agent.indexOf('Opera') > -1 || agent.indexOf('OPR') > -1:
			browser = 'Opera'
			break

		case agent.indexOf('Trident') > -1:
			browser = 'Microsoft Internet Explorer'
			break

		case agent.indexOf('Edge') > -1:
			browser = 'Microsoft Edge (Legacy)'
			break

		case agent.indexOf('Edg') > -1:
			browser = 'Edge (Chromium)'
			break

		case agent.indexOf('Chrome') > -1:
			browser = 'Chrome'
			break

		case agent.indexOf('Safari') > -1:
			browser = 'Apple Safari'
			break

		default:
			browser = 'unknown'
	}

	return browser
}
