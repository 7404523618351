import { fetch } from 'config'

type Params = {
	pointId: string
	questionId: string
	analysisId: string
}

export const deleteQuestionAnalysis = async ({
	analysisId,
	pointId,
	questionId
}: Params) => {
	return await fetch({
		url: `/projects/v1/divergence-point/${pointId}/question/${questionId}/analysis/${analysisId}`,
		method: 'DELETE'
	}).then(({ data }) => data)
}
