import { useContext } from 'react'
import { BiChevronLeft } from 'react-icons/bi'

import { Icon, IconButton, useBreakpointValue } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { DashboardContext } from 'modules/common/contexts/dashboard-context'

export const ToggleButton = () => {
	const { shrink, setShrink } = useContext(DashboardContext)
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	if (isMobile) {
		return null
	}

	const rotation = shrink ? 'rotate(180deg)' : ''
	const position = !shrink ? '-6%' : '50%'
	const right = shrink ? '4%' : '1'

	const handleExpandMenu = () => {
		setShrink.toggle()
		sendGAEvent('expand_menu')
	}

	return (
		<IconButton
			onClick={handleExpandMenu}
			size='sm'
			icon={
				<Icon
					as={BiChevronLeft}
					color='white'
					fontWeight='black'
					fontSize='xl'
				/>
			}
			aria-label='menu'
			position='absolute'
			top='50%'
			right={right}
			transform={`translate(${position}) ${rotation}`}
			bg='navbar_bg'
			_hover={{ bg: 'navbar_link_hover_bg' }}
			_active={{ bg: 'navbar_link_active_bg' }}
			rounded='full'
			transitionProperty='all'
			transitionDuration='normal'
			opacity={1}
			borderColor='white'
			borderWidth='0.5px'
			boxShadow='0px 2px 4px -1px #0000001A'
			_focus={{ boxShadow: 'none' }}
		/>
	)
}
