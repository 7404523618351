import defaultCustomColors from '../default/colors'

const jnjCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#322C2A',
		700: '#322C2A'
	},

	secondary_color_scheme_button: {
		700: '#EB1700'
	},

	switch_scheme_color_checked: {
		500: '#EB1700'
	},

	radio_color: {
		500: '#EB1700'
	}
}

export default jnjCustomColors
