import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	useToast
} from '@chakra-ui/react'

import Api from 'data/api'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FormField } from 'modules/common/components'

const StepChangePassword = ({
	onFinish,
	hash
}: {
	onFinish: () => void
	hash: string
}) => {
	const { t } = useTranslation()
	const toast = useToast()
	const handleSubmit = async (values: { password: string }): Promise<void> => {
		try {
			await Api.Users.passwordRecoverySetPassword(hash, values.password)
			onFinish()
		} catch (e) {
			toast({ title: t('forgotPassword:failed.body'), status: 'error' })
		}
	}

	return (
		<>
			<ModalHeader fontSize='2xl' mt={10}>
				{t('forgotPassword:changePassword.title')}
			</ModalHeader>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{
					password: '',
					confirmPassword: ''
				}}
				validationSchema={Yup.object({
					password: Yup.string()
						.required(t('errors:requiredField'))
						.min(8, t('signup:minPassword')),
					confirmPassword: Yup.string()
						.required(t('errors:requiredField'))
						.oneOf([Yup.ref('password'), null], t('signup:matchPassword'))
				})}
			>
				{({ isValid, dirty, setFieldValue }) => {
					return (
						<Form>
							<ModalBody>
								<Box>
									<FormField
										placeholder={t(
											'forgotPassword:changePassword.placeHolderPassword'
										)}
										label={t('signup:password')}
										name='password'
										variant='outline'
										type='password'
										labelProps={{
											mb: '0.75rem',
											fontSize: 'md'
										}}
									/>
									<FormField
										placeholder={t(
											'forgotPassword:changePassword.placeholderConfirmPassword'
										)}
										onPaste={(e) => e.preventDefault()}
										label={t('signup:confirmPassword')}
										name='confirmPassword'
										variant='outline'
										type='password'
										labelProps={{
											mt: '1.3rem',
											mb: '0.75rem',
											fontSize: 'md'
										}}
									/>
								</Box>
							</ModalBody>

							<ModalFooter>
								<Button
									type='submit'
									mt={4}
									mb={3}
									width='full'
									colorScheme='custom_scheme_color_button'
									isDisabled={!(isValid && dirty)}
								>
									{t('buttons:savePassword')}
								</Button>
							</ModalFooter>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default StepChangePassword
