import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Flex, Image, ModalBody, Text } from '@chakra-ui/react'

import loading from 'modules/common/assets/img/loading.svg'

interface Props extends BoxProps {
	nextStep?: () => void
}

export const Loading = ({ nextStep, ...props }: Props) => {
	const { t } = useTranslation()
	return (
		<ModalBody>
			<Box textAlign='center' mt='25%' {...props}>
				<Flex placeContent='center'>
					<Image
						css={{
							animationFillMode: 'both'
						}}
						w={12}
						animation='loading 1.5s linear infinite alternate'
						src={loading}
					/>
				</Flex>
				<Text p={10}>{t('startingPoint:loading.text')}</Text>
			</Box>
		</ModalBody>
	)
}
