import { useTranslation } from 'react-i18next'

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { ASSISTANT_CHAT } from 'modules/constants'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { User } from 'modules/user/types'

export const WarningTokenChatsModal = ({
	isOpen,
	onClose,
	user,
	handleSendMessage,
	tokensInitialValue
}: {
	isOpen: boolean
	onClose: () => void
	user: User | undefined
	handleSendMessage: (isTokenRefilled: string) => void
	tokensInitialValue: number
}) => {
	const { t } = useTranslation()

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size='lg'>
				<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
				<ModalContent pt={4} borderRadius='8px'>
					<ModalHeader fontWeight='bold' fontSize='md'>
						{t('assistants:modal.chat.warning-tokens.title')}
					</ModalHeader>
					<ModalCloseButton mt={4} mr={3} />
					<ModalBody>
						<Text mb={0.5} color='gray.400' fontWeight='medium'>
							{t('assistants:modal.chat.warning-tokens.description', {
								tokensValue: tokensInitialValue.toLocaleString('pt-BR')
							})}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							fontSize='sm'
							mr={3}
							onClick={() => {
								onClose()
								sendGAEvent(
									'assistant_chat_go_back_to_conversation_button_clicked'
								)
							}}
						>
							{t('assistants:modal.chat.warning-tokens.action.cancel')}
						</Button>
						<CreateContentBtn
							coins={ASSISTANT_CHAT}
							isDisabled={false}
							nexStep={() => {
								handleSendMessage('true')
								onClose()
								sendGAEvent(
									'assistant_chat_buy_more_conversation_button_clicked'
								)
							}}
							user={user}
							text={t('assistants:modal.chat.warning-tokens.action.confirm')}
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
