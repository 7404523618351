import { Point } from '../types'
import { buildGrid, pointsToGridLayout } from './grid-utils'
import { activateNeighbors } from './neighbors-utils'

export function makeMap(points: Point[], canAdd: boolean) {
	const pointMap = pointsToGridLayout(points)
	const isEmpty = !points.length

	buildGrid(pointMap)

	if (isEmpty && canAdd) {
		pointMap[0][0].point_type = 'ADD'
	} else if (canAdd) {
		points.forEach(({ position }) => activateNeighbors(pointMap, position))
	}

	return pointMap
}
