import React from 'react'

import { Box, BoxProps, Center, Heading, Text } from '@chakra-ui/react'

type Props = {
	title: string
	description?: string
	children?: React.ReactNode
} & BoxProps

export const Empty = (emptyProps: Props) => {
	const { title, description, children, ...props } = emptyProps
	return (
		<Center
			fontSize={{ base: 'sm', sm: 'sm' }}
			textAlign='center'
			py={12}
			borderWidth={1}
			borderRadius='xl'
			borderColor='secondary.light'
			{...props}
		>
			<Box>
				<Heading fontSize={{ base: 'xs', sm: 'sm' }} color='gray.400' mb={2}>
					{title}
				</Heading>
				{description && (
					<Text
						w={['xs', 'xl']}
						fontSize={{ base: 'xs', sm: 'sm' }}
						px={4}
						color='gray.300'
					>
						{description}
					</Text>
				)}
				{children && <Box mt={5}>{children}</Box>}
			</Box>
		</Center>
	)
}
