import { ReactNode } from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

type DivergenceStore = {
	isAnonym: boolean
	setISAnonym: (isAnonym: boolean) => void
}

const { Provider, useStore } = createContext<DivergenceStore>()

type DivergenceProviderProps = {
	children: ReactNode
	value: { isAnonym: boolean }
}

function DivergenceProvider({ value, children }: DivergenceProviderProps) {
	const createStore = () =>
		create<DivergenceStore>((set) => ({
			setISAnonym: (isAnonym: boolean) => set({ isAnonym }),
			...value
		}))

	return <Provider createStore={createStore}>{children}</Provider>
}

export { useStore as useDivergenceStore, DivergenceProvider }
