import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { BiTrashAlt } from 'react-icons/bi'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useNavigate, useParams } from 'react-router-dom'

import {
	useBreakpointValue,
	useDisclosure,
	UseDisclosureReturn,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import useMonitorPointStatusStore from 'modules/map/components/points/monitor-point/store/use-monitor-point-status-store'
import { useClosePoint } from 'modules/map/hook/use-close-point'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point } from 'modules/map/modules/map-canvas/types'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import { useMe } from 'modules/user/hooks'
import { User } from 'modules/user/types'

import { monitorPointChannel } from '../socket/monitor-point-channel'
import {
	MonitorPointStatusCommentResponse,
	MonitorPointStatusResponse,
	MonitorType,
	QualitativePointStatus,
	QuantitativeMonitorPoint
} from '../types/monitor-point'
import { deleteMonitorPoint } from '../usecases/delete-monitor-point'
import { useMonitorPoint } from './use-monitor-point'
import { useMonitorPointStatus } from './use-monitor-point-status'

interface MonitorPointMenuOption {
	title: string
	onClick: () => void
	icon: IconType
	iconColor?: string
}

interface UseMonitorPointLogicReturn {
	state: { isLoading: boolean; selectedPoint: Point | null }
	disclosures: {
		addStatusDisclosure: UseDisclosureReturn
		deleteMonitorPointDisclosure: UseDisclosureReturn
		analysisMonitorPointDisclosure: UseDisclosureReturn
	}
	data: {
		monitorPoint: QuantitativeMonitorPoint | undefined
		monitorPointStatus: MonitorPointStatusResponse[] | undefined
		monitorPointMenuOptions: MonitorPointMenuOption[]
	}
	utils: {
		showAddStatusButton: boolean
		user: User | undefined
		conclusionDate: string
		monitorType: MonitorType
		isQualitative: boolean
		monitorCurrentStatusValue: string | number
		qualitativeCurrentStatus: string | undefined
		quantitativeGoal: number | undefined
		isMobile: boolean | undefined
		hasPrivileges: boolean
	}
	actions: {
		handleDeleteMonitorPoint: () => void
		handleToggleVisibility: () => void
	}
}

export const useMonitorPointLogic = (): UseMonitorPointLogicReturn => {
	const addStatusDisclosure = useDisclosure()
	const deleteMonitorPointDisclosure = useDisclosure()
	const analysisMonitorPointDisclosure = useDisclosure()
	const {
		id: journeyId = '',
		mapId = '',
		pointId: monitorPointId = ''
	} = useParams()
	const [showAddStatusButton, setShowAddStatusButton] = useState(false)
	const { data: monitorPoint, isError } = useMonitorPoint(monitorPointId)
	const { data: monitorPointStatus } = useMonitorPointStatus(monitorPointId)
	const {
		syncMonitorPointStatus,
		statuses,
		addStatusState,
		deleteStatusState,
		updateStatusState
	} = useMonitorPointStatusStore()
	const {
		actions: { clearSelected, removePoint, updatePoint },
		state: { selectedPoint }
	} = useMapStore()
	const { subscribe } = useSocket(monitorPointChannel({ projectId: journeyId }))
	const { emit } = useSocket(pointChannel({ projectId: journeyId, mapId }))
	const { user } = useMe()
	const navigate = useNavigate()
	const toast = useToast()
	const { t } = useTranslation()
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'monitor-point',
		pointId: selectedPoint?.id || ''
	})
	const isMobile = useBreakpointValue({ base: true, md: false })
	const hasPrivileges = useJourneyPrivileges()
	const closePoint = useClosePoint()

	useEffect(() => {
		if (isError) closePoint(monitorPointId, true)

		const timer = setTimeout(() => {
			setShowAddStatusButton(true)
		}, 500)

		return () => clearTimeout(timer)
	}, [isError])

	useEffect(() => {
		if (monitorPointStatus) {
			syncMonitorPointStatus(monitorPointStatus)
		}
	}, [monitorPointStatus])

	useEffect(() => {
		if (selectedPoint) {
			subscribe<MonitorPointStatusResponse | MonitorPointStatusCommentResponse>(
				'ADD',
				(data) => {
					if (data.monitor_point_id === selectedPoint.id) {
						addStatusState(data)
					}
				}
			)
			subscribe<MonitorPointStatusResponse | MonitorPointStatusCommentResponse>(
				'DELETE',
				(data) => {
					if (data.monitor_point_id === selectedPoint.id) {
						deleteStatusState(data)
					}
				}
			)
			subscribe<MonitorPointStatusResponse>('ADD_AGREEMENT', (data) => {
				if (data.monitor_point_id === selectedPoint.id) {
					updateStatusState(data)
				}
			})
			subscribe<MonitorPointStatusResponse>('DELETE_AGREEMENT', (data) => {
				if (data.monitor_point_id === selectedPoint.id) {
					updateStatusState(data)
				}
			})
		}
	}, [addStatusState, deleteStatusState, journeyId, selectedPoint, subscribe])

	if (!monitorPoint || !monitorPointStatus) {
		return {
			state: { isLoading: true, selectedPoint },
			disclosures: {
				addStatusDisclosure,
				deleteMonitorPointDisclosure,
				analysisMonitorPointDisclosure
			},
			data: {
				monitorPoint: undefined,
				monitorPointStatus: undefined,
				monitorPointMenuOptions: []
			},
			utils: {
				showAddStatusButton: false,
				user: undefined,
				conclusionDate: '',
				monitorType: MonitorType.QUALITATIVE,
				isQualitative: false,
				monitorCurrentStatusValue: '',
				qualitativeCurrentStatus: undefined,
				quantitativeGoal: undefined,
				isMobile,
				hasPrivileges
			},
			actions: {
				handleDeleteMonitorPoint: () => {},
				handleToggleVisibility: () => {}
			}
		}
	}

	const conclusionDate = new Date(
		monitorPoint?.conclusion_date
	).toLocaleDateString(user?.language === 'PT_BR' ? 'pt-BR' : undefined)

	const monitorType = monitorPoint?.monitor_type
	const isQualitative = monitorType === MonitorType.QUALITATIVE
	const currentStatusValue = monitorPoint?.current_status?.value

	const monitorCurrentStatusValue = isQualitative
		? currentStatusValue ?? QualitativePointStatus.STARTED
		: `${currentStatusValue ?? 0}%`

	const qualitativeStatusMapper = {
		[QualitativePointStatus.STARTED]: t(
			'map:points:monitor:status:name:options:started'
		),
		[QualitativePointStatus.IN_PROGRESS]: t(
			'map:points:monitor:status:name:options:progress'
		),
		[QualitativePointStatus.COMPLETED]: t(
			'map:points:monitor:status:name:options:completed'
		),
		[QualitativePointStatus.SUSPENDED]: t(
			'map:points:monitor:status:name:options:suspended'
		)
	}

	const currentStatus =
		qualitativeStatusMapper[
			monitorCurrentStatusValue as keyof typeof qualitativeStatusMapper
		]

	const handleDeleteMonitorPoint = async () => {
		toast.closeAll()

		try {
			await deleteMonitorPoint(monitorPointId)
			removePoint(monitorPoint as unknown as Point)

			const socketPayload = {
				id: monitorPoint.id,
				position: monitorPoint.position,
				point_type: 'MONITOR'
			}
			emit('DELETE', socketPayload)

			toast({
				title: t('map:points.divergence.delete.success'),
				status: 'success'
			})

			clearSelected()
			navigate('../..')
		} catch (error) {
			console.log('error on handleDeleteMonitorPoint', error)
			toast({
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error'
			})
		}
	}

	const handleToggleVisibility = async () => {
		toast.closeAll()

		if (!selectedPoint) {
			toast({
				title: t('errors:point.error.title'),
				description: t('errors:point.error.description'),
				status: 'error'
			})
			return
		}

		updatePointVisibility(!selectedPoint.visible, {
			onSuccess: () => {
				updatePoint({ ...selectedPoint, visible: !selectedPoint.visible })
				emit('EDIT', {
					...selectedPoint,
					visible: !selectedPoint.visible
				})
				toast({
					title: selectedPoint.visible
						? t('map:points.action.hidden.success')
						: t('map:points.action.visible.success'),
					description: selectedPoint.visible
						? t('map:points.action.state.tooltip-alt')
						: '',
					status: 'success'
				})
			},
			onError: (error) => {
				console.log('error on handleDeleteMonitorPoint', error)
				toast({
					title: t('errors:request.error.title'),
					description: t('errors:point.error.description'),
					status: 'error'
				})
			}
		})
	}

	const deleteOption = {
		title: isMobile
			? t('map:points:monitor:view:actions:deleteMobileAlt')
			: t('map:points:monitor:view:actions:deleteAlt'),
		onClick: deleteMonitorPointDisclosure.onOpen,
		icon: BiTrashAlt,
		iconColor: 'red'
	}

	const toggleVisibilityOption = {
		title: selectedPoint?.visible
			? t('map:points.action.hidden.title')
			: t('map:points.action.visible.title'),
		onClick: handleToggleVisibility,
		icon: selectedPoint?.visible ? LuEye : LuEyeOff
	}

	const monitorPointMenuOptions: MonitorPointMenuOption[] = isMobile
		? [toggleVisibilityOption, deleteOption]
		: [deleteOption]

	return {
		state: { isLoading: false, selectedPoint },
		disclosures: {
			addStatusDisclosure,
			deleteMonitorPointDisclosure,
			analysisMonitorPointDisclosure
		},
		data: {
			monitorPoint,
			monitorPointStatus: statuses,
			monitorPointMenuOptions
		},
		utils: {
			showAddStatusButton,
			user,
			conclusionDate,
			monitorType,
			isQualitative,
			monitorCurrentStatusValue,
			qualitativeCurrentStatus: currentStatus,
			quantitativeGoal: monitorPoint.goal ?? 0,
			isMobile,
			hasPrivileges
		},
		actions: { handleDeleteMonitorPoint, handleToggleVisibility }
	}
}
