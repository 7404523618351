import { fetch } from 'config'

import { Point, Position } from '../../map-canvas/types'

type Params = {
	description: string
	position: Position
	send_email: boolean
	map_id: string
}

export async function createNoticePoint(params: Params) {
	const { map_id, ...data } = params

	const response = await fetch<Point>({
		url: `/projects/v1/map/${map_id}/notice-point`,
		method: 'POST',
		data
	})
	return response.data
}
