import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#0046A7',
		light: '#7FB5FF'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FFEBDB'
	}
}

export default themeColors
