import { fetch } from 'config'
import { mountParams, SortOrder } from 'data/helpers/mount-params'

import { QueryFilter } from 'modules/common/types/common-model'

import { TemplatesSummaryResponse } from '../types'

interface Params {
	search?: string
	order: SortOrder
	filter: QueryFilter
	size?: number
	pageParam: number
	official: boolean
	sortBy?: string
}

export const getOfficialTemplates = ({
	search,
	order,
	filter,
	pageParam = 0,
	size,
	sortBy = 'createdAt',
	official
}: Params) => {
	const params = mountParams({
		size,
		page: pageParam,
		sort: [sortBy, order],
		title: search,
		official,
		...filter
	})

	return fetch<TemplatesSummaryResponse>({
		url: 'templates/v1/template/explore',
		method: 'GET',
		params
	}).then(({ data }) => data)
}
