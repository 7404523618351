import { fetch } from 'config'

import { Map } from '../types'

export function loadMap(mapId: string): Promise<Map> {
	return fetch<Map>({
		url: `projects/v1/map/${mapId}`,
		method: 'GET'
	}).then(({ data }) => data)
}
