import { TenantColors } from '../types'
import defaultCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const defaultColors: TenantColors = {
	...defaultCustomColors,
	...themeColors,
	...componentColors,
	...screenColors
}

export default defaultColors
