import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'

import { Icon, IconButton, Tooltip } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { updateClipboard } from 'modules/common/utils/clipboard'

type Props = {
	params: ShareData & { url: string }
	onSuccess?: () => void
	pointType: string
}

export const ShareButton: FC<Props> = ({ params, onSuccess, pointType }) => {
	const { t } = useTranslation()

	const handleCopy = () => {
		updateClipboard(params.url).then(() => onSuccess?.())
		sendGAEvent(`share_${pointType}_point`)
	}

	const hasClipboardSupport = navigator.clipboard !== undefined

	if (hasClipboardSupport) {
		return (
			<Tooltip label={t('buttons:copyLink')}>
				<IconButton
					aria-label='copy'
					variant='ghost'
					color='gray.400'
					onClick={handleCopy}
					icon={<Icon as={BiLink} />}
				/>
			</Tooltip>
		)
	}

	return null
}
