import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { BiPrinter } from 'react-icons/bi'
import { useReactToPrint } from 'react-to-print'

import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalHeader,
	ModalBody,
	ModalContent,
	Text,
	Box,
	Image,
	Flex,
	useBreakpointValue,
	useToast,
	HStack,
	Icon,
	Circle,
	ModalFooter
} from '@chakra-ui/react'

import loading from 'modules/common/assets/img/loading.svg'
import { QUICK_SUMMARY_CREATION_COST } from 'modules/constants'
import { User } from 'modules/user/types'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import { createQuickSummary } from './usecases/create-quick-summary'

type Props = {
	onClose: () => void
	isOpen: boolean
	language: string
	user: User
	pointId: string
	questionId: string
}

export const QuickSummary = ({
	onClose,
	isOpen,
	language,
	user,
	pointId,
	questionId
}: Props) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const toast = useToast()
	const [quickSummary, setQuickSummary] = useState('')

	const modalRef = useRef(null)

	const handlePrint = useReactToPrint({
		content: () => modalRef.current
	})

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleQuickSummary = async () => {
		try {
			const response = await createQuickSummary({
				pointId,
				questionId,
				language
			})
			setQuickSummary(response.summary)
			await consumeCredits(QUICK_SUMMARY_CREATION_COST)
			updateUserCredits((user?.credits || 0) - QUICK_SUMMARY_CREATION_COST)
		} catch (error) {
			onClose()
			toast({
				title: t('errors:summaryGPT.error'),
				status: 'error',
				isClosable: true
			})
		}
	}

	useEffect(() => {
		handleQuickSummary()
	}, [])

	return (
		<Modal
			closeOnOverlayClick={false}
			isCentered={!isMobile}
			size='3xl'
			onClose={onClose}
			isOpen={isOpen}
		>
			<ModalOverlay />
			<ModalContent ref={modalRef} minH='60%'>
				<ModalCloseButton mt={isMobile ? 2 : 8} mx={isMobile ? 2 : 8} />
				<ModalHeader px={isMobile ? 6 : 10} pt={isMobile ? 6 : 10}>
					<Text>{t('divergencePoint:quickSummary.title')}</Text>
				</ModalHeader>
				<ModalBody px={isMobile ? 6 : 10} mb={6}>
					<Box>
						{!quickSummary ? (
							<Box textAlign='center' mt='25%'>
								<Flex placeContent='center'>
									<Image
										css={{
											animationFillMode: 'both'
										}}
										w={12}
										animation='loading 1.5s linear infinite alternate'
										src={loading}
									/>
								</Flex>
								<Text
									textColor={'gray.400'}
									fontSize={'sm'}
									fontWeight='semibold'
									whiteSpace={'break-spaces'}
									p={10}
								>
									{t('divergencePoint:quickSummary.loading')}
								</Text>
							</Box>
						) : (
							<Text variant='breakLines'>{quickSummary}</Text>
						)}
					</Box>
				</ModalBody>
				{quickSummary && (
					<ModalFooter>
						<HStack spacing={3} alignItems={'center'}>
							<Icon as={AiOutlineExclamationCircle} color='red' h={5} w={5} />
							<Text textColor={'gray.400'} fontSize='sm' fontWeight='semibold'>
								{t('divergencePoint:quickSummary.print')}
							</Text>
							<Circle
								size={10}
								cursor={'pointer'}
								backgroundColor='mobile_print_floating_action_button'
								aria-label='Scroll to top'
								onClick={handlePrint}
							>
								<Icon color='white' as={BiPrinter} w={6} h={6} />
							</Circle>
						</HStack>
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	)
}
