import Notifications from 'modules/journeys/hooks/usecases'
import Projects from 'modules/journeys/usecases'
import Authentication from 'modules/login/usecases'
import Templates from 'modules/templates/usecases'
import Tools from 'modules/tools/usecases'
import Users from 'modules/user/usecases'

const Api = {
	Projects,
	Authentication,
	Notifications,
	Tools,
	Users,
	Templates
}

export default Api
