import { PointMap, PointType } from '../types'
import { isAValidPosition } from './grid-utils'
import { EvenNeighbors, OddNeighbors } from './hexagon-utils'
import { isOdd } from './position-utils'

const defaultPosition = { row: 0, col: 0 }

export function activateNeighbors(map: PointMap, position = defaultPosition) {
	const { row, col } = position
	const directions = isOdd(row) ? EvenNeighbors : OddNeighbors

	for (const [dRow, dCol] of directions) {
		const refRow = row + dRow
		const refCol = col + dCol

		if (isAValidPosition(refRow, refCol)) {
			const point = map[refRow][refCol]

			if (point.point_type === 'EMPTY') {
				point.point_type = 'ADD'
			}
		}
	}
}

export function findEmptyPoint(map: PointMap, position = defaultPosition) {
	const { row, col } = position
	const directions = isOdd(row) ? EvenNeighbors : OddNeighbors
	const queue: { row: number; col: number }[] = []

	queue.push({ row, col })

	while (queue.length > 0) {
		const currentPosition = queue.shift()
		if (!currentPosition) {
			continue
		}

		const { row, col } = currentPosition
		if (!isAValidPosition(row, col)) {
			continue
		}

		const point = map[row][col]
		if (point.point_type === 'ADD') {
			return point.position
		}

		for (const [dRow, dCol] of directions) {
			const refRow = row + dRow
			const refCol = col + dCol
			queue.push({ row: refRow, col: refCol })
		}
	}

	return defaultPosition
}

export function disableNeighbors(map: PointMap, position = defaultPosition) {
	const { row, col } = position
	const directions = isOdd(row) ? EvenNeighbors : OddNeighbors

	for (const [dRow, dCol] of directions) {
		const refRow = row + dRow
		const refCol = col + dCol

		if (isAValidPosition(refRow, refCol)) {
			const point = map[refRow][refCol]

			if (!hasFilledNeighbors(map, point.position)) {
				if (point.point_type === 'ADD') {
					point.point_type = 'EMPTY'
				}
			}
		}
	}
}

export function hasFilledNeighbors(map: PointMap, position = defaultPosition) {
	const { row, col } = position
	const directions = isOdd(row) ? EvenNeighbors : OddNeighbors

	const neighbors = directions.map(([dRow, dCol]) => {
		const refRow = row + dRow
		const refCol = col + dCol

		if (isAValidPosition(refRow, refCol)) {
			const point = map[refRow][refCol]
			return point
		}
		return null
	})

	const invalidNeighbors: PointType[] = ['ADD', 'EMPTY']

	return neighbors.some((neighbor) => {
		if (neighbor) {
			return !invalidNeighbors.includes(neighbor.point_type)
		}
		return false
	})
}
