import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Stack, Text, HStack, useToken } from '@chakra-ui/react'

import { ConvergenceQuestion } from '../../types'
import { BeliefAmount } from '../belief-amount'
import { BeliefOptionAllocation } from '../belief-option-allocation'
import { CardQuestion } from '../card-question'

type Props = {
	question: ConvergenceQuestion
}

export const BeliefAllocation: React.FC<Props> = ({ question }) => {
	const { t } = useTranslation()
	const environment = (question.environment_average || 0) * 100

	return (
		<CardQuestion>
			<Stack direction='row'>
				<Text fontSize='sm' fontWeight='semibold'>
					{question.text}
				</Text>
			</Stack>

			<Stack mt={4} spacing={4}>
				{question.options.map((option) => (
					<BeliefOptionAllocation key={option.id} option={option} />
				))}
			</Stack>

			<Stack align='flex-end' mt={4}>
				<Box>
					<BeliefAmount
						text={t('map:points.convergence.remainingChips')}
						amount={environment}
					/>
				</Box>
			</Stack>
		</CardQuestion>
	)
}

export const BeliefAllocationMy: React.FC<Props> = ({ question }) => {
	// const environment = question.environment || 0
	const [borderColor] = useToken('colors', ['gray.100'])

	return (
		<CardQuestion>
			<Stack direction='row'>
				<Text fontSize='sm' fontWeight='semibold'>
					{question.text}
				</Text>
			</Stack>

			<Stack mt={4} spacing={4}>
				{question.options.map((option) => (
					<HStack
						key={option.option_id}
						w='full'
						justify='space-between'
						align='center'
					>
						<Text minW='3xs' fontSize='sm' fontWeight='medium'>
							{option.text}
						</Text>
						<Box textAlign='center' border={`1px solid ${borderColor}`}>
							{option.belief}
						</Box>
					</HStack>
				))}
			</Stack>

			{/* <Stack align="flex-end" mt={4}>
				<Box>
					<BeliefAmount
						text={t('map:points.convergence.remainingChips')}
						amount={environment}
					/>
				</Box>
			</Stack> */}
		</CardQuestion>
	)
}
