import { TenantColors } from '../types'
import umuarama2050CustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const umuarama2050Colors: TenantColors = {
	...umuarama2050CustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default umuarama2050Colors
