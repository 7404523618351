export const handleDateFormat = (date: Date, lang: string) => {
	const dt = new Date(date)
	const day = String(dt.getDate()).padStart(2, '0')
	const pt_month = String(dt.getMonth() + 1).padStart(2, '0')
	const month = String(dt.getMonth() + 1)
	const year = dt.getFullYear()

	if (lang === 'ptBR') {
		return `${day}/${pt_month}/${year}`
	}
	return `${month}/${day}/${year}`
}
