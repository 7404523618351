import React from 'react'

import {
	Modal,
	ModalOverlay,
	UseDisclosureReturn,
	useBreakpointValue
} from '@chakra-ui/react'

import { ConversationPoint } from '../../types'
import { ConversationForm } from '../form'

type Props = {
	disclosure: UseDisclosureReturn
	point?: ConversationPoint
}

export const UpdateConversationPoint: React.FC<Props> = ({
	disclosure,
	point
}) => {
	const mobileScreen = useBreakpointValue({ base: true, md: false })

	return (
		<Modal {...disclosure} size='2xl' isCentered={!mobileScreen}>
			<ModalOverlay />
			<ConversationForm point={point} />
		</Modal>
	)
}
