// hook utilizado para validar o token externo enviado pela CIA de Talentos

import { useQuery } from 'react-query'

import { validateExternalToken } from '../usecases/validate-external-toker'

export const useGetValidateExternalToken = ({
	accountId,
	payload
}: {
	accountId: string
	payload: string
}) => {
	const { data } = useQuery('externalToken', () =>
		validateExternalToken({ accountId, payload })
	)

	return {
		data
	}
}
