import { useTranslation } from 'react-i18next'
import { HiOutlineUserGroup } from 'react-icons/hi'

import { Button, Tag, useBreakpointValue, useDisclosure } from '@chakra-ui/react'

import { User } from 'modules/user/types'

import { CommentAll } from '.'

type Props = {
	user?: User
	pointId: string
	questionId: string
}

export const CommentAllButton = ({ user, pointId, questionId }: Props) => {
	const disclosure = useDisclosure()
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	return (
		<>
			<Button
				leftIcon={<HiOutlineUserGroup size={18} />}
				size='sm'
				colorScheme='green'
				rightIcon={<BetaBadge />}
				onClick={disclosure.onOpen}
			>
				{isMobile ? t('divergencePoint:commentAll.mobileButton') : t('divergencePoint:commentAll.webButton')}
			</Button>
			{disclosure.isOpen && user && <CommentAll user={user} questionId={questionId} pointId={pointId} {...disclosure} />}
		</>
	)
}

const BetaBadge = () => {
	return (
		<Tag fontSize={12} variant='solid' colorScheme='red'>
			beta
		</Tag>
	)
}
