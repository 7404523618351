import { fetch } from 'config'

import { Labs } from 'modules/labs/types'

export const getLabMembers = (labId: string) => {
	return fetch<{ admin: Labs.TeamMember; team: Labs.TeamMember[] }>({
		url: `users/v1/lab/${labId}/team`,
		method: 'GET'
	})
}
