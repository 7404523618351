import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiLike, BiComment, BiChat, BiSolidLike } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import {
	Box,
	Button,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	Tooltip,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import useSocket from 'config/socket/useSocket'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { IconStLightning } from 'modules/common/components/icons/st-lightning'
import { CreditsBtn } from 'modules/common/components/use-credits-button/credits-button'
import { EVALUATION_POINT_EVALUATE_ESSAY } from 'modules/constants'
import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { EvaluationAgreement, EvaluationResponse } from 'modules/map/types'
import { useMe } from 'modules/user/hooks'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import { evaluateEssay } from '../usecases/evaluate-essay'
import { likeEssayResponse } from '../usecases/like-essay-response'
import { EvaluationCard } from './essay-evaluation-card'

const buttonProps = {
	color: 'pink.500',
	fontWeight: 'semibold',
	fontSize: 'xs',
	background: 'transparent'
}

const iconProps = {
	width: '1.500rem',
	height: '1.500rem',
	color: 'pink.500'
}

type LikeProps = {
	commentsAgreements: EvaluationAgreement[]
	userId: string
	pointId: string
	responseId: string
}

export const Like = ({
	commentsAgreements,
	userId,
	pointId,
	responseId
}: LikeProps) => {
	const { t } = useTranslation()
	const { id: journeyId = '' } = useParams<{ id: string }>()
	const { isAnonymous } = useEvaluationStore()
	const hasUserLike = commentsAgreements.some(
		(comment) => comment.user_id === userId
	)
	const { updateEssayState } = useEssaysStore()
	const countLike = commentsAgreements.length
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))

	const stringLike =
		countLike > 0
			? t('evaluationPoint:essay.like', { count: countLike })
			: t('divergencePoint:Like')

	const handleLike = async () => {
		if (hasUserLike) {
			const likeResponse = await likeEssayResponse({
				action: 'disagree',
				pointId,
				responseId
			})
			updateEssayState(likeResponse)
			const agreementInfo = {
				essay_point_id: pointId,
				parent_id: likeResponse.id,
				id: responseId
			}
			emit('DELETE_AGREEMENT', agreementInfo)
		} else {
			const likeResponse = await likeEssayResponse({
				action: 'agree',
				pointId,
				responseId
			})
			updateEssayState(likeResponse)
			const agreementInfo = {
				essay_point_id: pointId,
				parent_id: likeResponse.id,
				id: responseId
			}
			emit('DELETE_AGREEMENT', agreementInfo)
		}
	}

	const renderLabels = () => {
		if (isAnonymous) {
			return null
		}

		if (commentsAgreements.length) {
			return (
				<Box>
					{commentsAgreements.map((agreement, index) => (
						<Text key={`${agreement.user_id}_${index}`}>{agreement.name}</Text>
					))}
				</Box>
			)
		}

		return null
	}

	return (
		<Tooltip label={renderLabels()}>
			<Button
				sx={{
					color: 'evaluation_answer_like_button',
					svg: { color: 'evaluation_answer_like_button' }
				}}
				leftIcon={
					<Icon
						as={hasUserLike ? BiSolidLike : BiLike}
						type='solid'
						{...iconProps}
					/>
				}
				onClick={handleLike}
				{...buttonProps}
			>
				{stringLike}
			</Button>
		</Tooltip>
	)
}

type CommentProps = {
	commentsCount: number
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}
export const Comment = ({ commentsCount, isOpen, setIsOpen }: CommentProps) => {
	const { t } = useTranslation()

	const handleClickToReply = () => {
		setIsOpen(!isOpen)
	}

	return commentsCount === 0 ? (
		<Button
			sx={{
				color: 'evaluation_answer_comment_button',
				svg: { color: 'evaluation_answer_comment_button' }
			}}
			leftIcon={<Icon as={BiComment} {...iconProps} />}
			onClick={handleClickToReply}
			{...buttonProps}
		>
			{t('divergencePoint:Comment')}
		</Button>
	) : (
		<Button
			sx={{
				color: 'evaluation_answer_comment_button',
				svg: { color: 'evaluation_answer_comment_button' }
			}}
			leftIcon={<Icon as={BiChat} {...iconProps} />}
			onClick={handleClickToReply}
			{...buttonProps}
		>
			{t('divergencePoint:comments', { count: commentsCount })}
			{isOpen && <ChevronUpIcon boxSize='1.563rem' color='gray.400' />}
			{!isOpen && <ChevronDownIcon boxSize='1.563rem' color='gray.400' />}
		</Button>
	)
}

type EvaluateProps = {
	essayId: string
	pointId: string
}
export const EvaluateEssay = ({ essayId, pointId }: EvaluateProps) => {
	const { t } = useTranslation()
	const { user } = useMe()
	const { addEvaluateEssaytState } = useEssaysStore()
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const disclosure = useDisclosure()
	const [evaluationResult, setEvaluationResult] = useState<EvaluationResponse>()
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	const handleEvaluate = async () => {
		if (user && user.credits >= 2) {
			try {
				setIsLoading(true)
				const evaluation = await evaluateEssay({ essayId, pointId })
				setEvaluationResult(evaluation)
				addEvaluateEssaytState(evaluation)
				setIsLoading(false)
				if (isMobile) {
					disclosure.onOpen()
				}

				await consumeCredits(EVALUATION_POINT_EVALUATE_ESSAY)
				updateUserCredits(
					(user?.credits || 0) - EVALUATION_POINT_EVALUATE_ESSAY
				)
				setIsLoading(false)
			} catch (error) {
				toast({
					title: t('errors:summaryGPT.error'),
					status: 'error',
					isClosable: true
				})
				setIsLoading(false)
			}
		} else {
			emptyCreditsDisclosure.onOpen()
		}
	}

	return (
		<>
			<CreditsBtn
				icon={<Icon as={IconStLightning} fontSize='md' fill='white' />}
				onClick={handleEvaluate}
				price={EVALUATION_POINT_EVALUATE_ESSAY}
				text={t('buttons:evaluateEssay')}
				userCredits={user?.credits || 0}
				isLoading={isLoading}
			/>
			<ModalSeeEvaluation {...disclosure} content={evaluationResult} />
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={user?.credits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</>
	)
}

export const OpenEvaluationModal = ({
	content
}: {
	content: EvaluationResponse
}) => {
	const { t } = useTranslation()
	const disclosure = useDisclosure()

	return (
		<>
			<Button onClick={disclosure.onOpen} {...buttonProps}>
				{t('buttons:seeEvaluation')}
			</Button>
			<ModalSeeEvaluation {...disclosure} content={content} />
		</>
	)
}

const ModalSeeEvaluation = ({
	isOpen,
	onClose,
	content
}: {
	onClose: () => void
	isOpen: boolean
	content?: EvaluationResponse
}) => {
	return (
		<Modal size='full' isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody py={12}>
					{content && <EvaluationCard userLanguage='PT_BR' content={content} />}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
