import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#5B319E',
		light: '#805AD5'
	},

	secondary: {
		dark: '#EF6C00',
		light: '#E9B489'
	}
}

export default themeColors
