import { fetch } from 'config'

import { EvaluationResponse as EvaluationAnswerResponse } from 'modules/map/types'

type Params = {
	pointId: string
	text: string
	title: string
}

export async function createEssayAnswer(params: Params) {
	const { pointId, ...data } = params
	const response = await fetch<EvaluationAnswerResponse>({
		url: `/projects/v1/essay-point/${pointId}/responses`,
		method: 'POST',
		data
	})
	return response.data
}
