import { fetch } from 'config'

import { MonitorPointStatusResponse } from '../types/monitor-point'

/**
 * Retrieves all statuses and comments associated with a monitor point.
 *
 * Despite the endpoint containing "comments" in the URL, this function returns all statuses
 * of the monitor point, including comments for each status, if any.
 */
export const getMonitorPointStatus = async (monitorPointId: string) => {
	const response = await fetch<MonitorPointStatusResponse[]>({
		url: `projects/v1/monitor-point/${monitorPointId}/comments`,
		method: 'GET'
	})

	return response.data
}
