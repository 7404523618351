import i18n from 'i18n/i18n'
import { object, string, ref, boolean } from 'yup'

export const ciaValidation = () =>
	object({
		password: string()
			.required(i18n.t('errors:requiredField'))
			.min(8, i18n.t('signup:minPassword')),
		confirmPassword: string()
			.required(i18n.t('errors:requiredField'))
			.oneOf([ref('password'), null], i18n.t('signup:matchPassword'))
			.min(8, i18n.t('signup:minPassword')),
		term_accepted: boolean()
			.oneOf([true], i18n.t('signup:acceptTermsMessage'))
			.required(i18n.t('signup:acceptTermsMessage'))
	})
