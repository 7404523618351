import { Avatar, Icon } from '@chakra-ui/react'

import { IconStAnonym } from 'modules/common/components/icons/st-anonym'
import { useStringToColor } from 'modules/common/hooks'

type AnonymAvatarProps = {
	backgroundColor: string
	name: string
	avatarUrl?: string
	isAnonym: boolean
}

export const AnonymAvatar = (props: AnonymAvatarProps) => {
	const { name, backgroundColor, avatarUrl, isAnonym } = props
	const colorScheme = [
		'yellow',
		'teal',
		'pink',
		'orange',
		'magenta',
		'purple',
		'gray'
	]

	const [, iconColor] = useStringToColor({ text: name }, [400], colorScheme)

	const fg = isAnonym ? iconColor : '#FFFFFF'
	const bg = isAnonym ? 'gray.500' : backgroundColor

	const icon = isAnonym ? (
		<Icon as={IconStAnonym} fontSize='xl' color={fg} />
	) : undefined
	const avatarImage = isAnonym ? '' : avatarUrl

	return (
		<Avatar
			size='sm'
			w={9}
			h={9}
			backgroundColor={bg}
			name={isAnonym ? undefined : name}
			color='#FFFFFF'
			icon={icon}
			src={avatarImage}
		/>
	)
}
