import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStNotice: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 35 35' {...props}>
		<path
			d='M16.5 0.577351C17.1188 0.220085 17.8812 0.220085 18.5 0.57735L31.6554 8.17265C32.2742 8.52991 32.6554 9.19017 32.6554 9.9047V25.0953C32.6554 25.8098 32.2742 26.4701 31.6554 26.8274L18.5 34.4226C17.8812 34.7799 17.1188 34.7799 16.5 34.4226L3.34456 26.8274C2.72575 26.4701 2.34456 25.8098 2.34456 25.0953V9.9047C2.34456 9.19017 2.72575 8.52992 3.34456 8.17265L16.5 0.577351Z'
			fill='currentColor'
		/>
		<path
			d='M22.25 11H11.75C11.1533 11 10.581 11.2371 10.159 11.659C9.73705 12.081 9.5 12.6533 9.5 13.25V20.75C9.5 21.3467 9.73705 21.919 10.159 22.341C10.581 22.7629 11.1533 23 11.75 23H20.4425L23.2175 25.7825C23.2876 25.852 23.3707 25.907 23.4621 25.9443C23.5534 25.9817 23.6513 26.0006 23.75 26C23.8484 26.0025 23.946 25.982 24.035 25.94C24.172 25.8837 24.2892 25.7882 24.372 25.6654C24.4547 25.5426 24.4993 25.3981 24.5 25.25V13.25C24.5 12.6533 24.2629 12.081 23.841 11.659C23.419 11.2371 22.8467 11 22.25 11ZM23 23.4425L21.2825 21.7175C21.2124 21.648 21.1293 21.593 21.0379 21.5557C20.9466 21.5183 20.8487 21.4994 20.75 21.5H11.75C11.5511 21.5 11.3603 21.421 11.2197 21.2803C11.079 21.1397 11 20.9489 11 20.75V13.25C11 13.0511 11.079 12.8603 11.2197 12.7197C11.3603 12.579 11.5511 12.5 11.75 12.5H22.25C22.4489 12.5 22.6397 12.579 22.7803 12.7197C22.921 12.8603 23 13.0511 23 13.25V23.4425ZM17 18.5C16.8517 18.5 16.7067 18.544 16.5833 18.6264C16.46 18.7088 16.3639 18.8259 16.3071 18.963C16.2503 19.1 16.2355 19.2508 16.2644 19.3963C16.2933 19.5418 16.3648 19.6754 16.4697 19.7803C16.5746 19.8852 16.7082 19.9567 16.8537 19.9856C16.9992 20.0145 17.15 19.9997 17.287 19.9429C17.4241 19.8861 17.5412 19.79 17.6236 19.6667C17.706 19.5433 17.75 19.3983 17.75 19.25C17.75 19.0511 17.671 18.8603 17.5303 18.7197C17.3897 18.579 17.1989 18.5 17 18.5ZM17 14C16.8011 14 16.6103 14.079 16.4697 14.2197C16.329 14.3603 16.25 14.5511 16.25 14.75V17C16.25 17.1989 16.329 17.3897 16.4697 17.5303C16.6103 17.671 16.8011 17.75 17 17.75C17.1989 17.75 17.3897 17.671 17.5303 17.5303C17.671 17.3897 17.75 17.1989 17.75 17V14.75C17.75 14.5511 17.671 14.3603 17.5303 14.2197C17.3897 14.079 17.1989 14 17 14Z'
			fill='white'
		/>
	</IconBase>
)
