import { useQuery } from 'react-query'

import { checkFreeFirstChatRoomAvailability } from 'modules/assistants/usecases/chat-room/check-free-first-chat-room-availability'

export const useCheckFreeFirstChatRoom = (enabled: boolean) => {
	return useQuery(
		'free_first_chatroom_availability',
		() => checkFreeFirstChatRoomAvailability(),
		{
			enabled
		}
	)
}
