import queryClient from 'config/query-client'

import { userKeys } from '../query'
import { User } from '../types'

export const updateUserCache = (userParams: Partial<User>) => {
	const [Query] = queryClient.getQueryCache().findAll(userKeys.me())
	if (Query) {
		Query.setData((user: User) => {
			return { ...user, ...userParams }
		})
	}
}

export const decreaseFollowingInfo = () => {
	const [Query] = queryClient.getQueryCache().findAll(userKeys.me())
	return Query?.setData((user: User) => {
		user.network_info.following_count -= 1
		return user
	})
}

export const incrementFollowingInfo = () => {
	const [Query] = queryClient.getQueryCache().findAll(userKeys.me())
	return Query?.setData((user: User) => {
		user.network_info.following_count += 1
		return user
	})
}

export const decreaseFollowerInfo = () => {
	const [Query] = queryClient.getQueryCache().findAll(userKeys.me())
	return Query?.setData((user: User) => {
		user.network_info.followers_count -= 1
		return user
	})
}

export const incrementFollowerInfo = () => {
	const [Query] = queryClient.getQueryCache().findAll(userKeys.me())
	return Query?.setData((user: User) => {
		user.network_info.followers_count += 1
		return user
	})
}
