import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { ASSISTANT } from 'modules/constants'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { createAssistandComment } from 'modules/map/usecases/create-assistant-comment'
import { useMe } from 'modules/user/hooks'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

type SelectProps = {
	assistantId: string
	divergencePointId: string
	questionId: string
	onResponse: (response: any) => void
	responseSize: string
}

export const AssistantQuestionCommentButton = ({
	assistantId,
	divergencePointId,
	questionId,
	onResponse,
	responseSize
}: SelectProps) => {
	const { t } = useTranslation()
	const { user } = useMe()
	const [isLoading, setIsloading] = useState(false)
	const isValid = !!assistantId
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()
	const toast = useToast()

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleCreateComment = async (option: string) => {
		if (user && user.credits < ASSISTANT) {
			return emptyCreditsDisclosure.onOpen()
		}

		setIsloading(true)

		try {
			const comment = await createAssistandComment({
				assistantId,
				divergencePointId,
				questionId,
				language: option,
				responseSize
			})
			onResponse(comment)

			await consumeCredits(ASSISTANT)
			updateUserCredits((user?.credits || 0) - ASSISTANT)
		} catch (error) {
			console.error(error)
			toast({
				title: t('errors:request.error.title'),
				status: 'error'
			})
		} finally {
			setIsloading(false)
		}
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	return (
		<Menu>
			{!isValid ? (
				<CreateContentBtn
					coins={ASSISTANT}
					isDisabled={!isValid}
					nexStep={() => { }}
					user={user}
					text={t('buttons:requestAnswerAssistant')}
				/>
			) : (
				<MenuButton w={'full'}>
					<CreateContentBtn
						coins={ASSISTANT}
						nexStep={() => { }}
						user={user}
						isDisabled={!isValid || isLoading}
						text={t('buttons:requestAnswerAssistant')}
						isLoading={isLoading}
					/>
				</MenuButton>
			)}
			{isValid && !isLoading && (
				<MenuList bgColor='blue.2' minW='100px' rounded='8'>
					<MenuItem
						w='full'
						onClick={() => handleCreateComment('PT_BR')}
						roundedTop='8'
					>
						<HStack justify='space-between'>
							<Text fontWeight='normal' color='black'>
								{t('startingPoint:create.languages.pt')}
							</Text>
						</HStack>
					</MenuItem>
					<MenuItem onClick={() => handleCreateComment('EN_US')}>
						<HStack>
							<Text fontWeight='normal' color='black'>
								{t('startingPoint:create.languages.en')}
							</Text>
						</HStack>
					</MenuItem>
					<MenuItem
						onClick={() => handleCreateComment('ES_ES')}
						roundedBottom='8'
					>
						<HStack>
							<Text fontWeight='normal' color='black'>
								{t('startingPoint:create.languages.es')}
							</Text>
						</HStack>
					</MenuItem>
				</MenuList>
			)}
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={user?.credits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Menu>
	)
}
