import { ReactNode } from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

import { getEssaypointResponses } from '../components/view-evaluation-point/usecases/get-essay-responses'
import { EvaluationResponse } from '../types'

interface EssayStoreState {
	essayPointResponses: EvaluationResponse[]
	addEssayState: (data: EvaluationResponse) => void
	addReplyState: (data: EvaluationResponse) => void
	fetchEssaysAndReplies: (evaluationPointId: string) => void
	deleteEssayState: (data: EvaluationResponse, id?: string) => void
	deleteReplyState: (data: EvaluationResponse) => void
	addEvaluateEssaytState: (data: EvaluationResponse) => void
	updateEssayState: (data: EvaluationResponse) => void
}

const { Provider, useStore } = createContext<EssayStoreState>()

const EssaysStore = () =>
	create<EssayStoreState>((set, get) => ({
		essayPointResponses: [],

		addEssayState: async (data: EvaluationResponse) => {
			const { essayPointResponses } = get()
			if (data.parent_id) {
				get().addReplyState(data)
				return
			}

			const hasResponse = essayPointResponses.find(({ id }) => id === data.id)
			if (hasResponse) {
				return
			}
			essayPointResponses.push(data)
			set(() => ({
				essayPointResponses
			}))
		},

		addReplyState: async (data: EvaluationResponse) => {
			const { essayPointResponses } = get()
			const responsesArray = essayPointResponses.find(
				(essayPointResponse) => essayPointResponse.id === data.parent_id
			)
			const hasComment = responsesArray?.responses.find(
				({ id }) => id === data.id
			)
			if (hasComment) {
				return
			}
			responsesArray?.responses.push(data)
			set(() => ({
				essayPointResponses
			}))
		},

		fetchEssaysAndReplies: async (evaluationPointId: string) => {
			const answers = await getEssaypointResponses(evaluationPointId)
			set(() => ({
				essayPointResponses: answers
			}))
		},

		deleteEssayState: async (
			data: EvaluationResponse,
			userResponseId?: string
		) => {
			const commentId = data.id ? data.id : userResponseId
			const { essayPointResponses } = get()
			if (data.parent_id) {
				get().deleteReplyState(data)
				return
			}
			const essayIndex = essayPointResponses.findIndex(
				(essayPointResponse) => essayPointResponse.id === commentId
			)

			if (essayIndex !== -1) {
				essayPointResponses.splice(essayIndex, 1)
			}

			set(() => ({
				essayPointResponses
			}))
		},

		deleteReplyState: async ({
			parent_id,
			id: replyId
		}: EvaluationResponse) => {
			const { essayPointResponses } = get()
			const replyIndex = essayPointResponses
				.find((essayPointResponse) => essayPointResponse.id === parent_id)
				?.responses.findIndex((response) => response.id === replyId)

			if (typeof replyIndex === 'number' && replyIndex !== -1) {
				essayPointResponses
					.find((essayPointResponse) => essayPointResponse.id === parent_id)
					?.responses.splice(replyIndex, 1)
			}
			set(() => ({
				essayPointResponses
			}))
		},

		addEvaluateEssaytState: async (data: EvaluationResponse) => {
			const { essayPointResponses } = get()
			const essay = essayPointResponses.find(
				(essayPointResponse) => essayPointResponse.id === data.id
			)
			if (essay) {
				essay.evaluation_result = data.evaluation_result
			}
			set(() => ({
				essayPointResponses
			}))
		},

		updateEssayState: async ({
			id,
			agreements,
			parent_id
		}: EvaluationResponse) => {
			const { essayPointResponses } = get()
			const isReply = !!parent_id
			if (!isReply) {
				const essayIndex = essayPointResponses.findIndex(
					(essayPointResponse) => essayPointResponse.id === id
				)
				essayPointResponses[essayIndex].agreements = [...agreements]
			} else {
				const essayIndex = essayPointResponses.findIndex(
					(essayPointResponse) => essayPointResponse.id === parent_id
				)
				const replyIndex = essayPointResponses[essayIndex].responses.findIndex(
					(reply) => reply.id === id
				)
				essayPointResponses[essayIndex].responses[replyIndex].agreements = [
					...agreements
				]
			}

			set(() => ({
				essayPointResponses
			}))
		}
	}))

export function EssaysStoreProvider({ children }: { children: ReactNode }) {
	return <Provider createStore={EssaysStore}>{children}</Provider>
}

export default useStore
