import { Trans, useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded, BiTrashAlt } from 'react-icons/bi'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import DeleteModal from 'modules/common/components/modals/delete-modal'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { EssayCreateResponse } from 'modules/map/modules/evaluation-point/types'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point } from 'modules/map/modules/map-canvas/types'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { useAnonymousMutation } from '../hooks/use-anonymous-mutation'
import { useIndividualMutation } from '../hooks/use-individual-mutation'
import { useMultipleResponseMutation } from '../hooks/use-multiple-response-mutation'
import { useUserEvaluationMutation } from '../hooks/use-user-evaluation-mutation'
import { deleteEssaypoint } from '../usecases/delete-essay-point'
import {
	Anonymous,
	Individual,
	MultipleResponse,
	UserEvaluation
} from './manage-point'

type Props = {
	essayPoint: EssayCreateResponse
	onDownloadSheets: () => void
	userCredits: number
}

// TODO: this component needs to be refactored
const EvaluationSettings = ({ essayPoint }: Props) => {
	const { t } = useTranslation()
	const deleteDisClosure = useDisclosure()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const anonymousMutation = useAnonymousMutation(essayPoint.id)
	const individualMutation = useIndividualMutation(essayPoint.id)
	const multipleResponseMutation = useMultipleResponseMutation(essayPoint.id)
	const userEvaluationMutation = useUserEvaluationMutation(essayPoint.id)
	const { isAnonymous, isIndividual, isMultipleResponse, isUserEvaluation } =
		useEvaluationStore()
	const toast = useToast()
	const navigate = useNavigate()
	const {
		actions: { removePoint, clearSelected, updatePoint },
		state: { selectedPoint }
	} = useMapStore()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'essay-point',
		pointId: selectedPoint?.id || ''
	})
	const { isOpen, onOpen, onClose } = useDisclosure()
	const hasPrivileges = useJourneyPrivileges()

	const handleDelete = async () => {
		try {
			deleteEssaypoint(essayPoint.id)
			toast({
				title: t('map:points.divergence.delete.success'),
				status: 'success'
			})
			const point = {
				color: 'orange',
				id: essayPoint.id,
				created_at: essayPoint.created_at as unknown as string,
				point_type: 'ESSAY',
				position: essayPoint.position
			} as Point
			removePoint(point)
			clearSelected()
			const pointInfo = {
				id: essayPoint.id,
				position: essayPoint.position,
				point_type: essayPoint.type
			}
			emit('DELETE', pointInfo)
			navigate('../')
		} catch (error) {
			const { message } = error as Error
			toast({ title: message, status: 'error' })
		}
	}

	const handleManageIcognitoMode = () => {
		anonymousMutation.mutate({
			isAnonymous: !isAnonymous,
			pointId: essayPoint.id
		})
	}

	const handleManageIndividualMode = () => {
		individualMutation.mutate({
			isIndividual: !isIndividual,
			pointId: essayPoint.id
		})
	}

	const handleManageMultipleResponseMode = () => {
		multipleResponseMutation.mutate({
			isMultipleResponse: !isMultipleResponse,
			pointId: essayPoint.id
		})
	}

	const handleToggleVisibility = () => {
		toast.closeAll()

		if (selectedPoint) {
			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint({
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					if (isMobile) {
						toast({
							title: selectedPoint?.visible
								? t('map:points.action.state.tooltip-mobile')
								: t('map:points.action.visible.success'),
							status: 'success'
						})
					} else {
						toast({
							title: selectedPoint.visible
								? t('map:points.action.hidden.success')
								: t('map:points.action.visible.success'),
							description: selectedPoint.visible
								? t('map:points.action.state.tooltip-alt')
								: '',
							status: 'success'
						})
					}
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	const handleManageUserEvaluationMode = () => {
		userEvaluationMutation.mutate({
			isUserEvaluation: !isUserEvaluation,
			pointId: essayPoint.id
		})
	}

	return (
		<Box>
			<Menu
				placement='bottom-end'
				closeOnBlur
				closeOnSelect={false}
				onClose={onClose}
				isOpen={isOpen}
				onOpen={onOpen}
			>
				{!isMobile && hasPrivileges && (
					<>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label={
									selectedPoint?.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')
								}
								size='sm'
								position='absolute'
								top={2}
								mt='18.5px'
								color='gray.400'
								right='102px'
								icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
								fontSize='xl'
								variant='ghost'
							/>
							<MenuList>
								<MenuItem
									borderRadius='md'
									fontSize='md'
									onClick={handleToggleVisibility}
								>
									{selectedPoint?.visible
										? t('map:points.action.hidden.title')
										: t('map:points.action.visible.title')}
								</MenuItem>
							</MenuList>
						</Menu>
					</>
				)}
				<MenuButton
					as={IconButton}
					aria-label={t('buttons:more')}
					variant='ghost'
					mb='1px'
					color='gray.400'
					icon={<Icon as={BiDotsVerticalRounded} fontSize='xl' />}
					_active={{ bgColor: 'gray.100' }}
					mt={selectedPoint?.visible ? 0 : ['-23px', '-25px']}
				/>
				<MenuList maxWidth='300px'>
					{isMobile && hasPrivileges && (
						<MenuItem
							borderRadius='md'
							fontSize='md'
							onClick={() => {
								handleToggleVisibility()
								onClose()
							}}
							mb={2}
						>
							{selectedPoint?.visible
								? t('map:points.action.hidden.title')
								: t('map:points.action.visible.title')}
						</MenuItem>
					)}
					<MenuItem
						isDisabled={isAnonymous}
						onClick={handleManageIndividualMode}
					>
						<Individual isAnonymous={isAnonymous} isIndividual={isIndividual} />
					</MenuItem>

					<MenuItem
						isDisabled={isIndividual}
						onClick={handleManageIcognitoMode}
					>
						<Anonymous isAnonymous={isAnonymous} isIndividual={isIndividual} />
					</MenuItem>

					<MenuItem onClick={handleManageMultipleResponseMode}>
						<MultipleResponse isMultipleResponse={isMultipleResponse} />
					</MenuItem>

					<MenuItem onClick={handleManageUserEvaluationMode}>
						<UserEvaluation isUserEvaluation={isUserEvaluation} />
					</MenuItem>

					<MenuItem onClick={deleteDisClosure.onOpen} borderRadius='md'>
						<HStack fontSize='sm'>
							<Icon as={BiTrashAlt} color='red' />
							<Text>{t('evaluationPoint:view.delete')}</Text>
						</HStack>
					</MenuItem>
				</MenuList>
			</Menu>

			<DeleteModal
				title={t('evaluationPoint:delete.title')}
				deleteText={t('buttons:delete')}
				onDelete={handleDelete}
				{...deleteDisClosure}
			>
				<Trans
					i18nKey='map:points.divergence.delete.description'
					values={{ title: essayPoint.theme }}
					components={[<strong />]}
				/>
			</DeleteModal>
		</Box>
	)
}

export default EvaluationSettings
