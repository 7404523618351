import defaultCustomColors from '../default/colors'

const mitsloanreviewCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#232735',
		700: '#232735'
	},

	secondary_color_scheme_button: {
		700: defaultCustomColors.pink[500]
	},

	switch_scheme_color_checked: {
		500: '#E0181B'
	},

	radio_color: {
		500: '#E0181B'
	}
}

export default mitsloanreviewCustomColors
