import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStJourneys: React.FC = (props: IconProps) => (
	<IconBase strokeWidth={0.2} viewBox='0 0 14 14' {...props}>
		<path
			d='M3.77087 7.52613L6.37958 6.05997C6.51848 5.98563 6.61033 5.84125 6.61033 5.6753V2.69581C6.61033 2.52985 6.51848 2.38547 6.37957 2.31113L3.77087 0.84498C3.6984 0.792826 3.61149 0.768589 3.52442 0.768589C3.43735 0.768589 3.35044 0.792826 3.27797 0.84498L0.669268 2.31114C0.530361 2.38547 0.438511 2.52986 0.438511 2.69581V5.6753C0.438511 5.84125 0.530357 5.98563 0.669258 6.05997L3.27797 7.52613C3.35044 7.57828 3.43735 7.60252 3.52442 7.60252C3.61149 7.60252 3.6984 7.57828 3.77087 7.52613ZM1.31344 5.41777V2.95334L3.52442 1.70888L5.7354 2.95334V5.41777L3.52442 6.66223L1.31344 5.41777Z'
			fill='currentcolor'
			stroke='currentcolor'
		/>
		<path
			d='M10.724 7.52613L13.3327 6.05997C13.4716 5.98564 13.5635 5.84125 13.5635 5.6753V2.69581C13.5635 2.52985 13.4716 2.38547 13.3327 2.31113L10.724 0.84498C10.6515 0.792826 10.5646 0.768589 10.4775 0.768589C10.3905 0.768589 10.3036 0.792826 10.2311 0.84498L7.62239 2.31114C7.48349 2.38547 7.39164 2.52986 7.39164 2.69581V5.6753C7.39164 5.84125 7.48349 5.98564 7.62239 6.05997L10.2311 7.52613C10.3036 7.57828 10.3905 7.60252 10.4775 7.60252C10.5646 7.60252 10.6515 7.57828 10.724 7.52613ZM8.26657 5.41777V2.95334L10.4775 1.70888L12.6885 2.95334V5.41777L10.4775 6.66223L8.26657 5.41777Z'
			fill='currentcolor'
			stroke='currentcolor'
		/>
		<path
			d='M7.28649 13.155L9.8952 11.6889C10.0341 11.6145 10.126 11.4702 10.126 11.3042V8.32471C10.126 8.15876 10.0341 8.01438 9.8952 7.94004L7.28649 6.47389C7.21402 6.42173 7.12712 6.3975 7.04005 6.3975C6.95297 6.3975 6.86607 6.42173 6.7936 6.47389L4.18489 7.94004C4.04599 8.01438 3.95414 8.15876 3.95414 8.32471V11.3042C3.95414 11.4702 4.04598 11.6145 4.18488 11.6889L6.7936 13.155C6.86607 13.2072 6.95298 13.2314 7.04005 13.2314C7.12712 13.2314 7.21402 13.2072 7.28649 13.155ZM4.82907 11.0467V8.58225L7.04005 7.33778L9.25102 8.58225V11.0467L7.04005 12.2911L4.82907 11.0467Z'
			fill='currentcolor'
			stroke='currentcolor'
		/>
	</IconBase>
)
