import { useTranslation } from 'react-i18next'

import { Text } from '@chakra-ui/react'

type Props = {
	content: string
	count: number
}

export const MotivationText = ({ content, count }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			<Text color='gray.300' fontSize='sm'>
				{t('evaluationPoint:view.supportText', { count })}
			</Text>
			<Text
				color='gray.400'
				variant='breakLines'
				fontWeight='medium'
				fontSize={['sm', 'md']}
			>
				{content}
			</Text>
		</>
	)
}
