import {
	Button,
	Image,
	Tooltip,
	useBreakpointValue,
	useTheme
} from '@chakra-ui/react'

import PlusIcon from 'modules/common/assets/icons/plus.svg'

interface AddStatusButtonProps {
	onOpen: () => void
}

export const AddStatusButton = ({ onOpen }: AddStatusButtonProps) => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const theme = useTheme()
	return (
		<>
			<Tooltip
				placement='left'
				label='clique aqui para adicionar o status do indicador'
				backgroundColor='gray.400'
				borderRadius='4px'
				fontSize='12px'
			>
				<Button
					onClick={onOpen}
					position={isMobile ? 'fixed' : 'absolute'}
					bottom='4'
					right={[4, 7]}
					borderRadius='50%'
					maxWidth='3rem'
					width='3rem'
					height='3rem'
					maxHeight='3rem'
					backgroundColor={theme.colors.add_comment_button_bg}
					_hover={{ backgroundColor: 'none' }}
				>
					<Image src={PlusIcon} />
				</Button>
			</Tooltip>
		</>
	)
}
