import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FiMoreVertical } from 'react-icons/fi'
import { MdOutlineShare } from 'react-icons/md'
import { RiRobot2Line } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom'

import {
	Avatar,
	Box,
	Button,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	VStack,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { useChatRoom } from 'modules/assistants/hooks/chat-room/use-chat-room'
import { useFreeChatStore } from 'modules/assistants/store/free-chat-store'
import { AssistantCardProps } from 'modules/assistants/types'
import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { useStringToColor } from 'modules/common/hooks'
import { ASSISTANT_CHAT } from 'modules/constants'
import { useMe } from 'modules/user/hooks'
import { userKeys } from 'modules/user/query'

import { AssistantChatFreeModal } from '../assistant-chat-free'
import { AssistantChatModal } from '../assistant-chat-modal'
import { AssistantDeleteConfirmModal } from '../assistant-delete-confirm-modal'
import { AssistantShareModal } from '../assistant-share-modal'
import { AssistantCardActionButton } from './components/assistant-card-action-button'

export const AssistantCard = ({
	assistantData,
	iconSize,
	useMode,
	createAnswerData,
	commentAllData,
	assistantTab
}: AssistantCardProps) => {
	const [color, bgColor] = useStringToColor({ text: assistantData.name })
	const { t } = useTranslation()
	const { mutate: createChatRoom, isLoading: isLoadingCreateChatRoom } =
		useChatRoom()
	const chatRoomIdRef = useRef<string>('')
	const { user } = useMe()
	const toast = useToast()
	const { state } = useLocation()
	const { setIsLoading } = useFreeChatStore()

	const hasFreeAssistantChat = state?.hasFreeAssistantChat

	const hasPrivileges =
		assistantData.created_by === user?.id &&
		assistantTab &&
		assistantTab.type === 'private'

	const {
		isOpen: isDeleteOpen,
		onClose: onDeleteClose,
		onOpen: onDeleteOpen
	} = useDisclosure()
	const {
		isOpen: isShareOpen,
		onClose: onShareClose,
		onOpen: onShareOpen
	} = useDisclosure()
	const {
		isOpen: isChatOpen,
		onClose: onChatClose,
		onOpen: onChatOpen
	} = useDisclosure()
	const {
		isOpen: isChatFreeAssistantOpen,
		onClose: onChatFreeAssistantClose,
		onOpen: onChatFreeAssistantOpen
	} = useDisclosure()
	const {
		isOpen: isBuyCreditsOpen,
		onClose: onBuyCreditsClose,
		onOpen: onBuyCreditsOpen
	} = useDisclosure()

	const handleCreateChatRoom = (assistantId: string) => {
		sendGAEvent('assistant_card_chat_button_clicked')

		setIsLoading(assistantId, true)

		if (user) {
			if (!hasFreeAssistantChat && user.credits < ASSISTANT_CHAT) {
				onBuyCreditsOpen()
				return
			}

			createChatRoom(assistantId, {
				onSuccess: ({ data }) => {
					queryClient.invalidateQueries(userKeys.me())
					queryClient.invalidateQueries('free_first_chatroom_availability')
					chatRoomIdRef.current = data.id
					onChatOpen()
					setIsLoading(assistantId, false)
				},
				onError: (error) => {
					console.log('error on createChatRoom', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
					setIsLoading(assistantId, false)
				}
			})
		}
	}

	const handleCreateChatFreeRoom = () => {
		onChatFreeAssistantOpen()
	}

	return (
		<Box
			position='relative'
			bgColor={`${color}.400`}
			borderWidth='1px'
			borderRadius='2xl'
			borderColor={`${color}.400`}
		>
			{useMode === 'edit' && hasPrivileges && (
				<Menu closeOnBlur>
					<MenuButton
						as={IconButton}
						aria-label={t('journeys:card.menu.aria')}
						variant='ghost'
						color='white'
						isRound
						icon={<Icon as={FiMoreVertical} fontSize='xl' />}
						_active={{ bgColor: 'gray.100', color: 'gray.400' }}
						_hover={{ color: 'gray.400', bgColor: 'white' }}
						position='absolute'
						right={0}
						top={0}
						margin={2}
						zIndex={2}
					/>
					<MenuList rounded='8'>
						<MenuItem
							as={Link}
							rounded='8'
							to={`/dashboard/assistants/edit/${assistantData.id}`}
						>
							{t('assistants:card.action.edit')}
						</MenuItem>
						<MenuItem rounded='8' onClick={onDeleteOpen}>
							{t('assistants:card.action.delete')}
						</MenuItem>
					</MenuList>
				</Menu>
			)}
			<Box textAlign='center' position='absolute' w='full' top={6}>
				<Avatar
					size={iconSize}
					bgColor={bgColor}
					icon={<Icon as={RiRobot2Line} fontSize='2xl' color='white' />}
				/>
			</Box>
			<VStack
				bgColor='white'
				mt={14}
				borderBottomRadius='2xl'
				pt={10}
				px={4}
				pb={4}
				w='full'
			>
				<Text
					fontWeight='semibold'
					fontSize='md'
					isTruncated
					maxWidth='100%'
					title={assistantData.name}
				>
					{assistantData.name}
				</Text>
				<Text
					fontWeight='semibold'
					fontSize='xs'
					color='gray.300'
					noOfLines={1}
					title={assistantData.formation}
				>
					{assistantData.formation}
				</Text>
				<AssistantCardActionButton
					useMode={useMode}
					assistantData={assistantData}
					createAnswerData={createAnswerData}
					commentAllData={commentAllData}
					onChatOpen={{
						isLoading: isLoadingCreateChatRoom,
						method: hasFreeAssistantChat
							? handleCreateChatFreeRoom
							: () => handleCreateChatRoom(assistantData.id)
					}}
				/>
				{useMode === 'edit' && hasPrivileges && (
					<Button width='100%' variant='ghost' gap={2} onClick={onShareOpen}>
						<Icon color='gray.400' as={MdOutlineShare} fontSize='lg' />
						<Text fontSize='xs'>{t('assistants:card.action.share')}</Text>
					</Button>
				)}
			</VStack>
			{isChatOpen && (
				<AssistantChatModal
					isOpen={isChatOpen}
					onClose={onChatClose}
					assistantData={assistantData}
					chatRoomId={chatRoomIdRef.current}
				/>
			)}
			{isChatFreeAssistantOpen && (
				<AssistantChatFreeModal
					isOpen={isChatFreeAssistantOpen}
					onClose={onChatFreeAssistantClose}
					assistantId={assistantData.id}
					handleCreateChatRoom={handleCreateChatRoom}
				/>
			)}
			{isShareOpen && (
				<AssistantShareModal
					isOpen={isShareOpen}
					onClose={onShareClose}
					assistantData={assistantData}
				/>
			)}
			{isDeleteOpen && (
				<AssistantDeleteConfirmModal
					isOpen={isDeleteOpen}
					onClose={onDeleteClose}
					assistantName={assistantData.name}
					userAssistantId={assistantData.id}
				/>
			)}
			{isBuyCreditsOpen && (
				<BuyCreditsContent
					isOpen={isBuyCreditsOpen}
					onClose={onBuyCreditsClose}
				/>
			)}
		</Box>
	)
}
