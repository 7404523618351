import React from 'react'

import { Box, Image } from '@chakra-ui/react'

import loading from 'modules/common/assets/img/loading.svg'

const Loading: React.FC = () => {
	return (
		<Box
			zIndex='900'
			top='50%'
			left='50%'
			position='fixed'
			display='inline-block'
			transform='translate(-50%,-50%)'
		>
			<Image
				css={{
					animationFillMode: 'both'
				}}
				w={12}
				animation='loading 1.5s linear infinite alternate'
				src={loading}
			/>
		</Box>
	)
}

export default Loading
