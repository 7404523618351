import { fetch } from 'config'
import { mountParams, SortOrder } from 'data/helpers/mount-params'

import { QueryFilter } from 'modules/common/types/common-model'

import { TemplatesSummaryResponse } from '../types'

interface Params {
	search: string
	order: SortOrder
	filter: QueryFilter
	size?: number
	pageParam: number
	sortBy?: string
}

export async function getMyTemplates({
	search,
	order,
	filter,
	size,
	pageParam = 0,
	sortBy = 'createdAt'
}: Params) {
	const params = mountParams({
		size: 12,
		page: pageParam,
		sort: [sortBy, order],
		title: search,
		...filter
	})
	const { data } = await fetch<TemplatesSummaryResponse>({
		url: '/templates/v1/template',
		method: 'GET',
		params
	})

	return data
}
