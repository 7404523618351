import { useTranslation } from 'react-i18next'
import { LuEye, LuEyeOff } from 'react-icons/lu'

import {
	Box,
	Fade,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Stack,
	Text,
	VStack
} from '@chakra-ui/react'

import { MenuOptions } from 'modules/common/components/cards/card-tool/menu-options'
import SelectColor from 'modules/common/components/hexagon-icon'
import { IconMonitorPoint } from 'modules/common/components/icons/st-monitor-point'
import Loading from 'modules/common/components/loading'
import DeleteModal from 'modules/common/components/modals/delete-modal'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'

import { useMonitorPointLogic } from '../../hooks/use-monitor-point-logic'
import { MonitorPointAnalysisModal } from '../monitor-point-analysis'
import { AddStatusButton } from './components/add-status-button'
import { AddStatusFormModal } from './components/add-status-form'
import { StatusCard } from './components/status-card'

export const MonitorPointView = () => {
	const {
		state: { isLoading, selectedPoint },
		actions: { handleDeleteMonitorPoint, handleToggleVisibility },
		data: { monitorPoint, monitorPointStatus, monitorPointMenuOptions },
		disclosures: {
			addStatusDisclosure,
			deleteMonitorPointDisclosure,
			analysisMonitorPointDisclosure
		},
		utils: {
			showAddStatusButton,
			user,
			conclusionDate,
			monitorType,
			isQualitative,
			qualitativeCurrentStatus,
			quantitativeGoal,
			isMobile,
			hasPrivileges
		}
	} = useMonitorPointLogic()
	const { t } = useTranslation()

	if (isLoading) return <Loading />

	return (
		<>
			{hasPrivileges && !isMobile && selectedPoint && (
				<>
					<Menu>
						<MenuButton
							as={IconButton}
							aria-label={
								selectedPoint.visible
									? t('map:points.action.hidden.title')
									: t('map:points.action.visible.title')
							}
							size='sm'
							position='absolute'
							top={6}
							right='102px'
							icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
							fontSize='xl'
							variant='ghost'
							color='gray.400'
						/>
						<MenuList>
							<MenuItem borderRadius='md' onClick={handleToggleVisibility}>
								{selectedPoint.visible
									? t('map:points.action.hidden.title')
									: t('map:points.action.visible.title')}
							</MenuItem>
						</MenuList>
					</Menu>
				</>
			)}
			{hasPrivileges && (
				<Box
					pos='absolute'
					alignSelf='flex-end'
					mr={['55px', '60px']}
					mt={['13px', '20.3px']}
				>
					<MenuOptions
						label={t('journeys:card.menu.aria')}
						options={monitorPointMenuOptions}
					/>
				</Box>
			)}
			<ModalCloseButton color='gray.400' mt={['9px', 4]} mr={[2, 4]} />
			<ModalHeader px={[4, 7]} pt={[3, 7]} maxW='98%'>
				<HStack alignItems='flex-start'>
					{selectedPoint && (
						<SelectColor
							color='green.400'
							Icon={IconMonitorPoint}
							visible={selectedPoint.visible}
							mt={[3, 1]}
						/>
					)}
					<Box overflow='hidden'>
						<HStack>
							<Text
								mt={[2.5, 0]}
								fontWeight='semibold'
								fontSize={['lg', 'xl']}
								isTruncated
							>
								{monitorPoint?.name}
							</Text>
						</HStack>
						<Text
							color='gray.400'
							fontWeight='medium'
							fontSize='md'
							noOfLines={[3, 2]}
							overflow='hidden'
							textOverflow='ellipsis'
							title={monitorPoint?.description}
							mt={[2, 1]}
						>
							{monitorPoint?.description}
						</Text>
					</Box>
				</HStack>
			</ModalHeader>
			<ModalBody p={0}>
				<Stack
					direction={['column', 'row']}
					gap={isQualitative && !monitorPointStatus?.length ? [20, '300px'] : 4}
					ml={isQualitative ? 4 : 0}
					justify={isQualitative ? 'flex-start' : 'space-between'}
					alignItems={['initial', 'center']}
					mx={[0, 2]}
					px={[4, 6]}
					pr={[0, 8]}
					mt={isQualitative && !selectedPoint?.visible ? 3 : 0}
				>
					<HStack flex={2}>
						<VStack gap={1} alignItems='flex-start' flex={1}>
							<Text color='gray.900' fontSize='lg' fontWeight='semibold'>
								{isQualitative ? qualitativeCurrentStatus : quantitativeGoal}
							</Text>
							<Text color='gray.400' fontSize='sm' alignSelf='flex-start'>
								{isQualitative
									? t('map:points:monitor:view:current:status')
									: t('map:points:monitor:view:current:goal')}
							</Text>
						</VStack>
						<VStack gap={1} alignItems='flex-start' flex={1}>
							<Text color='gray.900' fontSize='lg' fontWeight='semibold'>
								{conclusionDate}
							</Text>
							<Text color='gray.400' fontSize='sm'>
								{t('map:points:monitor:view:date')}
							</Text>
						</VStack>
					</HStack>
					{/* {!isQualitative && (
						<VStack gap={1} alignItems='flex-start' flex={1}>
							<Text color='gray.900' fontSize='lg' fontWeight='semibold'>
								{monitorCurrentStatusValue}
							</Text>
							<Text color='gray.400' fontSize='sm'>
								{t('map:points:monitor:view:percent')}
							</Text>
						</VStack>
					)} */}
					{monitorPointStatus?.length && hasPrivileges && (
						<VStack pr={[3, 0]}>
							<CreateContentBtn
								coins={4}
								isDisabled={false}
								nexStep={analysisMonitorPointDisclosure.onOpen}
								user={user}
								text={t('map:points:monitor:view:analysis')}
								flex={1}
								w='100%'
								h={10}
							/>
						</VStack>
					)}
				</Stack>

				<Flex
					direction='column'
					mt={6}
					maxH={['full', '60vh']}
					overflowY='auto'
					pl={[3, 5]}
					pr={[3, 0]}
				>
					{monitorPointStatus?.map((status) => (
						<StatusCard
							key={status.id + status.created_at}
							status={status}
							userId={user?.id || ''}
							userName={user?.name || ''}
							userLanguage={user?.language || 'ptBR'}
							monitorPointId={monitorPoint?.id || ''}
							isQualitative={isQualitative}
							quantitativeGoal={quantitativeGoal}
						/>
					))}
				</Flex>
			</ModalBody>
			<ModalFooter mt={12}>
				{hasPrivileges && (
					<Fade
						in={showAddStatusButton}
						transition={{ enter: { duration: 0.5 } }}
					>
						<AddStatusButton onOpen={addStatusDisclosure.onOpen} />
					</Fade>
				)}
			</ModalFooter>
			<AddStatusFormModal
				isOpen={addStatusDisclosure.isOpen}
				onClose={addStatusDisclosure.onClose}
				monitorType={monitorType}
			/>
			<DeleteModal
				title={t('map:points:monitor:view:actions:delete:title')}
				deleteText={t('buttons:delete')}
				onDelete={handleDeleteMonitorPoint}
				{...deleteMonitorPointDisclosure}
			>
				{t('map:points:monitor:view:actions:delete:description')}{' '}
				<Text as='span' fontWeight='semibold'>
					{monitorPoint?.name}
				</Text>
				?
			</DeleteModal>
			<MonitorPointAnalysisModal
				isOpen={analysisMonitorPointDisclosure.isOpen}
				onClose={analysisMonitorPointDisclosure.onClose}
				monitorPointId={monitorPoint?.id}
				monitorPointName={monitorPoint?.name}
			/>
		</>
	)
}
