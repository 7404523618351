import defaultCustomColors from '../default/colors'

const saebCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: defaultCustomColors.pink[500],
		700: defaultCustomColors.pink[500]
	},

	secondary_color_scheme_button: {
		700: '#1D4080'
	},

	switch_scheme_color_checked: {
		500: '#1D4080'
	},

	radio_color: {
		500: '#1D4080'
	}
}

export default saebCustomColors
