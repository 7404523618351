import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import produce from 'immer'
import create from 'zustand'

import { formatToLocalDateTime } from 'modules/common/utils/format-to-local-date-time'

type State = {
	date: string
	hour: string
	updateTime: (date: string, language: string) => void
	formatHours: string
}

export const useConvergenceStore = create<State>((set) => {
	const setState = (fn: (state: State) => void) => set(produce(fn))

	return {
		date: '',
		hour: '',
		formatHours: '',
		updateTime(dateToFormat: string, language: string) {
			setState((state) => {
				const formattedDate = new Date(formatToLocalDateTime(dateToFormat))
				const date = formattedDate.toLocaleDateString(language)
				const [hour, minute] = formattedDate
					.toLocaleTimeString(language)
					.split(':')

				const formatHours = format(new Date(formattedDate), 'aaa', {
					locale: enUS
				})
				state.date = date
				state.hour = `${hour}:${minute}`
				state.formatHours = formatHours
			})
		}
	}
})
