import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	UseDisclosureReturn,
	useDisclosure
} from '@chakra-ui/react'

import StepChangePassword from './stepChangePassword'
import StepCode from './stepCode'
import StepEmail from './stepEmail'
import StepSucess from './stepSucess'

type Props = UseDisclosureReturn & {
	children: (
		status: Status,
		setStatus: React.Dispatch<React.SetStateAction<Status>>
	) => ReactNode
}

type Status = 'email' | 'code' | 'password' | 'sucess'

const RecoverContainer: React.FC<Props> = ({
	isOpen,
	onOpen,
	onClose,
	children
}) => {
	const [status, setStatus] = useState<Status>('email')

	const closeSession = (): void => {
		onClose()
		setStatus('email')
	}

	return (
		<>
			<Modal size='md' isCentered isOpen={isOpen} onClose={closeSession}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton mr={3} mt={3} />
					{children(status, setStatus)}
				</ModalContent>
			</Modal>
		</>
	)
}

const ForgotPassword = () => {
	const disclosureForgotPassword = useDisclosure()
	const { t } = useTranslation()
	const [email, setEmail] = useState('')
	const [hash, setHash] = useState('')

	return (
		<>
			<Button
				fontSize={['xs', 'md']}
				alignSelf='center'
				variant='link'
				colorScheme='blue'
				onClick={disclosureForgotPassword.onOpen}
			>
				{t('buttons:forgotPassword')}
			</Button>
			<RecoverContainer {...disclosureForgotPassword}>
				{(status, setStatus) => (
					<>
						{status === 'email' && (
							<StepEmail
								onFinish={(email) => {
									setStatus('code')
									setEmail(email)
								}}
							/>
						)}
						{status === 'code' && (
							<StepCode
								email={email}
								onFinish={(hash) => {
									setStatus('password')
									setHash(hash)
								}}
							/>
						)}
						{status === 'password' && (
							<StepChangePassword
								hash={hash}
								onFinish={() => setStatus('sucess')}
							/>
						)}
						{status === 'sucess' && (
							<StepSucess
								onFinish={() => {
									disclosureForgotPassword.onClose()
									setStatus('email')
								}}
							/>
						)}
					</>
				)}
			</RecoverContainer>
		</>
	)
}

export default ForgotPassword
