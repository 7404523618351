import defaultCustomColors from '../default/colors'

const lumosacademyCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#BF897C',
		700: defaultCustomColors.yellow[500]
	},

	secondary_color_scheme_button: {
		700: '#CDAE66'
	},

	switch_scheme_color_checked: {
		500: '#CDAE66'
	},

	radio_color: {
		500: '#CDAE66'
	}
}

export default lumosacademyCustomColors
