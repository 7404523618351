import React, { useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	InputGroup,
	InputRightElement,
	Text,
	Textarea,
	TextareaProps,
	useBreakpointValue,
	useTheme
} from '@chakra-ui/react'

interface FormTextareaProps extends TextareaProps {
	label: string
	name: string
	placeholder: string
	register: UseFormRegister<any>
	isRequired?: boolean
	errors: Record<string, any>
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
	label,
	name,
	placeholder,
	register,
	isRequired,
	errors,
	...props
}) => {
	const theme = useTheme() as { colors: any }
	const isMobile = useBreakpointValue({ base: true, md: false })
	const errorMessage = errors[name]?.message
	const [charCount, setCharCount] = useState(0)

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCharCount(e.target.value.length)
	}

	return (
		<FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
			<FormLabel fontWeight='semibold' color='gray.400'>
				{label}
			</FormLabel>
			<InputGroup>
				<Textarea
					{...register(name, {
						onChange: handleChange
					})}
					placeholder={placeholder}
					borderColor='gray.195'
					pr={20}
					_placeholder={{
						color: 'gray.300'
					}}
					_focus={{
						borderColor: theme.colors.input_border,
						borderWidth: '0px'
					}}
					maxH={isMobile ? '150px' : '100px'}
					maxLength={1000}
					resize={isMobile ? 'vertical' : 'none'}
					{...props}
				/>
				<InputRightElement mr={3}>
					<Text fontSize='sm'>{charCount}/1000</Text>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage>{errorMessage}</FormErrorMessage>
		</FormControl>
	)
}
