import React from 'react'

import { Grid, GridProps } from '@chakra-ui/react'

export const CreditsDetailsContainer: React.FC<GridProps> = ({
	children,
	gridTemplateColumns
}) => {
	return (
		<Grid
			w='full'
			py={4}
			px={[4, 8]}
			gap={[8, 0]}
			borderWidth={1}
			borderRadius='xl'
			borderColor='blue.50'
			gridTemplateColumns={gridTemplateColumns}
		>
			{children}
		</Grid>
	)
}
