import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react'

type Props = {
	setAssistantResponseSize: React.Dispatch<React.SetStateAction<string>>
	assistantResponseSize: string
}

const boxStyle = {
	marginInlineStart: '0 !important',
	flex: '1',
	height: '4px',
	bg: 'gray.190'
}

const radioStyle = {
	marginInlineStart: '0 !important',
	size: 'lg',
	colorScheme: 'pink'
}

export const AssistantQuestionCommentSize = ({
	setAssistantResponseSize,
	assistantResponseSize
}: Props) => {
	const { t } = useTranslation()

	return (
		<Box mt={3}>
			<Text color='gray.400'>
				{t('divergencePoint:assistants.responseSize')}
			</Text>
			<RadioGroup
				onChange={setAssistantResponseSize}
				value={assistantResponseSize}
			>
				<HStack px={3} mt={4} align='center'>
					<Radio
						size='lg'
						colorScheme='custom_scheme_color_button'
						value='SMALL'
					/>
					<Box {...boxStyle} />
					<Radio
						{...radioStyle}
						colorScheme='custom_scheme_color_button'
						value='MEDIUM'
					/>
					<Box {...boxStyle} />
					<Radio
						{...radioStyle}
						colorScheme='custom_scheme_color_button'
						value='LARGE'
					/>
				</HStack>
				<HStack justify='space-between' mt={2} align='center'>
					<Text>{t('divergencePoint:assistants.small')}</Text>
					<Text>{t('divergencePoint:assistants.medium')}</Text>
					<Text>{t('divergencePoint:assistants.big')}</Text>
				</HStack>
			</RadioGroup>
		</Box>
	)
}
