import { fetch } from 'config'

export const checkFreeFirstChatRoomAvailability = async () => {
	const { data } = await fetch<boolean>({
		url: 'projects/v1/chat-room/usage/me/free-chat-availability',
		method: 'GET'
	})

	return data
}
