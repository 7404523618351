import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	HStack,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'

import { User } from 'modules/user/types'

import { ChooseAssistant } from './choose-assistant'
import { ConfirmComment } from './confirm-comments'

export type Status = 'select' | 'confirm'

type Propss = {
	isOpen: boolean
	modalSize: string
	onClose: () => void
	children: (
		status: Status,
		setStatus: React.Dispatch<React.SetStateAction<Status>>
	) => React.ReactNode
}

const CommentAllContainer = ({
	children,
	onClose,
	isOpen,
	modalSize
}: Propss) => {
	const [status, setStatus] = useState<Status>('select')
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { t } = useTranslation()

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={['full', modalSize]}
				isCentered
			>
				<ModalOverlay />
				<ModalContent transition='max-width 0.3s ease' overflow='hidden'>
					<ModalHeader px={isMobile ? 4 : 6} pt={isMobile ? 14 : 8}>
						<HStack>
							<Text fontSize={['18', 'xl']} fontWeight='bold'>
								{t('divergencePoint:commentAll.modal.title')}
							</Text>
							<Tag fontSize={12} variant='solid' colorScheme='red'>
								beta
							</Tag>
						</HStack>
					</ModalHeader>
					<ModalCloseButton mt={isMobile ? 1 : 5} mr={isMobile ? 0 : 4} />
					{children(status, setStatus)}
				</ModalContent>
			</Modal>
		</>
	)
}

export const CommentAll = ({
	isOpen,
	onClose,
	pointId,
	questionId,
	user
}: {
	user: User
	pointId: string
	questionId: string
	isOpen: boolean
	onClose: () => void
}) => {
	const [commentsMissing, setCommentsMissing] = useState(0)
	const [totalComments, setTotalComments] = useState(0)
	const [assistantId, setAssistantId] = useState('')
	const [timestap, setTimestamp] = useState('')
	const [modalSize, setModalSize] = useState('2xl')

	return (
		<CommentAllContainer
			isOpen={isOpen}
			onClose={onClose}
			modalSize={modalSize}
		>
			{(status, setStatus) => (
				<>
					{status === 'select' && (
						<ChooseAssistant
							setStatus={setStatus}
							pointId={pointId}
							questionId={questionId}
							setCommentsMissing={setCommentsMissing}
							setTimestamp={setTimestamp}
							setAssistantId={setAssistantId}
							setTotalComments={setTotalComments}
							setModalSize={setModalSize}
						/>
					)}

					{status === 'confirm' && (
						<ConfirmComment
							user={user}
							commentsMissing={commentsMissing}
							onClose={onClose}
							pointId={pointId}
							questionId={questionId}
							assistantId={assistantId}
							setStatus={setStatus}
							timestap={timestap}
							totalComments={totalComments}
						/>
					)}
				</>
			)}
		</CommentAllContainer>
	)
}
