import React, { PropsWithChildren } from 'react'

import { Flex, SimpleGrid, VStack } from '@chakra-ui/react'

import LoadingStrateegia from 'modules/common/components/loading'

type Props = {
	dataColumns?: number
	listCount?: number
	isLoading: boolean
	isFetchingNextPage?: boolean
	isSearching?: boolean
	children?: React.ReactNode
}
interface SubComponents {
	Loading: React.FC<PropsWithChildren>
	Empty: React.FC<PropsWithChildren>
	EmptySearch: React.FC<PropsWithChildren>
	WithData: React.FC<PropsWithChildren>
	WithDataList: React.FC<PropsWithChildren>
}
interface Context extends Props {
	isEmpty: boolean
	isEmptySearch: boolean
	initialListCount: number
	dataColumns?: number
	children?: React.ReactNode
}

const ListContext = React.createContext<Context>({
	isLoading: false,
	isEmpty: true,
	isEmptySearch: false,
	isFetchingNextPage: false,
	isSearching: false,
	listCount: 0,
	initialListCount: 0,
	dataColumns: 3
})

const useListContext = () => {
	const context = React.useContext(ListContext)
	if (!context) {
		throw new Error(
			'List compound components cannot be rendered outside the List component'
		)
	}
	return context
}

const DefaultLoading: React.FC = () => {
	return (
		<Flex w='100%' height='100%' alignItems='center' justify='center'>
			<LoadingStrateegia />
		</Flex>
	)
}

const ListComponent: React.FC<Props> & SubComponents = ({
	listCount,
	isLoading,
	isFetchingNextPage = false,
	isSearching = false,
	dataColumns = 3,
	children
}) => {
	const isEmpty = typeof listCount === 'undefined' || listCount === 0
	const isEmptySearch = listCount === 0 && isSearching

	const initialCountRef = React.useRef<number | undefined>(listCount)
	const currentCount = initialCountRef.current
	if (typeof currentCount === 'undefined' && typeof listCount !== 'undefined') {
		initialCountRef.current = listCount
	}

	const initialCount = currentCount || 0

	return (
		<ListContext.Provider
			value={{
				isLoading,
				isFetchingNextPage,
				isEmpty,
				isEmptySearch,
				isSearching,
				listCount,
				initialListCount: initialCount,
				dataColumns
			}}
		>
			{children}
		</ListContext.Provider>
	)
}

const Loading: React.FC<PropsWithChildren> = ({ children }) => {
	const { isLoading, isFetchingNextPage, listCount: count } = useListContext()
	if (isLoading || isFetchingNextPage || typeof count === 'undefined') {
		if (!children) {
			return <DefaultLoading />
		}
		return <>{children}</>
	}
	return null
}

const Empty: React.FC<PropsWithChildren> = ({ children }) => {
	const {
		isLoading,
		isEmpty,
		isEmptySearch,
		listCount: count
	} = useListContext()
	if (isEmpty && !isLoading && !isEmptySearch && typeof count !== 'undefined') {
		return <>{children}</>
	}
	return null
}

const EmptySearch: React.FC<PropsWithChildren> = ({ children }) => {
	const { isLoading, isFetchingNextPage, isEmptySearch, isSearching } =
		useListContext()
	if (!isLoading && !isFetchingNextPage && isEmptySearch && isSearching) {
		return <>{children}</>
	}
	return null
}

const WithData: React.FC<PropsWithChildren> = ({ children }) => {
	const { isEmpty, isEmptySearch, dataColumns = 3 } = useListContext()
	if (isEmpty || isEmptySearch) {
		return null
	}
	return (
		<SimpleGrid
			columns={[1, 1, dataColumns]}
			spacing={4}
			maxWidth='100%'
			overflowX='hidden'
		>
			{children}
		</SimpleGrid>
	)
}

const WithDataList: React.FC<PropsWithChildren> = ({ children }) => {
	const { isEmpty, isEmptySearch } = useListContext()
	if (isEmpty || isEmptySearch) {
		return null
	}
	return <VStack>{children}</VStack>
}

ListComponent.Loading = Loading
ListComponent.Empty = Empty
ListComponent.EmptySearch = EmptySearch
ListComponent.WithData = WithData
ListComponent.WithDataList = WithDataList

export { ListComponent }
