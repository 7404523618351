import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

// import { journeyKeys } from 'modules/journeys/query'
import { journeyKeys } from 'modules/journeys/query'
import useJourneyStore from 'modules/journeys/store/use-journey-store'
import { makeMap } from 'modules/map/modules/map-canvas/utils/make-map'
import { loadMap } from 'modules/map/usecases/load-map'
import { ROLE, Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks/use-Me'

import { useMapStore } from '../modules/map-canvas/store'

export const useLoadMap = (id: string) => {
	const { setMap, setPoints, selectPointById } = useMapStore(
		(store) => store.actions
	)
	const { getActiveUsers } = useJourneyStore()
	const { pointId, mapId } = useParams()

	const members = getActiveUsers()
	const currentUserId = useMe().user?.id
	const currentUserRoles = useMemo(
		() => members.find((m) => m.id === currentUserId)?.project_roles,
		[currentUserId, members]
	) as ROLE[]
	const canAddPoint = Role(currentUserRoles).can('point.add')

	return useQuery([journeyKeys.map(mapId as string)], () => loadMap(id), {
		enabled: !!id && !!members.length,
		onSuccess: (data) => {
			setPoints(data.points)
			setMap(makeMap(data.points, canAddPoint), !canAddPoint)
			if (pointId) {
				selectPointById(pointId)
			}
		}
	})
}
