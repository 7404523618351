import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStEyeClosed: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 25 24' {...props}>
		<path
			d='M12 9.36792C11.206 9.37316 10.4459 9.69091 9.88447 10.2524C9.32299 10.8139 9.00524 11.5739 9 12.3679C9 14.0099 10.358 15.3679 12 15.3679C13.641 15.3679 15 14.0099 15 12.3679C15 10.7269 13.641 9.36792 12 9.36792Z'
			fill='#505565'
		/>
		<path
			d='M11.9997 19.375C12.9457 19.375 13.8097 19.272 14.5977 19.094L12.8407 17.337C12.5677 17.358 12.2907 17.375 11.9997 17.375C6.64874 17.375 4.57573 13.529 4.07373 12.375C4.45068 11.5338 4.95978 10.7583 5.58173 10.078L4.18373 8.67997C2.64573 10.347 2.06273 12.026 2.05173 12.059C1.98276 12.2643 1.98276 12.4866 2.05173 12.692C2.07273 12.758 4.36673 19.375 11.9997 19.375ZM11.9997 5.37497C10.1627 5.37497 8.65373 5.77097 7.39573 6.35597L3.70673 2.66797L2.29273 4.08197L20.2927 22.082L21.7067 20.668L18.3877 17.349C21.0017 15.398 21.9347 12.734 21.9487 12.692C22.0177 12.4866 22.0177 12.2643 21.9487 12.059C21.9267 11.992 19.6327 5.37497 11.9997 5.37497ZM16.9717 15.933L14.6917 13.653C14.8817 13.263 14.9997 12.834 14.9997 12.375C14.9997 10.734 13.6407 9.37497 11.9997 9.37497C11.5407 9.37497 11.1117 9.49297 10.7227 9.68397L8.91473 7.87597C9.90725 7.53538 10.9504 7.36597 11.9997 7.37497C17.3507 7.37497 19.4237 11.221 19.9257 12.375C19.6237 13.067 18.7597 14.717 16.9717 15.933Z'
			fill='#505565'
		/>
	</IconBase>
)
