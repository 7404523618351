import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiPrinter } from 'react-icons/bi'
import { BsExclamationCircle } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'

import {
	Box,
	Circle,
	HStack,
	Icon,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'

import { Loading } from 'modules/map/modules/starting-point/components/content/loading'
import { useMe } from 'modules/user/hooks'
import { userKeys } from 'modules/user/query'

import { useCreateMonitorPointAnalysis } from '../../hooks/use-create-monitor-point-analysis'

interface MonitorPointProps {
	isOpen: boolean
	onClose: () => void
	monitorPointId: string | undefined
	monitorPointName: string | undefined
}

export const MonitorPointAnalysisModal = ({
	isOpen,
	onClose,
	monitorPointId,
	monitorPointName
}: MonitorPointProps) => {
	const [analysis, setAnalysis] = useState<string | null>(null)
	const [isPrinting, setIsPrinting] = useState(false)
	const analysisTextToPrintRef = useRef(null)
	const { mutate: createMonitorPointAnalysis } = useCreateMonitorPointAnalysis()
	const toast = useToast()
	const { t } = useTranslation()
	const { user } = useMe()

	const handlePrint = useReactToPrint({
		onBeforeGetContent: () => {
			setIsPrinting(true)
		},
		content: () => analysisTextToPrintRef.current,
		onAfterPrint: () => setIsPrinting(false),
		documentTitle: `${monitorPointName} - ${t('map:points:monitor:view:analysis')}}`
	})

	useEffect(() => {
		if (!isOpen || !monitorPointId) return

		createMonitorPointAnalysis(
			{ monitorPointId, data: { language: user?.language ?? 'PT_BR' } },
			{
				onSuccess(data) {
					setAnalysis(data.analysis)
					queryClient.invalidateQueries(userKeys.me())
				},
				onError(error) {
					console.log('error on createMonitorPointAnalysis', error)
					onClose()
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)

		return () => {
			setAnalysis('')
		}
	}, [isOpen])

	return (
		<Modal
			scrollBehavior='outside'
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			isCentered
			size={analysis ? ['full', '4xl'] : ['full', '2xl']}
		>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent h='fit-content' transition='max-width 0.3s ease' p={3}>
				<ModalCloseButton mt={3} mr={2} />
				<Box ref={analysisTextToPrintRef}>
					<ModalHeader>
						<Text fontSize='md'>{t('map:points:monitor:view:analysis')}</Text>
					</ModalHeader>
					{analysis ? (
						<ModalBody>
							<Text color='gray.400' mb={2}>
								{analysis}
							</Text>
						</ModalBody>
					) : (
						<Loading mt={['50%', 10]} />
					)}
				</Box>
				{analysis && (
					<>
						<ModalFooter alignSelf='flex-start'>
							<HStack alignItems='flex-start'>
								<Icon
									mt='5px'
									as={BsExclamationCircle}
									color='red'
									size='xs'
								></Icon>
								<Text
									fontWeight='semibold'
									color='gray.400'
									fontSize='sm'
									maxW='90%'
								>
									{t('map:points:monitor:view:print')}
								</Text>
							</HStack>
						</ModalFooter>
						<Box position='absolute' bottom={5} right={5}>
							<Circle
								size={10}
								as={IconButton}
								isLoading={isPrinting}
								cursor='pointer'
								backgroundColor='analysis_modal_print_button'
								aria-label='print analysis content'
								onClick={handlePrint}
							>
								<Icon color='white' as={BiPrinter} w={6} h={6} />
							</Circle>
						</Box>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}
