import defaultCustomColors from '../default/colors'

const institutoAnimaCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#FDB400'
	},

	secondary_color_scheme_button: {
		700: '#32CDAC'
	},

	switch_scheme_color_checked: {
		500: '#32CDAC'
	},

	radio_color: {
		500: '#32CDAC'
	}
}

export default institutoAnimaCustomColors
