import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#A32341',
		light: '#EDBAC5'
	},

	secondary: {
		dark: '#482306',
		light: '#F0D5B9'
	}
}

export default themeColors
