import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import {
	IconStJourneys,
	IconStLab,
	IconStPeople
} from 'modules/common/components/icons'
import { DashboardContext } from 'modules/common/contexts/dashboard-context'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { useTypeUser } from 'modules/common/contexts/user-type-context'

import { StrateegiaLogo } from './components/strateegia-logo'
import { StrateegiaOptions } from './components/strateegia-options'
import { ToggleButton } from './components/toggle-button'
import { UserOptions } from './components/user-options'
import { filterOptionsBasedOnRole } from './utils/filter-options'

type NavBarProps = {
	handleClick?: () => void
	isIconMode?: boolean
}

export const NavBar: React.FC<NavBarProps> = ({ handleClick, isIconMode }) => {
	const { shrink, setShrink } = useContext(DashboardContext)
	const { pathname } = useLocation()
	const [expandedIndex, setExpandedIndex] = useState<number>(-1)
	const { tenant } = useTenant()
	const { userType } = useTypeUser()
	const { t } = useTranslation()

	useEffect(() => {
		const pathToIndexMap = {
			'/dashboard/journeys': 0,
			'/dashboard/view/journeys': 1,
			'/dashboard/labs': 2
		}

		const foundIndex = Object.keys(pathToIndexMap).findIndex((key) =>
			pathname.includes(key)
		)
		if (foundIndex !== -1) {
			setExpandedIndex(foundIndex)
		}
	}, [pathname])

	const handleAccordionChange = (index: number) => {
		setExpandedIndex(index)
	}

	const handleLinkClick = () => {
		handleClick?.()
		setExpandedIndex(-1)
	}

	const handleNavbarClick = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement
		const element = target.nodeName.toLowerCase()

		const isMenuArea =
			element === 'div' &&
			!target.className.includes('chakra-stack') &&
			target.role !== 'region'

		if (isMenuArea) {
			setShrink.toggle()
			sendGAEvent('expand_menu')
		}
	}

	const journeySubItems = [
		{
			name: t('common:navbar.journeys.createJourney'),
			path: '/dashboard/journeys/select-template'
		},
		{
			name: t('common:navbar.journeys.myJourneys'),
			path: '/dashboard/journeys/my',
			match() {
				return pathname.includes('journeys/my')
			}
		},
		{
			name: t('common:navbar.journeys.exploreTemplates'),
			path: '/dashboard/journeys/explore-journeys'
		}
	]

	const labOptions = [
		{
			name: t('common:navbar.labs.my'),
			path: '/dashboard/labs/my',
			match() {
				return pathname.includes('labs/my')
			}
		},
		{
			name: t('common:navbar.labs.shared'),
			path: '/dashboard/labs/participation'
		}
	]

	const allItemsShrink = [
		{
			path: '/dashboard/journeys',
			icon: IconStJourneys,
			name: t('common:navbar.journeys.title')
		},
		{
			path: '/dashboard/view/journeys',
			icon: IconStPeople,
			name: t('common:navbar.dashboard.title')
		},
		{
			path: '/dashboard/labs/my',
			icon: IconStLab,
			name: t('common:navbar.labs.title'),
			match() {
				return pathname.includes('participation')
			}
		}
	]

	const filteredJourneySubItems = filterOptionsBasedOnRole(
		journeySubItems,
		'journeys_options',
		shrink,
		userType,
		tenant
	)
	const filteredLabSubItems = filterOptionsBasedOnRole(
		labOptions,
		'labs_options',
		shrink,
		userType,
		tenant
	)

	const allItems = [
		{
			path: '/dashboard/journeys',
			icon: IconStJourneys,
			text: t('common:navbar.journeys.title'),
			subItems: filteredJourneySubItems
		},

		{
			path: '/dashboard/view',
			icon: IconStPeople,
			text: t('common:navbar.dashboard.title'),
			subItems: [
				{
					name: t('common:navbar.dashboard.journeys'),
					path: '/dashboard/view/journeys'
				}
			]
		},

		{
			path: '/dashboard/labs',
			icon: IconStLab,
			text: t('common:navbar.labs.title'),
			subItems: filteredLabSubItems
		}
	]

	const items = filterOptionsBasedOnRole(
		allItems,
		'navbar_options',
		shrink,
		userType,
		tenant
	)

	const itemsShrink = filterOptionsBasedOnRole(
		allItemsShrink,
		'navbar_options',
		true,
		userType,
		tenant
	)

	const currentIndex = items.findIndex((item) => {
		return `${pathname}`.includes(item.path)
	})

	const showContent = !shrink

	return (
		<Box
			w='100%'
			h='100%'
			pt={5}
			pb={10}
			px={0}
			display='flex'
			flexDirection='column'
			backgroundColor='navbar_bg'
			borderRadius='3xl'
			borderTopLeftRadius='none'
			borderBottomLeftRadius='none'
			position='relative'
			onClick={handleNavbarClick}
		>
			<StrateegiaLogo showContent={showContent} />

			<Flex
				overflow='hidden'
				h='100%'
				direction='column'
				justify='space-between'
				overflowY='auto'
			>
				<StrateegiaOptions
					isIconMode={isIconMode}
					handleClick={handleClick}
					handleLinkClick={handleLinkClick}
					showContent={showContent}
					currentIndex={currentIndex}
					expandedIndex={expandedIndex}
					handleAccordionChange={handleAccordionChange}
					itemsShrink={itemsShrink}
					items={items}
				/>

				<UserOptions
					handleLinkClick={handleLinkClick}
					isIconMode={isIconMode}
				/>
			</Flex>

			<ToggleButton />
		</Box>
	)
}
