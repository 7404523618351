import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheckCircle } from 'react-icons/bi'

import {
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	Text,
	Icon,
	HStack,
	PinInput,
	PinInputField,
	useBreakpointValue
} from '@chakra-ui/react'

import Api from 'data/api'

const StepCode = ({
	onFinish,
	email
}: {
	onFinish: (hash: string) => void
	email: string
}) => {
	const { t } = useTranslation()
	const [value, setValue] = useState('')
	const [pinValue, setPinValue] = useState('')
	const [count, setCount] = useState(0)
	const [showClock, setShowClock] = useState(true)
	const [minutes, setMinutes] = useState(3)
	const [seconds, setSeconds] = useState(0)
	const [erro, setErro] = useState(false)
	const [redPinInput, setRedPinInput] = useState(false)
	const [valid, setValid] = useState(false)
	const [expired, setExpired] = useState(false)
	const [desablePin, setDesablePin] = useState(false)

	const pinInputSize = useBreakpointValue({ base: 'md', lg: 'lg' })

	useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1)
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval)
				} else {
					setMinutes(minutes - 1)
					setSeconds(59)
				}
			}
			if (seconds === 0 && minutes === 0) {
				setShowClock(false)
			}
		}, 1000)
		return () => {
			clearInterval(myInterval)
		}
	})

	const handleChange = (value: string) => {
		setPinValue(value)
		setValue(value)
	}

	const resendCode = () => {
		setMinutes(3)
		setSeconds(0)
		setShowClock(true)
		setExpired(true)
		setDesablePin(false)
		setErro(false)
		setCount(0)
		Api.Users.passwordRecovery(email)
	}

	const handleSubmit = async () => {
		try {
			const { hash } = await Api.Users.passwordRecoverySetHash(email, pinValue)
			setValid(true)
			setErro(false)
			setTimeout(() => {
				onFinish(hash)
			}, 1000)
		} catch (e) {
			setCount(count + 1)
			setErro(true)
			setValue('')
			setRedPinInput(true)
			setTimeout(() => {
				setRedPinInput(false)
			}, 1000)
		}

		if (count === 2) {
			setShowClock(false)
			setDesablePin(true)
		}
	}
	return (
		<>
			<ModalHeader fontSize='2xl' mt={10}>
				{expired
					? t('forgotPassword:code.title_2')
					: t('forgotPassword:code.title')}
			</ModalHeader>
			<ModalBody>
				<Box>
					<Text fontSize='sm' mb='1.7rem'>
						{t('forgotPassword:code.body')}
					</Text>
					<Text
						mb='0.75rem'
						fontWeight='semibold'
						color='gray.400'
						fontSize='sm'
					>
						{t('forgotPassword:code.subtitle')}
					</Text>
				</Box>
				{valid ? (
					<Box flexDirection='column' display='flex' alignItems='center'>
						<Icon as={BiCheckCircle} fontSize='7xl' color='green.500' />
					</Box>
				) : (
					<HStack spacing='auto'>
						<PinInput
							isDisabled={desablePin}
							isInvalid={redPinInput}
							value={value}
							onChange={handleChange}
							size={pinInputSize}
						>
							<PinInputField borderRadius='base' />
							<PinInputField borderRadius='base' />
							<PinInputField borderRadius='base' />
							<PinInputField borderRadius='base' />
							<PinInputField borderRadius='base' />
							<PinInputField borderRadius='base' />
						</PinInput>
					</HStack>
				)}
				{erro && (
					<Text mt='1.5rem' fontSize='xs' textColor='red'>
						{count <= 2 &&
							t('errors:changePassword.wrongCode', { count: 3 - count })}
						{count > 2 && t('errors:changePassword.codeExpired')}
					</Text>
				)}
				<Text display='inline-block' mt='1.5rem' fontSize='xs'>
					{t('forgotPassword:code.notReceive')}{' '}
				</Text>{' '}
				{showClock ? (
					<>
						<Text fontSize='xs' display='inline-block'>
							{t('forgotPassword:code.in')}
						</Text>{' '}
						<Text display='inline-block' fontSize='xs'>
							{' '}
							{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
						</Text>{' '}
						<Text fontSize='xs' display='inline-block'>
							{t('forgotPassword:code.receiveOther')}
						</Text>
					</>
				) : (
					<Button
						fontSize='xs'
						variant='link'
						colorScheme='blue'
						onClick={resendCode}
					>
						{t('buttons:clickHere')}
					</Button>
				)}
			</ModalBody>

			<ModalFooter pt={0}>
				<Button
					mt={4}
					mb={3}
					width='full'
					colorScheme='custom_scheme_color_button'
					isDisabled={!value}
					onClick={handleSubmit}
				>
					{t('buttons:confirmCode')}
				</Button>
			</ModalFooter>
		</>
	)
}

export default StepCode
