import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Text,
	VStack,
	Badge,
	HStack,
	useBreakpointValue,
	useToast,
	useDisclosure
} from '@chakra-ui/react'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { User } from 'modules/user/types'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import { evalauteAllAnswers } from '../../usecases/evaluate-all-answers'

type Props = {
	onClose: () => void
	isOpen: boolean
	responsesAmount: number
	pointId: string
	user: User
}

export const ModalEvaluateAll = ({
	isOpen,
	onClose,
	responsesAmount,
	pointId,
	user
}: Props) => {
	const { t } = useTranslation()
	const time = (responsesAmount * 30) / 60
	const price = responsesAmount * 2
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const toast = useToast()
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()
	const [isLoading, setIsLoading] = useState(false)
	const { clearSelected } = useMapStore((state) => state.actions)

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleEvaluate = () => {
		if (user.credits < price) {
			emptyCreditsDisclosure.onOpen()
			return
		}
		try {
			setIsLoading(true)
			evalauteAllAnswers(pointId)
			clearSelected()
			onClose()
			setIsLoading(false)
			toast({
				position: 'top',
				title: t('evaluationPoint:evaluateAll.infoTitle'),
				description: (
					<Trans
						i18nKey='evaluationPoint:evaluateAll.infoMessage'
						values={{ time }}
					/>
				),
				status: 'info'
			})
			consumeCredits(price)
			updateUserCredits(user.credits - price)
		} catch (error) {
			setIsLoading(false)
			toast({
				title: t('errors:evaluationPoint.evaluateAll'),
				status: 'error'
			})
		}
	}

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
					<HStack>
						<Text fontSize={['18', 'xl']} fontWeight='bold'>
							{t('evaluationPoint:evaluateAll.title')}
						</Text>
						<Badge fontSize={12} variant='solid' colorScheme='red'>
							beta
						</Badge>
					</HStack>
				</ModalHeader>
				<ModalCloseButton mt={isMobile ? 1 : 5} mr={isMobile ? 0 : 4} />
				<ModalBody px={isMobile ? 4 : 8}>
					<VStack alignItems='flex-start' gap={4}>
						<Text>
							<Trans
								i18nKey='evaluationPoint:evaluateAll.message'
								components={[<strong />]}
								values={{ time, price }}
							/>
						</Text>
						<Text fontSize={['sm', 'lg']}>
							{t('evaluationPoint:evaluateAll.intructions')}
						</Text>
						<Text>
							<Trans
								i18nKey={t('evaluationPoint:evaluateAll.confirmation')}
								components={[<strong />]}
							/>
						</Text>
						<Text>{t('evaluationPoint:evaluateAll.flexibility')}</Text>
						<Text>
							<Trans
								i18nKey='evaluationPoint:evaluateAll.beta'
								components={[<strong />]}
							/>
						</Text>
					</VStack>
				</ModalBody>

				<ModalFooter mt={3} px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<HStack>
						<Button onClick={onClose}>{t('buttons:cancel')}</Button>
						<Button
							isLoading={isLoading}
							colorScheme='custom_scheme_color_button'
							onClick={handleEvaluate}
						>
							{t('buttons:confirm')}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={user?.credits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Modal>
	)
}
