import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Button,
	Grid,
	HStack,
	Icon,
	ModalBody,
	ModalHeader,
	SkeletonText,
	Text,
	useToast,
	VStack
} from '@chakra-ui/react'
import { QRCodeCanvas } from 'qrcode.react'

import { LinkIcon } from '@chakra-ui/icons'
import queryClient from 'config/query-client'

import { CustomDivider } from 'modules/labs/components/plans/common/custom-divider'
import { userKeys } from 'modules/user/query'

import { IconStCoin } from '../icons'
import { Counter } from './components/counter'
import { SucessPayment } from './components/sucess-payment'
import { generateCode } from './usecases/generate-code'
import { useGetPaymentStatus } from './usecases/get-paymant-status'

export const PayPix = () => {
	const { t } = useTranslation()
	const [showExpired, setShowExpired] = useState(false)

	const { getValues } = useFormContext()
	const [code, setCode] = useState('')
	const [codeId, setCodeId] = useState('')

	const { creditsAmount, creditsCost } = getValues()

	const { paymentStatus } = useGetPaymentStatus(codeId)

	const btnProps = {
		size: 'md',
		colorScheme: 'red',
		paddingLeft: '1rem',
		variant: 'outline',
		fontSize: 'sm',
		fontWeight: 'semibold'
	}

	const toast = useToast({
		position: 'bottom',
		isClosable: true,
		variant: 'solid'
	})

	const createCode = async () => {
		try {
			const responseInfo = await generateCode(creditsAmount)
			setCode(responseInfo.qr_code)
			setCodeId(responseInfo.id)
		} catch (error) {
			toast({
				title: t('errors:credits.pix'),
				status: 'error'
			})
		}
	}

	const onCopyLink = () => {
		navigator.clipboard.writeText(code)
		const toastId = 'public-link-toast'
		if (!toast.isActive(toastId)) {
			toast({
				id: toastId,
				title: t('inviteUsers:byPublicLink.copySuccess'),
				status: 'success'
			})
		}
	}

	useEffect(() => {
		queryClient.invalidateQueries(userKeys.me())
	}, [paymentStatus])

	useEffect(() => {
		createCode()
	}, [])

	return (
		<>
			<ModalHeader>
				<Text fontSize='xl' fontWeight='bold'>
					{t('credits:pix.title')}
				</Text>
			</ModalHeader>
			<ModalBody>
				<Grid
					placeItems='center'
					mb={4}
					alignItems='center'
					w='full'
					py={2}
					px={2}
					borderWidth={1}
					borderRadius='xl'
					borderColor='blue.50'
					gridTemplateColumns={{
						base: '1fr',
						md: '1fr 0.1fr 1fr'
					}}
				>
					<HStack>
						<Icon w={10} h={10} as={IconStCoin} />
						<Text fontWeight='semibold' fontSize='md'>
							{creditsAmount} {t('credits:credits')}
						</Text>
					</HStack>
					<CustomDivider />
					<VStack>
						<Text fontSize='xs'>{t('credits:pix.checkout')}</Text>
						<Text fontSize='md' fontWeight='bold'>
							R$ {creditsCost}
						</Text>
					</VStack>
				</Grid>

				<Box borderWidth={1} borderRadius='xl' borderColor='blue.50' pb={4}>
					{showExpired === false && paymentStatus !== 'PAID' && (
						<>
							<Text p={4} fontSize='xs'>
								{t('credits:pix.message')}
							</Text>
							{code ? (
								<VStack spacing={6}>
									<Counter setShowExpired={setShowExpired} />
									<QRCodeCanvas value={code} size={250} />
									<Button
										{...btnProps}
										onClick={onCopyLink}
										w={250}
										leftIcon={<LinkIcon />}
									>
										{t('buttons:copyPix')}
									</Button>
								</VStack>
							) : (
								<SkeletonText />
							)}
						</>
					)}
					{showExpired && paymentStatus !== 'PAID' && (
						<Text
							textAlign='center'
							p={4}
							color='gray.400'
							fontWeight='bold'
							fontSize='xs'
						>
							{t('credits:pix.expired')}
						</Text>
					)}
					{paymentStatus === 'PAID' && <SucessPayment />}
				</Box>
			</ModalBody>
		</>
	)
}
