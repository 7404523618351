import { fetch } from 'config'

type Params = {
	pointType:
		| 'notice-point'
		| 'divergence-point'
		| 'convergence-point'
		| 'check-point'
		| 'essay-point'
		| 'monitor-point'
	pointId: string
	visibility: boolean
}

export async function updatePointVisibility({
	pointType,
	pointId,
	visibility
}: Params) {
	const response = await fetch({
		url: `/projects/v1/${pointType}/${pointId}/visibility`,
		method: 'PATCH',
		data: {
			visible: visibility
		}
	})

	return response.data
}
