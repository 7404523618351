import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { Tokens, persistTerms } from 'config'
import { sessionManager } from 'config/session-manager'
import jwtDecode from 'jwt-decode'

import LoadingStrateegia from 'modules/common/components/loading'
import { useQueryParams } from 'modules/common/hooks'

import { useAuthenticateWithProvider } from '../hooks/useAuthenticateUserProvider'

type StateType = {
	providerId: string
	urlCallback: string
	previousUrl: string
}

export const CallBackProvider = () => {
	const navigate = useNavigate()
	const query = useQueryParams()
	const code = query.get('code') as string
	const state = query.get('state') as string
	const error = query.get('error') as string
	const objState = JSON.parse(state) as StateType
	const { data } = useAuthenticateWithProvider({
		code,
		providerId: objState.providerId,
		redirectUri: objState.urlCallback
	})

	const { pathname, search } = useLocation()
	const current = `${pathname}${search}`
	const ignoredPaths = ['/signin', '/', '/signup', current]
	const redirectURL = !ignoredPaths.includes(objState.previousUrl)
		? objState.previousUrl
		: '/dashboard'

	useEffect(() => {
		const hasAccount = !!data?.registered
		if (error) {
			navigate(`/signin?error=${error}`)
		}
		if (data && !hasAccount) {
			navigate(
				`/signup?action=withProvider&name=${data?.name}&email=${data?.email}&providerId=${objState.providerId}&externalId=${data.external_id}&accessToken=${data.access_token}&previousUrl=${redirectURL}`
			)
		}
		if (data && hasAccount) {
			Tokens.set(
				{ access_token: data.access_token, refresh_token: data.refresh_token },
				false
			)
			const token = jwtDecode<{ exp: number }>(data.access_token)

			sessionManager.startSession(token.exp)
			persistTerms.set(true)
			navigate(redirectURL)
		}
	}, [data])

	return (
		<Flex w='100%' height='100%' alignItems='center' justify='center'>
			<LoadingStrateegia />
		</Flex>
	)
}
