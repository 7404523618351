const key = 'session'

export const sessionManager = (() => {
	let value: number | null = null

	const _getLocalSession = (): number | null => {
		const localData = localStorage.getItem(key)
		const sessionString = localData

		if (sessionString) {
			return parseInt(sessionString)
		}

		return null
	}

	const _getSession = () => {
		if (value) {
			return value
		}

		return _getLocalSession()
	}

	const _getExpirationDate = (exp: number) => new Date(exp * 1000)

	const _clear = () => {
		localStorage.removeItem(key)
		value = null
	}

	const startSession = (exp: number) => {
		_clear()
		value = exp

		localStorage.setItem(key, exp.toString())
	}

	const endSession = () => {
		_clear()
	}

	const hasSession = () => {
		const session = _getSession()

		return !!session
	}

	const isExpired = () => {
		const exp = _getSession()
		if (!exp) {
			return true
		}

		const { isValid } = validateExpiration(exp)

		return !isValid
	}

	const validateExpiration = (exp: number) => {
		const isValid = new Date() < _getExpirationDate(exp)
		return { isValid }
	}

	const updateSession = (exp: number) => {
		startSession(exp)
	}

	return {
		startSession,
		endSession,
		hasSession,
		isExpired,
		updateSession,
		validateExpiration
	}
})()
