import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStPointConversation: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 31 35' {...props}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.1554 0.267949C14.7742 -0.0893162 15.5366 -0.0893166 16.1554 0.267949L29.3109 7.86325C29.9297 8.22051 30.3109 8.88077 30.3109 9.5953V24.7859C30.3109 25.5004 29.9297 26.1607 29.3109 26.5179L16.1554 34.1132C15.5366 34.4705 14.7742 34.4705 14.1554 34.1132L1 26.518C0.381199 26.1607 0 25.5004 0 24.7859V9.5953C0 8.88077 0.381198 8.22051 1 7.86325L14.1554 0.267949ZM10.9054 15.9406H12.4054V17.4406H10.9054V15.9406ZM10.9054 18.9406H12.4054V20.4406H10.9054V18.9406ZM13.9054 15.9406H15.4054V17.4406H13.9054V15.9406ZM13.9054 18.9406H15.4054V20.4406H13.9054V18.9406ZM16.9054 15.9406H18.4054V17.4406H16.9054V15.9406ZM16.9054 18.9406H18.4054V20.4406H16.9054V18.9406ZM19.9054 24.1906H9.40544C8.57819 24.1906 7.90544 23.5178 7.90544 22.6906V12.1906C7.90544 11.3633 8.57819 10.6906 9.40544 10.6906H10.9054V9.1906H12.4054V10.6906H16.9054V9.1906H18.4054V10.6906H19.9054C20.7327 10.6906 21.4054 11.3633 21.4054 12.1906V22.6906C21.4054 23.5178 20.7327 24.1906 19.9054 24.1906ZM19.9062 22.6906L19.9054 13.6906H9.40544V22.6906H19.9062Z'
			fill='currentColor'
		/>
	</IconBase>
)
