import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#E30000',
		light: '#FF7272'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FFF3D7'
	}
}

export default themeColors
