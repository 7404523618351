import defaultCustomColors from '../default/colors'

const luminaCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#BF897C',
		700: '#BF897C'
	},

	secondary_color_scheme_button: {
		700: '#272F42'
	},

	switch_scheme_color_checked: {
		500: '#272F42'
	},

	radio_color: {
		500: '#272F42'
	}
}

export default luminaCustomColors
