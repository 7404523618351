import { ReactNode } from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

import {
	MonitorPointStatusCommentResponse,
	MonitorPointStatusResponse
} from '../types/monitor-point'
import { addMonitorPointStatusComment } from '../usecases/add-monitor-point-status-comment'
import { deleteMonitorPointStatus } from '../usecases/delete-monitor-point-status'
import { deleteMonitorPointStatusComment } from '../usecases/delete-monitor-point-status-comment'
import { statusAgreement } from '../usecases/status-agreement '
import { statusCommentAgreement } from '../usecases/status-comment-agreement'

interface MonitorPointStatusStoreState {
	isLoading: { [key: string]: boolean }
	statuses: MonitorPointStatusResponse[]
	syncMonitorPointStatus: (statuses: MonitorPointStatusResponse[]) => void
	addStatusState: (newStatus: MonitorPointStatusResponse) => void
	addComment: (
		monitorPointId: string,
		statusId: string,
		text: string
	) => Promise<MonitorPointStatusCommentResponse>
	addCommentState: (data: MonitorPointStatusCommentResponse) => void
	deleteStatus: (monitorPointId: string, statusId: string) => Promise<void>
	deleteStatusState: (
		statusToRemove:
			| MonitorPointStatusResponse
			| MonitorPointStatusCommentResponse
	) => void
	deleteComment: (commentId: string) => Promise<void>
	deleteCommentState: (status_id: string, commentId: string) => void
	manageLike: (
		mode: 'add' | 'delete',
		monitorPointId?: string,
		statusId?: string,
		commentId?: string
	) => Promise<
		MonitorPointStatusResponse | MonitorPointStatusCommentResponse | void
	>
	updateStatusState: (
		newData: MonitorPointStatusResponse | MonitorPointStatusCommentResponse
	) => void
}

const { Provider, useStore } = createContext<MonitorPointStatusStoreState>()

const MonitorPointStatusStore = () =>
	create<MonitorPointStatusStoreState>((set, get) => ({
		isLoading: {},
		statuses: [],

		syncMonitorPointStatus: async (statuses: MonitorPointStatusResponse[]) => {
			set({ statuses })
		},

		addStatusState: async (newStatus: any) => {
			if (newStatus.monitor_point_status_id) {
				get().addCommentState(newStatus)
				return
			}
			newStatus.replies = []

			const { statuses } = get()

			const alreadyHaveThisStatus = statuses.find(
				(status) => status.id === newStatus.id
			)
			if (alreadyHaveThisStatus) {
				return
			}

			const updatedStatuses = [...statuses]
			updatedStatuses.unshift(newStatus)

			set(() => ({
				statuses: updatedStatuses
			}))
		},

		deleteStatus: async (monitorPointId: string, statusId: string) => {
			try {
				set((state) => ({
					isLoading: { ...state.isLoading, [statusId]: true }
				}))
				await deleteMonitorPointStatus(monitorPointId, statusId)
			} catch (error) {
				console.log('error on deleteStatus', error)
				throw error
			} finally {
				set((state) => ({
					isLoading: { ...state.isLoading, [statusId]: false }
				}))
			}
		},

		deleteStatusState: (
			statusToRemove:
				| MonitorPointStatusCommentResponse
				| MonitorPointStatusResponse
		) => {
			if ('monitor_point_status_id' in statusToRemove) {
				get().deleteCommentState(
					statusToRemove.monitor_point_status_id,
					statusToRemove.id
				)
				return
			}
			const { statuses } = get()

			const updatedStatuses = statuses.filter(
				(status) => status.id !== statusToRemove.id
			)
			set({ statuses: updatedStatuses })
		},

		addComment: async (
			monitorPointId: string,
			statusId: string,
			text: string
		) => {
			set((state) => ({
				isLoading: { ...state.isLoading, [statusId]: true }
			}))
			try {
				const { data } = await addMonitorPointStatusComment(
					monitorPointId,
					statusId,
					text
				)
				return data
			} catch (error) {
				console.log('error on addComment', error)
				throw error
			} finally {
				set((state) => ({
					isLoading: { ...state.isLoading, [statusId]: false }
				}))
			}
		},

		addCommentState: (data: any) => {
			const { statuses } = get()
			const status = statuses.find(
				(status) => status.id === data.monitor_point_status_id
			)
			if (status) {
				const alreadyHaveThisComment = status.replies.find(
					(comment) => comment.id === data.id
				)
				if (alreadyHaveThisComment) {
					return
				}

				status.replies = [...(status.replies || []), data]
				set(() => ({ statuses: [...statuses] }))
			}
		},

		deleteComment: async (commentId: string) => {
			try {
				set((state) => ({
					isLoading: { ...state.isLoading, [commentId]: true }
				}))
				await deleteMonitorPointStatusComment(commentId)
			} catch (error) {
				console.log('error on deleteComment', error)
				throw error
			} finally {
				set((state) => ({
					isLoading: { ...state.isLoading, [commentId]: false }
				}))
			}
		},

		deleteCommentState: (status_id: string, commentId: string) => {
			const { statuses } = get()

			const updatedStatuses = statuses.map((status) => {
				if (status.id === status_id) {
					return {
						...status,
						replies: status.replies.filter((reply) => reply.id !== commentId)
					}
				}
				return status
			})
			set({ statuses: updatedStatuses })
		},
		manageLike: async (
			mode: 'add' | 'delete',
			monitorPointId?: string,
			statusId?: string,
			commentId?: string
		) => {
			if (commentId) {
				try {
					const data = await statusCommentAgreement(commentId, mode)
					return data
				} catch (error) {
					console.log(error)
				}
			}

			if (monitorPointId && statusId) {
				try {
					const data = await statusAgreement(monitorPointId, statusId, mode)
					get().updateStatusState(data)
					return data
				} catch (error) {
					console.log(error)
				}
			}
		},
		updateStatusState(newData: any) {
			if (newData.monitor_point_status_id) {
				// its a comment
				return
			}
			const { statuses } = get()
			const statusIndex = statuses.findIndex(
				(status) => status.id === newData.id
			)
			const statusToUpdate = statuses[statusIndex]

			if (statusToUpdate) {
				statusToUpdate.agreements = newData.agreements

				set(() => ({ statuses }))
			}
		}
	}))

export function MonitorPointStatusStoreProvider({
	children
}: {
	children: ReactNode
}) {
	return <Provider createStore={MonitorPointStatusStore}>{children}</Provider>
}

export default useStore
