import { useQuery } from 'react-query'

import { questionKeys } from 'modules/map/query'
import { getQuestionAnalysis } from 'modules/map/usecases/get-question-analysis'
type Params = {
	pointId: string
	questionId: string
}

export const useGetQuestionAnalysis = ({ pointId, questionId }: Params) => {
	return useQuery(questionKeys.questionSummary(questionId), () =>
		getQuestionAnalysis({ pointId, questionId })
	)
}
