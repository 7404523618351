import { fetch } from 'config'

import { EvaluationResponse } from 'modules/map/types'

type Params = {
	pointId: string
	essayId: string
}

export async function evaluateEssay({ pointId, essayId }: Params) {
	const response = await fetch<EvaluationResponse>({
		url: `/projects/v1/essay-point/${pointId}/responses/${essayId}/evaluate`,
		method: 'PATCH'
	})
	return response.data
}
