import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Checkbox,
	HStack,
	Image,
	Stack,
	Textarea,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'

import { UserAvatar } from 'modules/common/components'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import UseCommentsStore from 'modules/map/store/use-comments-store'
import { commentWithAssistant } from 'modules/map/usecases/comment-with-assistant'
import { CommentWithAssistantValidation } from 'modules/map/validators/comment-with-assistant'

import SendIcon from '../send.svg'

type FormValues = {
	comment: string
	confirmation: boolean
}

type Props = {
	comment: string
	divergencePointId: string
	questionId: string
	assistantId: string
	projectId: string
	assistantName: string
	closeModal: () => void
}

export const AssistantQuestionCommentReview = ({
	comment,
	divergencePointId,
	questionId,
	assistantId,
	projectId,
	closeModal,
	assistantName
}: Props) => {
	const { t } = useTranslation()
	const { addCommentState } = UseCommentsStore()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { emit } = useSocket(divergencyPointChannel({ projectId }))
	const validation = CommentWithAssistantValidation()
	const toast = useToast()

	const {
		register,
		formState: { isValid, isSubmitting },
		handleSubmit,
		getValues
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: { comment, confirmation: false },
		resolver: yupResolver(validation)
	})

	const onSubmit = async () => {
		try {
			const savedComment = await commentWithAssistant({
				assistantId,
				divergencePointId,
				questionId,
				text: getValues().comment.trim()
			})
			addCommentState(savedComment)
			const commentInfo = {
				divergence_point_id: savedComment.divergence_point_id,
				question_id: savedComment.question_id,
				parent_id: savedComment.parent_id,
				id: savedComment.id
			}
			emit('ADD', commentInfo)
			closeModal()
			toast({
				title: t('divergencePoint:assistants.sucess'),
				isClosable: true,
				status: 'success'
			})
		} catch (error) {
			toast({
				title: 'try again',
				isClosable: true,
				status: 'error'
			})
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<HStack alignItems='baseline'>
					<UserAvatar
						iconOnly
						size='sm'
						user={{ username: assistantName, avatar_url: '', id: '' }}
					/>
					<Textarea
						minHeight={80}
						borderRadius='lg'
						onCopy={(e) => e.preventDefault()}
						{...register('comment')}
					/>
				</HStack>
				<Stack
					direction={['column', 'row']}
					pb={isMobile ? 4 : 8}
					justify='space-between'
					pt={4}
				>
					<Checkbox size='sm' {...register('confirmation')}>
						{t('divergencePoint:assistants.review')}
					</Checkbox>
					<Button
						type='submit'
						isDisabled={!isValid}
						mt={4}
						mb={3}
						colorScheme='custom_scheme_color_button'
						leftIcon={<Image src={SendIcon} />}
						isLoading={isSubmitting}
					>
						{t('divergencePoint:send_answer')}
					</Button>
				</Stack>
			</form>
		</>
	)
}
