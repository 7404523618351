import { fetch } from 'config'

type Params = {
	pointId: string
	questionId: string
	language: string
}

type Response = {
	id: string
	summary: string
	outdated: boolean
	createdAt: Date
}

export const createQuickSummary = ({
	pointId,
	questionId,
	language
}: Params) => {
	return fetch<Response>({
		url: `/projects/v1/divergence-point/${pointId}/question/${questionId}/summary`,
		method: 'POST',
		data: { language }
	}).then(({ data }) => data)
}
