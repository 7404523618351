import { fetch } from 'config'
import { fixNullOrNotBlank } from 'data/helpers/fixNullOrNotBlank'
import { mountParams, SortOrder } from 'data/helpers/mount-params'
import { v4 as uuid } from 'uuid'

import { QueryFilter } from 'modules/common/types/common-model'
import {
	Journey,
	JourneyParticipation,
	JourneysSummaryResponse,
	JourneySummary,
	PlanByLabs,
	PublicLinkResponse,
	Statistics,
	Template,
	User
} from 'modules/journeys/types'
import { Lab } from 'modules/labs/types'
import { Map, GeneralData, AnswersAndComments } from 'modules/map/types'
import { MapStatistic, ToolStatistic } from 'modules/tools/types'
import { ROLE } from 'modules/user/constants'

const getParticipation = (userId?: string) => {
	const isMyParticipations = userId || 'me'
	return fetch<JourneyParticipation[]>({
		url: `projects/v1/project/user/${isMyParticipations}/participation`,
		method: 'GET'
	}).then(({ data }) =>
		data.map((participation) => ({
			...participation,
			key: uuid()
		}))
	)
}

const getLabsWithProjects = () => {
	return fetch<Lab[]>({
		url: 'projects/v1/project/lab',
		method: 'GET'
	}).then(({ data }) => data)
}

export type CreateJourneyParams = {
	lab_owner_id: string
	title: string
}

const create = (params: CreateJourneyParams) => {
	return fetch<JourneySummary>({
		url: 'projects/v1/project',
		method: 'POST',
		data: params
	}).then(({ data }) => data)
}

type updateProps = Partial<
	Pick<Journey, 'title' | 'description' | 'color' | 'visibility'>
>
interface UpdateParams extends updateProps {
	id: string
}

export interface NotificationCount {
	awaiting_approval?: number
	pending_approval?: number
	pending_nda?: number
}
interface NotificationCountResponse {
	count: NotificationCount
	project_role: ROLE
}

const update = ({ id, ...params }: UpdateParams) => {
	const data = fixNullOrNotBlank<updateProps>(params, ['description'])
	return fetch<JourneySummary>({
		url: `projects/v1/project/${id}`,
		method: 'PUT',
		data
	}).then(({ data }) => data)
}

const createMap = ({
	projectId,
	title
}: {
	title: string
	projectId: string
}) => {
	return fetch<Map>({
		url: `projects/v1/project/${projectId}/map`,
		method: 'POST',
		data: { title }
	}).then(({ data }) => data)
}

const updateMap = ({ id, title }: { title: string; id: string }) => {
	return fetch<Map>({
		url: `projects/v1/map/${id}`,
		method: 'PUT',
		data: { title }
	}).then(({ data }) => data)
}

const deleteMap = ({ mapId }: { mapId: string }) => {
	return fetch<Map>({
		url: `projects/v1/map/${mapId}`,
		method: 'DELETE'
	}).then(({ data }) => data)
}

const getById = async (id: string) => {
	return await fetch<Journey>({
		url: `projects/v1/project/${id}`,
		method: 'GET'
	}).then(({ data }) => data)
}

const getUsersByJourneyIds = async (ids: string[]) => {
	return await fetch<User[]>({
		url: 'projects/v1/project/users/get-by-journey-ids',
		method: 'POST',
		data: { journey_ids: ids }
	}).then(({ data }) => data)
}

const explore = ({
	search,
	order,
	filter,
	size,
	pageParam = 0
}: {
	search: string
	order: SortOrder
	filter: QueryFilter
	size?: number
	pageParam: number
}) => {
	const params = mountParams({
		size: 12,
		page: pageParam,
		sort: ['createdAt', order],
		title: search,
		...filter
	})
	return fetch<JourneysSummaryResponse>({
		url: 'projects/v1/project/explore',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

type baseParams = {
	search?: string
	size?: number
	sortBy?: string
	order: SortOrder
	filter: QueryFilter
	pageParam: number
	projectRoles: string[]
	subdomain: string
}

const summary = ({
	search,
	order,
	filter,
	pageParam = 0,
	size,
	projectRoles = [],
	sortBy = 'createdAt',
	subdomain
}: baseParams) => {
	const params = mountParams({
		size,
		page: pageParam,
		sort: [sortBy, order],
		title: search,
		subdomain,
		projectRoles,
		...filter
	})

	return fetch<JourneysSummaryResponse>({
		url: 'projects/v1/project/summary',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const destroy = (id: string) => {
	return fetch({
		url: `projects/v1/project/${id}`,
		method: 'DELETE'
	}).then(({ data }) => data)
}

const getProjectByCode = (code: string) => {
	return fetch<{ id: string; title: string; user: User }>({
		url: `projects/v1/project/invite/${code}`,
		method: 'POST'
	})
}

const generatePublicLinkByProjectId = (id: string) => {
	return fetch<PublicLinkResponse>({
		url: `projects/v1/project/${id}/invite`,
		method: 'POST'
	})
}

const getPublicLinkByProjectId = (id: string) => {
	return fetch<PublicLinkResponse>({
		url: `projects/v1/project/${id}/invite`,
		method: 'GET'
	}).catch(() => {
		return generatePublicLinkByProjectId(id)
	})
}

const changePublicLinkPermission = (projectId: string, approveAll: boolean) => {
	return fetch({
		url: `projects/v1/project/${projectId}/invite`,
		method: 'PATCH',
		data: { approve_all: approveAll }
	})
}

const getPlanUsageByLabs = () => {
	const params = mountParams({
		includeParticipation: true
	})
	return fetch<PlanByLabs[]>({
		url: 'projects/v1/me/plan-usage/lab',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const getMapById = (mapId: string) => {
	return fetch<Map>({
		url: `projects/v1/map/${mapId}`,
		method: 'GET'
	}).then(({ data }) => data)
}

const getProjectStatistics = (id: string) => {
	return fetch<Statistics>({
		url: `projects/v1/project/${id}/statistics`,
		method: 'GET'
	}).then(({ data }) => data)
}

const getCommentEngagementByContent = (id: string) => {
	return fetch<ToolStatistic[]>({
		url: `projects/v1/project/${id}/divergence-point-engagement`,
		method: 'GET'
	}).then(({ data }) => data)
}

const getDivergencePointById = (divergencePointId: string) => {
	return fetch<GeneralData>({
		url: `projects/v1/divergence-point/${divergencePointId}`,
		method: 'GET'
	}).then(({ data }) => data)
}

const getDivergencePointAnswersAndComments = (divergencePointId: string) => {
	return fetch<AnswersAndComments[]>({
		url: `projects/v1/divergence-point/${divergencePointId}/comment/report`,
		method: 'GET'
	}).then(({ data }) => data)
}

const disapproveMembers = (projectId: string, userIdList: string[]) => {
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user/disapprove`,
		method: 'DELETE',
		data: {
			ids: userIdList
		}
	}).then(({ data }) => data)
}

const approveMembers = (projectId: string, userIdList: string[]) => {
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user/approve`,
		method: 'PATCH',
		data: {
			ids: userIdList
		}
	}).then(({ data }) => data)
}

const removeMember = (projectId: string, userIdList: string[]) => {
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user/${userIdList}`,
		method: 'DELETE'
	}).then(({ data }) => data)
}

const getCommentEngagementByMap = (id: string) => {
	return fetch<MapStatistic[]>({
		url: `projects/v1/project/${id}/map-engagement`,
		method: 'GET'
	}).then(({ data }) => data)
}

const acceptTerms = (projectId: string, answer: boolean) => {
	return fetch({
		url: `projects/v1/project/${projectId}/user/nda`,
		method: 'PATCH',
		data: {
			answer
		}
	}).then(({ data }) => data)
}

const inviteUsers = (projectId: string, userIdList: string[]) => {
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user`,
		data: { users: userIdList },
		method: 'POST'
	}).then(({ data }) => data)
}

const removeAsMentor = (projectId: string, userIdList: string[]) => {
	const [memberId] = userIdList
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user/${memberId}`,
		method: 'PATCH',
		data: {
			project_role: 'INNOVATOR'
		}
	}).then(({ data }) => data)
}

const promoteToMentor = (projectId: string, userIdList: string[]) => {
	const [memberId] = userIdList
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/user/${memberId}`,
		method: 'PATCH',
		data: {
			project_role: 'MENTOR'
		}
	}).then(({ data }) => data)
}

const getNotificationsCount = () => {
	return fetch<NotificationCountResponse[]>({
		url: 'projects/v1/project/summary/report',
		method: 'GET'
	}).then(({ data }) => data)
}

const getAllTemplates = ({
	search,
	order,
	filter,
	size,
	pageParam = 0
}: {
	search: string
	order: SortOrder
	filter: QueryFilter
	size?: number
	pageParam: number
}) => {
	const params = mountParams({
		size: 12,
		page: pageParam,
		sort: ['createdAt', order],
		title: search,
		...filter
	})
	return fetch<JourneysSummaryResponse>({
		url: 'projects/v1/template',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const getTemplateById = (id: string) => {
	return fetch<Template>({
		url: `projects/v1/template/${id}`,
		method: 'GET'
	}).then(({ data }) => data)
}

const configRequestNDA = (projectId: string, requestNDA: boolean) => {
	return fetch<Journey>({
		url: `projects/v1/project/${projectId}/request-nda`,
		method: 'PATCH',
		data: {
			request_nda: requestNDA
		}
	}).then(({ data }) => data)
}

const ProjectsApi = {
	getParticipation,
	getLabsWithProjects,
	summary,
	create,
	getById,
	explore,
	destroy,
	update,
	deleteMap,
	createMap,
	updateMap,
	getProjectByCode,
	getPublicLinkByProjectId,
	changePublicLinkPermission,
	getPlanUsageByLabs,
	getMapById,
	getProjectStatistics,
	getCommentEngagementByContent,
	getDivergencePointById,
	getDivergencePointAnswersAndComments,
	disapproveMembers,
	removeMember,
	approveMembers,
	getCommentEngagementByMap,
	acceptTerms,
	inviteUsers,
	removeAsMentor,
	promoteToMentor,
	getNotificationsCount,
	getAllTemplates,
	getTemplateById,
	configRequestNDA,
	getUsersByJourneyIds
}

export default ProjectsApi
export * from './get-applets'
