import { useTranslation } from 'react-i18next'

import { Button, Center, Icon, Link, Tooltip } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { IconStWorld } from 'modules/common/components/icons'

type Props = {
	isIconMode?: boolean
	lang: string
	handleLinkClick: () => void
}

export const StrateegiaBlog = ({
	isIconMode,
	lang,
	handleLinkClick
}: Props) => {
	const { t } = useTranslation()
	const registerBlogNavbarClick = () => {
		sendGAEvent('blog_navbar_click')
	}

	return (
		<Center
			p={2}
			flexDirection='column'
			pl={isIconMode ? 4 : 8}
			pr={isIconMode ? 3.5 : 10}
		>
			<Tooltip
				isDisabled={!isIconMode}
				hasArrow
				label={t('common:navbar.blog')}
				bg='navbar_bg'
				placement='right'
				offset={[0, 25]}
			>
				<Button
					as={Link}
					href={
						lang === 'en'
							? 'https://strateegia.digital/en/blog/ '
							: 'https://strateegia.digital/blog/'
					}
					isExternal
					variant='nav'
					style={{ textDecoration: 'none' }}
					_hover={{ bg: 'navbar_link_hover_bg' }}
					onClick={() => {
						registerBlogNavbarClick()
						handleLinkClick()
					}}
					color={'navbar_text_color'}
					opacity={0.8}
				>
					<Icon as={IconStWorld} strokeWidth={0.6} fontSize='large' mr={3.5} />
					{!isIconMode && t('common:navbar.blog')}
				</Button>
			</Tooltip>
		</Center>
	)
}
