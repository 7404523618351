import { useMutation } from 'react-query'

import { AxiosResponse } from 'axios'

import { Assistant } from 'modules/map/types/assistants'

import { assistantShareLab } from '../usecases/assistant-share-lab'
import { assistantUnshareLab } from '../usecases/assistant-unshare-lab'

export const useUpdateShareLabIds = () => {
	return useMutation(
		async ({
			labId,
			assistantId,
			action
		}: {
			labId: string
			assistantId: string
			action: 'add' | 'remove'
		}) => {
			if (action === 'add') {
				return await assistantShareLab(labId, assistantId)
			}

			if (action === 'remove') {
				return await assistantUnshareLab(labId, assistantId)
			}

			return useMutation<AxiosResponse<Assistant>, unknown, Assistant>(
				async () => {
					throw new Error(
						'Invalid action type. The action type must be "add" or "remove"'
					)
				}
			)
		}
	)
}
