import defaultCustomColors from '../default/colors'

const deepesgCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#88CA09',
		700: '#88CA09'
	},

	secondary_color_scheme_button: {
		700: '#01305C'
	},

	switch_scheme_color_checked: {
		500: '#01305C'
	},

	radio_color: {
		500: '#01305C'
	}
}

export default deepesgCustomColors
