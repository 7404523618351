import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import DeleteModal from 'modules/common/components/modals/delete-modal'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point } from 'modules/map/modules/map-canvas/types'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { ConversationPoint } from '../../types'
import { deleteConversationPoint } from '../../usecases'
import { UpdateConversationPoint } from '../update'

const ConversationOptions: React.FC<{
	conversationPoint: ConversationPoint
	isScheduled: boolean
	projectId: string
	mapId: string
	handleToggleVisibility: () => void
	selectedPoint: Point | null
}> = ({
	conversationPoint,
	isScheduled,
	projectId,
	mapId,
	handleToggleVisibility,
	selectedPoint
}) => {
		const { t } = useTranslation()
		const toast = useToast()
		const deleteDisClosure = useDisclosure()
		const updateDisclosure = useDisclosure()
		const navigate = useNavigate()
		const { emit } = useSocket(pointChannel({ projectId, mapId }))
		const { removePoint, clearSelected } = useMapStore(({ actions }) => actions)

		const handleDelete = () => {
			try {
				deleteConversationPoint(conversationPoint.id)
				toast({
					title: t('map:points.conversation.delete.success'),
					status: 'success'
				})
				removePoint(conversationPoint)
				clearSelected()
				const pointType = 'CONVERSATION'
				const pointInfo = {
					id: conversationPoint.id,
					position: conversationPoint.position,
					point_type: pointType
				}
				emit('DELETE', pointInfo)

				navigate('../')
			} catch (error) {
				const { message } = error as Error
				toast({ title: message as string, status: 'error' })
			}
		}

		return (
			<Box>
				<Menu placement='bottom-end' closeOnBlur>
					<MenuButton
						as={IconButton}
						aria-label={t('buttons:more')}
						variant='ghost'
						color='gray.400'
						isRound
						icon={<Icon as={BiDotsVerticalRounded} fontSize='xl' />}
						_active={{ bgColor: 'gray.100' }}
					/>
					<MenuList minW='40'>
						<MenuItem onClick={handleToggleVisibility} borderRadius='md'>
							<Text>
								{selectedPoint?.visible
									? t('map:points.action.hidden.title')
									: t('map:points.action.visible.title')}
							</Text>
						</MenuItem>
						{isScheduled && (
							<MenuItem onClick={updateDisclosure.onOpen}>
								<Text>{t('buttons:edit')}</Text>
							</MenuItem>
						)}
						<MenuItem onClick={deleteDisClosure.onOpen} borderRadius='md'>
							<Text>{t('buttons:delete')}</Text>
						</MenuItem>
					</MenuList>
				</Menu>

				<UpdateConversationPoint
					disclosure={updateDisclosure}
					point={conversationPoint}
				/>

				<DeleteModal
					title={t('map:points.conversation.delete.title')}
					deleteText={t('buttons:delete')}
					onDelete={handleDelete}
					{...deleteDisClosure}
				>
					<Text color='gray.400'>
						<Trans
							i18nKey='map:points.conversation.delete.description'
							values={{ title: conversationPoint.description }}
							components={[<strong />]}
						/>
					</Text>
					<Text color='gray.400'>
						<Trans
							i18nKey='map:points.conversation.delete.warning'
							components={[<strong />]}
						/>
					</Text>
				</DeleteModal>
			</Box>
		)
	}

export default ConversationOptions
