import { turnIntoColor } from 'modules/common/utils/string-to-color'

type Props = {
	text?: string
	forceColor?: string
}

function useStringToColor(
	{ text = 'pink', forceColor }: Props,
	shades = [500, 100],
	colorScheme?: string[]
) {
	const color = forceColor
		? forceColor.toLowerCase()
		: turnIntoColor(text, colorScheme)
	const makeTones = shades.map((tone) => `${color}.${tone}`)
	return [color, ...makeTones]
}

export default useStringToColor
