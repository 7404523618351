import i18n from 'i18n/i18n'
import { string, object, array } from 'yup'

import { hasNoUrl } from 'modules/common/validators'

export const ToolsValidation = () =>
	object({
		title: string()
			.required(i18n.t('errors:requiredField'))
			.min(3, i18n.t('errors:fields.name.min', { min: 3 }))
			.max(100, i18n.t('errors:fields.name.max', { max: 100 }))
			.test({ test: hasNoUrl, message: i18n.t('errors:fields.name.hasUrl') }),
		description: string().required(i18n.t('errors:requiredField')),
		question: string().required(i18n.t('errors:requiredField')),
		references: array().of(
			object({
				url: string().url(i18n.t('errors:tools.hasUrl')),
				description: string()
					.notRequired()
					.when('url', {
						is: (value: string) => value?.length,
						then: (rule) => rule.required(i18n.t('errors:requiredField'))
					})
			})
		)
	})

export const ReferenceValidation = () =>
	object({
		url: string()
			.url(i18n.t('errors:tools.hasUrl'))
			.required(i18n.t('errors:requiredField')),
		description: string().required(i18n.t('errors:requiredField'))
	})
