import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'

import {
	Box,
	Input,
	Select,
	FormControl,
	FormLabel,
	FormErrorMessage,
	As
} from '@chakra-ui/react'

import { getCountries } from 'modules/common/utils/en-pt-countries-list'

interface InputFieldProps {
	label: string
	fieldName: string
	placeholder?: string
}

interface InputMaskFieldProps {
	label: string
	fieldName: string
	placeholder?: string
	mask: string
}

export const CommonInputField: React.FC<InputFieldProps> = ({
	label,
	fieldName,
	placeholder
}) => {
	const {
		fieldState: { error },
		field
	} = useController({
		name: fieldName
	})

	return (
		<Box w='full'>
			<FormControl isInvalid={!!error}>
				<FormLabel
					htmlFor={fieldName}
					fontSize='sm'
					fontWeight='semibold'
					color='gray.400'
				>
					{label}
				</FormLabel>
				<Input
					placeholder={placeholder}
					_placeholder={{
						fontSize: 'sm',
						fontWeight: 'medium',
						color: 'gray.300'
					}}
					{...field}
				/>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</Box>
	)
}

export const CommonInputMaskField: React.FC<InputMaskFieldProps> = ({
	label,
	fieldName,
	placeholder,
	mask
}) => {
	const {
		fieldState: { error },
		field
	} = useController({
		name: fieldName
	})

	return (
		<Box w='full'>
			<FormControl isInvalid={!!error}>
				<FormLabel
					htmlFor={fieldName}
					fontSize='sm'
					fontWeight='semibold'
					color='gray.400'
				>
					{label}
				</FormLabel>
				<Input
					as={InputMask as As}
					mask={mask}
					placeholder={placeholder}
					_placeholder={{
						fontSize: 'sm',
						fontWeight: 'medium',
						color: 'gray.300'
					}}
					{...field}
				/>
				{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
			</FormControl>
		</Box>
	)
}

export const CountriesSelectField: React.FC = React.memo(() => {
	const {
		t,
		i18n: { language }
	} = useTranslation()

	const countries = getCountries(language)

	const { control, setValue } = useFormContext()
	const {
		field,
		fieldState: { error }
	} = useController({
		control,
		name: 'address.country'
	})

	const handleChange = (name: string, value: string) => {
		setValue(name, value)
	}

	return (
		<FormControl isInvalid={!!error}>
			<FormLabel
				htmlFor='address.country'
				fontSize='sm'
				fontWeight='semibold'
				color='gray.400'
			>
				{t('lab:my.plans.subscription.form.country.field')}
			</FormLabel>
			<Select
				fontSize='sm'
				fontWeight='medium'
				color='gray.300'
				placeholder={t('lab:my.plans.subscription.form.country.placeholder')}
				onChange={(e) => {
					handleChange('address.country', e.target.value)
					field.onChange(e)
				}}
				defaultValue={field.value}
			>
				{countries.map(([code, country]) => (
					<option key={code} value={code}>
						{country}
					</option>
				))}
			</Select>
			{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
		</FormControl>
	)
})
