import Api from 'data/api'
import { Locale } from 'date-fns'
import { enUS, ptBR, es } from 'date-fns/locale'
import i18n from 'i18n/i18n'

const fixLanguage = (lang: string) => {
	switch (lang) {
		case 'EN_US':
			return 'en'
		case 'PT_BR':
			return 'pt'
		case 'ES_ES':
			return 'es'
		default:
			return lang
	}
}

export const parseLanguage = (lang: string) => {
	switch (lang) {
		case 'en':
			return 'EN_US'
		case 'es':
			return 'ES_ES'
		default:
			return 'PT_BR'
	}
}

export function setLanguage(lang: string | null, remoteUpdate = false) {
	if (lang) {
		i18n.changeLanguage(fixLanguage(lang))
		if (remoteUpdate) {
			Api.Users.updateLanguage(parseLanguage(lang))
		}
	}
}

export function getCurrentLanguage() {
	return i18n.language
}

export function getLocale() {
	const lang = getCurrentLanguage()
	const locales: Record<string, Locale> = {
		pt: ptBR,
		en: enUS,
		es
	}

	return locales[lang]
}
