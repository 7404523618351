import { QueryFilter } from 'modules/common/types'
import { makeKey } from 'modules/common/utils/make-key'

export const planKeys = {
	all: ['plan'],
	lists: () => [...planKeys.all, 'lists'],
	plans: (filters?: QueryFilter) =>
		makeKey([...planKeys.lists(), 'plans', filters]),
	history: (filters?: QueryFilter) =>
		makeKey([...planKeys.lists(), 'history', filters]),
	billing: (filters?: QueryFilter) =>
		makeKey([...planKeys.lists(), 'billing', filters])
} as const
