import jwtDecode from 'jwt-decode'

import { TokenModel } from 'modules/login/types'

const key = 'tokens'

type DecodedToken = {
	sub: string
	uid: string
	roles: unknown
	name: string
	exp: number
	iat: number
}

export const Tokens = (() => {
	let value: null | TokenModel = null

	const set = (tokens: TokenModel, persist = false) => {
		const tokensString = JSON.stringify(tokens)
		value = JSON.parse(tokensString) as TokenModel
		if (persist) {
			localStorage.setItem(key, tokensString)
		} else {
			sessionStorage.setItem(key, tokensString)
		}
	}

	const get = () => {
		let tokens

		if (value) {
			tokens = value
		} else {
			tokens = value = getLocalTokens()
		}

		return {
			access_token: tokens?.access_token,
			refresh_token: tokens?.refresh_token
		}
	}

	const clear = () => {
		sessionStorage.removeItem(key)
		localStorage.removeItem(key)
		value = null
	}

	const getLocalTokens = (): TokenModel | null => {
		const sessionData = sessionStorage.getItem(key)
		const localData = localStorage.getItem(key)
		const tokensString = sessionData || localData

		if (tokensString) {
			return JSON.parse(tokensString)
		}

		return null
	}

	const decode = (token: string): DecodedToken => {
		return jwtDecode(token)
	}

	const getExpirationDate = (exp: number) => new Date(exp * 1000)

	const isValid = (exp: number) => new Date() < getExpirationDate(exp)

	return {
		set,
		get,
		clear,
		value,
		decode,
		isValid
	}
})()

export type AccessToken = typeof Tokens
