import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Avatar,
	Box,
	Flex,
	HStack,
	Icon,
	Stack,
	StackDirection,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'

import { CheckIcon } from '@chakra-ui/icons'

import { HookFormStepperContext } from './form-stepper'

type IndicatorProps = {
	number: number
	name: string
}

const Indicator = ({ number, name }: IndicatorProps) => {
	const { step } = useContext(HookFormStepperContext)
	const size = useBreakpointValue({ base: 'xs', lg: 'sm' })

	const isCurrent = step + 1 === number
	const isDone = number < step + 1

	// TODO: Rethink these conditionals
	const textColor = isDone ? 'gray.300' : isCurrent ? 'gray.400' : 'gray.200'
	const borderColor = isDone ? 'indicators_icon' : 'currentcolor'

	return (
		<HStack mb={2}>
			<Avatar
				bg={isCurrent || isDone ? 'indicators_icon' : 'none'}
				color={isCurrent || isDone ? 'white' : 'gray.200'}
				size={size}
				border={`1px solid ${borderColor}`}
				name={isDone ? undefined : `${number}`}
				icon={<Icon as={CheckIcon} />}
			/>
			<Box as='span' color={textColor} fontWeight='semibold'>
				{name}
			</Box>
			<hr />
		</HStack>
	)
}

export const Indicators = React.memo(
	({ indicators }: { indicators: string[] }) => {
		const { inline, step, size } = useContext(HookFormStepperContext)
		const direction: StackDirection = inline
			? 'row'
			: { base: 'row', lg: 'column' }

		const { t } = useTranslation()

		return (
			<Flex direction={'column'}>
				<Text fontWeight={'semibold'} pb={2} pl={0.5}>
					{t('journeys:editor.widgets.assistants.stepper', {
						step: step + 1,
						size
					})}
				</Text>
				<Stack
					mb={6}
					direction={direction}
					align='flex-end'
					gridArea='indicators'
				>
					<Stack
						order={{ base: 1, lg: 2 }}
						direction={direction}
						align='flex-end'
						flexWrap={{ base: 'wrap', lg: 'nowrap' }}
						__css={{ margin: 0 }}
					>
						{indicators.map((name, i) => (
							<Indicator key={name} name={name} number={i + 1} />
						))}
					</Stack>
				</Stack>
			</Flex>
		)
	}
)
