import io from 'socket.io-client'

import { baseURL } from '../fetch'

interface SocketClient {
	on: (evt: string, callback: Function) => void
}

const createConnection = (channel: string) => {
	const socket = io.connect(`${baseURL}${channel}`, {
		withCredentials: true,
		transports: ['polling', 'websocket'],
		path: '/websocket/socket.io',
		timeout: 60000,
		reconnection: true,
		reconnectionDelay: 500
	}) as SocketClient
	return socket
}

export { createConnection }
