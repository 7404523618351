import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useDivergenceStore } from 'modules/map/store/divergence-store'
import { changeIncognitoMode } from 'modules/map/usecases/change-anonym-mode'

export function useAnonymMutation(pointId: string) {
	const channel = '/point/divergence-point/incognito-mode'

	const { emit } = useSocket(channel)
	const setISAnonym = useDivergenceStore((state) => state.setISAnonym)

	const mutation = useMutation(changeIncognitoMode, {
		onSuccess: (_, { isAnonym }) => {
			setISAnonym(isAnonym)
			emit('EDIT', pointId)
		}
	})

	return mutation
}
