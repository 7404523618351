import { useParams } from 'react-router-dom'

import { useJourney } from 'modules/journeys/hooks'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

export function useJourneyPrivileges() {
	const { id: journeyId } = useParams()
	const { user: currUser } = useMe()

	const { data: users = [] } = useJourney(journeyId as string, {
		select: (journey) => journey.users
	})

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('project.update'))

	return hasPrivileges
}
