import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStAnonym: React.FC = (props: IconProps) => (
	<IconBase width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
		<path
			d='M13.5 2.15649H4.5L3.6 7.55649H14.4L13.5 2.15649ZM0 9.35649C0 9.35649 1.8 10.2565 9 10.2565C16.2 10.2565 18 9.35649 18 9.35649L14.4 7.55649H3.6L0 9.35649ZM7.2 12.9565H10.8V13.8565H7.2V12.9565Z'
			fill='currentcolor'
		/>
		<path
			d='M5.4002 16.5565C6.89136 16.5565 8.10019 15.3477 8.10019 13.8565C8.10019 12.3653 6.89136 11.1565 5.4002 11.1565C3.90903 11.1565 2.7002 12.3653 2.7002 13.8565C2.7002 15.3477 3.90903 16.5565 5.4002 16.5565Z'
			fill='currentcolor'
		/>
		<path
			d='M12.5999 16.5565C14.0911 16.5565 15.2999 15.3477 15.2999 13.8565C15.2999 12.3653 14.0911 11.1565 12.5999 11.1565C11.1087 11.1565 9.8999 12.3653 9.8999 13.8565C9.8999 15.3477 11.1087 16.5565 12.5999 16.5565Z'
			fill='currentcolor'
		/>
	</IconBase>
)
