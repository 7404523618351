import React, { ReactNode } from 'react'

import { Box, Link } from '@chakra-ui/react'

type Props = {
	children?: ReactNode
	logoAsLink: boolean
	href: string
}

export const DynamicLogo = ({
	children,
	logoAsLink,
	href,
	...props
}: Props) => {
	const linkProps = logoAsLink ? { href } : {}

	return (
		<Box
			as={logoAsLink ? Link : Box}
			_focus={{ outline: 'none' }}
			{...linkProps}
			{...props}
		>
			{children}
		</Box>
	)
}
