import { fetch } from 'config'
import { mountParams, SortOrder } from 'data/helpers/mount-params'

import { QueryFilter } from 'modules/common/types/common-model'

import { TemplatesSummaryResponse } from '../types'

interface Params {
	search: string
	order: SortOrder
	filter: QueryFilter
	size?: number
	pageParam: number
	official: boolean
	sortBy?: string
}

export async function exploreTemplates({
	search,
	order,
	filter,
	size,
	pageParam = 0,
	official,
	sortBy = 'createdAt'
}: Params) {
	const params = mountParams({
		official,
		page: pageParam,
		size: 12,
		title: search,
		sort: [sortBy, order],
		...filter
	})
	const { data } = await fetch<TemplatesSummaryResponse>({
		url: '/templates/v1/template/explore',
		method: 'GET',
		params
	})

	return data
}
