import { UseFormRegister } from 'react-hook-form'
import { BsQuestionCircle } from 'react-icons/bs'

import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Select,
	SelectProps,
	Text,
	Tooltip,
	useBreakpointValue,
	useTheme
} from '@chakra-ui/react'

interface SelectOption {
	value: string
	label: string
}

interface BaseFormSelectProps extends SelectProps {
	label: string
	name: string
	options: SelectOption[]
	register: UseFormRegister<any>
	isRequired?: boolean
	errors: Record<string, any>
	withTip?: boolean
}

interface FormSelectPropsWithTip extends BaseFormSelectProps {
	withTip: true
	tip: string
}

interface FormSelectPropsWithoutTip extends BaseFormSelectProps {
	withTip?: false
	tip?: never
}

type FormSelectProps = FormSelectPropsWithTip | FormSelectPropsWithoutTip

export const FormSelect = ({
	label,
	name,
	options,
	register,
	isRequired,
	errors,
	withTip,
	tip,
	...props
}: FormSelectProps) => {
	const theme = useTheme() as { colors: any }
	const isMobile = useBreakpointValue({ base: true, md: false })
	const errorMessage = errors[name]?.message

	return (
		<FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
			<Flex justifyContent='flex-start' alignItems='center'>
				<FormLabel fontWeight='semibold' color='gray.400'>
					{label}
				</FormLabel>
				{withTip && !isMobile && (
					<Tooltip label={tip} placement='right-end'>
						<Text as='span'>
							<Icon as={BsQuestionCircle} />
						</Text>
					</Tooltip>
				)}
			</Flex>
			<Select
				borderColor='gray.195'
				color='gray.400'
				_focus={{
					borderColor: theme.colors.input_border,
					borderWidth: '0px'
				}}
				{...register(name)}
				width='100%'
				{...props}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</Select>
			<FormErrorMessage>{errorMessage}</FormErrorMessage>
		</FormControl>
	)
}
