import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStEye: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 25 24' {...props}>
		<path
			d='M12 9.505C11.206 9.51025 10.4459 9.828 9.88447 10.3895C9.32299 10.951 9.00524 11.711 9 12.505C9 14.147 10.358 15.505 12 15.505C13.641 15.505 15 14.147 15 12.505C15 10.864 13.641 9.505 12 9.505Z'
			fill='#505565'
		/>
		<path
			d='M12 5.505C4.36701 5.505 2.07301 12.122 2.05201 12.189L1.94601 12.505L2.05101 12.821C2.07301 12.888 4.36701 19.505 12 19.505C19.633 19.505 21.927 12.888 21.948 12.821L22.054 12.505L21.949 12.189C21.927 12.122 19.633 5.505 12 5.505ZM12 17.505C6.64901 17.505 4.57601 13.659 4.07401 12.505C4.57801 11.347 6.65201 7.505 12 7.505C17.351 7.505 19.424 11.351 19.926 12.505C19.422 13.663 17.348 17.505 12 17.505Z'
			fill='#505565'
		/>
	</IconBase>
)
