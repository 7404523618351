import React, { useEffect } from 'react'

import { useConversationChatStore } from '../../store/chat-store'
import { ReplyCardListItem } from './reply-card-list-item'

type Props = {
	conversationPointId: string | undefined
	commentId: string
	userId: string
	userName: string
	userLanguage: string
}

export const ReplyCard: React.FC<Props> = ({
	conversationPointId,
	commentId,
	userId,
	userName,
	userLanguage
}) => {
	const { fetchCommentsWithReplies, comments } = useConversationChatStore()

	useEffect(() => {
		if (conversationPointId) {
			fetchCommentsWithReplies(commentId)
		}
	}, [conversationPointId, commentId, fetchCommentsWithReplies])

	const comment = comments.find((comment) => comment.id === commentId)

	if (!comment) {
		return null
	}

	return (
		<>
			{comment.replies?.map((reply) => (
				<ReplyCardListItem
					key={`${comment.id}_${reply.id}`}
					reply={reply}
					userId={userId}
					userName={userName}
					userLanguage={userLanguage}
				/>
			))}
		</>
	)
}
