const defaultCustomColors = {
	brand: {
		900: '#1a365d',
		800: '#153e75',
		700: '#2a69ac'
	},

	blue: {
		1: '#F9FAFE',
		2: '#F3F5FD',
		3: '#8CC2ED',
		4: '#8EC2ED',
		50: '#E8EDFC',
		200: '#BBC9F7',
		250: '#2F80ED',
		300: '#436DF4',
		500: '#335DE6',
		600: '#2A52D4',
		700: '#0034B3',
		900: '#2F80ED'
	},

	magenta: {
		50: '#FFE0FA',
		100: '#F7DFF3',
		200: '#D55DC1',
		300: '#D14DBB',
		400: '#CD3CB5',
		500: '#B62FA0',
		900: '#B62FA0'
	},

	pink: {
		100: '#FED7E2',
		200: '#FBB6CE',
		300: '#FDEEF3',
		400: '#F7A2B5',
		500: '#E6335D',
		700: '#97266D',
		900: '#EE587B'
	},

	yellow: {
		50: '#FFF3D7',
		100: '#FAF089',
		500: '#FDB400',
		900: '#FDB400'
	},

	green: {
		50: '#DDFFF8',
		400: '#32CDAC',
		500: '#1BC47D',
		600: '#369793',
		900: '#32CDAC'
	},

	gray: {
		50: '#FEFEFF',
		60: '#F3F5FB',
		70: '#F0F0F0',
		100: '#F9F9FD',
		150: '#FCFCFF',
		180: '#EDF2F7',
		190: '#E2E8F0',
		195: '#DFE0E3',
		200: '#C7C9D1',
		300: '#8B90A0',
		400: '#505565',
		500: '#232735',
		900: '#232735'
	},

	red: {
		50: '#F5BCBC',
		400: '#C20734',
		600: '#DE2121',
		900: '#DE2121'
	},

	purple: {
		50: '#E9DCFF',
		100: '#E9D8FD',
		700: '#5B319E',
		900: '#825DED'
	},

	orange: {
		50: '#FFEBDB',
		700: '#EF6C00',
		900: '#EF6C00'
	},

	teal: {
		900: '#38b2ac'
	},

	extra_color_scheme_button: {
		500: '#E6335D'
	},

	secondary_color_scheme_button: {
		500: '#E6335D'
	},

	radio_color: {
		500: '#E6335D'
	},
	switch_scheme_color_checked: {
		500: '#335DE6'
	}
}

export default defaultCustomColors
