import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const deleteUserAssistant = async (userAssistantId: string) => {
	return await fetch<Assistant>({
		url: `projects/v1/assistants/${userAssistantId}`,
		method: 'DELETE'
	})
}
