import { fetch } from 'config'

export const deleteMonitorPointStatus = async (
	monitorPointId: string,
	statusId: string
) => {
	return fetch({
		url: `/projects/v1/monitor-point/${monitorPointId}/status/${statusId}`,
		method: 'DELETE'
	})
}
