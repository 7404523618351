import { fetch } from 'config'

import { Point } from '../../map-canvas/types'
import { Params } from '../types'

export async function createConversationPoint(params: Params) {
	const { mapId, ...data } = params

	const response = await fetch<Point>({
		url: `/projects/v1/map/${mapId}/checkpoint`,
		method: 'POST',
		data
	})

	return response.data
}
