import { fetch } from 'config'

type Params = {
	pointId: string
	isAnonymous: boolean
}

export async function manageIcognitoMode({ isAnonymous, pointId }: Params) {
	const action = isAnonymous ? 'enable' : 'disable'
	const response = await fetch({
		url: `/projects/v1/essay-point/${pointId}/incognito-mode/${action}`,
		method: 'PATCH'
	})
	return response.data
}
