import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#529B4D',
		light: '#A4CEA1'
	},

	secondary: {
		dark: '#18581C',
		light: '#9DBFA3'
	}
}

export default themeColors
