import defaultCustomColors from '../default/colors'

const bbCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#FFBE01',
		700: '#FFBE01'
	},

	secondary_color_scheme_button: {
		700: '#465EFF'
	},

	switch_scheme_color_checked: {
		500: '#465EFF'
	},

	radio_color: {
		500: '#465EFF'
	}
}

export default bbCustomColors
