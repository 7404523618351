import { useMutation } from 'react-query'

import { patchChatRoomMessage } from 'modules/assistants/usecases/chat-room/patch-chat-room-message'

export const useChatRoomMessage = () => {
	return useMutation(
		({
			chatRoomId,
			message,
			openWindow
		}: {
			chatRoomId: string
			message: string
			openWindow?: boolean
		}) => patchChatRoomMessage(chatRoomId, message, openWindow)
	)
}
