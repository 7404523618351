import { useTranslation } from 'react-i18next'
import { FiLogOut, FiUser } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { Button, Center, Icon, Image, Tooltip } from '@chakra-ui/react'

import { persistTerms } from 'config'
import { sendGAEvent } from 'config/trackers/google-analytics'

import PoweredFooter from 'modules/common/assets/img/layout/inline-powered-footer.svg'
import { IconStSettings } from 'modules/common/components/icons'
import { NavBarLink } from 'modules/common/components/nav-bar-link'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import AuthenticationApi from 'modules/login/usecases'

type Props = {
	isIconMode?: boolean
	handleLinkClick: () => void
}

export const UserOptions = ({ isIconMode, handleLinkClick }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { tenant, isTenantDefault } = useTenant()

	const logOut = async () => {
		sendGAEvent('logout')
		await AuthenticationApi.logout()
		persistTerms.clear()
		navigate('/')
	}

	return (
		<Center
			p={2}
			flexDirection='column'
			pl={isIconMode ? 4 : 8}
			pr={isIconMode ? 4 : 10}
		>
			<NavBarLink
				leftIcon={<Icon as={FiUser} strokeWidth={3} fontSize='large' />}
				to='/dashboard/profile/my'
				onClick={handleLinkClick}
				isIconMode={isIconMode}
				buttonName={t('common:navbar.profile')}
				xOffset={27}
			>
				{!isIconMode && t('common:navbar.profile')}
			</NavBarLink>

			<NavBarLink
				leftIcon={
					<Icon as={IconStSettings} strokeWidth={3.5} fontSize='large' />
				}
				to='/dashboard/settings'
				onClick={handleLinkClick}
				isIconMode={isIconMode}
				buttonName={t('common:navbar.config')}
				xOffset={27}
			>
				{!isIconMode && t('common:navbar.config')}
			</NavBarLink>

			<Tooltip
				isDisabled={!isIconMode}
				hasArrow
				label={t('common:navbar.logout')}
				bg='navbar_bg'
				placement='right'
				offset={[0, 25]}
			>
				<Button
					onClick={logOut}
					leftIcon={<Icon as={FiLogOut} strokeWidth={2.5} fontSize='large' />}
					variant='nav'
					_hover={{ bg: 'navbar_link_hover_bg' }}
					ml={1}
					color={'navbar_text_color'}
					opacity={0.8}
				>
					{!isIconMode && t('common:navbar.logout')}
				</Button>
			</Tooltip>
			{!isTenantDefault(tenant) && (
				<Center
					p={2}
					flexDirection='row'
					pl={8}
					pr={10}
					position='absolute'
					bottom='10px'
					width='100%'
				>
					<Image src={PoweredFooter} alt='Strateegia' width={40} />
				</Center>
			)}
		</Center>
	)
}
