import { fetch } from 'config'

import { EvaluationResponse } from 'modules/map/types'

export async function evalauteAllAnswers(pointId: string) {
	const response = await fetch<EvaluationResponse>({
		url: `/projects/v1//essay-point/${pointId}/responses/all/evaluate`,
		method: 'PATCH'
	})
	return response.data
}
