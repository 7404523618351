import React from 'react'

import { Tabs } from '@chakra-ui/react'

import useCommentsStore from 'modules/map/store/use-comments-store'
import { Question } from 'modules/tools/types/tool-model'

import { QuestionCard } from '../question-card'

export const AnalysisQuestions: React.FC<{ questionsWithText: Question[] }> = ({
	questionsWithText
}) => {
	const { questions, setQuestionSummaryIndex, summaryQuestionIndex } =
		useCommentsStore()

	const handleClick = (questionId: string) => () => {
		setQuestionSummaryIndex(questions.findIndex((q) => q.id === questionId))
	}

	return (
		<Tabs flexDirection='column' overflowY='auto' h='100%'>
			{questions.map((q, index) => (
				<QuestionCard
					click={handleClick(q.id)}
					qId={q.id}
					key={`${q.id}_${index}`}
					text={questionsWithText[index].question}
					index={index + 1}
					questionCommentsCount={
						q.comments.length ? q.comments.length : q.count
					}
					isOpened={q.id === questions[summaryQuestionIndex].id}
				/>
			))}
		</Tabs>
	)
}
