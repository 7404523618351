import { fetch } from 'config'

import { AssistantComment } from '../types/assistants'

type Params = {
	assistantId: string
	divergencePointId: string
	questionId: string
	text: string
}

export const commentWithAssistant = ({
	assistantId,
	divergencePointId,
	questionId,
	text
}: Params) => {
	return fetch<AssistantComment>({
		url: `/projects/v1/divergence-point/${divergencePointId}/question/${questionId}/comment/assisted/${assistantId}`,
		method: 'POST',
		data: { text }
	}).then(({ data }) => data)
}
