import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#01124C',
		light: '#81A2C7'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FFEBDB'
	}
}

export default themeColors
