import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
	Button,
	HStack,
	ListItem,
	ModalBody,
	ModalFooter,
	Stack,
	Text,
	UnorderedList,
	VStack,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { User } from 'modules/user/types'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'

import { Status } from '.'
import { commentAll } from './usecases/comment-all-answers'

type Props = {
	onClose: () => void
	user: User
	pointId: string
	questionId: string
	commentsMissing: number
	assistantId: string
	setStatus: React.Dispatch<React.SetStateAction<Status>>
	timestap: string
	totalComments: number
}

export const ConfirmComment = ({
	onClose,
	user,
	pointId,
	questionId,
	commentsMissing,
	assistantId,
	setStatus,
	timestap,
	totalComments
}: Props) => {
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { t } = useTranslation()
	const toast = useToast()
	const alreadyCommented = !commentsMissing
	const price = commentsMissing ? commentsMissing * 4 : totalComments * 4
	const time = (commentsMissing * 30) / 60
	const { clearSelected } = useMapStore((state) => state.actions)

	const messageText =
		time > 2
			? 'divergencePoint:commentAll.modal.message_plural'
			: 'divergencePoint:commentAll.modal.message_singular'

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleSubmit = async (force?: boolean) => {
		onClose()
		try {
			await commentAll({
				assistantId,
				pointId,
				questionId,
				until: timestap,
				force
			})
			toast({
				position: 'bottom',
				title: t('divergencePoint:commentAll.modal.toast'),
				status: 'info'
			})
			consumeCredits(price)
			updateUserCredits(user.credits - price)
			onClose()
			clearSelected()
		} catch (error) {
			toast({
				title: t('errors:evaluationPoint.evaluateAll'),
				status: 'error'
			})
			onClose()
		}
	}

	if (alreadyCommented) {
		return (
			<>
				<ModalBody px={isMobile ? 4 : 8}>
					<Trans
						i18nKey='divergencePoint:commentAll.modal.alreadyCreated'
						components={[<strong />]}
					/>
				</ModalBody>
				<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<Stack width={['full', 'fit-content']} direction={['column', 'row']}>
						<Button
							color='gray.400'
							bgColor='gray.180'
							fontSize='xs'
							fontWeight='semibold'
							width={['full', 'fit-content']}
							onClick={() => setStatus('select')}
							colorScheme='gray'
						>
							{t('divergencePoint:commentAll.modal.selectOtherAgent')}
						</Button>
						<CreateContentBtn
							coins={price}
							isDisabled={false}
							nexStep={() => handleSubmit(true)}
							text={t('divergencePoint:commentAll.modal.commentAgain')}
							user={user}
						/>
					</Stack>
				</ModalFooter>
			</>
		)
	} else {
		return (
			<>
				<ModalBody px={isMobile ? 4 : 8}>
					<VStack alignItems='start' gap={4}>
						<Text>
							<Trans
								i18nKey={messageText}
								components={[<strong />]}
								values={{ time, price }}
							/>
						</Text>

						<Text>{t('divergencePoint:commentAll.modal.instructions')}</Text>

						<UnorderedList>
							<ListItem>
								{t('divergencePoint:commentAll.modal.confirmation')}
							</ListItem>
							<ListItem>
								{t('divergencePoint:commentAll.modal.flexibility')}
							</ListItem>
						</UnorderedList>

						<Text>{t('divergencePoint:commentAll.modal.beta')}</Text>
					</VStack>
				</ModalBody>
				<ModalFooter mt={3} px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<HStack>
						<Button
							borderRadius='md'
							color='gray.400'
							bgColor='gray.180'
							fontSize='xs'
							fontWeight='bold'
							colorScheme='gray'
							onClick={onClose}
						>
							{t('buttons:cancel')}
						</Button>
						<CreateContentBtn
							coins={price}
							isDisabled={false}
							nexStep={handleSubmit}
							text={t('divergencePoint:commentAll.modal.generateComments')}
							user={user}
						/>
					</HStack>
				</ModalFooter>
			</>
		)
	}
}
