import React from 'react'
import { Navigate } from 'react-router-dom'

import { useIsAuthenticated } from 'modules/common/hooks'

type Props = {
	children: React.ReactNode
}

export const RecoverSessionRoute = ({ children }: Props) => {
	const isAuthenticated = useIsAuthenticated()

	if (isAuthenticated) {
		return (
			<>
				<Navigate to='/dashboard' />
			</>
		)
	}

	return <>{children}</>
}
