import { lazy } from 'react'
import { Route, Navigate } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

const SelectJourneyTemplate = lazy(
	() => import('modules/journeys/pages/select-journey-template')
)
const CreateJourney = lazy(
	() => import('modules/journeys/pages/create-journey')
)
const ExploreJourneys = lazy(
	() => import('modules/journeys/pages/explore-journeys')
)
const Journeys = lazy(() => import('modules/journeys/pages/journeys'))
const TemplateDetails = lazy(
	() => import('modules/templates/components/details')
)

const JourneysRoute = () => {
	return (
		<ApmRoutes>
			<Route index element={<Navigate to='my' />} />
			<Route
				path='my'
				element={
					<Loading>
						<Journeys />
					</Loading>
				}
			/>
			<Route path='select-template'>
				<Route
					index
					element={
						<Loading>
							<SelectJourneyTemplate />
						</Loading>
					}
				/>
				<Route path=':templateId'>
					<Route index element={<Navigate to='./map' />} />
					<Route
						path=':tab'
						element={
							<Loading>
								<TemplateDetails />
							</Loading>
						}
					/>
				</Route>
			</Route>
			<Route
				path='create'
				element={
					<Loading>
						<CreateJourney />
					</Loading>
				}
			/>
			<Route
				path='explore-journeys'
				element={
					<Loading>
						<ExploreJourneys />
					</Loading>
				}
			/>
		</ApmRoutes>
	)
}

export default JourneysRoute
