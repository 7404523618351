import { Permissions } from 'config/permissions/type'

import defaultKeyValuePermissions from './key_value'
import defaultListPermissions from './list'

const tenantDefaultPermissions: Permissions = {
	key_value: defaultKeyValuePermissions,
	list: defaultListPermissions
}

export default tenantDefaultPermissions
