import { Author } from 'modules/map/store/use-comments-store'
import { PointPosition } from 'modules/map/types'

export enum MonitorType {
	QUALITATIVE = 'qualitative',
	QUANTITATIVE = 'quantitative'
}

export interface BaseMonitorPoint {
	position: PointPosition
	name: string
	description: string
	conclusion_date: string
	monitor_type: MonitorType
}

export interface MonitorPoint extends BaseMonitorPoint {
	id: string
	map_id: string
	current_status: {
		id: string
		message: string
		author: Author
		created_at: string
		monitor_type: MonitorType
		value: QualitativePointStatus | number
	}
	visible: boolean
	created_at: string
	updated_at: string
	created_by: string
	updated_by: string
}

export enum QuantitativeMonitorPointFlow {
	INCREASING = 'INCREASING',
	DECREASING = 'DECREASING'
}

export enum QuantitativeMonitorPointType {
	CUMULATIVE = 'CUMULATIVE',
	RECURRING = 'RECURRING'
}

export interface QuantitativeMonitorPoint extends MonitorPoint {
	goal?: number
	flow?: QuantitativeMonitorPointFlow
	type?: QuantitativeMonitorPointType
}

export enum QualitativePointStatus {
	STARTED = 'STARTED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	SUSPENDED = 'SUSPENDED'
}

export interface MonitorPointStatus {
	message: string
	monitor_type: MonitorType
	value: QualitativePointStatus | number
}

export interface MonitorPointStatusResponse {
	id: string
	monitor_point_id: string
	value: string
	message: string
	agreed: boolean
	agreements: any[]
	author: Author
	replies: MonitorPointStatusCommentResponse[]
	created_at: string
	created_by: string
	updated_at: string
	updated_by: string
}

export interface MonitorPointStatusCommentResponse
	extends MonitorPointStatusResponse {
	monitor_point_status_id: string
	text: string
}
