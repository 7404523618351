import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStLab: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 18 18' {...props}>
		<path
			d='M3 15.4286C3 15.8453 3.16805 16.245 3.46718 16.5397C3.7663 16.8344 4.17201 17 4.59504 17H13.405C13.828 17 14.2337 16.8344 14.5328 16.5397C14.832 16.245 15 15.8453 15 15.4286V6H3V15.4286ZM4.59504 7.57143H13.405V15.4286H4.59504V7.57143Z'
			fill='currentcolor'
		/>
		<path
			d='M5.39526 8.81167C5.39526 8.77418 5.44085 8.75347 5.46973 8.77738C5.80677 9.05649 7.24473 10.1189 8.89403 9.45225C10.6129 8.7575 12.2482 8.71507 12.5272 8.71304C12.5507 8.71287 12.569 8.73189 12.569 8.75535V14.7936H5.39526V8.81167Z'
			fill='currentcolor'
		/>
		<ellipse
			cx='7.8367'
			cy='4.21863'
			rx='0.907496'
			ry='0.859708'
			fill='currentcolor'
		/>
		<ellipse
			cx='7.44753'
			cy='1.49103'
			rx='0.518326'
			ry='0.491031'
			fill='currentcolor'
		/>
		<ellipse
			cx='10.1168'
			cy='3.05448'
			rx='1.13437'
			ry='1.07464'
			fill='currentcolor'
		/>
	</IconBase>
)
