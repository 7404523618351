import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Image,
	useDisclosure,
	Tooltip,
	useBreakpointValue,
	useTheme
} from '@chakra-ui/react'

import { PointInfos } from 'modules/map/store/use-comments-store'

import { AnswerQuestion } from './answer-question'
import Plus from './plus.svg'
export const FloatingButton: React.FC<{
	selectedPointId: string
	questionId: string
	questionNumber: number
	textQuestion: string
	journeyId: string
	point: PointInfos
}> = ({
	selectedPointId,
	questionId,
	questionNumber,
	textQuestion,
	journeyId,
	point
}) => {
	const disclosure = useDisclosure()
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { t } = useTranslation()
	const theme = useTheme()
	return (
		<>
			<Tooltip placement='left' label={t('divergencePoint:chat:add_comment')}>
				<Button
					onClick={() => disclosure.onOpen()}
					position={isMobile ? 'fixed' : 'absolute'}
					bottom='6'
					right='6'
					borderRadius='50%'
					max-width='3rem'
					w='3rem'
					h='3rem'
					max-height='3rem'
					backgroundColor={theme.colors.add_comment_button_bg}
					_hover={{ backgroundColor: 'none' }}
				>
					<Image src={Plus} />
				</Button>
			</Tooltip>
			<AnswerQuestion
				{...disclosure}
				divergencePointId={selectedPointId}
				questionId={questionId}
				index={questionNumber}
				textQuestion={textQuestion}
				journeyId={journeyId}
				point={point}
			/>
		</>
	)
}
