import { useTranslation } from 'react-i18next'

import {
	Button,
	Stack,
	Text,
	useBreakpointValue,
	VStack,
	useDisclosure,
	Box
} from '@chakra-ui/react'

import { ENCOURAGE_DEBATE_RESPONSE_CREATION_COST } from 'modules/constants'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { useMe } from 'modules/user/hooks'

import { EncourageDebate } from './encourage-debate'

export const HighMaturity = ({
	openConvergence
}: {
	openConvergence: () => void
}) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	return (
		<Stack
			direction={['column', 'row']}
			w='full'
			mt='2'
			px='1.125rem'
			pt='1.125rem'
			borderRadius='8'
			border='1px solid'
			borderColor='purple.400'
			backgroundColor='#fff'
			alignItems='center'
			gap={4}
			pb={[6, 2]}
		>
			<VStack alignItems='flex-start'>
				<Text fontWeight='semibold'>{t('maturity:high.title')}</Text>
				<Text marginTop='0 !important' fontSize='sm'>
					{t('maturity:high.message')}
				</Text>
			</VStack>
			<Button
				w={isMobile ? 'full' : '90%'}
				p={6}
				fontSize='sm'
				colorScheme='purple'
				onClick={openConvergence}
			>
				<Text>
					{t('buttons:create')}
					{!isMobile && <br />} {t('buttons:convergence')}
				</Text>
			</Button>
		</Stack>
	)
}

type LowMaturityProps = {
	journeyId: string
	pointId: string
	questionId: string
	summary: string
}

export const LowMaturity = ({
	pointId,
	questionId,
	journeyId,
	summary
}: LowMaturityProps) => {
	const { t } = useTranslation()
	const disclosure = useDisclosure()
	const { user } = useMe()

	return (
		<>
			<Stack
				direction='column'
				w='full'
				mt='2'
				px='1.125rem'
				pt='1.125rem'
				borderRadius='8'
				border='1px solid'
				borderColor='pink.400'
				backgroundColor='#fff'
				alignItems='center'
				gap={1}
				pb={[6, 2]}
			>
				<VStack alignItems='flex-start'>
					<Text fontWeight='semibold'>{t('maturity:low.title')}</Text>
					<Text marginTop='0 !important' fontSize='sm'>
						{t('maturity:low.message')}
					</Text>
				</VStack>
				<Box alignSelf='flex-end'>
					{user && (
						<CreateContentBtn
							coins={ENCOURAGE_DEBATE_RESPONSE_CREATION_COST}
							isDisabled={false}
							nexStep={disclosure.onOpen}
							text='gerar resposta'
							user={user}
						/>
					)}
				</Box>
			</Stack>
			{disclosure.isOpen && user && (
				<EncourageDebate
					{...disclosure}
					divergencePointId={pointId}
					questionId={questionId}
					summary={summary}
					journeyId={journeyId}
					user={user}
				/>
			)}
		</>
	)
}
