import { fetch } from 'config'

import { FlowResponseModel } from 'modules/flow/types'

function flowNotifications(pageParam = 0) {
	const params = { size: 8, page: pageParam }

	return fetch<FlowResponseModel>({
		url: 'notifications/v1/flow',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const markProjectNotificationsAsRead = (notificationId: string) => {
	return fetch<FlowResponseModel>({
		url: `notifications/v1/flow/${notificationId}/read/project`,
		method: 'PUT',
		data: notificationId
	}).then(({ data }) => data)
}

const NotificationsApi = {
	flowNotifications,
	markProjectNotificationsAsRead
}
export default NotificationsApi
