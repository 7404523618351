import { fetch } from 'config'

type Params = {
	pointId: string,
	questionId: string,
	assistantId: string
	until?: string
}

type response = {
	total: number
	missing: number
}

export const getAnswersCount = ({ until, assistantId, pointId, questionId }: Params) => {
	return fetch<response>({
		url: `/projects/v1/divergence-point/${pointId}/question/${questionId}/comment/assisted/${assistantId}/all/count`,
		method: 'GET',
		params: { until },
	}).then(({ data }) => data)
}
