import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStApplets: React.FC = (props: IconProps) => (
	<IconBase strokeWidth={0.2} viewBox='0 0 18 18' {...props}>
		<path
			d='M15 2.25H3C2.17275 2.25 1.5 2.92275 1.5 3.75V14.25C1.5 15.0773 2.17275 15.75 3 15.75H15C15.8273 15.75 16.5 15.0773 16.5 14.25V3.75C16.5 2.92275 15.8273 2.25 15 2.25ZM3 14.25V5.25H15L15.0015 14.25H3Z'
			fill='currentColor'
		/>
		<path
			d='M6.9697 6.9707L4.18945 9.75095L6.9697 12.5312L8.0302 11.4707L6.31045 9.75095L8.0302 8.0312L6.9697 6.9707ZM11.0302 6.9707L9.9697 8.0312L11.6895 9.75095L9.9697 11.4707L11.0302 12.5312L13.8105 9.75095L11.0302 6.9707Z'
			fill='currentColor'
		/>
	</IconBase>
)
