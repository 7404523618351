import themeColors from './themeColors'

const loginColors = {
	login_button_schema: 'secondary'
}

const dashboardColors = {
	dashboard_buy_credits_button: 'secondary',
	sub_components_link: themeColors.primary.dark
}

const signupColors = {
	radio_button_border: themeColors.primary.dark,
	radio_button_color: themeColors.primary.dark,
	radio_button_filled: themeColors.primary.dark,
	radio_button_filled_color: 'white'
}

const createJourney = {
	go_back_link: themeColors.primary.dark
}

const screenColors = {
	...loginColors,
	...dashboardColors,
	...signupColors,
	...createJourney
}

export default screenColors
