import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	Button,
	Icon,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { ArrowBackIcon } from '@chakra-ui/icons'

import LoadingStrateegia from 'modules/common/components/loading'
import { Assistant } from 'modules/map/types/assistants'

import { AssistantForm } from '../components/assistant-form'
import { useUserAssistant } from '../hooks/use-user-assistant'
import { createAssistant, updateAssistant } from '../usecases'

// These one first prop below is only for the modal version, so it is optional
interface AssistantManagerProps {
	onClose?: () => void
	mode: 'create' | 'edit' | 'start'
}

export const AssistantManager = ({ onClose, mode }: AssistantManagerProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id: assistantId } = useParams()
	const toast = useToast()
	const { data, isFetching, isError } = useUserAssistant(assistantId)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [currentValidationStep, setCurrentValidationStep] = useState(1)
	const mobileScreen = useBreakpointValue({
		base: true,
		md: false
	})
	const location = useLocation()

	const hasActiveAssistantLink = mode === 'start'

	const isAssistantDataAvailable = hasActiveAssistantLink && data
	const isAssistantDataPrivate = hasActiveAssistantLink && isError

	useEffect(() => {
		if (isAssistantDataAvailable) {
			navigate('/dashboard', {
				state: {
					...location.state,
					assistant: { id: data.id, name: data.name }
				}
			})
		}

		if (isAssistantDataPrivate) {
			toast({
				title: t('assistants:redirect.toast.error.title'),
				description: t('assistants:redirect.toast.error.description'),
				status: 'error',
				duration: 4000,
				isClosable: true
			})
			navigate('/dashboard')
		}
	}, [data, isError])

	if (isFetching) {
		return <LoadingStrateegia />
	}

	if (mode === 'start') {
		return null
	}

	const handleSubmit = async (values: Assistant) => {
		try {
			setIsSubmitting(true)
			const action =
				mode === 'edit'
					? updateAssistant(assistantId || '', values)
					: createAssistant(values)
			await action

			toast({
				id: 'assistants_form_toast',
				title: t(
					`journeys:editor.widgets.assistants.toast.success.${mode === 'edit' ? 'update' : 'create.title'}`
				),
				description:
					mode !== 'edit'
						? t(
							'journeys:editor.widgets.assistants.toast.success.create.description'
						)
						: undefined,
				status: 'success',
				duration: mode === 'edit' ? 2000 : 3000,
				isClosable: true,
				containerStyle: {
					width: '100%',
					maxWidth: mode === 'edit' && !mobileScreen ? '317px' : '755px',
					margin: mobileScreen ? '65px' : '17px',
					zIndex: 10
				}
			})

			navigate('/dashboard/assistants')
		} catch (error) {
			toast({
				id: 'assistants_form_toast',
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error',
				duration: 4000,
				isClosable: true,
				containerStyle: {
					width: '100%',
					maxWidth: '675px',
					margin: mobileScreen ? '65px' : '17px',
					zIndex: 10
				}
			})
		} finally {
			setIsSubmitting(false)
			onClose?.()
		}
	}

	return (
		<>
			<Button
				mb={4}
				as={Link}
				variant='link'
				color='go_back_link'
				leftIcon={<Icon as={ArrowBackIcon} />}
				to='/dashboard/assistants'
			>
				{t('buttons:back')}
			</Button>
			<Box mb={4}>
				<Text fontWeight='bold'>
					{t('journeys:editor.widgets.assistants.title', {
						actionPT: mode === 'edit' ? 'editar' : 'criar',
						actionEN: mode === 'edit' ? 'edit' : 'create',
						actionES: mode === 'edit' ? 'editar' : 'crear'
					})}
				</Text>
				<Text mt={2.5} fontSize={['sm', 'md']} color='gray.400'>
					{t('journeys:editor.widgets.assistants.description')}
				</Text>
			</Box>
			<AssistantForm
				data={data}
				editMode={mode === 'edit'}
				handleSubmit={handleSubmit}
				isSubmitting={isSubmitting}
				currentValidationStep={currentValidationStep}
				setCurrentValidationStep={setCurrentValidationStep}
			/>
		</>
	)
}
