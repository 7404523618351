import React from 'react'
import { IconType } from 'react-icons'
import { FiMoreVertical } from 'react-icons/fi'

import {
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuButtonProps,
	MenuItem,
	MenuList,
	Text
} from '@chakra-ui/react'

import { createId } from 'modules/common/utils/create-id'

interface Option {
	title: string
	onClick: () => void
	icon?: IconType
	iconColor?: string
}

interface Props extends MenuButtonProps {
	label: string
	options: Option[]
}

export const MenuOptions = ({ label, options, ...props }: Props) => {
	return (
		<Menu isLazy placement='bottom-end' closeOnBlur>
			<MenuButton
				as={IconButton}
				aria-label={label}
				variant='ghost'
				color='gray.400'
				icon={<Icon as={FiMoreVertical} fontSize='lg' />}
				_active={{ bgColor: 'gray.100' }}
				position='relative'
				zIndex={99}
				h='32px'
				mt={1}
				_hover={{ backgroundColor: 'gray.70' }}
				{...props}
			/>
			<MenuList>
				{options.map((item) => (
					<MenuItem
						key={createId()}
						_hover={{ backgroundColor: 'blue.2' }}
						onClick={item.onClick}
						borderRadius='md'
					>
						<HStack fontSize='sm'>
							<Icon as={item.icon} color={item.iconColor} />
							<Text>{item.title}</Text>
						</HStack>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	)
}
