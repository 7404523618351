import { fetch } from 'config'

import { AssistantComment } from '../types/assistants'

type Params = {
	assistantId: string
	divergencePointId: string
	questionId: string
	language: string
	responseSize: string
}

export const createAssistandComment = ({
	assistantId,
	divergencePointId,
	questionId,
	language,
	responseSize
}: Params) => {
	return fetch<AssistantComment>({
		url: `/projects/v1/divergence-point/${divergencePointId}/question/${questionId}/comment/assisted/${assistantId}/dry`,
		method: 'POST',
		data: { language, response_size: responseSize }
	}).then(({ data }) => data)
}
