import { fetch } from 'config'

import { TemplateMapPoints } from '../types'

export async function getMapPoints(mapId: string) {
	const { data } = await fetch<TemplateMapPoints>({
		url: `/templates/v1/template/map/${mapId}`,
		method: 'GET'
	})

	return data
}
