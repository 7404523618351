import React from 'react'

import {
	Button,
	Box,
	useRadio,
	UseRadioProps,
	useRadioGroup,
	HStack,
	StackProps,
	UseRadioGroupProps,
	useTheme
} from '@chakra-ui/react'

type Option = {
	text: string
	value: string | number
}

type RadioCardProps = UseRadioProps & {
	children: React.ReactNode
}

type Props = UseRadioGroupProps & {
	options: Option[]
	stackProps?: StackProps
}

function RadioCard(props: RadioCardProps) {
	const { getInputProps, getCheckboxProps } = useRadio(props)

	const input = getInputProps()
	const checkbox = getCheckboxProps()
	const theme = useTheme()

	return (
		<Box as='label'>
			<input {...input} />
			<Button
				{...checkbox}
				pl={['none', 6]}
				pr={['none', 6]}
				as={Box}
				cursor='pointer'
				borderWidth='px'
				borderRadius='full'
				borderColor={theme.colors.radio_button_border}
				color={theme.colors.radio_button_color}
				variant='outline'
				_checked={{
					bg: theme.colors.radio_button_filled,
					color: theme.colors.radio_button_filled_color
				}}
				_hover={{
					bg: theme.colors.radio_button_filled,
					color: theme.colors.radio_button_filled_color
				}}
				sx={{
					'&[data-active]': {
						bg: theme.colors.radio_button_filled,
						color: theme.colors.radio_button_filled_color
					}
				}}
			>
				{props.children}
			</Button>
		</Box>
	)
}

export function ButtonsRadio(props: Props) {
	const { options, stackProps, ...radioProps } = props
	const { getRootProps, getRadioProps } = useRadioGroup({
		...radioProps,
		isNative: false
	})

	const group = getRootProps()
	const hStackProps = stackProps || {}

	return (
		<HStack
			alignItems='center'
			justifyContent='space-between'
			{...hStackProps}
			{...group}
			w='full'
		>
			{options.map(({ text, value }) => {
				const radio = getRadioProps({ value })
				return (
					<RadioCard key={value} {...radio}>
						{text}
					</RadioCard>
				)
			})}
		</HStack>
	)
}
