import React from 'react'

import { BoxProps, LinkBox, useToken } from '@chakra-ui/react'

import { useStringToColor } from 'modules/common/hooks'

type Props = {
	link?: string
	color?: string
	height?: string
	width?: string
	boxProps?: BoxProps
	children: React.ReactNode
}

export const Card: React.FC<Props> = ({
	color,
	children,
	width,
	height,
	boxProps = {}
}) => {
	const [baseColor, fgColor, borderColor] = useStringToColor(
		{ text: color, forceColor: color },
		[500, 100, 50]
	)
	const [shadowColor] = useToken('colors', [`${baseColor}.100`])

	return (
		<LinkBox
			minH={height}
			minW={width}
			borderRadius='0.75rem'
			borderColor={borderColor}
			borderWidth={1}
			px={4}
			py={2}
			transition='all 0.3s linear'
			_hover={{
				borderColor: fgColor,
				boxShadow: `8px 8px 0 0 ${shadowColor}`
			}}
			{...boxProps}
		>
			{children}
		</LinkBox>
	)
}
