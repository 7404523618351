import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Flex,
	Input,
	Text,
	Image,
	useBreakpointValue
} from '@chakra-ui/react'

import Send from 'modules/map/components/chat/send.svg'

import { validText } from '../../utils'

interface LayoutProps {
	px?: number
	py?: number
	bg?: string
	borderRadius?: string
}

interface Props {
	cantComment: boolean
	handleComment: (text: string) => void
	layoutProps?: LayoutProps
}

export const CommentInputField: React.FC<Props> = ({
	cantComment,
	handleComment,
	layoutProps
}) => {
	const { t } = useTranslation()
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const mobileButton = useBreakpointValue({ base: 20, md: 28 })
	const [text, setText] = useState('')

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({
		key
	}) => {
		if (key === 'Enter') {
			if (text) {
				handleComment(text)
				setText('')
			}
		}
	}

	return (
		<Flex w='full' h='full' gridGap='2' alignItems='center' {...layoutProps}>
			<Input
				value={text}
				isDisabled={cantComment}
				onChange={(e) => {
					if (!(text.length > 1000)) {
						setText(e.target.value)
					}
				}}
				onKeyPress={handleKeyDown}
				maxLength={1000}
				placeholder={t('map:points.conversation.view.chat.commentPlaceholder')}
				maxW='100%'
				border='none'
				fontSize='md'
				fontWeight='medium'
			/>
			<Button
				color='white'
				backgroundColor={
					validText(text)
						? 'conversation_points_send_button'
						: 'conversation_points_send_disabled_button'
				}
				_hover={{ backgroundColor: 'none' }}
				borderRadius={20}
				size='md'
				w={mobileButton}
				h={8}
				isDisabled={cantComment || !text}
				onClick={() => {
					handleComment(text)
					setText('')
				}}
			>
				{!mobileScreen ? (
					<>
						<Text fontSize='sm' fontWeight='semibold'>
							{t('map:points.conversation.view.chat.sendComment')}
						</Text>
						<Image ml={1} src={Send} />
					</>
				) : (
					<Image src={Send} />
				)}
			</Button>
		</Flex>
	)
}
