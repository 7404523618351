import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStEvaluation: React.FC = (props: IconProps) => (
	<IconBase width='35' height='36' viewBox='0 0 35 36' fill='none' {...props}>
		<path
			d='M16.0288 1.3494C16.9392 0.82379 18.0608 0.82379 18.9712 1.3494L31.1842 8.4006C32.0946 8.92621 32.6554 9.89758 32.6554 10.9488V25.0512C32.6554 26.1024 32.0946 27.0738 31.1842 27.5994L18.9712 34.6506C18.0608 35.1762 16.9392 35.1762 16.0288 34.6506L3.81576 27.5994C2.90538 27.0738 2.34456 26.1024 2.34456 25.0512V10.9488C2.34456 9.89758 2.90538 8.92621 3.81576 8.4006L16.0288 1.3494Z'
			fill='currentColor'
		/>
		<path
			d='M15 14C14.8674 14 14.7402 14.0527 14.6464 14.1464C14.5527 14.2402 14.5 14.3674 14.5 14.5C14.5 14.6326 14.5527 14.7598 14.6464 14.8536C14.7402 14.9473 14.8674 15 15 15H21C21.1326 15 21.2598 14.9473 21.3536 14.8536C21.4473 14.7598 21.5 14.6326 21.5 14.5C21.5 14.3674 21.4473 14.2402 21.3536 14.1464C21.2598 14.0527 21.1326 14 21 14H15ZM14.5 16.5C14.5 16.3674 14.5527 16.2402 14.6464 16.1464C14.7402 16.0527 14.8674 16 15 16H21C21.1326 16 21.2598 16.0527 21.3536 16.1464C21.4473 16.2402 21.5 16.3674 21.5 16.5C21.5 16.6326 21.4473 16.7598 21.3536 16.8536C21.2598 16.9473 21.1326 17 21 17H15C14.8674 17 14.7402 16.9473 14.6464 16.8536C14.5527 16.7598 14.5 16.6326 14.5 16.5ZM15 18C14.8674 18 14.7402 18.0527 14.6464 18.1464C14.5527 18.2402 14.5 18.3674 14.5 18.5C14.5 18.6326 14.5527 18.7598 14.6464 18.8536C14.7402 18.9473 14.8674 19 15 19H21C21.1326 19 21.2598 18.9473 21.3536 18.8536C21.4473 18.7598 21.5 18.6326 21.5 18.5C21.5 18.3674 21.4473 18.2402 21.3536 18.1464C21.2598 18.0527 21.1326 18 21 18H15ZM15 20C14.8674 20 14.7402 20.0527 14.6464 20.1464C14.5527 20.2402 14.5 20.3674 14.5 20.5C14.5 20.6326 14.5527 20.7598 14.6464 20.8536C14.7402 20.9473 14.8674 21 15 21H18C18.1326 21 18.2598 20.9473 18.3536 20.8536C18.4473 20.7598 18.5 20.6326 18.5 20.5C18.5 20.3674 18.4473 20.2402 18.3536 20.1464C18.2598 20.0527 18.1326 20 18 20H15Z'
			fill='white'
		/>
		<path
			d='M12 12C12 11.4696 12.2107 10.9609 12.5858 10.5858C12.9609 10.2107 13.4696 10 14 10H22C22.5304 10 23.0391 10.2107 23.4142 10.5858C23.7893 10.9609 24 11.4696 24 12V24C24 24.5304 23.7893 25.0391 23.4142 25.4142C23.0391 25.7893 22.5304 26 22 26H14C13.4696 26 12.9609 25.7893 12.5858 25.4142C12.2107 25.0391 12 24.5304 12 24V12ZM22 11H14C13.7348 11 13.4804 11.1054 13.2929 11.2929C13.1054 11.4804 13 11.7348 13 12V24C13 24.2652 13.1054 24.5196 13.2929 24.7071C13.4804 24.8946 13.7348 25 14 25H22C22.2652 25 22.5196 24.8946 22.7071 24.7071C22.8946 24.5196 23 24.2652 23 24V12C23 11.7348 22.8946 11.4804 22.7071 11.2929C22.5196 11.1054 22.2652 11 22 11Z'
			fill='white'
		/>
	</IconBase>
)
