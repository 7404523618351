export function escapeCSV(value: string): string {
	let escapedValue = value.replace(/"/g, '""')

	if (
		escapedValue.includes(',') ||
		escapedValue.includes('\n') ||
		escapedValue.includes('"')
	) {
		escapedValue = `"${escapedValue}"`
	}
	return escapedValue
}

export async function downloadXLSX(
	csvContent: string,
	filename: string = 'download.xlsx'
): Promise<void> {
	const { read, write, utils } = await import('xlsx')

	// Convert the CSV string to a workbook
	const workbook = read(csvContent, { type: 'string' })
	const firstSheetName = workbook.SheetNames[0]
	const worksheet = workbook.Sheets[firstSheetName]

	// Convert sheet to JSON
	const jsonData = utils.sheet_to_json(worksheet)

	// Convert transformed data back to a new workbook and worksheet
	const newWorksheet = utils.json_to_sheet(jsonData)
	const newWorkbook = utils.book_new()
	utils.book_append_sheet(newWorkbook, newWorksheet, firstSheetName)

	// Convert the workbook to a binary string
	const xlsxBinary: string = write(newWorkbook, {
		bookType: 'xlsx',
		type: 'binary'
	})

	// Convert the binary string to a Blob
	const xlsxBlob = new Blob([s2ab(xlsxBinary)], {
		type: 'application/octet-stream'
	})

	// Download logic...
	if (navigator.msSaveBlob) {
		navigator.msSaveBlob(xlsxBlob, filename)
	} else {
		const link = document.createElement('a')

		if (link.download !== undefined) {
			const url = URL.createObjectURL(xlsxBlob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
}

function s2ab(s: string): ArrayBuffer {
	const buf = new ArrayBuffer(s.length)
	const view = new Uint8Array(buf)
	for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
	return buf
}

type SheetForm = {
	name: string[]
	evaluation: string[]
	grade: number[]
	answer: string[]
}

export function csvmakerEvaluationPoint(data: SheetForm) {
	const csvRows = []

	const headers = Object.keys(data).map((header) => escapeCSV(header))
	csvRows.push(headers.join(','))

	for (let i = 0; i < data.name.length; i++) {
		const user = escapeCSV(data.name[i])
		const grades = data.grade[i]
		const answer = escapeCSV(data.answer[i])
		const evaluation = escapeCSV(data.evaluation[i])
		const row = [user, grades, answer, evaluation].join(',')
		csvRows.push(row)
	}

	return csvRows.join('\n')
}
