import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Box, Button, Image, Text, VStack } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { InputField } from 'modules/common/components/forms/hook-form-fields'
import { useGetPublicProviders } from 'modules/login/hooks/useGetPublicProviders'
import { Provider } from 'modules/login/types'

import { AcceptTermsField } from './accept-terms-field'

export const SignUpData = ({ previousURL }: { previousURL: string }) => {
	const { t } = useTranslation()
	const { trigger, watch } = useFormContext()
	const { data: providers } = useGetPublicProviders()

	const [password, confirmPassword] = watch(['password', 'confirmPassword'])

	const { pathname, search } = useLocation()
	const current = `${pathname}${search}`
	const ignoredPaths = ['/signin', '/', '/signup', current]
	const redirectURL = !ignoredPaths.includes(previousURL)
		? previousURL
		: '/dashboard'
	const tenantUrl = `${window.location.origin}/auth/callback`

	const handleRedirectProvider = (providerData: Provider) => {
		sendGAEvent(`create-with-provider-${providerData.name}`)
		const stateObject = {
			providerId: providerData.id,
			urlCallback: tenantUrl,
			previousUrl: redirectURL
		}

		const encodedState = encodeURIComponent(JSON.stringify(stateObject))

		const providerAuthUrl = `${providerData.authorization_endpoint}?client_id=${providerData.client_id
			}&scope=${providerData.scope.join(
				' '
			)}&redirect_uri=${tenantUrl}&response_type=code&state=${encodedState}`
		window.location.href = providerAuthUrl
	}

	useEffect(() => {
		if (password && confirmPassword) {
			trigger(['password', 'confirmPassword'])
		}
	}, [password, confirmPassword])

	return (
		<>
			<VStack spacing={4}>
				{providers?.map((provider) => (
					<Button
						key={provider.id}
						variant='outline'
						w='full'
						leftIcon={<Image boxSize='24px' src={provider.logo_url} />}
						onClick={() => handleRedirectProvider(provider)}
					>
						{t('signup:enterWith', { value: provider.name })}
					</Button>
				))}

				<Box
					display='flex'
					flexDirection='row'
					justifyContent='center'
					alignItems='center'
					h={8}
					w='full'
				>
					<Box borderBottom='1px solid #C2C8D0' flex='none' flexGrow={1} />
					<Text
						display='flex'
						flexDirection='column'
						alignItems='center'
						px={3}
						fontSize='sm'
					>
						{t('signup:or')}
					</Text>
					<Box borderBottom='1px solid #C2C8D0' flex='none' flexGrow={1} />
				</Box>

				<InputField
					fieldName='name'
					label={t('signup:name')}
					placeholder={t('signup:placeholders.name')}
				/>
				<InputField
					fieldName='email'
					label={t('login:email')}
					placeholder={t('login:placeholders.email')}
				/>
				<InputField
					fieldName='confirmEmail'
					label={t('signup:confirmEmail')}
					placeholder={t('signup:placeholders.confirmEmail')}
					onPaste={(e) => {
						e.preventDefault()
					}}
				/>
				<InputField
					fieldName='password'
					label={t('signup:password')}
					placeholder={t('login:placeholders.password')}
					type='password'
				/>
				<InputField
					fieldName='confirmPassword'
					label={t('signup:confirmPassword')}
					placeholder={t('login:placeholders.password')}
					type='password'
					onPaste={(e) => {
						e.preventDefault()
					}}
				/>
			</VStack>
			<AcceptTermsField />
		</>
	)
}
