import { fetch } from 'config'

import { QuestionAnalysis } from '../types'

type Params = {
	pointId: string
	questionId: string
}

export const getQuestionAnalysis = ({ questionId, pointId }: Params) => {
	return fetch<QuestionAnalysis>({
		url: `/projects/v1/divergence-point/${pointId}/question/${questionId}/analysis`,
		method: 'GET'
	}).then(({ data }) => data)
}
