import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#32A558',
		light: '#A4CEA1'
	},

	secondary: {
		dark: '#353189',
		light: '#8C8AB6'
	}
}

export default themeColors
