import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#2A4190',
		light: '#9AA3C3'
	},

	secondary: {
		dark: '#8D0083',
		light: '#C189BD'
	}
}

export default themeColors
