import defaultCustomColors from '../default/colors'

const animaCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: defaultCustomColors.orange[700]
	},

	secondary_color_scheme_button: {
		700: '#EF6C00',
		500: '#EF6C00'
	},

	radio_color: {
		500: '#EF6C00',
		700: '#EF6C00'
	},

	switch_scheme_color_checked: {
		500: '#EF6C00'
	}
}

export default animaCustomColors
