import { fetch } from 'config'

import { ConversationPoint } from '../types'

export async function getConversationPoint(id: string) {
	const response = await fetch<ConversationPoint>({
		url: `/projects/v1/checkpoint/${id}`,
		method: 'GET'
	})

	return response.data
}
