import React from 'react'

import { Global } from '@emotion/react'

export const Fonts: React.FC = () => (
	<Global
		styles={`
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
    `}
	/>
)
