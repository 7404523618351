import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#01305C',
		light: '#737BB4'
	},

	secondary: {
		dark: '#88CA09',
		light: '#C3E584'
	}
}

export default themeColors
