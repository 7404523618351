import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStPeople: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 18 18' {...props}>
		<path
			d='M9.36647 10.9367L13.3827 8.67953C13.5891 8.56916 13.7255 8.3548 13.7255 8.10838V3.52292C13.7255 3.2765 13.5891 3.06214 13.3827 2.95177L9.36647 0.694562C9.25925 0.617026 9.13009 0.580703 9 0.580703C8.86992 0.580703 8.74075 0.617026 8.63353 0.694562L4.61735 2.95176C4.41093 3.06212 4.27455 3.27649 4.27455 3.52292V8.10838C4.27455 8.35481 4.41093 8.56917 4.61734 8.67954L8.63353 10.9367C8.74075 11.0143 8.86992 11.0506 9 11.0506C9.13009 11.0506 9.25925 11.0143 9.36647 10.9367ZM5.57354 7.72592V3.90538L9 1.97677L12.4265 3.90538V7.72592L9 9.65453L5.57354 7.72592Z'
			fill='currentcolor'
			stroke='currentcolor'
		/>
		<path
			d='M9.36647 17.3059L13.3827 15.0487C13.5891 14.9383 13.7255 14.7239 13.7255 14.4775V9.89206C13.7255 9.64564 13.5891 9.43128 13.3827 9.32091L9.36647 7.0637C9.25925 6.98617 9.13009 6.94984 9 6.94984C8.86992 6.94984 8.74075 6.98617 8.63353 7.0637L4.61735 9.3209C4.41093 9.43127 4.27455 9.64563 4.27455 9.89206V14.4775C4.27455 14.7239 4.41093 14.9383 4.61735 15.0487L8.63353 17.3059C8.74075 17.3834 8.86992 17.4197 9 17.4197C9.13009 17.4197 9.25925 17.3834 9.36647 17.3059ZM5.57354 14.0951V10.2745L9 8.34591L12.4265 10.2745V14.0951L9 16.0237L5.57354 14.0951Z'
			fill='currentcolor'
			stroke='currentcolor'
		/>
	</IconBase>
)
