import { FormEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Button,
	FormControl,
	FormErrorMessage,
	HStack,
	Icon,
	Input,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Text,
	VStack,
	useTheme
} from '@chakra-ui/react'

import { getStringFormattedBRL } from 'modules/common/utils/credits'

import { CustomSchemeButton } from '../buttons'
import { IconStCoin } from '../icons'
import { InputCredits } from './components/select-credits'

export const ChooseAmount = ({
	onClose,
	onNext
}: {
	onClose: () => void
	onNext: () => void
}) => {
	const { t } = useTranslation()

	const {
		register,
		setValue,
		watch,
		formState: { errors },
		trigger
	} = useFormContext()

	const inputValue: number = watch('creditsAmount')

	function getExactNumber(value: number): number {
		if (isNaN(value)) {
			return 0
		}

		const numberFormattedBRL = getStringFormattedBRL(Number(value))
		const numericPart = numberFormattedBRL.replace(',', '.')
		const number = Number(numericPart)
		return number
	}

	const onSubmit = (e: FormEvent) => {
		e.preventDefault()
		setValue('creditsCost', getExactNumber(inputValue * 0.1))
		onNext()
	}

	useEffect(() => {
		trigger('creditsAmount')
	}, [inputValue])

	const theme = useTheme()
	return (
		<form onSubmit={onSubmit}>
			<ModalHeader>
				<Text fontSize='lg' fontWeight='bold'>
					{t('credits:buyCredits')}
				</Text>
			</ModalHeader>
			<FormControl isInvalid={!!errors.creditsAmount}>
				<ModalBody mt={2}>
					<VStack spacing={4}>
						<VStack>
							<Icon w={12} h={12} as={IconStCoin} />
							<Text fontWeight='bold'>{`${isNaN(inputValue) ? 0 : inputValue
								} ${t('credits:credits')}`}</Text>
							<Text fontWeight='bold'>
								{t('credits:price', {
									value: getStringFormattedBRL(inputValue * 0.1)
								})}
							</Text>
						</VStack>
						<HStack>
							<Input
								textAlign='center'
								type='number'
								step={'any'}
								w='80px'
								{...register('creditsAmount', { valueAsNumber: true })}
							/>
						</HStack>
						<FormErrorMessage>{t('errors:buyCredits.min')}</FormErrorMessage>
						<InputCredits
							name='selectCredits'
							value={inputValue.toString()}
							onChange={async (value) => {
								setValue('creditsAmount', value)
							}}
						/>
					</VStack>
				</ModalBody>
			</FormControl>
			<ModalFooter mt={2}>
				<HStack w='100%' justifyContent='center'>
					<Button fontSize='sm' onClick={onClose} colorScheme='gray'>
						{t('buttons:cancel')}
					</Button>
					<CustomSchemeButton
						fontSize='sm'
						type='submit'
						colorScheme={theme.colors.buy_credits_modal_continue_button_scheme}
						isDisabled={!!errors.creditsAmount}
						w='70%'
					>
						{t('buttons:continue')}
					</CustomSchemeButton>
				</HStack>
			</ModalFooter>
		</form>
	)
}
