// este componente é usado para validar os usuários que acessam strateegia atraves do link fornecido pela CIA de Talentos

import { useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import LoadingStrateegia from 'modules/common/components/loading'
import { useIsAuthenticated, useQueryParams } from 'modules/common/hooks'
import { getEnroll } from 'modules/login/usecases/get-enroll'
import { useGetValidateExternalToken } from 'modules/signup/hooks/use-get-validate-external-token'

export const ExternalTokenCallback = () => {
	const query = useQueryParams()
	const accountId = query.get('account_id') as string
	const payload = query.get('payload') as string
	const isAuthenticated = useIsAuthenticated()
	const navigate = useNavigate()

	const { data } = useGetValidateExternalToken({
		accountId,
		payload
	})

	const handleAddtoJourney = async () => {
		const { id } = await getEnroll({ accountId, payload })
		if (id) {
			navigate(`/journey/${id}`)
			return
		}
		navigate('/dashboard')
	}

	if (!data) {
		navigate('/signin?error')
		return
	}

	if (isAuthenticated) {
		if (data?.enrollment) {
			navigate(`/journey/${data.journey_id}`)
			return
		}
		handleAddtoJourney()
		return
	}
	if (data?.registered) {
		navigate(
			`/signin?action=externalToken&account_id=${accountId}&payload=${payload}`
		)
	}
	if (!data?.registered) {
		navigate(
			`/signup?action=externalToken&email=${data?.email}&name=${data?.name}&account_id=${accountId}&payload=${payload}`
		)
	}

	return (
		<Flex w='100%' height='100%' alignItems='center' justify='center'>
			<LoadingStrateegia />
		</Flex>
	)
}
