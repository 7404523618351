export function getStringFormattedBRL(value: number): string {
	if (isNaN(value)) {
		return '0'
	}

	const formatterBRL = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		maximumSignificantDigits: 3,
		minimumFractionDigits: 2
	})

	const formattedBRLCost = formatterBRL.format(value)
	const formattedCostNumber = addRightZeros(formattedBRLCost)

	return formattedCostNumber
}

export function addRightZeros(str: string): string {
	// Use regex to remove non-numeric and non-decimal-separator characters from the string, with the exception of the "," and "." characters
	const numericPart = str.replace(/[^0-9,.]/g, '')
	// if after the , or . there is number with only one digit, add a 0 to the end
	if (numericPart.includes(',')) {
		const [, decimalPart] = numericPart.split(',')
		if (decimalPart.length === 1) {
			return `${numericPart}0`
		}
	}
	return numericPart + ',00'
}
