import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Heading,
	HStack,
	Button,
	Icon,
	Text,
	UseDisclosureReturn
} from '@chakra-ui/react'

import { IconStProCrown } from 'modules/common/components/icons'

interface WarningProps extends UseDisclosureReturn {
	title: string
	description: string
	showIcon?: boolean
}

export const WarningModal = ({
	title,
	description,
	showIcon,
	...disclosure
}: WarningProps) => {
	const { t } = useTranslation()

	return (
		<Modal
			size='xl'
			isCentered
			isOpen={disclosure.isOpen}
			onClose={disclosure.onClose}
		>
			<ModalOverlay />
			<ModalContent p={4}>
				<ModalHeader>
					<Heading>
						<HStack w='md'>
							<Text fontSize='lg'>{title}</Text>
							{showIcon && (
								<Icon as={IconStProCrown} color='yellow.500' fontSize='md' />
							)}
						</HStack>
						<ModalCloseButton mt={4} mr={4} />
					</Heading>
				</ModalHeader>
				<ModalBody>
					<Text fontSize='md' fontWeight='normal' color='gray.400'>
						<Trans i18nKey={description} components={[<strong />]} />
					</Text>
				</ModalBody>
				<ModalFooter gridGap={4}>
					<Button bg='gray.180' onClick={disclosure.onClose}>
						<Text fontSize='xs' fontWeight='bold' color='gray.400'>
							{t('journeys:limitedFeatures.modal.buttons.cancel')}
						</Text>
					</Button>
					<Button
						as={Link}
						to='/dashboard/labs/select-plan'
						colorScheme='custom_scheme_color_button'
					>
						<Text fontSize='xs' fontWeight='bold'>
							{t('journeys:limitedFeatures.modal.buttons.proceed')}
						</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
