import React, { useState, createContext } from 'react'

import { Box, useBoolean } from '@chakra-ui/react'

import { BrowserRecommendation } from 'modules/common/components/chrome-recomendation'
import { FullPageLayout } from 'modules/common/templates'

import { Header, Form } from './components'

interface Context {
	termsChecked: boolean
	setTermsChecked: React.Dispatch<React.SetStateAction<boolean>>
	newsChecked: boolean
	setNewsChecked: React.Dispatch<React.SetStateAction<boolean>>
	logoAsLink: boolean
	setLogoAsLink: React.Dispatch<React.SetStateAction<boolean>>
	showMoreData: boolean
	setShowMoreData: {
		on: () => void
		off: () => void
		toggle: () => void
	}
}

export const SignUpContext = createContext<Context>({} as Context)

const SignUp = ({ previousURL }: { previousURL: string }) => {
	const [logoAsLink, setLogoAsLink] = useState(true)
	const [showMoreData, setShowMoreData] = useBoolean()
	const [termsChecked, setTermsChecked] = useState(false)
	const [newsChecked, setNewsChecked] = useState(false)

	const value = {
		termsChecked,
		setTermsChecked,
		newsChecked,
		setNewsChecked,
		logoAsLink,
		setLogoAsLink,
		showMoreData,
		setShowMoreData
	}

	return (
		<FullPageLayout logoAsLink={logoAsLink}>
			<SignUpContext.Provider value={value}>
				<Box p={[0.01, 7]} padding={'20px 0'}>
					<Header />
					<Form previousURL={previousURL} />
					<BrowserRecommendation />
				</Box>
			</SignUpContext.Provider>
		</FullPageLayout>
	)
}

export default SignUp
