import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStPointConvergence: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 31 35' {...props}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.5 0.577276C15.1188 0.22001 15.8812 0.22001 16.5 0.577275L29.6555 8.17257C30.2743 8.52984 30.6555 9.19009 30.6555 9.90462V25.0952C30.6555 25.8098 30.2743 26.47 29.6555 26.8273L16.5 34.4226C15.8812 34.7798 15.1189 34.7798 14.5 34.4226L1.34461 26.8273C0.725803 26.47 0.344604 25.8098 0.344604 25.0952V9.90463C0.344604 9.19009 0.725802 8.52984 1.3446 8.17257L14.5 0.577276ZM12.0391 19.9293L9.19094 19.9262V18.7353L14.2586 18.7353L14.2586 23.8029H13.0677L13.0643 20.7128L8.63479 25.1423L7.7304 24.238L12.0391 19.9293ZM16.9392 15.287L21.3667 10.8595L22.2711 11.7639L17.9683 16.0667L20.8126 16.0698V17.2607L15.7449 17.2607L15.7449 12.193L16.9358 12.193L16.9392 15.287Z'
			fill='currentcolor'
		/>
	</IconBase>
)
