import themeColors from './themeColors'

const buttonComponentColors = {
	primary_button_hover: themeColors.primary.light,
	primary_button_active: themeColors.primary.dark,
	primary_button_focus: themeColors.primary.light,
	primary_button_bg: themeColors.primary.dark,
	primary_button_border: themeColors.primary.dark,
	primary_button_disabled_bg: themeColors.primary.light,

	secondary_button_hover: themeColors.secondary.light,
	secondary_button_active: themeColors.secondary.dark,
	secondary_button_focus: themeColors.secondary.light,
	secondary_button_bg: themeColors.secondary.dark,
	secondary_button_disabled_bg: themeColors.secondary.light
}

const navbarComponentColors = {
	navbar_bg: themeColors.primary.dark,
	navbar_text_color: 'white',
	navbar_link_hover_bg: themeColors.primary.light,
	navbar_link_active_bg: themeColors.primary.light,
	navbar_link_active_color: 'white',
	navbar_link_focus_bg: themeColors.primary.light,
	navbar_link_focus_box_shadow: themeColors.primary.light,
	navbar_link_focus_color: 'white'
}

const tabs = {
	tabs_color: themeColors.primary.dark,
	tabs_aria_selected_color: themeColors.primary.dark,
	tabs_aria_selected_box_shadow: themeColors.primary.dark
}

const indicators = {
	outlined_indicators_border: themeColors.secondary.light,
	filled_indicators: themeColors.primary.light,
	indicators_icon: themeColors.primary.light
}

const input = {
	input_border: themeColors.secondary.light
}

const divergencePoints = {
	divergence_points_button: themeColors.primary.dark,
	divergence_points_go_back_link: themeColors.primary.dark
}

const conversationPoints = {
	conversation_points_button: 'secondary',
	conversation_points_send_button: themeColors.primary.dark,
	conversation_points_send_disabled_button: themeColors.primary.light
}

const convergencePoints = {
	convergence_points_button_outlined_text_color: themeColors.primary.dark,
	convergence_points_button: 'secondary'
}

const evaluationPoints = {
	evaluation_box_border_color: themeColors.primary.dark,
	evaluation_box_bg_color: themeColors.primary.dark,
	evaluation_create_answer_button: themeColors.primary.dark,
	evaluation_answer_like_button: themeColors.primary.dark,
	evaluation_answer_comment_button: themeColors.primary.dark,
	evaluation_answer_comment_delete_button: themeColors.primary.dark,
	evaluation_answer_comment_card_border: themeColors.primary.dark,
	evaluation_answer_comment_card_icon: themeColors.primary.dark,
	evaluation_answer_comment_card_send_button: themeColors.primary.dark,
	evaluation_answer_comment_card_send_button_disabled:
		themeColors.primary.light,
	evaluation_answer_eval_button: themeColors.primary.dark,
	evaluation_answer_send_button: themeColors.primary.dark
}

const createJourneyButton = {
	create_journey_button_hover_bg: themeColors.primary.dark,
	create_journey_button_hover_color: 'white',
	create_journey_button_color: themeColors.primary.dark,
	secondary_create_journey_button_color: themeColors.secondary.dark,
	create_journey_button_border_color: themeColors.primary.dark,
	secondary_create_journey_button_border_color: themeColors.secondary.dark
}

const explorationKitControls = {
	exploration_kit_control_save_color: themeColors.primary.dark,
	secondary_kit_control_save_color: themeColors.secondary.dark,
	secondary_kit_control_save_border_color: themeColors.secondary.dark,
	exploration_kit_control_save_border_color: themeColors.primary.dark,
	exploration_kit_control_save_hover_bg: themeColors.primary.dark,
	secondary_exploration_kit_control_save_hover_bg: themeColors.secondary.dark,
	exploration_kit_control_save_hover_color: 'white'
}

const addComment = {
	add_comment_button_bg: themeColors.primary.dark
}

const mapHexagon = {
	hex_stroke_color: themeColors.primary.dark,
	hex_stroke_hover_bg: themeColors.primary.light
}

const mobileInvitePeople = {
	mobile_invite_people_button_bg: themeColors.primary.dark,
	mobile_invite_people_button_color: 'white',
	mobile_invite_people_button_hover_bg: themeColors.primary.light
}

const mobileNewMap = {
	mobile_new_map_button_bg: themeColors.primary.dark,
	mobile_new_map_button_color: 'white',
	mobile_new_map_button_hover_bg: themeColors.primary.light
}

const newsCards = {
	news_card_bg_color: '#32CDAC',
	news_card_button_bg: themeColors.secondary.light,
	news_card_button_text: '#369793'
}

const donwloadButton = {
	download_button_bg: themeColors.primary.dark
}

const componentColors = {
	screen: themeColors.primary.dark,

	switch_bg: themeColors.primary.dark,

	communication_modal_button: 'secondary',

	buy_credits_modal_continue_button_scheme: 'secondary',

	starting_point_button: 'secondary',

	logo_update_modal_button: 'secondary',

	analysis_modal_print_button: themeColors.primary.dark,

	create_map_button_hover: themeColors.secondary.dark,

	mobile_add_floating_action_button: themeColors.primary.dark,
	mobile_print_floating_action_button: themeColors.primary.dark,

	custom_scheme_color_button: {
		500: themeColors.secondary.dark,
		600: themeColors.secondary.light
	},

	custom_card_button: {
		500: themeColors.secondary.dark,
		600: themeColors.secondary.dark
	},

	custom_primary_button: {
		500: themeColors.primary.dark,
		600: themeColors.primary.light
	},

	...tabs,
	...buttonComponentColors,
	...navbarComponentColors,
	...indicators,
	...input,
	...divergencePoints,
	...conversationPoints,
	...convergencePoints,
	...evaluationPoints,
	...createJourneyButton,
	...addComment,
	...mapHexagon,
	...explorationKitControls,
	...mobileInvitePeople,
	...mobileNewMap,
	...newsCards,
	...donwloadButton
}

export default componentColors
