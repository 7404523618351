import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useToast
} from '@chakra-ui/react'

import { ANALYSIS_CREATION_COST } from 'modules/constants'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { User } from 'modules/user/types'

import { useAddQuestionAnalysisMutation } from '../../hooks/use-add-question-analysis-mutation'

type Props = {
	user?: User
	pointId: string
	questionId: string
	setDisabledButton: Dispatch<SetStateAction<boolean>>
}

export const CreateQuestionAnalysis = ({
	user,
	pointId,
	questionId,
	setDisabledButton
}: Props) => {
	const { t } = useTranslation()
	const toast = useToast()
	const createSummaryMutation = useAddQuestionAnalysisMutation({
		questionId,
		setButton: setDisabledButton
	})

	const handleSelect = async (option: string) => {
		try {
			setDisabledButton(true)
			createSummaryMutation.mutate({ pointId, questionId, language: option })
		} catch (error) {
			toast({
				description: t('errors:summaryGPT.error'),
				status: 'error'
			})
		}
	}

	return (
		<Menu>
			{user && user?.credits < ANALYSIS_CREATION_COST ? (
				<CreateContentBtn
					coins={ANALYSIS_CREATION_COST}
					isDisabled={false}
					nexStep={() => { }}
					text={t('buttons:summary')}
					user={user}
				/>
			) : (
				<MenuButton>
					<CreateContentBtn
						coins={ANALYSIS_CREATION_COST}
						isDisabled={false}
						nexStep={() => { }}
						text={t('buttons:summary')}
						user={user}
					/>
				</MenuButton>
			)}
			<MenuList minWidth='240px'>
				<Text fontSize='xs' color='gray.300' py={3} paddingInlineStart='0.8rem'>
					{t('divergencePoint:quickSummary.selectLanguage')}
				</Text>
				<MenuItem onClick={() => handleSelect('PT_BR')}>
					{t('startingPoint:create.languages.pt')}
				</MenuItem>
				<MenuItem onClick={() => handleSelect('EN_US')}>
					{t('startingPoint:create.languages.en')}
				</MenuItem>
				<MenuItem onClick={() => handleSelect('ES_ES')}>
					{t('startingPoint:create.languages.es')}
				</MenuItem>
			</MenuList>
		</Menu>
	)
}
