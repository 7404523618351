import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { manageIcognitoMode } from '../usecases/manage-icognito-mode'

export function useAnonymousMutation(pointId: string) {
	const channel = '/point/essay-point/incognito-mode'

	const { emit } = useSocket(channel)
	const setIsAnonym = useEvaluationStore((state) => state.setIsAnonymous)

	const mutation = useMutation(manageIcognitoMode, {
		onSuccess: (_, { isAnonymous }) => {
			setIsAnonym(isAnonymous)
			emit('EDIT', pointId)
		}
	})

	return mutation
}
