import defaultCustomColors from '../default/colors'

const cgeeCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#18581C',
		700: '#18581C'
	},

	secondary_color_scheme_button: {
		700: '#18581C'
	},

	switch_scheme_color_checked: {
		500: '#18581C'
	},

	radio_color: {
		500: '#18581C'
	}
}

export default cgeeCustomColors
