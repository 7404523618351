import React, { useState } from 'react'

import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	useTheme
} from '@chakra-ui/react'

import { Field, FieldProps } from 'formik'

import { IconStEye, IconStEyeClosed } from '../../icons'

type Props = FormControlProps & {
	label?: string
	name: string
	helperText?: string
	isDisabled?: boolean
	labelProps?: FormLabelProps
	rightElement?: React.ReactNode
	maxLength?: number
	ref?: React.Ref<HTMLInputElement>
} & InputProps

export const FormField: React.FC<Props> = ({
	label,
	isInvalid,
	isDisabled,
	helperText,
	name,
	maxLength,
	labelProps = {} as FormLabelProps,
	rightElement,
	ref,
	...props
}: Props) => {
	const [showPassword, setShowPassword] = useState(false)
	const isPassword = props.type === 'password'
	const type = isPassword ? (showPassword ? 'text' : 'password') : props.type

	const theme = useTheme()
	return (
		<Field name={name}>
			{({ field, meta }: FieldProps) => {
				return (
					<FormControl
						isInvalid={!!(meta.error && meta.touched)}
						isDisabled={isDisabled}
					>
						{label && (
							<FormLabel
								mb={0}
								fontSize='xs'
								fontWeight='semibold'
								color='gray.400'
								{...labelProps}
							>
								{label}
							</FormLabel>
						)}
						<InputGroup size='md'>
							<Input
								ref={ref}
								data-testid={name}
								variant='flushed'
								_focus={
									!meta.error
										? {
												borderColor: theme.colors.input_border,
												boxShadow: `0px 1px 0px 0px ${theme.colors.input_border}`
											}
										: {}
								}
								fontWeight='medium'
								autoComplete='false'
								_placeholder={{ color: 'gray.300' }}
								{...field}
								{...props}
								type={type}
								maxLength={maxLength}
							/>
							{rightElement && (
								<InputRightElement>{rightElement}</InputRightElement>
							)}
							{isPassword && (
								<InputRightElement mr={1}>
									<IconButton
										aria-label='Toggle password visibility'
										h='1rem'
										size='sd'
										fontSize='2xl'
										_hover={{ bg: 'none' }}
										_focus={{ border: 'none' }}
										_active={{
											bg: 'none',
											border: 'none'
										}}
										background='none'
										onClick={() => setShowPassword(!showPassword)}
										icon={
											<Icon as={showPassword ? IconStEye : IconStEyeClosed} />
										}
									/>
								</InputRightElement>
							)}
						</InputGroup>
						<FormErrorMessage>{meta.error}</FormErrorMessage>
					</FormControl>
				)
			}}
		</Field>
	)
}
