import { ThemeColors } from '../types'
import sinergiaCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: sinergiaCustomColors.gray[500],
		light: sinergiaCustomColors.gray[300]
	},

	secondary: {
		dark: sinergiaCustomColors.pink[700],
		light: sinergiaCustomColors.pink[400]
	}
}

export default themeColors
