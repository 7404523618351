import i18n from 'i18n/i18n'
import { InferType, object, string } from 'yup'

export const loginInitialSchema = object({
	email: string()
		.required(i18n.t('errors:requiredField'))
		.email(i18n.t('login:validEmail'))
})

export type LoginInitialType = InferType<typeof loginInitialSchema>

export const loginFinalSchema = object({
	email: string()
		.required(i18n.t('errors:requiredField'))
		.email(i18n.t('login:validEmail')),
	password: string()
		.required(i18n.t('errors:requiredField'))
		.min(8, i18n.t('login:minPassword'))
})

export type LoginFinalType = InferType<typeof loginFinalSchema>
