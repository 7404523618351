import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import {
	Box,
	Button,
	Checkbox,
	Heading,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { persistTerms } from 'config'
import Api from 'data/api'

import { DocHeader } from 'modules/common/components'
import { BrowserRecommendation } from 'modules/common/components/chrome-recomendation/index'
import ModalTermsOfUse from 'modules/common/components/terms-of-use'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { useQueryParams } from 'modules/common/hooks'
import { getDomainFromEmail } from 'modules/common/utils/get-domain-from-email'
import { Authentication } from 'modules/login/types'

import { FinalLoginForm } from './components/final-login-form'
import { InitialLoginForm } from './components/initial-login-form'
import { useGetPrivateProvidersMutation } from './hooks/use-get-private-provider-mutation'
import { useGetPublicProvidersMutation } from './hooks/use-get-public-providers-mutation'
import { UserInformationModal } from './user-information'
import {
	loginFinalSchema,
	LoginFinalType,
	loginInitialSchema,
	LoginInitialType
} from './validators'

type Props = {
	authentication: Authentication
	previousURL: string
}

export const Login: React.FC<Props> = ({ authentication, previousURL }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [dirtyToast, setDirtyToast] = useState(false)
	const [isFullLogin, setIsFullLogin] = useState(false)
	const query = useQueryParams()
	const [isLoading, setIsLoading] = useState(false)
	const { tenant } = useTenant()

	const error = query.get('error') as string

	useEffect(() => {
		if (error) {
			toast({
				title: t('login:providerErrorTitle'),
				description: t('login:providerErrorDescription'),
				position: 'bottom',
				status: 'error',
				isClosable: true
			})
		}

		setDirtyToast(true)
	}, [])

	const toast = useToast()

	// TODO: improve it
	const { pathname, search } = document.location
	const current = `${pathname}${search}`
	const ignoredPaths = ['/signin', '/', '/signup', current]
	const isExternalToken = query.get('action') === 'externalToken'
	const accountId = query.get('account_id') as string
	const payload = query.get('payload') as string

	const redirectURL = !ignoredPaths.includes(previousURL)
		? previousURL
		: '/dashboard'
	const logOut = (): void => {
		navigate('/')
	}
	const publicProvidersMutation = useGetPublicProvidersMutation()
	const privateProviderMutation = useGetPrivateProvidersMutation()

	const disclosure = useDisclosure({ onClose: logOut })
	const [termsChecked, setTermsChecked] = useState(false)
	const informationDisclosure = useDisclosure()

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<LoginInitialType>({
		resolver: yupResolver(loginInitialSchema),
		mode: 'onSubmit'
	})

	const {
		register: registerFinal,
		handleSubmit: handleSubmitFinal,
		setValue: setValueFinal,
		formState: { errors: errorsFinal }
	} = useForm<LoginFinalType>({
		resolver: yupResolver(loginFinalSchema),
		defaultValues: {
			email: '',
			password: ''
		},
		mode: 'onSubmit'
	})

	const onSubmitFinalForm = async (values: LoginFinalType): Promise<void> => {
		setIsLoading(true)
		try {
			await authentication.auth({
				email: values.email,
				password: values.password
			})

			const user = await Api.Users.getMe()
			persistTerms.set(user.term_accepted)
			setIsLoading(false)

			if (isExternalToken) {
				navigate(`/import?account_id=${accountId}&payload=${payload}`)
			}

			if (user.term_accepted && !!user.extra) {
				navigate(redirectURL)
				sessionStorage.removeItem('previousURL')
				return
			}

			if (!user.extra && tenant !== 'CIADETALENTOS') {
				informationDisclosure.onOpen()
				return
			}
			disclosure.onOpen()
		} catch (err) {
			setIsLoading(false)
			toast({
				title: t('login:loginError'),
				position: 'bottom',
				status: 'error',
				isClosable: true
			})
		}
	}

	const onSubmitInitialForm = async (values: FieldValues) => {
		await privateProviderMutation
			.mutateAsync(getDomainFromEmail(values.email))
			.catch(async () => {
				await publicProvidersMutation.mutateAsync()
			})
			.finally(() => {
				setValueFinal('email', values.email)
				setIsFullLogin(true)
			})
	}

	const updateUser = async () => {
		try {
			await Api.Users.updateTermAccepted({ term_accepted: true })
			persistTerms.set(true)
			navigate(redirectURL)
		} catch {
			toast({
				title: t('login:term'),
				position: 'top',
				status: 'error',
				isClosable: true
			})
		}
	}

	return (
		<Box className='loginWrap' p={[6, 7]}>
			<DocHeader title={t('buttons:logIn')} />
			<Box display='flex' flexDirection='column' alignItems='center' mb={10}>
				<Heading mb={4} fontSize={['lg', 'xl', '2xl']} fontWeight='extrabold'>
					<Trans i18nKey='login:title' components={[<br />]} />
				</Heading>
				<Text>
					{t('login:registerText')}{' '}
					<Button as={Link} to='/signup' variant='link' colorScheme='blue'>
						{t('buttons:createAccount')}
					</Button>
				</Text>
			</Box>
			{!isFullLogin ? (
				<InitialLoginForm
					onSubmit={handleSubmit(onSubmitInitialForm)}
					register={register}
					errors={errors}
					isLoading={privateProviderMutation.isLoading}
				/>
			) : (
				<FinalLoginForm
					onSubmit={handleSubmitFinal(onSubmitFinalForm)}
					register={registerFinal}
					errors={errorsFinal}
					providers={
						privateProviderMutation.isError
							? publicProvidersMutation.data
							: privateProviderMutation.data
					}
					previousURL={previousURL}
					isLoading={isLoading}
				/>
			)}

			<ModalTermsOfUse
				{...disclosure}
				renderHeader={() => (
					<Box>
						<Text mr={2} fontSize={['md', '2xl']}>
							{t('login:termsOldUsers')}
						</Text>
					</Box>
				)}
				renderFooter={
					<Box w='full'>
						<Checkbox
							ml='1.5rem'
							isChecked={termsChecked}
							onChange={() => setTermsChecked(!termsChecked)}
						>
							<Text fontSize={['sm', 'md']}>
								{t('signup:completeAcceptTerms')}
							</Text>
						</Checkbox>
						<Button
							onClick={updateUser}
							isDisabled={!termsChecked}
							mt={4}
							mb={3}
							color='secondary.text'
							backgroundColor='secondary.dark'
							_hover={{ bg: 'secondary.light' }}
							width='full'
							type='submit'
						>
							{t('buttons:aceptTerms')}
						</Button>
					</Box>
				}
			></ModalTermsOfUse>
			{dirtyToast && <BrowserRecommendation />}
			<UserInformationModal {...informationDisclosure} redirect={redirectURL} />
		</Box>
	)
}
