import React from 'react'

import { Tabs } from '@chakra-ui/react'

import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Question } from 'modules/tools/types/tool-model'

import useCommentsStore from '../../store/use-comments-store'
import { QuestionCard } from './question-card'

export const StructQuestionMobile: React.FC<{
	questionsWithText: Question[]
	setNotIsSideBar: () => void
	intro: string
}> = ({ questionsWithText, setNotIsSideBar, intro }) => {
	const {
		setQuestionOpenedIndex,
		questions,
		markQuestionAsFetched,
		fetchCommentsAndRepliesAndSetQuestionById,
		fetchedQuestionsIds
	} = useCommentsStore()
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)

	let countQuestions = [...questions]
	let pointQuestions = [...questionsWithText]

	if (intro) {
		pointQuestions = [{ id: '0', question: intro }, ...questionsWithText]
		countQuestions = [{ id: '0', comments: [], count: 0 }, ...questions]
	}

	const handleClick = (questionId: string) => () => {
		if (questionId !== '0' && !fetchedQuestionsIds.includes(questionId)) {
			fetchCommentsAndRepliesAndSetQuestionById(selectedPoint!.id, questionId)
			markQuestionAsFetched(questionId)
		}

		setQuestionOpenedIndex(countQuestions.findIndex((q) => q.id === questionId))
		setNotIsSideBar()
	}

	return (
		<Tabs flexDirection='column' overflowY='auto' h='100%'>
			{countQuestions.map((q, index) => (
				<QuestionCard
					click={handleClick(q.id)}
					qId={q.id}
					key={`${q.id}_${index}`}
					text={pointQuestions[index].question}
					index={index + 1}
					questionCommentsCount={
						q.comments.length ? q.comments.length : q.count
					}
					isOpened={false}
					isIntro={q.id === '0'}
					hasIntro={!!intro}
				/>
			))}
		</Tabs>
	)
}
