import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Box, Text } from '@chakra-ui/react'

import Loading from 'modules/common/components/loading'
import { useJourney } from 'modules/journeys/hooks'
import {
	PointInfos,
	QuestionWithComments
} from 'modules/map/store/use-comments-store'
import { Question } from 'modules/tools/types'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { BackButtonMobile } from '../back-button-mobile'
import { useGetQuestionAnalysis } from '../hooks/use-get-question-analysis'
import { EmptyQuestionAnalysis } from './empty-question-analysis'
import { QuestionAnalysisCard } from './question-analysis-card'

type Props = {
	questionOpenedIndex: number
	point: PointInfos
	questions: QuestionWithComments
	analysisQuestionIndex: number
	setIsSideBar?: () => void
}

export const QuestionAnalysis = ({
	point,
	questions,
	analysisQuestionIndex,
	setIsSideBar
}: Props) => {
	const { t } = useTranslation()
	const { id = '' } = useParams()
	const { data: journey } = useJourney(id)
	const { user: currUser } = useMe()
	const isBasicJourney = React.useMemo(() => !journey?.pro, [journey])
	const { data: users = [] } = useJourney(id as string, {
		select: (journey) => journey.users
	})

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('project.update'))

	const questionId: string | undefined =
		questions && questions.id ? questions.id : undefined

	const { data, isLoading } = useGetQuestionAnalysis({
		questionId: questionId || '',
		pointId: point.id
	})

	const hasElements = !!data

	const questionNumber = analysisQuestionIndex + 1

	let question: Question | undefined
	if (point && point.tool && point.tool.questions && questionId) {
		question = point.tool.questions.find(({ id }) => id === questionId)
	}
	let textQuestion = ''
	if (question && question.question) {
		textQuestion = question.question
	}

	if (isLoading) return <Loading />

	return (
		<Box h='100%' w='full'>
			<Box
				w='full'
				backgroundColor='#fff'
				py='4'
				px='4'
				minH='fit-content'
				h='6.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			>
				{setIsSideBar && (
					<BackButtonMobile
						onClickFunc={() => {
							setIsSideBar()
						}}
					/>
				)}
				<Text fontSize='md' fontWeight='600' color='gray.300'>
					{`${t('divergencePoint:question')} ${questionNumber !== undefined ? questionNumber : ''}`}
				</Text>
				<Text
					wordBreak='break-all'
					display='-webkit-box'
					sx={{ WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
					fontSize='sm'
					fontWeight='600'
					color='gray.1'
					mt='2'
					overflow='hidden'
				>
					{textQuestion}
				</Text>
			</Box>
			{!hasElements ? (
				<EmptyQuestionAnalysis
					pointId={point.id}
					questionId={questionId || ''}
					isBasicJourney={isBasicJourney}
					hasPrivileges={hasPrivileges}
				/>
			) : (
				<Box overflowY='scroll' h='100%' pb={40}>
					<QuestionAnalysisCard
						hasPrivileges={hasPrivileges}
						pointId={point.id}
						questionId={questionId || ''}
						userLanguage={currUser?.language}
						questionAnalysisData={data}
						journeyId={id}
					/>
				</Box>
			)}
		</Box>
	)
}
