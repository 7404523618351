import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#CDAE66',
		light: '#8B90A0'
	},

	secondary: {
		dark: '#BF897C',
		light: '#DABFB8'
	}
}

export default themeColors
