import { useInfiniteQuery } from 'react-query'

import { listAssistants } from '../usecases'

type Params = {
	type?: 'public' | 'private' | 'all' | 'journey' | 'lab' | 'private_and_lab'
	simple?: boolean
	query?: string
	order?: 'ASC' | 'DESC'
	sortBy?: string
	labId?: string
	journeyId?: string
	size?: number
	enabled?: boolean
}

export const useAssistants = ({
	type,
	simple,
	query = '',
	order = 'ASC',
	sortBy,
	labId,
	journeyId,
	size,
	enabled
}: Params) => {
	const types = {
		private: 'userAssistants',
		public: 'assistants',
		lab: 'labAssistants',
		all: 'allAssistants',
		journey: 'journeyAssistants',
		private_and_lab: 'private_and_lab'
	}

	const currentType = types[type ?? 'private']

	return useInfiniteQuery(
		[currentType, query, order, sortBy, labId, journeyId],
		({ pageParam = 0 }) => {
			return listAssistants({
				type,
				simple,
				query,
				order,
				sortBy,
				pageParam,
				labId,
				journeyId,
				size
			})
		},
		{
			getNextPageParam: (lastPage) => {
				return lastPage.data.last
					? undefined
					: lastPage.data.pageable.page_number + 1
			},
			keepPreviousData: true,
			cacheTime: 60000,
			enabled
		}
	)
}
