import { NavLink, NavLinkProps, useMatch } from 'react-router-dom'

import { Button, ButtonProps, Tooltip, useTheme } from '@chakra-ui/react'

type Props = NavLinkProps &
	ButtonProps & {
		isActive?: boolean
		isIconMode?: boolean
		buttonName?: string
		xOffset?: number
	}

export function NavBarLink({
	children,
	isActive,
	isIconMode,
	buttonName,
	xOffset = 25,
	...props
}: Props) {
	const match = useMatch(`${props.to}`)
	const theme = useTheme()

	const activeStyle =
		match || isActive
			? { bg: 'navbar_link_active_bg', color: 'navbar_text_color', opacity: 1 }
			: {}

	return (
		<Tooltip
			isDisabled={!isIconMode}
			hasArrow
			label={buttonName}
			bg='navbar_bg'
			placement='right'
			offset={[0, xOffset]}
		>
			<Button
				as={NavLink}
				variant='nav'
				_focusWithin={{ bg: 'navbar_link_focus_bg' }}
				_focus={{
					boxShadow: `0 0 0 3px ${theme.colors.navbar_link_focus_box_shadow}`,
					bg: 'navbar_link_focus_bg',
					color: 'navbar_link_focus_color'
				}}
				color={'navbar_text_color'}
				opacity={0.8}
				_hover={{ bg: 'navbar_link_hover_bg' }}
				_active={{ bg: 'navbar_link_active_bg' }}
				sx={{ ...activeStyle }}
				{...props}
			>
				{children}
			</Button>
		</Tooltip>
	)
}
