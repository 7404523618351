import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
	Button,
	Flex,
	Image,
	Text,
	Textarea,
	useBreakpointValue
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { useStringToColor } from 'modules/common/hooks'
import { validText } from 'modules/map/components/chat/answer-question'
import SendIcon from 'modules/map/components/chat/send.svg'
import useMonitorPointStatusStore from 'modules/map/components/points/monitor-point/store/use-monitor-point-status-store'
import { AnonymAvatar } from 'modules/map/components/view-divergence-point/anonym-mode/anonym-avatar'

import { monitorPointChannel } from '../../../../socket/monitor-point-channel'

interface InputStatusCommentProps {
	monitorPointId: string
	monitorPointStatusId: string
	userName: string
	userId: string
	userUrl?: string
}

export const InputStatusComment = ({
	monitorPointId,
	monitorPointStatusId,
	userName,
	userId,
	userUrl
}: InputStatusCommentProps) => {
	const { t } = useTranslation()
	const [inputComment, setInputComment] = useState('')
	const bg = useStringToColor({ text: userName }, [500])[1]
	const { addComment, isLoading, addCommentState } =
		useMonitorPointStatusStore()
	const { id: journeyId = '' } = useParams()
	const { emit } = useSocket(monitorPointChannel({ projectId: journeyId }))
	const isMobile = useBreakpointValue({ base: true, md: false })

	const isAnonym = false // TODO: to be implemented later

	const handleCommentChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setInputComment(event.target.value)
	}

	const handleCommentSubmit = async () => {
		if (inputComment.trim() !== '') {
			const comment = await addComment(
				monitorPointId,
				monitorPointStatusId,
				inputComment
			)
			addCommentState(comment)

			const socketPayload = {
				id: comment.id,
				monitor_point_id: comment.monitor_point_id,
				monitor_point_status_id: comment.monitor_point_status_id
			}
			emit('ADD', socketPayload)

			setInputComment('')
		}
	}

	return (
		<Flex w='full' mt='4' justifyContent='space-around'>
			<AnonymAvatar
				isAnonym={isAnonym}
				avatarUrl={userUrl}
				name={userName}
				backgroundColor={bg}
			/>
			<Textarea
				flex={1}
				onChange={handleCommentChange}
				border='none'
				outline='none'
				placeholder={t('divergencePoint:write_comment')}
				mr={2}
				ml={2}
				minH='50px'
				maxH='100px'
				backgroundColor='gray.100'
				borderRadius='0.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				p='3'
				value={inputComment}
				fontSize='sm'
				color='gray.400'
				_placeholder={{ color: 'gray.400', fontSize: 'sm' }}
			/>

			<Button
				onClick={handleCommentSubmit}
				backgroundColor={
					validText(inputComment)
						? 'evaluation_answer_comment_card_send_button'
						: 'evaluation_answer_comment_card_send_button_disabled'
				}
				borderRadius={['50%', '18rem']}
				size='md'
				w={['2rem', '6rem']}
				alignItems='center'
				justifyContent='center'
				alignSelf='center'
				_hover={{ backgroundColor: 'none' }}
				isDisabled={!validText(inputComment)}
				isLoading={isLoading[monitorPointStatusId]}
			>
				{!isMobile && (
					<Text color='#fff' fontWeight='600' fontSize='sm'>
						{t('divergencePoint:send')}
					</Text>
				)}
				<Image src={SendIcon} maxW='none' p={0} />
			</Button>
		</Flex>
	)
}
