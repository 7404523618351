import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	Stack,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionIcon,
	AccordionPanel
} from '@chakra-ui/react'

import { ConvergenceQuestion } from '../../types'
import { BeliefAllocationMy } from '../belief-allocation/belief-allocation'
import { BeliefOptionResult } from '../belief-option-result'
import { CardQuestion } from '../card-question'

type Props = {
	question: ConvergenceQuestion
	myAnswer: ConvergenceQuestion
}

export const BeliefResult: React.FC<Props> = ({ question, myAnswer }) => {
	const { t } = useTranslation()
	const icc = Math.round((question.convergence_consensus_index || 0) * 100)

	const sortedOptions = question.options.sort((a, b) => {
		if (a.average && b.average) {
			return a.average > b.average ? -1 : 1
		}
		return 0
	})

	return (
		<CardQuestion>
			<Stack direction='row'>
				<Text fontSize='sm' fontWeight='semibold'>
					{question.text}
				</Text>
			</Stack>

			<Stack mt={4} spacing={4}>
				{sortedOptions.map((option) => (
					<BeliefOptionResult key={option.id} option={option} />
				))}
			</Stack>
			<Stack
				my={4}
				spacing={4}
				justify='space-between'
				fontSize='sm'
				direction='row'
			>
				<Text fontWeight='bold'>
					{t('map:points.convergence.iccInfo')} {icc}%
				</Text>
			</Stack>

			{myAnswer && (
				<Accordion allowToggle>
					<AccordionItem>
						<h2>
							<AccordionButton px={0}>
								<AccordionIcon />
								<Box flex='1' textAlign='left'>
									{t('map:points.convergence.viewYourAnswer')}
								</Box>
							</AccordionButton>
						</h2>
						<AccordionPanel px={0}>
							<BeliefAllocationMy question={myAnswer} />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			)}
		</CardQuestion>
	)
}
