import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	UseDisclosureReturn,
	Text,
	Button,
	useBreakpointValue,
	ModalHeader,
	HStack
} from '@chakra-ui/react'

type Props = {
	disclosure: UseDisclosureReturn
	onConfirm: () => void | null | undefined
}

export const SendEmptyConfirmation = (props: Props) => {
	const { t } = useTranslation()
	const { disclosure, onConfirm } = props
	const { isOpen, onClose } = disclosure
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					{/* <ModalCloseButton mt={isMobile ? 1 : 5} mr={isMobile ? 0 : 4} /> */}
					<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
						<Text
							fontSize={['18', 'xl']}
							fontWeight='bold'
							wordBreak='break-word'
						>
							{t('map:points.convergence.aswerConfirmation.title')}
						</Text>
					</ModalHeader>

					<ModalBody mt={2} px={isMobile ? 4 : 8} fontSize={['sm', 'md']}>
						<Text>{t('map:points.convergence.aswerConfirmation.text')}</Text>
						<Text fontWeight='semibold'>
							{t('map:points.convergence.aswerConfirmation.description')}
						</Text>
					</ModalBody>

					<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
						<HStack>
							<Button
								fontWeight='semibold'
								color='gray.400'
								bgColor='gray.180'
								fontSize={['xs', 'md']}
								onClick={onClose}
							>
								{t('buttons:review')}
							</Button>
							<Button
								fontSize={['xs', 'md']}
								colorScheme='custom_scheme_color_button'
								onClick={onConfirm}
							>
								{t('buttons:confirmDecision')}
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
