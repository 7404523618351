import { v4 as uuid } from 'uuid'

import { Point, PointMap } from '../types'
import { makeHexagon } from './hexagon-utils'

export interface GridSize {
	rows: number
	cols: number
}

export const gridSize = {
	rows: 16,
	cols: 25
}

export interface GridOptions {
	size?: GridSize
	hideBlank?: boolean
}

export function buildGrid(pointMap: PointMap, options?: GridOptions) {
	const { cols, rows } = options?.size || gridSize

	const fillEmptyColumns = (rowIndex: number) => {
		if (options?.hideBlank) return

		let colIndex = 0

		while (colIndex <= cols) {
			const col = pointMap[rowIndex][colIndex]
			const colExists = !!col
			if (!colExists) {
				pointMap[rowIndex][colIndex] = makeHexagon(rowIndex, colIndex)
			}
			colIndex++
		}
	}

	const makeRow = (rowIndex: number) => {
		pointMap[rowIndex] = { id: uuid() }
		fillEmptyColumns(rowIndex)
	}

	let rowIndex = -1

	while (rowIndex <= rows) {
		const rowExists = !!pointMap[rowIndex]

		if (rowExists) {
			fillEmptyColumns(rowIndex)
		} else {
			makeRow(rowIndex)
		}

		rowIndex++
	}
}

export function pointsToGridLayout(points: Point[]) {
	return points.reduce((map, point) => {
		const {
			position: { row, col }
		} = point

		if (!map[row]) {
			map[row] = { id: uuid() }
		}

		map[row][col] = point

		return map
	}, {} as PointMap)
}

export function isAValidPosition(row: number, col: number) {
	const isAValidRow = row >= 0 && row <= gridSize.rows
	const isAValidCol = col >= 0 && col <= gridSize.cols
	return isAValidRow && isAValidCol
}
