import defaultCustomColors from '../default/colors'

const sebraepbCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: defaultCustomColors.yellow[500],
		700: defaultCustomColors.yellow[500]
	},

	secondary_color_scheme_button: {
		700: '#0055BF'
	},

	switch_scheme_color_checked: {
		500: '#0055BF'
	},

	radio_color: {
		500: '#0055BF'
	}
}

export default sebraepbCustomColors
