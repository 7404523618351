export function placeHexagon(row: number, col: number) {
	return isOdd(row) ? placeEven(row, col) : placeOdd(row, col)
}

function placeEven(row: number, col: number) {
	if (row < 1) {
		return `translate(${93 * col}px, ${80 * row}px)`
	}
	return `translate(${93 * col}px, ${82 + 80 * (row - 1)}px)`
}

function placeOdd(row: number, col: number) {
	return `translate(${46 + 93 * col}px, ${1.3 + 80 * row}px)`
}

export function isOdd(n: number) {
	return !(n % 2 === 0)
}
