import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Stack, Text } from '@chakra-ui/react'

import { ConvergenceQuestion } from '../../types'
import { BeliefOption } from '../belief-option'
import { CardQuestion } from '../card-question'

type Props = {
	question: ConvergenceQuestion
	index: number
}

type FormOption = {
	belief: number
	option_id: string
}

export type FormQuestion = {
	question_id: string
	environment: number
	options: FormOption[]
}

export type ConvergencePointAnswer = {
	answers: FormQuestion[]
}

export const BeliefQuestion: React.FC<Props> = ({ question, index }) => {
	const [ammount, setAmmount] = useState(100)
	const { watch } = useFormContext<ConvergencePointAnswer>()
	const formvalues = watch()

	React.useEffect(() => {
		const beliefUsage = formvalues.answers[index].options.reduce(
			(total, { belief }) => (total += Number(belief)),
			0
		)
		!isNaN(beliefUsage) && setAmmount(100 - beliefUsage)
	}, [formvalues, index])

	return (
		<CardQuestion>
			<Stack direction='row' justify='space-between'>
				<Text fontSize='sm' fontWeight='semibold'>
					{question.text}
				</Text>
				{/* <Box>
					<BeliefAmount amount={ammount} />
				</Box> */}
			</Stack>

			<Stack mt={4} spacing={4}>
				{question.options.map((option, optionIndex) => (
					<BeliefOption
						key={option.id}
						option={option}
						name={`answers.${index}.options.${optionIndex}.belief`}
						availableTokens={ammount}
					/>
				))}
			</Stack>
		</CardQuestion>
	)
}
