import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BiPencil } from 'react-icons/bi'

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Icon,
	ModalBody,
	ModalFooter,
	Stack,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'

import { useConvergenceStore } from '../../../store'
import { ConvergenceQuestion } from '../../../types'
import { BeliefAllocationMy } from '../../belief-allocation/belief-allocation'
import { SubmissionMessage } from '../../submission-message'

export const Answer = ({
	myAnswer,
	setIsEditingAnswer
}: {
	myAnswer: ConvergenceQuestion[]
	setIsEditingAnswer: Dispatch<SetStateAction<boolean>>
}) => {
	const { t } = useTranslation()
	const { date, hour } = useConvergenceStore()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	return (
		<>
			<ModalBody p={0}>
				<SubmissionMessage timeInfo={{ date, hour }} />
				<Box borderBottomRadius='xl' bg='purple.50' p={2}>
					<Accordion w='full' defaultIndex={[0]} allowMultiple>
						<AccordionItem>
							<AccordionButton>
								<AccordionIcon />
								<Box flex='1' textAlign='left'>
									{t('map:points.convergence.viewYourAnswer')}
								</Box>
								<Button
									fontSize={['xs', 'md']}
									onClick={() => setIsEditingAnswer(true)}
									colorScheme='custom_scheme_color_button'
									leftIcon={isMobile ? undefined : <Icon as={BiPencil} />}
								>
									{t('buttons:editAnswer')}
								</Button>
							</AccordionButton>
							<AccordionPanel pb={4}>
								<Stack spacing={4}>
									{myAnswer.map((question) => (
										<BeliefAllocationMy
											key={question.question_id}
											question={question}
										/>
									))}
								</Stack>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</Box>
			</ModalBody>

			<ModalFooter justifyContent='start' display='flex' alignItems='top'>
				<Box display='flex' alignItems='top'>
					<Icon
						mr={1}
						w={[4, 4]}
						h={[4, 4]}
						color='gray.400'
						as={AiOutlineQuestionCircle}
					></Icon>
				</Box>

				<Text fontSize='xs' color='gray.400'>
					{t('map:points.convergence.editExplanation')}
				</Text>
			</ModalFooter>
		</>
	)
}
