import { hasPermissionInList } from 'modules/common/utils/has-permission-in-list'

export function filterOptionsBasedOnRole(
	items: any[],
	permission: string,
	shrink: boolean = false,
	userType: string,
	tenant: string
) {
	return items.filter((item) => {
		const listPath = item.path.split('/')
		if (shrink) {
			listPath.pop()
		}
		const lastItem = listPath[listPath.length - 1]
		return hasPermissionInList(permission, lastItem, userType, tenant)
	})
}
