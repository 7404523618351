import { ReactElement } from 'react'

import {
	Text,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Icon,
	useDisclosure,
	FlexProps
} from '@chakra-ui/react'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { IconStCoin } from 'modules/common/components/icons'

type Props = {
	price: number
	onClick: () => void
	isDisabled?: boolean
	text: string
	icon: ReactElement
	userCredits: number
	buttonProps?: FlexProps
	isLoading: boolean
}

export const CreditsBtn = ({
	isDisabled,
	userCredits,
	text,
	onClick,
	price,
	icon,
	buttonProps,
	isLoading
}: Props) => {
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()

	const handleCreate = () => {
		if (userCredits >= price) {
			onClick()
			return
		}
		emptyCreditsDisclosure.onOpen()
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	return (
		<Flex
			border='1px'
			borderRadius='lg'
			borderColor='primary_button_border'
			bg={'primary_button_bg'}
			opacity={isDisabled ? '50%' : '100%'}
			justifyContent='space-evenly'
			_hover={{ bg: 'primary_button_bg', color: 'primary_button_bg' }}
			role='group'
			{...buttonProps}
		>
			<Button
				leftIcon={icon}
				w='full'
				p={2.5}
				color='white'
				fontSize='xs'
				fontWeight='bold'
				bgColor='transparent'
				cursor='pointer'
				textAlign='center'
				_groupHover={{ bg: 'none' }}
				onClick={handleCreate}
				isDisabled={isDisabled}
				isLoading={isLoading}
			>
				{text}
			</Button>
			<Flex>
				<Divider orientation='vertical' />
			</Flex>
			<Box alignSelf='center'>
				<HStack px={2}>
					<Icon w={4} h={4} as={IconStCoin} />
					<Text fontWeight='bold' fontSize='sm' color='white'>
						{price}
					</Text>
				</HStack>
			</Box>
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={userCredits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Flex>
	)
}
