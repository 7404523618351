import { fetch } from 'config'

import { ConvergencePoint } from '../types'

export async function closeConvergencePoint(id: string) {
	const response = await fetch<ConvergencePoint>({
		url: `/projects/v1/convergence-point/${id}/close`,
		method: 'PUT'
	})

	return response.data
}
