import { fetch } from 'config'

import { ConvergenceQuestion } from 'modules/map/modules/convergence-point/types'

import { Point, Position } from '../../map-canvas/types'

type Params = {
	mapId: string
	closing_date: string
	position: Position
	questions: ConvergenceQuestion[]
}

export async function createConvergencePoint(params: Params) {
	const { mapId, ...data } = params

	const response = await fetch<Point>({
		url: `/projects/v1/map/${mapId}/convergence-point`,
		method: 'POST',
		data
	})

	return response.data
}
