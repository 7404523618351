import { fetch } from 'config'

import { Point } from 'modules/map/modules/map-canvas/types'

import { UpdateParams } from '../types'

export async function updateConversationPoint(params: UpdateParams) {
	const { id, ...data } = params

	const response = await fetch<Point>({
		url: `/projects/v1/checkpoint/${id}`,
		method: 'PUT',
		data
	})

	return response.data
}
