import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

const Journeys = lazy(() => import('modules/dashboard/pages/journeys'))

const DashboardRoutes = () => {
	return (
		<ApmRoutes>
			<Route
				path='journeys'
				element={
					<Loading>
						<Journeys />
					</Loading>
				}
			/>
		</ApmRoutes>
	)
}

export default DashboardRoutes
