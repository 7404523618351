import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from '@chakra-ui/react'

import { questionKeys } from 'modules/map/query'
import { QuestionAnalysis } from 'modules/map/types'
import { deleteQuestionAnalysis } from 'modules/map/usecases/delete-question-analysis'

export const useRemoveQuestionAnalysisMutation = (questionId: string) => {
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const toast = useToast({
		position: 'bottom',
		isClosable: true,
		variant: 'solid'
	})

	const removeFromList = (analisysId: string) => {
		const [Query] = queryClient
			.getQueryCache()
			.findAll(questionKeys.questionSummary(questionId))
		if (!Query) return

		Query.setData((currentAnalysis: QuestionAnalysis) => {
			if (currentAnalysis && currentAnalysis.id === analisysId) {
				return undefined
			}
			return currentAnalysis
		})
	}

	const mutation = useMutation(deleteQuestionAnalysis, {
		onSuccess: (_, { analysisId }) => {
			removeFromList(analysisId)
			toast({ title: t('summary:card.delete'), status: 'success' })
		},
		onError: () => {
			toast({ title: 'try again', status: 'error' })
		}
	})

	return mutation
}
