import React from 'react'
import {
	useFormContext,
	useController,
	useWatch,
	FieldValues
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Text,
	Flex,
	Button,
	RadioGroup,
	Radio,
	HStack,
	Image,
	ModalHeader,
	Icon,
	VStack,
	useToast
} from '@chakra-ui/react'

import { ArrowBackIcon } from '@chakra-ui/icons'

import {
	CommonInputField,
	CommonInputMaskField,
	CountriesSelectField
} from 'modules/labs/components/plans/subscription/custom-form-fields'
import { BillingAccountRequest } from 'modules/labs/types'
import {
	createBillingAccount,
	updateBillingAccount
} from 'modules/labs/usecases'

import Paypal from '../../../assets/img/paypal.svg'
import Pix from '../../../assets/img/pix.svg'
import { IconStCoin } from '../../icons'
import { CreditsDetailsContainer } from './credits-details-container'

// import { CommonInputField, CountriesSelectField } from './custom-form-fields'
interface FormProps {
	onNext?: () => void
	onBack: () => void
	userHasAccount: boolean
	formType: 'pix' | 'paypal'
}

const corporate = 'BUSINESS'
const personal = 'PERSONAL'

export const CreditsForm: React.FC<FormProps> = ({
	userHasAccount,
	formType,
	onNext,
	onBack
}) => {
	const { t } = useTranslation()

	const { getValues, ...methods } = useFormContext()

	const { creditsAmount, creditsCost } = getValues()

	const { isValid, dirtyFields, isSubmitting } = methods.formState

	// make a variable to check if the form is dirty. consider it dirty if the fields name, fiscal_id, email, address, account_type are dirty
	const isFormDirty = Object.keys(dirtyFields).some((field) =>
		['name', 'fiscal_id', 'email', 'address', 'account_type'].includes(field)
	)

	const toast = useToast()

	const handleSubmit = async (values: FieldValues) => {
		const { name, fiscal_id, email, address, account_type } = values

		const billingPayload: BillingAccountRequest = {
			name,
			email,
			account_type: account_type || personal,
			fiscal_id: fiscal_id || null,
			address: {
				country: address?.country,
				postal_code: address.postal_code,
				city: address?.city || '',
				state: address?.state || '',
				street: address?.street || '',
				number: address?.number || '',
				complement: address?.complement || ''
			}
		}

		try {
			if (userHasAccount && isFormDirty) {
				await updateBillingAccount(billingPayload)
			} else if (!userHasAccount) {
				await createBillingAccount(billingPayload)
			}
			// onSubscribe()
		} catch (error) {
			console.error(error)
		}

		// TODO: make connection to paypal
		// console.log(values)
		if (formType === 'paypal') {
			toast({
				title: 'Formulário enviado com sucesso!',
				description: 'Feature de conexão com o paypal a ser implementada',
				status: 'success'
			})
		}
		if (formType === 'pix' && onNext) {
			onNext()
		}
	}

	function addZerosRightIfInteger(creditsCostArg: number) {
		if (creditsCostArg % 1 === 0) {
			return creditsCostArg + ',00'
		}
		return creditsCostArg
	}

	return (
		<>
			<ModalHeader mb={6} p={0}>
				<VStack justify={'space-between'} align={'flex-start'}>
					<Button
						onClick={onBack}
						variant='link'
						colorScheme='blue'
						fontWeight='semibold'
						leftIcon={<Icon as={ArrowBackIcon} />}
					>
						{t('buttons:back')}
					</Button>
					<Text fontSize='xl' fontWeight='semibold' mb={6} mt={0}>
						{t('credits:buyCredits')}
					</Text>
					<Text fontSize='sm' fontWeight='medium' color={'gray.400'}>
						{t('credits:explainOneBuy')}
					</Text>
				</VStack>
			</ModalHeader>

			<CreditsDetailsContainer
				gridTemplateColumns={{
					base: '1fr',
					md: '1fr 1fr'
				}}
			>
				<HStack justify={'center'} align={'center'}>
					<Icon w={10} h={10} as={IconStCoin} />
					<Text fontSize={'md'} fontWeight={'semibold'}>
						{creditsAmount} {t('credits:credits')}
					</Text>
				</HStack>

				<VStack justify={'center'} align={'center'}>
					<Text fontSize={'sm'} fontWeight='medium' color={'gray.400'}>
						{t('credits:totalValue')}
					</Text>
					<Text fontSize={'md'} fontWeight={'bold'}>
						R$ {addZerosRightIfInteger(creditsCost)}
					</Text>
				</VStack>
			</CreditsDetailsContainer>

			<form onSubmit={methods.handleSubmit(handleSubmit)}>
				<Box
					borderWidth={1}
					borderRadius='xl'
					borderColor='blue.50'
					p={4}
					mt={4}
				>
					<Text fontSize='sm' fontWeight='semibold'>
						{t('credits:paymentMethod')}
					</Text>
					{formType === 'pix' && (
						<Image src={Pix} alt='Pix icon' htmlWidth={76} mt={3} mb={4} />
					)}
					{formType === 'paypal' && (
						<Image
							src={Paypal}
							alt='PayPal icon'
							htmlWidth={76}
							mt={3}
							mb={4}
						/>
					)}
					<Text fontSize='sm' fontWeight='semibold'>
						{t('lab:my.plans.subscription.details')}
					</Text>
					<AccountType />
					<Box w='full'>
						<SubscriberName />
						<Flex direction={['column', 'row']} gridGap={[4, 12]} mt={4}>
							<FiscalIDInput />
							<CommonInputField
								label={t('lab:my.plans.subscription.form.email.field')}
								placeholder={t(
									'lab:my.plans.subscription.form.email.placeholder'
								)}
								fieldName='email'
							/>
						</Flex>
						<Flex direction={['column', 'row']} gridGap={[4, 12]} mt={4}>
							<CountriesSelectField />
							<CommonInputField
								label={t('lab:my.plans.subscription.form.zip.field')}
								placeholder={t(
									'lab:my.plans.subscription.form.zip.placeholder'
								)}
								fieldName='address.postal_code'
							/>
						</Flex>
					</Box>
					<Box w='full' mt={5}>
						<Text fontSize='sm' fontWeight='semibold' mb={2}>
							{t('lab:my.plans.subscription.checkout.title')}
						</Text>
						<Text fontSize='xs' fontWeight='medium' color='gray.400' mb={4}>
							{t('lab:my.plans.subscription.checkout.description')}
						</Text>
						<Button
							w='full'
							colorScheme='custom_scheme_color_button'
							fontSize='xs'
							fontWeight='bold'
							type='submit'
							isDisabled={!isValid || isSubmitting}
						>
							{formType === 'paypal' && t('credits:paypal:proceedWithPaypal')}
							{formType === 'pix' && t('credits:pix:proceedWithPix')}
						</Button>
					</Box>
				</Box>
			</form>
		</>
	)
}

const AccountType: React.FC = () => {
	const { t } = useTranslation()
	const { control, setValue, trigger } = useFormContext()
	const { field } = useController({
		control,
		name: 'account_type',
		defaultValue: personal
	})

	const handleChange = (name: string, value: string) => {
		setValue(name, value)
	}

	return (
		<RadioGroup
			my={4}
			fontSize='sm'
			fontWeight='semibold'
			onChange={async (value) => {
				handleChange('account_type', value)
				field.onChange(value)
				await trigger(['fiscal_id', 'account_type'])
			}}
			defaultValue={field.value}
		>
			<Flex direction='row' gridGap={4}>
				<Radio value={personal}>{t('credits:physicalPerson')}</Radio>
				<Radio value={corporate}>{t('credits:company')}</Radio>
			</Flex>
		</RadioGroup>
	)
}

const FiscalIDInput: React.FC = () => {
	const { t } = useTranslation()

	const accountType = useWatch({
		name: 'account_type'
	})

	const fiscalIDLabel =
		accountType === corporate ? t('credits:cnpj') : t('credits:cpf')

	const mask =
		accountType === corporate ? '99.999.999/9999-99' : '999.999.999-99'

	return (
		<CommonInputMaskField
			mask={mask}
			label={fiscalIDLabel}
			placeholder={fiscalIDLabel}
			fieldName='fiscal_id'
		/>
	)
}

const SubscriberName: React.FC = () => {
	const { t } = useTranslation()

	const accountType = useWatch({
		name: 'account_type'
	})

	const nameLabel =
		accountType === corporate
			? t('lab:my.plans.subscription.form.corporate.field')
			: t('lab:my.plans.subscription.form.name.field')

	return (
		<Box mt={4}>
			<CommonInputField
				label={nameLabel}
				placeholder={t('lab:my.plans.subscription.form.name.placeholder')}
				fieldName='name'
			/>
		</Box>
	)
}
