import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar, AvatarProps, Flex, Text } from '@chakra-ui/react'

import { useStringToColor } from 'modules/common/hooks'
import { Author } from 'modules/tools/types/tool-model'

interface Props extends AvatarProps {
	user: Author
	iconOnly?: boolean
}

const UserAvatar = React.memo(({ user, iconOnly, ...props }: Props) => {
	const [, bgColor] = useStringToColor({ text: user.username })
	const iconProps = { src: user.avatar_url, name: user.username, bg: bgColor }
	const profilePath = `/dashboard/profile/${user.username}`

	if (!user.username) return null

	if (iconOnly) {
		return <AvatarIcon {...iconProps} {...props} />
	}

	return (
		<Flex as={Link} to={profilePath} align='center' color='gray.400'>
			<AvatarIcon {...iconProps} {...props} />
			<Text
				_hover={{ textDecoration: 'underline' }}
				as='span'
				ml='1'
				fontWeight='medium'
				fontSize='xs'
				textTransform='lowercase'
			>
				@{user.username}
			</Text>
		</Flex>
	)
})

const AvatarIcon = (props: AvatarProps) => (
	<Avatar size='2xs' color='white' {...props} />
)

export default UserAvatar
