import { fetch } from 'config'

type Params = {
	pointId: string
	isUserEvaluation: boolean
}

export async function manageUserEvaluationMode({
	isUserEvaluation,
	pointId
}: Params) {
	const action = isUserEvaluation ? 'enable' : 'disable'

	const response = await fetch({
		url: `/projects/v1/essay-point/${pointId}/user-evaluation-mode/${action}`,
		method: 'PATCH'
	})
	return response.data
}
