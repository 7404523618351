import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconMonitorPoint: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 36 35' {...props}>
		<path
			d='M16.0288 1.3494C16.9392 0.82379 18.0608 0.82379 18.9712 1.3494L31.1842 8.4006C32.0946 8.92621 32.6554 9.89758 32.6554 10.9488V25.0512C32.6554 26.1024 32.0946 27.0738 31.1842 27.5994L18.9712 34.6506C18.0608 35.1762 16.9392 35.1762 16.0288 34.6506L3.81576 27.5994C2.90538 27.0738 2.34456 26.1024 2.34456 25.0512V10.9488C2.34456 9.89758 2.90538 8.92621 3.81576 8.4006L16.0288 1.3494Z'
			fill='currentColor'
		/>
		<path
			d='M15.9997 21.172L25.1917 11.979L26.6067 13.393L15.9997 24L9.63574 17.636L11.0497 16.222L15.9997 21.172Z'
			fill='white'
		/>
	</IconBase>
)
