import defaultCustomColors from '../default/colors'

const magaluCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: defaultCustomColors.yellow[500],
		700: defaultCustomColors.yellow[500]
	},

	secondary_color_scheme_button: {
		500: defaultCustomColors.pink[500]
	},

	switch_scheme_color_checked: {
		700: '#007AFF',
		500: '#007AFF'
	},

	radio_color: {
		500: '#007AFF'
	}
}

export default magaluCustomColors
