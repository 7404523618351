import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { persistTerms } from 'config'
import { sessionManager } from 'config/session-manager'

import { useIsAuthenticated } from 'modules/common/hooks'

type Props = {
	children: ReactNode
}

export const PrivateRoute = ({ children }: Props) => {
	const isAuthenticated = useIsAuthenticated()
	const hadAcceptTerms = persistTerms.isAccepted()

	if (isAuthenticated && hadAcceptTerms) {
		return <>{children}</>
	}

	sessionManager.endSession()
	return <Navigate to='/signin' />
}
