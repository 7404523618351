import { format, formatDistanceToNow } from 'date-fns'
import i18n from 'i18n/i18n'
import { v4 as uuid } from 'uuid'

import { getLocale } from 'modules/common/utils/change-language'
import { formatToLocalDateTime } from 'modules/common/utils/format-to-local-date-time'
import { Color } from 'modules/common/utils/string-to-color'

import { ConvergencePoint } from '../../convergence-point/types'
import { ConversationPoint } from '../../conversation-point/types'
import { Point } from '../types'

export function makeHexagon(
	row: number,
	col: number,
	options: Partial<Point & ConversationPoint> = {}
): Point {
	const pointBase = {
		id: uuid(),
		point_type: 'EMPTY',
		position: {
			row,
			col
		},
		...options
	} as Point

	switch (options.point_type) {
		case 'CONVERSATION':
			return {
				...pointBase,
				color: handleHexagonColor(pointBase) as Color,
				title: handleHexagonTitle(pointBase)
			}

		case 'CONVERGENCE':
			return {
				...pointBase,
				color: handleHexagonColor(pointBase) as Color,
				title: handleHexagonTitle(pointBase)
			}

		default:
			return pointBase
	}
}

export const EvenNeighbors = [
	[-1, 0],
	[-1, +1],

	[0, 1],
	[0, -1],

	[1, 0],
	[1, 1]
]

export const OddNeighbors = [
	[-1, -1],
	[-1, 0],

	[0, 1],
	[0, -1],

	[1, -1],
	[1, 0]
]

export const handleHexagonColor = (point: Point) => {
	if (!point.visible) return 'gray.300'

	switch (point.point_type) {
		case 'CONVERSATION':
			return 'gray' as Color
		case 'CONVERGENCE':
			return 'purple'
		case 'DIVERGENCE':
			return point.color.toLowerCase()
		case 'NOTICE':
			return 'red'
		case 'ESSAY':
			return 'orange'
		case 'CHALLENGING_SITUATION':
			return 'orange'
		case 'SUBJECTIVE_QUESTION':
			return 'orange'
		case 'MONITOR':
			return 'green'
		default:
			return point.color as Color
	}
}

export const handleHexagonTitle = (
	point: Partial<Point & ConversationPoint>
) => {
	switch (point.point_type) {
		case 'CONVERSATION': {
			const date = point.opening_date
				? new Date(formatToLocalDateTime(point.opening_date))
				: new Date()

			return format(date, 'dd/MM/yyyy HH:mm')
		}

		case 'CONVERGENCE': {
			const { closing_date, status } = point as ConvergencePoint
			const date = new Date(formatToLocalDateTime(closing_date))
			const isClosed = status === 'CLOSED'
			if (isClosed) return i18n.t('map:points.convergence.completed')

			return formatDistanceToNow(date, { locale: getLocale() })
		}

		case 'NOTICE': {
			const date = point.created_at ? new Date(point.created_at) : new Date()
			const opDate = date.toLocaleDateString(i18n.language)
			return `${i18n.t('map:points:notice:point')} ${opDate}`
		}

		default: {
			return point.title || point.description
		}
	}
}

export const handleHexagonAlt = (point: Partial<Point & ConversationPoint>) => {
	if (point.point_type === 'NOTICE') return ''
	return point.title || point.description
}
