import { fetch } from 'config'

import { Journey } from 'modules/journeys/types'

export const getEnroll = async ({
	accountId,
	payload
}: {
	accountId: string
	payload: string
}) => {
	return await fetch<Journey>({
		url: 'projects/v1/project/user/me/enroll',
		method: 'GET',
		params: { accountId, payload }
	}).then(({ data }) => data)
}
