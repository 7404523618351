import { ThemeColors } from '../types'
import defaultCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: defaultCustomColors.blue[500],
		light: defaultCustomColors.blue[600]
	},
	secondary: {
		dark: defaultCustomColors.pink[500],
		light: defaultCustomColors.pink[900]
	}
}

export default themeColors
