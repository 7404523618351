import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { ns, resources } from './locales'

i18n.use(LanguageDetector)

const fallbackLng = navigator.language.split('-')[0]

if (initReactI18next) {
	i18n.use(initReactI18next).init({
		detection: {
			order: ['localStorage', 'navigator'],
			lookupLocalStorage: 'strateegiaLang',
			caches: ['localStorage']
		},
		resources,
		ns,
		fallbackLng,
		interpolation: {
			escapeValue: false
		},
		nsSeparator: ':'
	})
}

export default i18n
