import i18n from "i18n/i18n";
import { object, string} from "yup";

export const ResponsAanswerValidation = () => 
    object({
        title: string()
        .required(i18n.t('errors:requiredField'))
        .min(3, i18n.t('errors:fields.title.min', { min: 3 }))
        .max(100, i18n.t('errors:fields.title.max', { max: 100 })),
        response: string()
        .required(i18n.t('errors:requiredField'))
        .min(3, i18n.t('errors:fields.response.min', { min: 3 }))
        .max(3000, i18n.t('errors:fields.response.max', { max: 3000 })),
    })

    

