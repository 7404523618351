import { useQuery } from 'react-query'

import { fetch } from 'config'

export type StatusResponse = {
	id: string
	status: Status
}

export type Status = 'PENDING' | 'REJECTED' | 'FAILED' | 'PAID'

export const getPaymentStatus = (id: string) => {
	return fetch<StatusResponse>({
		url: `billing/v1/purchase/credits/pix/${id}/status`,
		method: 'GET'
	}).then(({ data }) => data)
}

export const useGetPaymentStatus = (id: string) => {
	const { data, isLoading } = useQuery({
		queryKey: ['pixId', id],
		queryFn: () => getPaymentStatus(id),
		refetchInterval: 10000
	})

	return {
		paymentStatus: data?.status,
		isLoading
	}
}
