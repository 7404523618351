import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChat, BiComment, BiLike, BiSolidLike } from 'react-icons/bi'

import {
	Badge,
	Box,
	Button,
	Collapse,
	Flex,
	Icon,
	Text
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { AnonymAvatar } from 'modules/map/components//view-divergence-point/anonym-mode/anonym-avatar'
import { MonitorPointStatusResponse } from 'modules/map/components/points/monitor-point/types/monitor-point'

import { useStatusCardLogic } from '../../hooks/use-status-card-logic'
import { InputStatusComment } from '../input-status-comment'
import { StatusCommentCard } from '../status-comment-card'

interface StatusCardProps {
	status: MonitorPointStatusResponse
	userId: string
	userName: string
	userLanguage: string
	monitorPointId: string
	isQualitative: boolean
	quantitativeGoal: number | undefined
}

export const StatusCard = ({
	status,
	userId,
	userName,
	userLanguage,
	monitorPointId,
	isQualitative,
	quantitativeGoal
}: StatusCardProps) => {
	const {
		state: { isCommentSectionOpen, isLoading },
		actions: { setIsCommentSectionOpen, handleDelete, handleLike },
		data: { qualitativeStatus },
		utils: {
			transparentBorderColor,
			useString,
			qualitativeBadgeBgStatusColor,
			hasLiked
		}
	} = useStatusCardLogic(status, userId)
	const [isDateVisible, setIsDateVisible] = useState(true)
	const { t } = useTranslation()

	const isAnonym = false // TODO: to be implemented later
	const canDelete = userId === status.author.id

	return (
		<Flex
			w='full'
			mt={2}
			px='1.125rem'
			pt='1.125rem'
			flexDirection='column'
			borderRadius={8}
			border='1px solid '
			borderColor={transparentBorderColor}
			backgroundColor='rgba(252, 252, 255, 0.8)'
			mx={[0, 2]}
			maxW={['full', '96%']}
			boxShadow='0px 1px 3px 0px rgba(0, 0, 0, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)'
		>
			<Flex w='full' justifyContent='space-between'>
				<Flex alignItems='center'>
					<AnonymAvatar
						isAnonym={isAnonym}
						avatarUrl={status.author.avatar_url}
						name={status.author.name}
						backgroundColor={useString}
					/>
					<Text ml={2} color='gray.900' fontWeight='600' fontSize='sm'>
						{isAnonym ? 'anônimo' : status.author.name}
					</Text>
				</Flex>
				<Flex alignItems='center' gap={2}>
					{isDateVisible && (
						<Text color='#8B90A0' fontWeight='500' fontSize='xs'>
							{handleDateFormat(
								new Date(status.created_at),
								userLanguage === 'PT_BR' ? 'ptBR' : ''
							)}
						</Text>
					)}
					{canDelete && (
						<DeleteConfirmButton
							isLoading={isLoading[status.id]}
							onConfirm={handleDelete}
							iconButtonProps={{ fontSize: 'xl', color: 'pink.500' }}
							setDataVisible={() => setIsDateVisible(true)}
							setNotDataVisible={() => setIsDateVisible(false)}
						/>
					)}
				</Flex>
			</Flex>
			{isQualitative ? (
				<Badge
					w='fit-content'
					px={1}
					my={2}
					mt={3}
					textTransform='lowercase'
					fontSize='sm'
					backgroundColor={qualitativeBadgeBgStatusColor}
					color='white'
					borderRadius={3}
					boxShadow='0px 1px 3px 0px rgba(0, 0, 0, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)'
				>
					{qualitativeStatus}
				</Badge>
			) : (
				<Text
					my={2}
					ml={1}
					fontSize='lg'
					color='gray.900'
					fontWeight='semibold'
					w='100%'
				>
					{Number.isInteger(Number(status.value))
						? Math.floor(Number(status.value))
						: status.value}
					<Text as='span' fontSize='sm' color='gray.400'>
						/{quantitativeGoal}
					</Text>
				</Text>
			)}
			<Text ml={!isQualitative ? 1 : 0} fontSize='sm' color='gray.400'>
				{status.message}
			</Text>
			<Flex
				mt={4}
				alignItems='center'
				w='full'
				h='3rem'
				justifyContent='space-around'
				borderTop='1px solid #DFE0E3'
				borderBottom={isCommentSectionOpen ? '1px solid #DFE0E3' : 'none'}
			>
				<Button
					background='transparent'
					onClick={() =>
						handleLike(hasLiked ? 'delete' : 'add', monitorPointId, status.id)
					}
				>
					<Icon
						as={hasLiked ? BiSolidLike : BiLike}
						w='1.500rem'
						h='1.500rem'
						color='evaluation_answer_like_button'
					/>
					<Text
						ml={2}
						color='evaluation_answer_like_button'
						fontWeight='600'
						fontSize='xs'
					>
						{status.agreements.length > 0
							? status.agreements.length > 1
								? `${status.agreements.length} ${t('divergencePoint:likes')}`
								: `${status.agreements.length} ${t('divergencePoint:like')}`
							: t('divergencePoint:Like')}
					</Text>
				</Button>
				<Box w='1px' h='60%' backgroundColor='#DFE0E3' />
				<Button
					background='transparent'
					onClick={() => setIsCommentSectionOpen(!isCommentSectionOpen)}
				>
					<Icon
						as={status.replies.length > 0 ? BiChat : BiComment}
						w='1.500rem'
						h='1.500rem'
						color='evaluation_answer_comment_button'
					/>
					<Text
						ml={2}
						color='evaluation_answer_comment_button'
						fontWeight='600'
						fontSize='xs'
					>
						{status.replies.length > 0
							? status.replies.length > 1
								? t('divergencePoint:comments_plural', {
									count: status.replies.length
								})
								: t('divergencePoint:comments', {
									count: status.replies.length
								})
							: t('divergencePoint:Comment')}
					</Text>
					{isCommentSectionOpen ? (
						<ChevronUpIcon boxSize='1.563rem' color='gray.400' />
					) : (
						<ChevronDownIcon boxSize='1.563rem' color='gray.400' />
					)}
				</Button>
			</Flex>
			<Collapse in={isCommentSectionOpen} animateOpacity>
				<Box pb={2}>
					{status.replies.map((comment) => (
						<StatusCommentCard
							key={comment.id}
							comment={comment}
							userId={userId}
							userName={userName}
							userLanguage={userLanguage}
							isDeleteCommentLoading={isLoading[comment.id]}
							onDelete={() => handleDelete(comment)}
						/>
					))}
					<InputStatusComment
						monitorPointId={monitorPointId}
						monitorPointStatusId={status.id}
						userName={userName}
						userId={userId}
					/>
				</Box>
			</Collapse>
		</Flex>
	)
}
