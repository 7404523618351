import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: tdsCustomColors.pink[500],
		light: tdsCustomColors.pink[400]
	},

	secondary: {
		dark: tdsCustomColors.yellow[500],
		light: tdsCustomColors.orange[50]
	}
}

export default themeColors
