import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { BiBookOpen, BiChat } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStResume } from 'modules/common/components/icons'
import { useJourney } from 'modules/journeys/hooks'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import useCommentsStore from '../../store/use-comments-store'
import { ButtonSharePoint } from '../button-share-point'
import { AboutTool } from './about-tool'
import { AnonymControl } from './anonym-mode/anonym-control'
import DivergenceOptions from './divergence-options'
import { StructQuestion } from './struct-question'
import { AnalysisQuestions } from './summary/analysis-questions-web'

export const LeftDivergenceView: React.FC<{
	setLinksOrRefs: Dispatch<SetStateAction<string>>
}> = ({ setLinksOrRefs }) => {
	const { t } = useTranslation()
	const { user: currUser } = useMe()
	const { id: projectId = '', mapId } = useParams<{
		id: string
		mapId: string
	}>()

	const { data: users = [] } = useJourney(projectId, {
		select: (journey) => journey.users
	})

	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { title = '', color = '' } = selectedPoint || {}

	const {
		setQuestionOpenedIndex,
		point,
		questionOpenedIndex,
		setQuestionSummaryIndex
	} = useCommentsStore()

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('divergence.delete'))

	const about = questionOpenedIndex === -1
	const summary = questionOpenedIndex === -2
	const questions = questionOpenedIndex !== -1 && questionOpenedIndex !== -2

	const handleClickAboutKit = () => {
		setQuestionOpenedIndex(-1)
		sendGAEvent('about_kit')
	}

	const handleClickSummary = () => {
		setQuestionSummaryIndex(0)
		setQuestionOpenedIndex(-2)
		sendGAEvent('summary_tab')
	}

	return (
		<Flex flexDirection='column' w='full' h='full'>
			<Flex
				alignItems='center'
				pl='10'
				justifyContent='space-between'
				h='6.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			>
				<Flex alignItems='center' w='full' minH='6.5rem'>
					<Flex flexDirection='column'>
						{selectedPoint && (
							<SelectColor
								color={color.toLowerCase()}
								visible={selectedPoint.visible}
							/>
						)}
					</Flex>

					<Text
						fontSize='lg'
						px='3'
						fontWeight='600'
						maxW='90%'
						display='-webkit-box'
						sx={{ WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}
						overflow='hidden'
						variant='breakLines'
					>
						{title}
					</Text>
				</Flex>

				{selectedPoint && (
					<ButtonSharePoint
						pointType='divergence'
						mr={!hasPrivileges ? 2 : 0}
					/>
				)}
				{hasPrivileges && mapId && (
					<DivergenceOptions
						divergencePoint={selectedPoint}
						projectId={projectId}
						mapId={mapId}
					/>
				)}
			</Flex>
			<Flex
				py={2}
				justifyContent='space-evenly'
				align='center'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			>
				<Tooltip>
					<Flex flexDirection='column'>
						<Button
							onClick={() => setQuestionOpenedIndex(0)}
							background={!about && !summary ? 'gray.100' : 'transparent'}
							p={2}
							display='flex'
							h='auto'
							alignItems='center'
							justifyContent='center'
							flexDirection='column'
							bg='gray.100'
						>
							<Icon
								as={BiChat}
								fontSize='2xl'
								color={
									!about && !summary ? 'tabs_aria_selected_color' : 'gray.300'
								}
							/>
							<Text
								fontWeight='600'
								fontSize='xs'
								color={
									!about && !summary ? 'tabs_aria_selected_color' : 'gray.300'
								}
								p={2}
							>
								{t('tools:edit.questions.questions')}
							</Text>
						</Button>
					</Flex>
				</Tooltip>

				<Tooltip>
					<Flex
						flexDirection='column'
						onClick={() => setQuestionOpenedIndex(-1)}
					>
						<Button
							onClick={handleClickAboutKit}
							background={about ? 'gray.100' : 'transparent'}
							p={2}
							display='flex'
							h='auto'
							alignItems='center'
							justifyContent='center'
							flexDirection='column'
							bg='gray.100'
						>
							<Icon
								as={BiBookOpen}
								fontSize='2xl'
								color={about ? 'tabs_aria_selected_color' : 'gray.300'}
							/>
							<Text
								fontWeight='600'
								fontSize='xs'
								color={about ? 'tabs_aria_selected_color' : 'gray.300'}
								p={2}
							>
								{t('divergencePoint:about_kit.references')}
							</Text>
						</Button>
					</Flex>
				</Tooltip>

				<Tooltip>
					<Flex flexDirection='column'>
						<Button
							onClick={handleClickSummary}
							background={summary ? 'gray.100' : 'transparent'}
							p={2}
							display='flex'
							h='auto'
							alignItems='center'
							justifyContent='center'
							flexDirection='column'
							bg='gray.100'
						// sx={{
						// 	'&[aria-selected="true"]': { color: 'tabs_aria_selected_color' },
						// }}
						>
							<Icon
								as={IconStResume}
								fontSize='26px'
								color={summary ? 'tabs_aria_selected_color' : 'gray.300'}
							/>
							<Text
								fontWeight='600'
								fontSize='xs'
								color={summary ? 'tabs_aria_selected_color' : 'gray.300'}
								p={2}
							>
								{t('divergencePoint:resume.title')}
							</Text>
						</Button>
					</Flex>
				</Tooltip>
			</Flex>

			{point && !summary && !about && <AnonymControl pointId={point.id} />}

			{point && about && (
				<AboutTool
					pointInfo={point}
					tool={point.tool}
					setLinksOrRefs={setLinksOrRefs}
				/>
			)}
			{point && questions && (
				<StructQuestion
					intro={point.introduction}
					questionsWithText={point.tool.questions}
				/>
			)}

			{point && summary && (
				<AnalysisQuestions questionsWithText={point.tool.questions} />
			)}
		</Flex>
	)
}
