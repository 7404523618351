import { TenantColors } from '../types'
import hgsCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const hgsColors: TenantColors = {
	...hgsCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default hgsColors
