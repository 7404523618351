import { Helmet } from 'react-helmet-async'

interface Props {
	tenant: string
	theme: Record<string, any>
}

const faviconPaths: { [key: string]: string } = {
	DEFAULT: '/assets/favicons/favicon.ico',
	SINERGIA: '/assets/favicons/sinergia.ico'
}

const MetaTenant = ({ theme, tenant }: Props) => {
	const faviconPath = faviconPaths[tenant] ?? faviconPaths.DEFAULT

	const metaThemeColor = document.querySelector('meta[name="theme-color"]')
	metaThemeColor?.setAttribute('content', theme.colors.primary.dark)
	return (
		<Helmet>
			<link rel='icon' href={faviconPath} />
		</Helmet>
	)
}

export default MetaTenant
