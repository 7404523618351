import { useQuery } from 'react-query'

import { noticePointKeys } from '../query'
import { getNoticePoint } from '../usecases'

export const useNoticePoint = (pointId: string) => {
	return useQuery(noticePointKeys.noticePoint(pointId), () =>
		getNoticePoint(pointId)
	)
}
