import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const getUserAssistant = async (userAssistantId: string) => {
	const response = await fetch<Assistant>({
		url: `projects/v1/assistants/${userAssistantId}`,
		method: 'GET'
	})

	return response.data
}
