import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'

import {
	Text,
	Button,
	Image,
	Icon,
	Box,
	useDisclosure,
	Tooltip,
	VStack
} from '@chakra-ui/react'

import { useMe } from 'modules/user/hooks'

import useReferencesStore from '../../store/use-references-store'
import Plus from '../chat/plus.svg'
import { BackButtonMobile } from './back-button-mobile'
import { LinkCard } from './link-card'
import { LinksEmpty } from './LinksOrReferencesEmpty'
import { ModalAddLink } from './modal-add-link'
import { ReferenceCard } from './reference-card'

export const AboutToolRightViewMobile: React.FC<{
	linksOrRefs: string
	divergencePointId: string
	setIsSideBar: () => void
}> = ({ linksOrRefs, divergencePointId, setIsSideBar }) => {
	const { referencesAndLinks } = useReferencesStore()
	const { user } = useMe()
	const disclosure = useDisclosure()
	const { t } = useTranslation()

	const stringReference = (index: number) =>
		`${t('divergencePoint:about_kit:reference')} ${index + 1}`

	switch (linksOrRefs) {
		case 'refs':
			return (
				<>
					<VStack
						backgroundColor='white'
						textAlign='center'
						p={10}
						h='6.5rem'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
						alignItems='flex-start'
						justifyContent='center'
					>
						<BackButtonMobile onClickFunc={setIsSideBar} />
						<Text
							fontSize='md'
							fontWeight='600'
							color='gray.1'
							display='flex'
							justifyContent='center'
						>
							<Icon as={BiLink} fontSize='xl' marginRight='0.5rem' />
							{t('divergencePoint:about_kit:references')}
						</Text>
					</VStack>

					{referencesAndLinks.references.length > 0 ? (
						<Box w='full' overflowY='auto' pb='2'>
							{referencesAndLinks.references.map((reference, index) => {
								return (
									<ReferenceCard
										title={stringReference(index)}
										description={reference.description}
										url={reference.url}
										key={`${reference.id}_${index}`}
									/>
								)
							})}
						</Box>
					) : (
						<LinksEmpty isLink={false} />
					)}
				</>
			)
		case 'links':
			return (
				<>
					<VStack
						backgroundColor='white'
						textAlign='center'
						p={10}
						h='6.5rem'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
						alignItems='flex-start'
						justifyContent='center'
					>
						<BackButtonMobile onClickFunc={setIsSideBar} />
						<Text fontSize='md' fontWeight='600' color='gray.1'>
							<Icon as={BiLink} fontSize='xl' marginRight='0.5rem' />
							{t('divergencePoint:about_kit:links')}
						</Text>
					</VStack>

					{referencesAndLinks.attached_links.length > 0 ? (
						<Box w='full' overflowY='auto' pb='2'>
							{referencesAndLinks.attached_links.map((link, index) => {
								return (
									<LinkCard
										userName={link.author.name}
										description={link.title}
										url={link.url}
										hasDeletePrivilege={user?.id === link.author.id}
										idLink={link.id}
										divergencePointId={divergencePointId}
										key={`${link.id}_${index}`}
									/>
								)
							})}
						</Box>
					) : (
						<LinksEmpty isLink={true} />
					)}

					<Tooltip
						placement='left'
						label={t('divergencePoint:about_kit:add_link')}
					>
						<Button
							onClick={() => {
								disclosure.onOpen()
							}}
							position='fixed'
							bottom='6'
							right='6'
							borderRadius='50%'
							max-width='3rem'
							w='3rem'
							h='3rem'
							max-height='3rem'
							backgroundColor='mobile_add_floating_action_button'
							_hover={{ backgroundColor: 'none' }}
						>
							<Image src={Plus} />
						</Button>
					</Tooltip>
					<ModalAddLink disclosure={disclosure} />
				</>
			)

		default:
			return null
	}
}
