import { fetch } from 'config'

import { UserExtraInfo } from '../types'

export async function updateInfos({
	acting,
	discover_strateegia,
	strateegia_usage
}: UserExtraInfo) {
	const response = await fetch({
		url: '/users/v1/user/me/extra',
		method: 'PATCH',
		data: {
			acting,
			discover_strateegia,
			strateegia_usage
		}
	})

	return response.data
}
