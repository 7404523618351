import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiCalendar, BiPlusCircle, BiTimeFive } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Icon,
	Input,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Stack,
	Text,
	useBreakpointValue,
	useTheme,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { IconStPointConvergence } from 'modules/common/components/icons'
import {
	initialValues,
	todayDate
} from 'modules/map/modules/convergence-point/helpers'
import { createConvergencePoint } from 'modules/map/modules/convergence-point/usecases'
import { CreateConvergencePointValidation } from 'modules/map/modules/convergence-point/validators'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point, PointMap, Position } from 'modules/map/modules/map-canvas/types'
import { makeHexagon } from 'modules/map/modules/map-canvas/utils/hexagon-utils'
import { findEmptyPoint } from 'modules/map/modules/map-canvas/utils/neighbors-utils'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { CreateConvergenceQuestions } from '../create-questions'

type Props = {
	highMaturityValues?: {
		date: string
		hour: string
		questions?: {
			id: string
			text: string
			options: {
				id: string
				text: string
			}[]
		}[]
	}
}

export const CreateConvergenceForm = ({ highMaturityValues }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { mapId = '', id: projectId = '' } = useParams<{
		mapId: string
		id: string
	}>()
	const validation = CreateConvergencePointValidation()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const methods = useForm({
		mode: 'onChange',
		defaultValues: highMaturityValues || initialValues,
		resolver: yupResolver(validation)
	})
	const { isValid, errors, isSubmitting } = methods.formState
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const isFullWidth = useBreakpointValue({ base: true, lg: false })
	const toast = useToast()

	const position = useMapStore(
		({ state }) => state.selectedPoint?.position
	) as Position

	const mapRows = useMapStore(({ state }) => state.map) as PointMap

	const isAIGenerated = highMaturityValues

	const { addPoint, clearSelected } = useMapStore(({ actions }) => actions)

	const insertPointInTheMap = (data: Point) => {
		const pointType = 'CONVERGENCE'
		const hexagon = makeHexagon(data.position.row, data.position.col, {
			...data,
			point_type: pointType
		})
		addPoint(hexagon)
		clearSelected()

		const setPosition = isAIGenerated
			? findEmptyPoint(mapRows, position)
			: hexagon.position

		const pointInfo = {
			id: hexagon.id,
			position: setPosition,
			point_type: pointType
		}
		emit('ADD', pointInfo)
	}

	const createPoint = async (params: FieldValues) => {
		const { date, hour, questions } = params as typeof initialValues
		const closeAt = new Date(`${date}T${hour}`)

		const setPosition = isAIGenerated
			? findEmptyPoint(mapRows, position)
			: position

		const payload = {
			mapId,
			closing_date: closeAt.toISOString(),
			position: setPosition,
			questions
		}

		try {
			const convergencePoint = await createConvergencePoint(payload)
			insertPointInTheMap(convergencePoint)
			toast({
				title: t('map:points.conversation.create.success'),
				status: 'success'
			})
			if (isAIGenerated) navigate('../../')
			sendGAEvent('create_convergence_point')
		} catch (error) {
			const { message } = error as Error
			toast({ title: message as string })
		}
	}

	const theme = useTheme() as { colors: any }
	const FooterForm = () => (
		<Stack
			direction={{ base: 'column', lg: 'row' }}
			spacing={4}
			justify='space-between'
			w='full'
		>
			<Stack direction='row' alignItems='center'>
				<Icon as={BiCalendar} />
				<Text fontWeight='medium'>
					{t('map:points.conversation.create.date.label')}
				</Text>
				<Input
					variant='filled'
					type='date'
					min={todayDate}
					{...methods.register('date')}
				/>
			</Stack>
			<Stack direction='row' alignItems='center'>
				<Icon as={BiTimeFive} />
				<Text fontWeight='medium' pl='xs'>
					{t('map:points.conversation.create.hour.label')}
				</Text>
				<Input
					variant='filled'
					type='time'
					pattern='[0-9]{2}:[0-9]{2}'
					placeholder='17:30'
					{...methods.register('hour')}
				/>
			</Stack>
			<Box>
				<CustomSchemeButton
					type='submit'
					isDisabled={!isValid}
					width={isFullWidth ? 'full' : 'fit-content'}
					colorScheme={theme.colors.convergence_points_button}
					isLoading={isSubmitting}
					fontSize={['xs', 'md']}
					leftIcon={<Icon as={BiPlusCircle} fontSize='lg' />}
				>
					{t('buttons:createPoint')}
				</CustomSchemeButton>
			</Box>
		</Stack>
	)

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(createPoint)}>
				<ModalContent>
					<ModalCloseButton mt={4} mr={isMobile ? 0 : 4} />
					<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8} pb={0}>
						<Stack direction='row' alignItems='center'>
							<Icon
								as={IconStPointConvergence}
								fontSize='5xl'
								color='purple.500'
							/>
							<Box>
								<Text fontWeight='semibold' fontSize={['lg', 'xl']}>
									{t('map:points.convergence.create.title')}
								</Text>
								{!isMobile && (
									<Text
										color='gray.400'
										fontWeight='medium'
										fontSize={['sm', 'md']}
									>
										{t('map:points.convergence.create.subtitle')}
									</Text>
								)}
							</Box>
						</Stack>
					</ModalHeader>
					<ModalBody overflow='auto' px={isMobile ? 4 : 8}>
						<CreateConvergenceQuestions />
					</ModalBody>
					<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
						<Stack direction='column' w='full'>
							<FooterForm />
							{errors.hour && (
								<Alert status='error'>
									<AlertIcon />
									<AlertDescription>{errors.hour.message}</AlertDescription>
								</Alert>
							)}
						</Stack>
					</ModalFooter>
				</ModalContent>
			</form>
		</FormProvider>
	)
}
