import { ThemeConfig, extendTheme } from '@chakra-ui/react'

import tenantColors from './colors'
import { ThemeColors } from './colors/types'
import Accordion from './components/Accordion'
import Badge from './components/Badge'
import Button from './components/Button'
import FormControl from './components/FormControl'
import Menu from './components/Menu'
import Modal from './components/Modal'
import NavLink from './components/NavLink'
import Tabs from './components/Tabs'
import Text from './components/Text'

export function createTheme(tenant: string) {
	const fonts = {
		heading: 'Montserrat, sans-serif',
		body: 'Montserrat, sans-serif'
	}

	const colors: ThemeColors = tenantColors[tenant]

	const extendedTheme = extendTheme({
		colors,
		fonts,
		components: {
			Button,
			FormControl,
			Accordion,
			Badge,
			Tabs,
			Menu,
			Modal,
			Text,
			NavLink
		}
	} as ThemeConfig)

	return extendedTheme
}
