import { useTranslation } from 'react-i18next'
import { BiCheckCircle } from 'react-icons/bi'

import { Icon, VStack, Text } from '@chakra-ui/react'

export const SucessPayment = () => {
	const { t } = useTranslation()
	return (
		<VStack py={16}>
			<Icon mb={3} as={BiCheckCircle} fontSize='4rem' color='green.500' />
			<Text fontWeight='bold' fontSize='md'>
				{t('credits:pix.sucess')}
			</Text>
		</VStack>
	)
}
