import { fetch } from 'config'
import { mountParams, SortOrder } from 'data/helpers/mount-params'
import { v4 as uuid } from 'uuid'

import { QueryFilter, Visibility } from 'modules/common/types/common-model'
import { InitialCreateTool } from 'modules/tools/pages/create-tool'
import { Reference, Tool, ToolResponse } from 'modules/tools/types'

type GetMineParams = {
	search: string
	filter: QueryFilter
	order: SortOrder
	pageParam: number
	sortBy?: string
}

const getMine = ({
	search,
	filter,
	order,
	pageParam = 0,
	sortBy = 'createdAt'
}: GetMineParams): Promise<ToolResponse> => {
	const params = mountParams({
		size: 12,
		page: pageParam,
		tiers: 'CUSTOM',
		title: search,
		sort: [sortBy, order],
		...filter
	})

	return fetch<ToolResponse>({
		url: 'tools/v1/tool',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const getById = (id: string): Promise<Tool> => {
	const makeReference = (reference: Reference) => ({
		...reference,
		id: uuid()
	})

	return fetch<Tool>({
		url: `tools/v1/tool/${id}`,
		method: 'GET'
	}).then(({ data }) => {
		const references = data.references.map(makeReference)
		return { ...data, references }
	})
}

const create = (params: InitialCreateTool): Promise<Tool> => {
	return fetch<Tool>({
		url: 'tools/v1/tool',
		method: 'POST',
		data: { ...params, description: params.description || null }
	}).then(({ data }) => data)
}

const edit = (params: Tool): Promise<Tool> => {
	return fetch<Tool>({
		url: `tools/v1/tool/${params.id}`,
		method: 'PUT',
		data: { ...params, description: params.description || null }
	}).then(({ data }) => data)
}

const duplicate = (id: string): Promise<Tool> => {
	return fetch<Tool>({
		url: `tools/v1/tool/${id}/copy`,
		method: 'POST'
	}).then(({ data }) => data)
}

const destroy = (id: string): Promise<Tool> => {
	return fetch<Tool>({
		url: `tools/v1/tool/${id}`,
		method: 'DELETE'
	}).then(({ data }) => data)
}

type ExplorePromes = {
	search: string
	order: SortOrder
	filter: QueryFilter
	pageParam: number
	official: boolean
	sortBy?: string
}

const explore = ({
	search,
	order,
	filter,
	pageParam = 0,
	official,
	sortBy = 'createdAt'
}: ExplorePromes) => {
	const params = mountParams({
		official,
		page: pageParam,
		size: 12,
		title: search,
		sort: [sortBy, order],
		...filter
	})

	return fetch<ToolResponse>({
		url: 'tools/v1/tool/explore',
		method: 'GET',
		params
	}).then(({ data }) => data)
}

const updateVisibility = (id: string, visibility: Visibility) => {
	return fetch({
		url: `tools/v1/tool/${id}/visibility`,
		method: 'PATCH',
		data: { visibility }
	})
}

const ToolsApi = {
	getMine,
	create,
	edit,
	getById,
	explore,
	destroy,
	duplicate,
	updateVisibility
}

export default ToolsApi
