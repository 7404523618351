import { Trans } from 'react-i18next'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

import {
	ModalBody,
	Box,
	Stack,
	ModalFooter,
	Icon,
	Text
} from '@chakra-ui/react'

import { ConvergencePoint, ConvergenceQuestion } from '../../../types'
import { BeliefResult } from '../../belief-result'

export const Result = ({
	point,
	myAnswer
}: {
	point: ConvergencePoint
	myAnswer: ConvergenceQuestion[]
}) => {
	return (
		<>
			<ModalBody p={0}>
				<Box bg='purple.50' p={7}>
					<Stack spacing={4}>
						{point.questions.map((question, index) => (
							<BeliefResult
								key={question.id}
								question={question}
								myAnswer={myAnswer[index]}
							/>
						))}
					</Stack>
				</Box>
			</ModalBody>
			<ModalFooter justifyContent='start' display='flex' alignItems='top'>
				<Box display='flex' alignItems='top'>
					<Icon
						mr={1}
						w={[4, 4]}
						h={[4, 4]}
						color='gray.400'
						as={AiOutlineQuestionCircle}
					></Icon>
				</Box>

				<Text fontSize='xs' color='gray.400'>
					<Trans i18nKey='map:points.convergence.iccExplanation' />
				</Text>
			</ModalFooter>
		</>
	)
}
