import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Box, Heading, Text, HStack } from '@chakra-ui/react'

import { DocHeader } from 'modules/common/components'

import { SignUpContext } from '..'

import { useTenant } from 'modules/common/contexts/global-tenant-context'

export const Header = () => {
	const { t } = useTranslation()
	const { showMoreData } = useContext(SignUpContext)
	const { tenant, isTenantDefault } = useTenant()

	const title = showMoreData
		? t('signup:usage.better')
		: t('signup:usage.createAccount')

	return (
		<Box display='flex' alignItems='center' flexDirection='column' mb={8}>
			<DocHeader title={t('buttons:signUp')} />
			<Heading mb={4} fontSize={['lg', '1xl', '2xl']} fontWeight='extrabold'>
				{title}
			</Heading>
			{showMoreData ? (
				<Box textAlign='center'>
					{isTenantDefault(tenant) && (
						<Text
							fontWeight='medium'
							color='gray.300'
							textAlign='center'
							mb={3}
						>
							{t('signup:usage.samePlatform')}
						</Text>
					)}
					<Text fontSize='md' fontWeight='semibold' color='gray.400'>
						{t('signup:usage.ask1')}
					</Text>
				</Box>
			) : (
				<HStack>
					<Text fontSize='md' fontWeight='medium' color='gray.400'>
						{t('signup:alreadyHaveAccount')}
					</Text>
					<Button as={Link} to='/signin' variant='link' colorScheme='blue'>
						{t('buttons:logIn')}
					</Button>
				</HStack>
			)}
		</Box>
	)
}
