import React from 'react'

import { Modal, ModalOverlay, UseDisclosureReturn } from '@chakra-ui/react'

import { CreateConvergenceForm } from '../form'

type Props = {
	disclosure: UseDisclosureReturn
	highMaturityValues?: {
		date: string
		hour: string
		questions?: {
			id: string
			text: string
			options: {
				id: string
				text: string
			}[]
		}[]
	}
}

export const CreateConvergencePoint: React.FC<Props> = ({
	disclosure,
	highMaturityValues
}) => {
	return (
		<Modal
			closeOnOverlayClick={false}
			{...disclosure}
			size='3xl'
			variant='stretched'
			isCentered
		>
			<ModalOverlay />
			<CreateConvergenceForm highMaturityValues={highMaturityValues} />
		</Modal>
	)
}
