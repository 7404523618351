import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LuLink } from 'react-icons/lu'
import { MdOutlineShare } from 'react-icons/md'

import {
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
	useToast
} from '@chakra-ui/react'
import { QRCodeCanvas } from 'qrcode.react'

import queryClient from 'config/query-client'

import { useUpdateShareAssistant } from 'modules/assistants/hooks/use-update-share-assistant'
import { useUpdateShareLabIds } from 'modules/assistants/hooks/use-update-share-lab-ids'
import { SelectEnhancedOption } from 'modules/common/components/select-enhanced-option/select-enhanced-option'
import { useLabs } from 'modules/journeys/hooks'
import { Assistant } from 'modules/map/types/assistants'

export const AssistantShareModal = ({
	isOpen,
	onClose,
	assistantData
}: {
	isOpen: boolean
	onClose: () => void
	assistantData: Assistant
}) => {
	const { control } = useForm({
		defaultValues: {
			sharedByLink: assistantData.shared_by_link,
			sharedWithCommunity: assistantData.public,
			sharedWithLab: false,
			selectedLab: [] as string[]
		}
	})
	const toast = useToast()
	const { t } = useTranslation()
	const shareLink = useRef<string | null>(null)

	const { labs, isLoading: isLoadingLabs } = useLabs()

	const {
		mutate: updateAssistantShareCommunity,
		isLoading: isLoadingUpdateAssistantShareCommunity
	} = useUpdateShareAssistant({ type: 'community' })

	const {
		mutate: updateAssistantShareLab,
		isLoading: isLoadingUpdateAssistantShareLab
	} = useUpdateShareAssistant({ type: 'lab' })

	const {
		mutate: updateAssistantShareByLink,
		isLoading: isLoadingUpdateAssistantShareByLink
	} = useUpdateShareAssistant({ type: 'link' })

	const { mutate: updateAssistantShareLabIds } = useUpdateShareLabIds()

	useEffect(() => {
		const domain = window.location.hostname

		domain.includes('localhost')
			? (shareLink.current = `http://localhost:5173/dashboard/assistants/start/${assistantData.id}`)
			: (shareLink.current = `https://${domain}/dashboard/assistants/start/${assistantData.id}`)
	}, [assistantData])

	const handleShare = async (
		type: 'community' | 'lab' | 'link',
		withToast = true
	) => {
		toast.closeAll()

		const updateAssistantShare = {
			community: updateAssistantShareCommunity,
			lab: updateAssistantShareLab,
			link: updateAssistantShareByLink
		}[type]

		const hasSharingEnabled =
			type === 'community' ? assistantData.public : assistantData.shared_lab

		updateAssistantShare(assistantData, {
			onSuccess: () => {
				if (withToast) {
					toast({
						title: !hasSharingEnabled
							? t(`assistants:toast.visibility.${type}.shared`)
							: t(`assistants:toast.visibility.${type}.unshared`),
						status: 'success',
						containerStyle: {
							textAlign: 'center'
						}
					})
				}
				queryClient.invalidateQueries('userAssistants')
			},
			onError: () => {
				toast({
					title: t('errors:request.error.title'),
					description: t('errors:request.error.description'),
					status: 'error'
				})
			}
		})
	}

	const handleShareLabSelectItem = async (labId: string, checked: boolean) => {
		toast.closeAll()

		updateAssistantShareLabIds(
			{
				labId,
				assistantId: assistantData.id,
				action: checked ? 'add' : 'remove'
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries('userAssistants')
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)
	}

	const handleCopyLink = () => {
		toast.closeAll()

		if (!shareLink.current) return

		navigator.clipboard
			.writeText(shareLink.current)
			.then(() => {
				toast({
					title: t('assistants:modal.share.link.copied'),
					status: 'success',
					duration: 2000,
					isClosable: true
				})
			})
			.catch(() => {
				toast({
					title: t('assistants:modal.share.link.error'),
					status: 'error',
					duration: 2000,
					isClosable: true
				})
			})
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size={['full', 'xl']}>
				<ModalOverlay
					bg='rgba(35, 39, 53, 0.40)'
					backdropFilter='blur(15px)'
					backdropBlur='2px'
				/>
				<ModalContent
					pt={4}
					borderRadius='xl'
					transition='max-width 0.3s ease'
					mt={40}
				>
					<ModalHeader fontWeight={'bold'} fontSize={'md'}>
						<Flex gap={2} align='center'>
							<Icon as={MdOutlineShare} fontSize='2xl' />
							<Text title={assistantData.name} maxW='80%' isTruncated>
								{t('assistants:modal.share.title')} {assistantData.name}
							</Text>
						</Flex>
					</ModalHeader>
					<ModalCloseButton mt={4} mr={3} />
					<ModalBody pb={6}>
						{assistantData.shared_by_link && (
							<Flex direction='column' mb={5} align='center' gap={5}>
								<Box ml={1} mt='4px'>
									<QRCodeCanvas
										value={shareLink.current as string}
										size={180}
									/>
								</Box>
								<Box w='100%'>
									<Flex mt={1} alignItems='center'>
										<Input
											defaultValue={shareLink.current as string}
											isReadOnly
											borderRadius='lg'
											variant='filled'
											borderWidth='1px'
											flex={1}
											mr={2}
										/>
										<Button
											variant='outline'
											borderColor='red.500'
											color='red.500'
											borderRadius='lg'
											onClick={handleCopyLink}
											isDisabled={isLoadingUpdateAssistantShareByLink}
											fontWeight='semibold'
										>
											<Icon as={LuLink} mr={1} />
											<Text fontSize='sm'>
												{t('assistants:modal.share.link.copy')}
											</Text>
										</Button>
									</Flex>
								</Box>
							</Flex>
						)}
						<Box mb={3}>
							<HStack fontWeight='medium' fontSize='sm'>
								<Controller
									name='sharedByLink'
									control={control}
									render={({ field: { onChange, value, ...rest } }) => (
										<Switch
											{...rest}
											isChecked={assistantData.shared_by_link}
											colorScheme='switch_scheme_color_checked'
											isDisabled={isLoadingUpdateAssistantShareByLink}
											onChange={(e) => {
												onChange(e)
												handleShare('link')
											}}
										/>
									)}
								/>
								<Text>{t('assistants:modal.share.link.title')}</Text>
							</HStack>
							<Text mt='5px' ml='2px' color='gray.300' fontSize='xs'>
								{t('assistants:modal.share.link.description')}
							</Text>
						</Box>

						<Box mb={3}>
							<HStack fontWeight='medium' fontSize='sm'>
								<Controller
									name='sharedWithCommunity'
									control={control}
									render={({ field: { onChange, value, ...rest } }) => (
										<Switch
											{...rest}
											isChecked={assistantData.public}
											colorScheme='switch_scheme_color_checked'
											isDisabled={isLoadingUpdateAssistantShareCommunity}
											onChange={(e) => {
												onChange(e)
												handleShare('community')
											}}
										/>
									)}
								/>
								<Text>{t('assistants:modal.share.community.title')}</Text>
							</HStack>
							<Text mt='5px' ml='2px' color='gray.300' fontSize='xs'>
								{t('assistants:modal.share.community.description')}
							</Text>
						</Box>

						<Box mb={2}>
							<HStack fontWeight='medium' fontSize='sm'>
								<Controller
									name='sharedWithLab'
									control={control}
									render={({ field: { onChange, value, ...rest } }) => (
										<Switch
											{...rest}
											isChecked={assistantData.shared_lab}
											colorScheme='switch_scheme_color_checked'
											isDisabled={isLoadingUpdateAssistantShareLab}
											onChange={(e) => {
												onChange(e)
												handleShare('lab')
											}}
										/>
									)}
								/>
								<Text>{t('assistants:modal.share.lab.title')}</Text>
							</HStack>
						</Box>
						<SelectEnhancedOption<any>
							useMode='lab'
							isDisabled={!assistantData.shared_lab || isLoadingLabs}
							placeholder={t('assistants:modal.share.lab.action')}
							name='selectedLab'
							isMobile={false}
							control={control}
							onSelectItem={handleShareLabSelectItem}
							options={labs.map((lab) => ({
								key: lab.id,
								value: lab.id,
								title: lab.lab_name ? lab.lab_name : lab.name,
								description: `@${lab.username}`,
								checked: assistantData.shared_lab_ids.includes(lab.id)
							}))}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
