import { fetch } from 'config'

import { Provider } from '../types'

export const getPublicProviders = () => {
	return fetch<Provider[]>({
		url: '/users/v1/auth/provider/public',
		method: 'GET'
	}).then(({ data }) => data)
}
