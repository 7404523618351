import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
	useTheme
} from '@chakra-ui/react'

interface FormInputProps extends InputProps {
	label: string
	name: string
	placeholder: string
	register: UseFormRegister<any>
	isRequired?: boolean
	errors: Record<string, any>
}

export const FormInput: React.FC<FormInputProps> = ({
	label,
	name,
	placeholder,
	register,
	isRequired,
	errors,
	...props
}) => {
	const theme = useTheme() as { colors: any }
	const errorMessage = errors[name]?.message

	return (
		<FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
			<FormLabel fontWeight='semibold' color='gray.400'>
				{label}
			</FormLabel>
			<Input
				{...register(name)}
				placeholder={placeholder}
				variant='flushed'
				borderColor='gray.195'
				_placeholder={{
					color: 'gray.300'
				}}
				_focus={{
					borderColor: theme.colors.input_border,
					borderBottomWidth: '0px'
				}}
				{...props}
			/>
			<FormErrorMessage>{errorMessage}</FormErrorMessage>
		</FormControl>
	)
}
