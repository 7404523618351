import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#2041AD',
		light: '#C5DCF8'
	},

	secondary: {
		dark: '#34A853',
		light: '#A4D8B2'
	}
}

export default themeColors
