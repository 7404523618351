import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Flex, Text, Skeleton, useToast } from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import Hyperlink from 'modules/common/components/hyperlink'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import useEssaysStore from 'modules/map/store/use-essays-store'

import { EvaluationAuthor, EvaluationResponse } from '../../../types/index'
import { AnonymAvatar } from '../../view-divergence-point/anonym-mode/anonym-avatar'
import { deleteEssayAnswer } from '../usecases/delete-essay-answer'
import { Like } from './essay-card-buttons'

type Props = {
	content: EvaluationResponse
	isAnonym: boolean
	author: EvaluationAuthor
	userId: string
	userLanguage: string
}

export const ReplyEssayAnswer = ({
	isAnonym,
	author,
	content,
	userId,
	userLanguage
}: Props) => {
	const profilePath = `/dashboard/profile/${author.username}`
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const anonymName = useRef('anonym'.padStart(6 + Math.random() * 10, '0'))
	const [dataVisible, setDataVisible] = useState(true)
	const hasDeletePrivilege = userId === content.author.id
	const tone = isAnonym ? 800 : 500
	const useString = useStringToColor({ text: author.name }, [tone])[1]
	const { deleteReplyState } = useEssaysStore()
	const { t } = useTranslation()
	const toast = useToast()
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))

	const renderName = () => {
		return (
			<>
				{isAnonym ? (
					<Skeleton animation='none' endColor='#DDDEE9' ml='4'>
						<Text
							as={Flex}
							color='gray.1'
							fontWeight='600'
							fontSize='sm'
							h='auto'
						>
							{isAnonym ? anonymName.current : content.author.name}
						</Text>
					</Skeleton>
				) : (
					<Skeleton isLoaded={true} animation='none' endColor='#DDDEE9' ml='4'>
						<Text
							as={Link}
							to={profilePath}
							_hover={{ textDecoration: 'underline' }}
							color='gray.1'
							fontWeight='600'
							fontSize='sm'
							h='auto'
						>
							{isAnonym ? anonymName.current : content.author.name}
						</Text>
					</Skeleton>
				)}
			</>
		)
	}

	const deleteReply = async () => {
		await deleteEssayAnswer({
			answerId: content.id,
			pointId: content.essay_point_id
		})
		deleteReplyState(content)
		const commentInfo = {
			id: content.id,
			essay_point_id: content.essay_point_id,
			parent_id: content.parent_id
		}
		emit('DELETE', commentInfo)

		toast({
			title: t('divergencePoint:comment_deleted'),
			isClosable: true,
			status: 'success'
		})
		setDataVisible(false)
	}

	return (
		<Flex flexDirection='column' width='100%'>
			<Flex flexDirection='row' width='full'>
				{isAnonym ? (
					<Flex mt='4'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={author.avatar_url}
							name={author.name}
							backgroundColor={useString}
						/>
					</Flex>
				) : (
					<Flex as={Link} to={profilePath} mt='4'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={author.avatar_url}
							name={author.name}
							backgroundColor={useString}
						/>
					</Flex>
				)}

				<Flex width='100%' flexDirection='column' ml='2' mt='4'>
					<Flex
						flexDirection='column'
						backgroundColor='gray.100'
						borderRadius='0.5rem'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
						width='100%'
					>
						<Flex alignItems='flex-start' justifyContent='space-between' mt={3}>
							{renderName()}
							<Flex alignItems='center'>
								{dataVisible && (
									<Text
										color='#8B90A0'
										fontWeight='500'
										mr='2'
										fontSize='0.625rem'
										mb='1'
									>
										{handleDateFormat(
											content.created_at,
											userLanguage === 'PT_BR' ? 'ptBR' : ''
										)}
									</Text>
								)}
								{userId && hasDeletePrivilege && (
									<DeleteConfirmButton
										onConfirm={deleteReply}
										iconButtonProps={{ fontSize: 'xl', color: 'pink.500' }}
										setDataVisible={() => setDataVisible(true)}
										setNotDataVisible={() => setDataVisible(false)}
									/>
								)}
							</Flex>
						</Flex>
						<Text
							ml='4'
							mb='3'
							fontSize='sm'
							color='#232735'
							fontWeight='500'
							pr='2'
							variant='breakLines'
						>
							<Hyperlink>{content.text.trim()}</Hyperlink>
						</Text>
					</Flex>
					<Flex marginLeft='15' mt='1'>
						<Like
							commentsAgreements={content.agreements}
							userId={userId}
							pointId={content.essay_point_id}
							responseId={content.id}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}
