import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Button,
	Text,
	ModalHeader,
	Heading,
	ModalFooter,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'

import { GetToKnow } from 'modules/signup/components/get-to-know'
import { getToKnowValidation } from 'modules/signup/validators'
import { UserExtraInfo } from 'modules/user/types'
import { updateInfos } from 'modules/user/usecases/update-user-extra-info'

type Props = {
	isOpen: boolean
	onClose: () => void
	redirect: string
}

export const UserInformationModal = ({ isOpen, onClose, redirect }: Props) => {
	const { t } = useTranslation()
	const toast = useToast()
	const navigate = useNavigate()
	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(getToKnowValidation())
	})
	const { isValid } = methods.formState
	const onSubmit = async (values: FieldValues) => {
		try {
			updateInfos(values as UserExtraInfo)
			navigate(redirect)
		} catch (error) {
			toast({
				title: t('login:term'),
				position: 'top',
				status: 'error',
				isClosable: true
			})
		}
	}
	return (
		<Modal
			closeOnOverlayClick={false}
			size='xl'
			isOpen={isOpen}
			onClose={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent padding={8}>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<ModalHeader
							mb={4}
							alignItems='center'
							display='flex'
							flexDir='column'
						>
							<Heading
								mb={4}
								fontSize={['lg', '1xl', '2xl']}
								fontWeight='extrabold'
							>
								{t('signup:usage.better')}
							</Heading>
							<Text fontSize='md' fontWeight='medium' color='gray.400'>
								{t('signup:usage.ask1')}
							</Text>
						</ModalHeader>
						<ModalBody>
							<GetToKnow />
						</ModalBody>
						<ModalFooter>
							<Button
								isDisabled={!isValid}
								type='submit'
								mt={8}
								w='100%'
								colorScheme='custom_scheme_color_button'
							>
								{t('buttons:send')}
							</Button>
						</ModalFooter>
					</form>
				</FormProvider>
			</ModalContent>
		</Modal>
	)
}
