import React from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, BoxProps, Flex, Icon, Tag, Text } from '@chakra-ui/react'

import { IconStToolsSolid } from '../icons'

interface HexagonIconProps extends BoxProps {
	color: string
	visible?: boolean
	isEvaluation?: boolean
	Icon?: any
}

const SelectColor: React.FC<HexagonIconProps> = ({
	color,
	visible = true,
	isEvaluation,
	Icon: PointIcon,
	...props
}) => {
	const bgColor = visible ? `${color}.50` : `gray.190`
	const fgColor = visible ? `${color}.500` : `gray.300`
	const { t } = useTranslation()

	const evaluationTreatment =
		isEvaluation && visible === false ? { mb: [4, 2] } : {}

	return (
		<Flex
			flexDirection='column'
			gap={2}
			alignItems='center'
			transition='all 0.3s ease'
			mt={visible === false ? 2 : '0'}
			height={visible === false ? '60px' : '32px'}
			{...evaluationTreatment}
			{...props}
		>
			{PointIcon ? (
				<PointIcon
					fontSize='5xl'
					width='48px'
					height='48px'
					color={visible === false ? fgColor : color}
				/>
			) : (
				<Avatar
					size='sm'
					bg={bgColor}
					icon={<Icon boxSize='4' as={IconStToolsSolid} color={fgColor} />}
					transition='transform 0.3s ease'
					transform={visible === false ? 'translate(-4px)' : 'translateY(0)'}
				/>
			)}
			<Tag
				maxWidth='60px'
				size='sm'
				colorScheme='gray'
				alignSelf='center'
				transition='all 0.3s ease'
				opacity={visible === false ? 1 : 0}
				visibility={visible === false ? 'visible' : 'hidden'}
				height={visible === false ? '20px' : '0'}
				overflow='hidden'
			>
				<Text mr={1} opacity={0.5}>
					{t('map:points.action.state.hidden')}
				</Text>
			</Tag>
		</Flex>
	)
}

export default SelectColor
