import { fetch } from 'config'

import { Template } from '../types'

export async function getTemplateById(templateId: string) {
	const { data } = await fetch<Template>({
		url: `/templates/v1/template/${templateId}`,
		method: 'GET'
	})

	return data
}
