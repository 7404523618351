import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Text,
	ModalBody,
	ModalHeader,
	HStack,
	Button,
	useRadio,
	Box,
	useRadioGroup,
	ModalFooter,
	Stack,
	Image
} from '@chakra-ui/react'

import Paypal from '../../../assets/img/paypal.svg'
import Pix from '../../../assets/img/pix.svg'
import { ModalSize, Status } from '../buy-credits'

interface ChoosePaymentProps {
	onNext: (paymentType: Status) => void
	setStatus: React.Dispatch<React.SetStateAction<Status>>
	setModalSize: React.Dispatch<React.SetStateAction<ModalSize>>
}

export const ChoosePayment = ({
	onNext,
	setStatus,
	setModalSize
}: ChoosePaymentProps) => {
	const { t } = useTranslation()
	const [paymentType, setPaymentType] = useState<string>('pix')

	const { handleSubmit } = useForm({
		mode: 'onChange'
	})
	const onSubmit = () => {
		onNext(paymentType as Status)
		setModalSize('5xl')
	}

	return (
		<>
			<ModalHeader>
				<Text fontSize='xl' fontWeight='bold'>
					{t('credits:choosePayment')}
				</Text>
			</ModalHeader>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<InputPayment
						defaultValue='pix'
						name='paymentOptions'
						onChange={(value) => {
							setPaymentType(value)
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<HStack w='100%'>
						<Button onClick={() => setStatus('amount')} colorScheme='gray'>
							{t('buttons:back')}
						</Button>
						<Button
							type='submit'
							w='full'
							colorScheme='custom_scheme_color_button'
							isDisabled={!paymentType}
						>
							{t('buttons:continue')}
						</Button>
					</HStack>
				</ModalFooter>
			</form>
		</>
	)
}

type InputProps = {
	name: string
	defaultValue?: string
	isDisabled?: boolean
	onChange: (payments: string) => void
}

// add paypal on this array
const paymentOptions = ['pix']

const InputPayment = ({ isDisabled, ...props }: InputProps) => {
	const { t } = useTranslation()
	const { getRootProps, getRadioProps } = useRadioGroup(props)
	const group = getRootProps()

	return (
		<Stack {...group} wrap='wrap' w='100%'>
			{paymentOptions.map((paymentOption) => {
				const radio = getRadioProps({ value: paymentOption })
				return (
					<>
						{/* {paymentOption === 'paypal' && (
							<PaymentRadio key={paymentOption} {...radio}>
								<Image src={Paypal} alt="PayPal icon" htmlWidth={105} />
							</PaymentRadio>
						)} */}
						{paymentOption === 'pix' && (
							<PaymentRadio key={paymentOption} {...radio}>
								<Image src={Pix} alt='Pix icon' htmlWidth={105} />
							</PaymentRadio>
						)}
						{/* remove the components bellow and use the PaymentRadio when pix is ready */}
						<Box
							as='label'
							cursor='pointer'
							h={20}
							borderRadius={8}
							borderWidth={1}
							borderColor='gray.190'
							opacity={'50%'}
							key={paymentOption}
						>
							<Box
								bg='none'
								py={2}
								px={4}
								display='flex'
								flexDir='row'
								justifyContent='center'
								alignItems='center'
								h={20}
								_checked={{
									borderWidth: '2px',
									borderColor: 'blue.500',
									borderRadius: 8,
									bg: 'blue.2'
								}}
							>
								<Box flex={1} />
								<Image
									mx={10}
									flex={1}
									src={Paypal}
									alt='PayPal icon'
									htmlWidth={105}
								/>
								<Box
									flex={1}
									fontSize='xs'
									fontWeight='semibold'
									color='gray.300'
									opacity='50%'
									borderWidth='1px'
									borderColor='gray.300'
									borderRadius='base'
									px='1'
									textAlign='center'
								>
									{t('buttons:comming')}
								</Box>
							</Box>
						</Box>
					</>
				)
			})}
		</Stack>
	)
}

type Props = ReturnType<typeof useRadioGroup>
type RadioPropsReturn = ReturnType<Props['getRadioProps']>

const PaymentRadio: React.FC<RadioPropsReturn> = ({ ...props }) => {
	const { getInputProps, getCheckboxProps } = useRadio(props)
	const input = getInputProps()
	const checkbox = getCheckboxProps()

	return (
		<Box
			as='label'
			cursor='pointer'
			h={20}
			borderRadius={8}
			borderWidth={1}
			borderColor='gray.190'
		>
			<Box as='input' {...input} />
			<Box
				{...checkbox}
				bg='none'
				py={2}
				px={4}
				display='flex'
				flexDir='row'
				justifyContent='center'
				alignItems='center'
				h={20}
				_checked={{
					borderWidth: '2px',
					borderColor: 'blue.500',
					borderRadius: 8,
					bg: 'blue.2'
				}}
			>
				{props.children}
			</Box>
		</Box>
	)
}
