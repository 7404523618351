const URL_REGEX = /(http:|https:|ftp:|www.)/

export const hasNoUrl = (text = '') => {
	const result = URL_REGEX.test(text)
	return !result
}

export const hasUrl = (text = '') => {
	const result = URL_REGEX.test(text)
	return result
}
