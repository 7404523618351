import { ReactElement } from 'react'

import {
	Box,
	Divider,
	Flex,
	FlexProps,
	HStack,
	Icon,
	Text,
	useDisclosure
} from '@chakra-ui/react'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { IconStCoin } from 'modules/common/components/icons'

import { CustomSchemeButton } from '../buttons/custom-scheme-button'

type Props = {
	price: number
	onClick: () => void
	isDisabled?: boolean
	text: string
	icon: ReactElement
	userCredits: number
	buttonProps?: FlexProps
	isLoading?: boolean
}

export const PrimaryCreditsBtn = ({
	isDisabled,
	isLoading,
	userCredits,
	text,
	onClick,
	price,
	icon,
	buttonProps
}: Props) => {
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()

	const handleCreate = () => {
		if (userCredits >= price) {
			onClick()
			return
		}
		emptyCreditsDisclosure.onOpen()
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	return (
		<Flex
			borderRadius='lg'
			border='1px'
			borderColor={'primary.dark'}
			bg={'primary.dark'}
			opacity={isDisabled ? '50%' : '100%'}
			justifyContent='space-evenly'
			role='group'
			mr='48px'
			{...buttonProps}
		>
			<CustomSchemeButton
				leftIcon={icon}
				w='full'
				p={2.5}
				fontSize='sm'
				fontWeight='bold'
				textAlign='center'
				onClick={handleCreate}
				isDisabled={isDisabled}
				isLoading={isLoading}
				backgroundColor={'primary.dark'}
				_hover={{ bg: 'primary.dark' }}
			>
				{text}
			</CustomSchemeButton>
			<Flex>
				<Divider orientation='vertical' />
			</Flex>
			<Box alignSelf='center'>
				<HStack px={2}>
					<Icon w={4} h={4} as={IconStCoin} />
					<Text fontWeight='bold' fontSize='sm' color='white'>
						{price}
					</Text>
				</HStack>
			</Box>
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={userCredits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Flex>
	)
}
