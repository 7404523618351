import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#2C6E70',
		light: '#8DBDBB'
	},

	secondary: {
		dark: '#FDB400',
		light: '#EED189'
	}
}

export default themeColors
