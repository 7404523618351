import { useState, useRef, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import {
	Box,
	Center,
	Divider,
	Flex,
	Skeleton,
	Text,
	VStack,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { useJourney } from 'modules/journeys/hooks'
import { deleteEssayAnswer } from 'modules/map/components/view-evaluation-point/usecases/delete-essay-answer'
import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { EvaluationResponse } from 'modules/map/types'
import { Role } from 'modules/user/constants'
import { User } from 'modules/user/types'

import { AnonymAvatar } from '../../view-divergence-point/anonym-mode/anonym-avatar'
import {
	Like,
	Comment,
	EvaluateEssay,
	OpenEvaluationModal
} from './essay-card-buttons'

type Props = {
	content: EvaluationResponse
	isOpen: boolean
	userLanguage: string
	hasDeletePrivilege: boolean
	hasEvaluation: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	currentUser: User
}

export const EssayCard = ({
	setIsOpen,
	hasEvaluation,
	hasDeletePrivilege,
	content,
	isOpen,
	userLanguage,
	currentUser
}: Props) => {
	const { t } = useTranslation()
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const anonymName = useRef('anonym'.padStart(6 + Math.random() * 10, '0'))
	const { isAnonymous, setHasAnswers, isUserEvaluation } = useEvaluationStore()
	const tone = isAnonymous ? 800 : 500
	const useString = useStringToColor({ text: content.author.name }, [tone])[1]
	const boxCommentRef = useRef<HTMLDivElement | null>(null)
	const profilePath = `/dashboard/profile/${content.author.username}`
	const authorAvatarUrl = content.author.avatar_url
	const [dataVisible, setDataVisible] = useState(true)
	const name = isAnonymous ? anonymName.current : content.author.name
	const toast = useToast()
	const { deleteEssayState, essayPointResponses } = useEssaysStore()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))
	const commentCount = content.responses?.length || content.reply_count
	const { data: users = [] } = useJourney(journeyId as string, {
		select: (journey) => journey.users
	})
	const isAdminMentor = !!users
		.filter((user) => user.id === currentUser?.id)
		.find(({ project_roles }) =>
			Role(project_roles).can('evaluation_point.evaluate')
		)
	const canEvaluate = isAdminMentor || isUserEvaluation

	const deleteCommentHandler = async () => {
		if (essayPointResponses.length === 1) {
			setHasAnswers(false)
		}
		await deleteEssayAnswer({
			answerId: content.id,
			pointId: content.essay_point_id
		})
		deleteEssayState(content)
		const commentInfo = {
			id: content.id,
			essay_point_id: content.essay_point_id
		}
		emit('DELETE', commentInfo)

		toast({
			title: t('divergencePoint:reply_deleted'),
			isClosable: true,
			status: 'success'
		})
		setDataVisible(false)
	}

	const renderName = () => {
		return (
			<Skeleton
				isLoaded={!isAnonymous}
				animation='none'
				endColor='#EDEDED'
				ml='2'
			>
				<Text
					_hover={{ textDecoration: 'underline' }}
					color='gray.1'
					fontWeight='600'
					fontSize='sm'
					ml='2'
				>
					{name}
				</Text>
			</Skeleton>
		)
	}

	return (
		<Flex
			w='full'
			mt='2'
			px='1.125rem'
			pt='1.125rem'
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor={useString}
			backgroundColor='#fff'
			ref={boxCommentRef}
		>
			<Flex w='full' justifyContent='space-between'>
				{isAnonymous ? (
					<Flex alignItems='center'>
						<AnonymAvatar
							isAnonym={isAnonymous}
							avatarUrl={authorAvatarUrl}
							name={content.author.name}
							backgroundColor={useString}
						/>
						{renderName()}
					</Flex>
				) : (
					<Flex as={Link} to={profilePath} alignItems='center'>
						<AnonymAvatar
							isAnonym={isAnonymous}
							avatarUrl={authorAvatarUrl}
							name={content.author.name}
							backgroundColor={useString}
						/>
						{renderName()}
					</Flex>
				)}
				<Flex alignItems='center'>
					{dataVisible && (
						<Text color='#8B90A0' fontWeight='500' fontSize='xs' mr='2'>
							{handleDateFormat(
								content.created_at,
								userLanguage === 'PT_BR' ? 'ptBR' : ''
							)}
						</Text>
					)}
					{hasDeletePrivilege && (
						<DeleteConfirmButton
							onConfirm={deleteCommentHandler}
							iconButtonProps={{
								fontSize: 'xl',
								color: 'pink.500',
								background: 'transparent'
							}}
							setDataVisible={() => setDataVisible(true)}
							setNotDataVisible={() => setDataVisible(false)}
						/>
					)}
				</Flex>
			</Flex>

			<Flex gap={4} direction='column' mt='2'>
				<Text fontSize={['sm', 'md']}>{content.title}</Text>
				<Text fontSize={['sm', 'md']} variant='breakLines'>
					{content.text.trim()}
				</Text>
			</Flex>

			{isMobile ? (
				<VStack>
					<Flex
						mt='4'
						w='full'
						alignItems='center'
						justifyContent='space-around'
						direction='row'
						borderTop='1px solid #DFE0E3'
						borderBottom={isOpen ? '1px solid #DFE0E3' : 'none'}
					>
						<Like
							commentsAgreements={content.agreements}
							userId={currentUser.id}
							pointId={content.essay_point_id}
							responseId={content.id}
						/>
						<Box w='1px' h='60%' backgroundColor='#DFE0E3' />
						<Comment
							commentsCount={commentCount}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
						/>
					</Flex>
					{canEvaluate && (
						<>
							<Divider color='#DFE0E3' />
							<Center height={16}>
								{!hasEvaluation ? (
									<EvaluateEssay
										essayId={content.id}
										pointId={content.essay_point_id}
									/>
								) : (
									<OpenEvaluationModal content={content} />
								)}
							</Center>
						</>
					)}
				</VStack>
			) : (
				<Flex
					mt='4'
					alignItems='center'
					w='full'
					h={16}
					justifyContent='space-around'
					borderTop='1px solid #DFE0E3'
					borderBottom={isOpen ? '1px solid #DFE0E3' : 'none'}
				>
					<Like
						commentsAgreements={content.agreements}
						userId={currentUser.id}
						pointId={content.essay_point_id}
						responseId={content.id}
					/>
					<Box w='1px' h='60%' backgroundColor='#DFE0E3' />
					<Comment
						commentsCount={commentCount}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
					/>
					{!hasEvaluation && canEvaluate && (
						<>
							<Box w='1px' h='60%' backgroundColor='#DFE0E3' />
							<EvaluateEssay
								essayId={content.id}
								pointId={content.essay_point_id}
							/>
						</>
					)}
				</Flex>
			)}
		</Flex>
	)
}
