import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { BiStopwatch } from 'react-icons/bi'

import { HStack, Icon, Text } from '@chakra-ui/react'

export const Counter = ({
	setShowExpired
}: {
	setShowExpired: Dispatch<SetStateAction<boolean>>
}) => {
	const [minutes, setMinutes] = useState(30)
	const [seconds, setSeconds] = useState(0)

	useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1)
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval)
				} else {
					setMinutes(minutes - 1)
					setSeconds(59)
				}
			}
			if (seconds === 0 && minutes === 0) {
				setShowExpired(true)
			}
		}, 1000)
		return () => {
			clearInterval(myInterval)
		}
	})

	return (
		<HStack>
			<Icon w={5} h={5} as={BiStopwatch} />
			<Text marginInlineStart='0 !important' fontSize={14}>
				<Trans
					i18nKey='credits:pix.expire'
					values={{
						value: `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
					}}
					components={[<strong />]}
				/>
			</Text>
		</HStack>
	)
}
