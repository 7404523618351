import { fetch } from 'config'

import { ChatRoom } from 'modules/assistants/types/chat-room'

export const closeChatRoom = async (chatRoomId: string) => {
	return await fetch<ChatRoom>({
		url: `projects/v1/chat-room/${chatRoomId}/close`,
		method: 'PATCH'
	})
}
