import { useQuery } from 'react-query'

import { providerKeys } from '../query'
import { getPrivateProvider } from '../usecases/get-private-provider'

export const useGetPrivateProviders = (domain: string) => {
	return useQuery(providerKeys.privateProviders(), () =>
		getPrivateProvider({ domain })
	)
}
