import { TenantColors } from '../types'
import tdsCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const tdsColors: TenantColors = {
	...tdsCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default tdsColors
