import { fetch } from 'config'

import { BillingAccount, BillingAccountRequest } from '../types'

export const createBillingAccount = (data: BillingAccountRequest) => {
	return fetch<BillingAccount>({
		url: 'billing/v1/billing/account',
		method: 'POST',
		data
	})
}
