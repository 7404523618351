import { useTranslation } from 'react-i18next'

import {
	Button,
	Image,
	Tooltip,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import Plus from 'modules/map/components/chat/plus.svg'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import AnswerPoint from '../answer-point'
import { getUserResponsesCount } from '../usecases/get-user-responses-count'
import { ModalDeleteAllAnswers } from './modal-delete-all-user-answers'

type Props = {
	topic: string
	pointId: string
	journeyId: string
}

export const CreateAnswerButton = ({ topic, pointId, journeyId }: Props) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false })
	const answerDisclosure = useDisclosure()
	const deleteDisclosure = useDisclosure()
	const { isMultipleResponse } = useEvaluationStore()
	const toast = useToast()

	const openAnswerModal = async () => {
		try {
			const count = await getUserResponsesCount(pointId)
			if (!isMultipleResponse && count > 0) {
				deleteDisclosure.onOpen()
				return
			}
			answerDisclosure.onOpen()
		} catch (error) {
			toast({
				title: t('errors:default'),
				status: 'error',
				isClosable: true,
				position: 'bottom'
			})
		}
	}

	return (
		<>
			<Tooltip placement='left' label={t('divergencePoint:chat:add_comment')}>
				<Button
					onClick={openAnswerModal}
					position={isMobile ? 'fixed' : 'absolute'}
					bottom='6'
					right='6'
					borderRadius='50%'
					max-width='3rem'
					w='3rem'
					h='3rem'
					max-height='3rem'
					backgroundColor='evaluation_create_answer_button'
					_hover={{ backgroundColor: 'none' }}
				>
					<Image src={Plus} />
				</Button>
			</Tooltip>
			<AnswerPoint {...answerDisclosure} topic={topic} pointId={pointId} />
			<ModalDeleteAllAnswers
				{...deleteDisclosure}
				journeyId={journeyId}
				pointId={pointId}
			/>
		</>
	)
}
