import { format } from 'date-fns'
import { FieldArrayRenderProps } from 'formik'

import { createId } from 'modules/common/utils/create-id'

export const todayDate = format(new Date(), 'yyyy-MM-dd')

export const initialValues = {
	date: todayDate,
	hour: '',
	questions: [
		{
			id: createId(),
			text: '',
			options: [
				{
					id: createId(),
					text: ''
				}
			]
		}
	]
}

export const addQuestion = (helper: FieldArrayRenderProps, index: number) => {
	helper.insert(index, {
		text: '',
		id: createId(),
		options: [
			{
				id: createId(),
				text: ''
			}
		]
	})
}

export const makeConvergenceQuestion = () => ({
	text: '',
	id: createId(),
	options: [
		{
			id: createId(),
			text: ''
		}
	]
})

export const addOption = (helper: FieldArrayRenderProps, index: number) => {
	helper.insert(index, {
		id: createId(),
		text: ''
	})
}

export function correctHourAmPm(
	date: string,
	formatHours: string,
	language: string
) {
	const [hour, minute] = date.split(':')
	if (formatHours === 'pm' && language === 'en') {
		return `${parseInt(hour, 10) + 12}:${minute}`
	} else {
		return date
	}
}

export function addLeadingZero(value: string, lengthCompare: number) {
	return value.length === lengthCompare ? `0${value}` : value
}

export function changeDateOrder(
	date: string,
	language: string,
	oldSeparator: string = '/',
	newSeparator: string = '-'
) {
	const [month, day, year] = date.split(oldSeparator)
	let newDate: string
	if (language === 'en')
		newDate = `${year}${newSeparator}${addLeadingZero(month, 1)}${newSeparator}${day}`
	else
		newDate = `${year}${newSeparator}${day}${newSeparator}${addLeadingZero(month, 1)}`
	return newDate
}
