import React from 'react'

import { Divider, Center, useBreakpointValue } from '@chakra-ui/react'

export const CustomDivider: React.FC = () => {
	const responsiveHeight = useBreakpointValue({
		base: 'none',
		md: '24',
		lg: '20'
	})

	const isMobile = useBreakpointValue({
		base: true,
		md: false
	})

	if (isMobile) {
		return <Divider />
	}

	return (
		<Center h={responsiveHeight}>
			<Divider borderColor='blue.50' orientation='vertical' />
		</Center>
	)
}
