import React from 'react'
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai'

import {
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	HStack,
	Icon,
	Text
} from '@chakra-ui/react'

import { NavBarLink } from 'modules/common/components/nav-bar-link'

import { NavButton } from './nav-button'

type SubItem = {
	name: string
	path: string
	match?: () => boolean
}

type ItemProps = {
	text: string
	badgeText?: string
	subItems?: SubItem[]
	icon: React.FC
	handleClick?: () => void
}

export const NavItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { text, badgeText, subItems, icon, handleClick } = props

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<>
					<NavButton
						asNav={!subItems}
						to='/dashboard'
						handleClick={handleClick}
					>
						<Box
							// color={isExpanded ? 'white' : 'blue.200'}
							flex='1'
							flexDirection='column'
							textAlign='left'
							color={'navbar_text_color'}
							opacity={isExpanded ? 1 : 0.8}
						>
							<HStack>
								<Box w={6}>
									{subItems && (
										<Text fontSize='xs'>
											{isExpanded ? <AiFillCaretDown /> : <AiFillCaretRight />}
										</Text>
									)}
								</Box>
								<Box w={6}>
									<Icon as={icon} fontSize='large' strokeWidth={0.6} />
								</Box>
								<Text fontWeight='semibold' fontSize='sm' whiteSpace='nowrap'>
									{text}
								</Text>
							</HStack>
						</Box>

						{badgeText && (
							<Badge color='blue.500' background='white' variant='round'>
								{badgeText}
							</Badge>
						)}
					</NavButton>

					{subItems && (
						<AccordionPanel pl={16}>
							{subItems.map((subItem) => (
								<NavBarLink
									to={subItem.path}
									key={Math.random() * 5}
									fontSize='sm'
									fontWeight='semibold'
									onClick={handleClick}
									isActive={subItem.match?.()}
								>
									{subItem.name}
								</NavBarLink>
							))}
						</AccordionPanel>
					)}
				</>
			)}
		</AccordionItem>
	)
}
