import { fetch } from 'config'

export const updateNoticePoint = (
	pointId: string,
	description: string,
	send_email: boolean
) => {
	return fetch({
		url: `/projects/v1/notice-point/${pointId}`,
		method: 'PUT',
		data: { description, send_email }
	})
}
