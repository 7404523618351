import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	VStack,
	useTheme
} from '@chakra-ui/react'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { LoginInitialType } from 'modules/login/validators'

type InitialFormProps = {
	onSubmit: () => void
	register: UseFormRegister<LoginInitialType>
	errors: FieldErrors<LoginInitialType>
	isLoading: boolean
}

export const InitialLoginForm = ({
	onSubmit,
	register,
	errors,
	isLoading
}: InitialFormProps) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing={4}>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel htmlFor='email' fontWeight='semibold' color={'gray.400'}>
						{t('login:email')}
					</FormLabel>
					<Input
						placeholder={t('login:placeholders.email')}
						_placeholder={{ fontWeight: 'medium', color: 'gray.300' }}
						autoComplete='username'
						_focus={{
							borderColor: theme.colors.input_border,
							boxShadow: `0 0 0 1px ${theme.colors.input_border}`
						}}
						{...register('email')}
					/>
					{errors.email && (
						<FormErrorMessage>{errors.email.message}</FormErrorMessage>
					)}
				</FormControl>
			</VStack>

			<VStack spacing={3}>
				<CustomSchemeButton
					type='submit'
					data-testid='submit'
					mt={8}
					width='full'
					isLoading={isLoading}
					colorScheme={theme.colors.login_button_schema}
					isDisabled={!!errors.email}
				>
					{t('login:title')}
				</CustomSchemeButton>
			</VStack>
		</form>
	)
}
