import { useQuery } from 'react-query'

import { exchangeProviderAuthorizationCode } from '../usecases/get-user-authorization-code'

type Params = {
	providerId: string
	code: string
	redirectUri: string
}

export const useAuthenticateWithProvider = ({
	code,
	providerId,
	redirectUri
}: Params) => {
	return useQuery('authenticateWithProvider', () =>
		exchangeProviderAuthorizationCode({ providerId, code, redirectUri })
	)
}
