import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLike, BiSolidLike } from 'react-icons/bi'

import { Text, Button, Icon, Box, Tooltip } from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { useDivergenceStore } from 'modules/map/store/divergence-store'

import useCommentsStore, { Agreement } from '../../store/use-comments-store'

type Props = {
	commentsAgreements: Agreement[]
	commentsId: string
	commentsQuestionId: string
	userId: string
	isReply: boolean
	replyId?: string
	journeyId: string
	userName: string
}

export const LikeButton: React.FC<Props> = ({
	userId,
	commentsAgreements,
	commentsId,
	commentsQuestionId,
	isReply,
	replyId,
	journeyId,
	userName
}) => {
	const hasUserLike = commentsAgreements.some(
		(comment) => comment.user_id === userId
	)
	const countLike = commentsAgreements.length
	const { likeComment, dislikeComment, updateCommentState } = useCommentsStore()
	const { isAnonym } = useDivergenceStore()
	const { t } = useTranslation()
	const { emit } = useSocket(divergencyPointChannel({ projectId: journeyId }))

	const stringLike =
		countLike > 0
			? countLike === 1
				? `${countLike} ${t('divergencePoint:like')}`
				: `${countLike} ${t('divergencePoint:likes')}`
			: t('divergencePoint:Like')

	const handleLike = async () => {
		const commentId = isReply && replyId ? replyId : commentsId
		const commentOpinion = {
			comment_id: commentId,
			question_id: commentsQuestionId,
			isReply,
			user_id: userId,
			parent_id: isReply ? commentsId : undefined,
			user_name: !hasUserLike ? userName : undefined
		}

		if (hasUserLike) {
			const savedAgreement = await dislikeComment(commentOpinion)
			updateCommentState(savedAgreement)
			const agreementInfo = {
				question_id: savedAgreement.question_id,
				parent_id: savedAgreement.parent_id,
				id: savedAgreement.id
			}
			emit('DELETE_AGREEMENT', agreementInfo)
		} else {
			const savedAgreement = await likeComment(commentOpinion)
			updateCommentState(savedAgreement)
			const agreementInfo = {
				question_id: savedAgreement.question_id,
				parent_id: savedAgreement.parent_id,
				id: savedAgreement.id
			}
			emit('ADD_AGREEMENT', agreementInfo)
		}
	}

	const renderLabels = () => {
		if (isAnonym) {
			return null
		}

		if (commentsAgreements.length) {
			return (
				<Box>
					{commentsAgreements.map((agreement, index) => (
						<Text key={`${agreement.user_id}_${index}`}>{agreement.name}</Text>
					))}
				</Box>
			)
		}

		return null
	}

	return (
		<Tooltip label={renderLabels()}>
			<Button background='transparent' onClick={handleLike}>
				<Icon
					as={hasUserLike ? BiSolidLike : BiLike}
					type='solid'
					w='1.500rem'
					h='1.500rem'
					color='evaluation_answer_like_button'
				/>
				<Text
					ml='2'
					color='evaluation_answer_like_button'
					fontWeight='600'
					fontSize='xs'
				>
					{stringLike}
				</Text>
			</Button>
		</Tooltip>
	)
}
