import { createTemplate } from './create-template'
import { deleteTemplate } from './delete-template'
import { exploreTemplates } from './explore-templates'
import { getDivergencePoint } from './get-divergence-point'
import { getMapPoints } from './get-map-points'
import { getMyTemplates } from './get-my-templates'
import { getOfficialTemplates } from './get-official-templates'
import { getTemplateById } from './get-template'
import { getTemplateKits } from './get-template-kits'
import { markAsFavorite } from './mark-as-favorite'
import { removeFavorite } from './remove-favorite'
import { updateTemplate } from './update-template'
import { updateVisibility } from './update-visibility'

const TemplatesApi = {
	getMyTemplates,
	createTemplate,
	updateTemplate,
	updateVisibility,
	deleteTemplate,
	markAsFavorite,
	exploreTemplates,
	removeFavorite,
	getTemplateById,
	getOfficialTemplates,
	getMapPoints,
	getTemplateKits,
	getDivergencePoint
}

export default TemplatesApi
