import { ListPermissions } from 'config/permissions/type'

const navbarPermissions = {
	navbar_options: {
		OWNER: ['tools', 'blog'],
		MEMBER: ['tools', 'blog', 'labs'],
		DEFAULT: ['tools', 'blog', 'view', 'labs']
	},
	journeys_options: {
		OWNER: ['explore-journeys'],
		MEMBER: ['explore-journeys'],
		DEFAULT: ['select-template', 'explore-journeys']
	},
	labs_options: {
		OWNER: ['participation'],
		MEMBER: ['participation']
	}
}

const homePermissions = {
	empty_journey_buttons: {
		DEFAULT: ['create']
	}
}

const defaultListPermissions: ListPermissions = {
	...navbarPermissions,
	...homePermissions
}

export default defaultListPermissions
