import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	BiCalendar,
	BiEdit,
	BiMailSend,
	BiMap,
	BiPlusCircle,
	BiTimeFive
} from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	HStack,
	Icon,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	useTheme
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'
import { sendGAEvent } from 'config/trackers/google-analytics'
import { format } from 'date-fns'
import { Form, Formik } from 'formik'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { FormField } from 'modules/common/components/forms'
import { IconStPointConversation } from 'modules/common/components/icons'
import { formatToLocalDateTime } from 'modules/common/utils/format-to-local-date-time'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import {
	ErrorResponse,
	Point,
	Position
} from 'modules/map/modules/map-canvas/types'
import { makeHexagon } from 'modules/map/modules/map-canvas/utils/hexagon-utils'
import { usePositionHandler } from 'modules/map/modules/map-canvas/utils/usePositionHandler'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { ConversationPoint } from '../../types'
import {
	createConversationPoint,
	updateConversationPoint
} from '../../usecases'
import { CreateConversationPointValidation } from '../../validators'

const todayDate = format(new Date(), 'yyyy-MM-dd')

const initialValues = {
	description: '',
	date: todayDate,
	hour: '',
	place: ''
}

export const ConversationForm: React.FC<{
	point?: ConversationPoint
}> = ({ point }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const toast = useToast()
	const validation = CreateConversationPointValidation()
	const isMobile = useBreakpointValue({ base: true, md: false })
	const positionErrorHandler = usePositionHandler()
	const { mapId = '', id: projectId = '' } = useParams<{
		mapId: string
		id: string
	}>()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const updateInitialValues = () => {
		if (point) {
			const { description, opening_date, meeting_place } = point

			const dateWithTimeZone = new Date(formatToLocalDateTime(opening_date))
			const formatedDt = format(dateWithTimeZone, 'yyyy-MM-dd')
			const formatedHour = format(dateWithTimeZone, 'HH:mm')

			const updateValues = {
				description,
				date: formatedDt,
				hour: formatedHour,
				place: meeting_place
			}

			return updateValues
		}
		return initialValues
	}

	const formValues = point ? updateInitialValues() : initialValues

	const [subjectInputCount, setSubjectInputCount] = useState(
		formValues.description
	)

	const { addPoint, updatePoint, clearSelected } = useMapStore(
		({ actions }) => actions
	)

	const position = useMapStore(
		({ state }) => state.selectedPoint?.position
	) as Position

	const insertPointInTheMap = (data: Point) => {
		const pointType = 'CONVERSATION'
		const hexagon = makeHexagon(data.position.row, data.position.col, {
			...data,
			point_type: pointType
		})
		addPoint(hexagon)
		clearSelected()

		const pointInfo = {
			id: hexagon.id,
			position: hexagon.position,
			point_type: pointType
		}
		emit('ADD', pointInfo)
	}

	const createPoint = async (params: typeof initialValues) => {
		const { date, hour, description, place } = params
		const openAt = new Date(`${date}T${hour}`)

		const payload = {
			mapId: mapId as string,
			opening_date: openAt.toISOString(),
			description,
			meeting_place: place,
			position
		}

		try {
			const conversationPoint = await createConversationPoint(payload)
			insertPointInTheMap(conversationPoint)
			toast({
				title: t('map:points.conversation.create.success'),
				status: 'success'
			})
			sendGAEvent('create_conversation_point')
		} catch (error) {
			positionErrorHandler(mapId as string, error as ErrorResponse)
			clearSelected()
		}
	}

	const editPoint = async (params: typeof initialValues) => {
		const { date, hour, description, place } = params
		const openAt = new Date(`${date}T${hour}`)

		if (point) {
			const payload = {
				id: point.id,
				description,
				meeting_place: place,
				opening_date: openAt.toISOString()
			}

			try {
				const conversationPoint = await updateConversationPoint(payload)
				toast({
					title: t('map:points.conversation.update.success'),
					status: 'success'
				})
				const pointType = 'CONVERSATION'
				updatePoint({ ...conversationPoint, point_type: pointType })
				clearSelected()
				navigate('../../')
				const pointInfo = {
					id: conversationPoint.id,
					position: conversationPoint.position,
					point_type: pointType
				}
				emit('EDIT', pointInfo)
			} catch (error) {
				const message = error as string
				toast({ title: message, status: 'error' })
			}
		}
	}

	const handleSubmit = (values: typeof initialValues) => {
		if (point) {
			editPoint(values)
		} else {
			createPoint(values)
		}
	}

	const numberOfCharacters = 280

	const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement
		if (target.value.length <= numberOfCharacters) {
			setSubjectInputCount(target.value)
		}
	}

	const theme = useTheme() as { colors: any }
	const ConversationFormButton: React.FC<{
		disable: boolean
		children: React.ReactNode
	}> = ({ disable, children }) => {
		return (
			<CustomSchemeButton
				aria-label='submit button'
				colorScheme={theme.colors.conversation_points_button}
				type='submit'
				isDisabled={disable}
				w={isMobile ? 'full' : 'unset'}
			>
				{children}
			</CustomSchemeButton>
		)
	}

	return (
		<Formik
			initialValues={formValues}
			onSubmit={handleSubmit}
			validationSchema={validation}
			validateOnMount={true}
		>
			{({ isValid, isSubmitting }) => (
				<Form>
					<ModalContent>
						<ModalCloseButton mt={4} mr={isMobile ? 0 : 4} />
						<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
							<Stack direction='row' alignItems='center'>
								<Icon
									as={IconStPointConversation}
									color='black.500'
									fontSize='5xl'
								/>
								<Box>
									<Text fontWeight='semibold' fontSize={['lg', 'xl']}>
										{t('map:points.conversation.create.title')}
									</Text>
									{!isMobile && (
										<Text
											color='gray.400'
											fontWeight='medium'
											fontSize={['sm', 'md']}
										>
											{t('map:points.conversation.create.description')}
										</Text>
									)}
								</Box>
							</Stack>
						</ModalHeader>
						<ModalBody px={isMobile ? 4 : 8}>
							<Text mb={4} fontSize='md' fontWeight='semibold'>
								{t('map:points.conversation.create.subject.label')}
							</Text>
							<FormField
								name='description'
								maxLength={numberOfCharacters}
								onKeyUp={handleChange}
								placeholder={t(
									'map:points.conversation.create.subject.placeholder'
								)}
								variant='flushed'
							/>
							<HStack justify='flex-end' mt='1.5'>
								<Text fontSize='xs' fontWeight='medium' color='gray.200'>
									{subjectInputCount.length}/{numberOfCharacters}
								</Text>
							</HStack>
							<Box py={10}>
								<Stack color='gray.400'>
									<Stack direction={{ base: 'column', md: 'row' }}>
										<Stack direction='row' alignItems='baseline'>
											<Icon as={BiCalendar} />
											<Text fontWeight='semibold'>
												{t('map:points.conversation.create.date.label')}
											</Text>
											<FormField
												name='date'
												variant='filled'
												type='date'
												min={todayDate}
											/>
										</Stack>
										<Stack direction='row' alignItems='baseline'>
											<Icon as={BiTimeFive} />
											<Text fontWeight='semibold'>
												{t('map:points.conversation.create.hour.label')}
											</Text>
											<FormField
												name='hour'
												variant='filled'
												type='time'
												pattern='[0-9]{2}:[0-9]{2}'
												placeholder='17:30'
											/>
										</Stack>
									</Stack>
									<Stack direction='row' alignItems='baseline'>
										<Icon as={BiMap} />
										<Text fontWeight='semibold'>
											{t('map:points.conversation.create.place.label')}
										</Text>
										<FormField
											name='place'
											variant='filled'
											placeholder={t(
												'map:points.conversation.create.place.placeholder'
											)}
										/>
									</Stack>
								</Stack>
							</Box>
						</ModalBody>
						<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
							<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
								<Stack align='center' direction='row' color='gray.300'>
									<Icon as={BiMailSend} fontSize='2xl' />
									<Text fontSize='xs' fontWeight='medium'>
										{t('map:points.conversation.create.footer.description')}
									</Text>
								</Stack>
								<Box>
									{point ? (
										<ConversationFormButton disable={!isValid || isSubmitting}>
											<Stack direction='row' justify='space-around'>
												<Icon as={BiEdit} />
												<Text fontSize={['xs', 'md']}>
													{t('buttons:saveEdition')}
												</Text>
											</Stack>
										</ConversationFormButton>
									) : (
										<ConversationFormButton disable={!isValid || isSubmitting}>
											<Stack direction='row' justify='space-around'>
												<Icon as={BiPlusCircle} />
												<Text fontSize={['xs', 'md']}>
													{t('buttons:createPoint')}
												</Text>
											</Stack>
										</ConversationFormButton>
									)}
								</Box>
							</Stack>
						</ModalFooter>
					</ModalContent>
				</Form>
			)}
		</Formik>
	)
}
