import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheckCircle } from 'react-icons/bi'

import {
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	Text,
	Icon
} from '@chakra-ui/react'

const StepSucess = ({ onFinish }: { onFinish: () => void }) => {
	const { t } = useTranslation()

	return (
		<>
			<ModalHeader></ModalHeader>
			<ModalBody>
				<Box flexDirection='column' display='flex' alignItems='center'>
					<Icon mb={5} as={BiCheckCircle} fontSize='8xl' color='green.500' />
					<Text textAlign='center' fontSize='xl'>
						{t('forgotPassword:sucess.body')}
					</Text>
				</Box>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={onFinish}
					mb={3}
					width='full'
					colorScheme='custom_scheme_color_button'
				>
					{t('buttons:goToLogin')}
				</Button>
			</ModalFooter>
		</>
	)
}

export default StepSucess
