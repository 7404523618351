import { fetch } from 'config'

import { Comment } from '../types'

export async function addConversationComment(
	conversationPointId: string,
	text: string
) {
	const { data } = await fetch<Comment>({
		url: `/projects/v1/checkpoint/${conversationPointId}/comment`,
		method: 'POST',
		data: { text }
	})

	return data
}
