import { useContext } from 'react'

import { Box, Center, Image, Link } from '@chakra-ui/react'

import { DashboardContext } from 'modules/common/contexts/dashboard-context'
import { useTenant } from 'modules/common/contexts/global-tenant-context'

import actualLogo from '../../../logo/actual-logo'

type Props = {
	showContent: boolean
}

export const StrateegiaLogo = ({ showContent }: Props) => {
	const { sidebar, shrink } = useContext(DashboardContext)
	const { tenant, isTenantDefault } = useTenant()
	const currentLogo = actualLogo(tenant)

	return (
		<Box
			ml={sidebar.logoMargin}
			transitionDuration='normal'
			as={Link}
			href={`${window.location.origin}/dashboard`}
			w='70%'
			sx={{ marginRight: !showContent ? 10 : 0, display: 'block' }}
		>
			{shrink ? (
				<Center>
					<Image
						src={currentLogo}
						w={isTenantDefault(tenant) ? 12 : 'auto'}
						alt='Strateegia'
					/>
				</Center>
			) : (
				<Image
					src={currentLogo}
					w={isTenantDefault(tenant) ? 12 : 'auto'}
					alt='Strateegia'
				/>
			)}
		</Box>
	)
}
