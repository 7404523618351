import { fetch } from 'config'

import { MonitorPointStatusCommentResponse } from '../types/monitor-point'

export const statusCommentAgreement = async (
	commentId: string,
	mode: 'add' | 'delete'
) => {
	const response = await fetch<MonitorPointStatusCommentResponse>({
		url: `projects/v1/monitor-point/comments/${commentId}/agreement`,
		method: mode === 'add' ? 'POST' : 'DELETE'
	})

	return response.data
}
