import { fetch } from 'config'

import { CommentResponse } from '../types'

export async function fetchConversationCommentReplies(commentId: string) {
	const {
		data: { content }
	} = await fetch<CommentResponse>({
		url: `/projects/v1/checkpoint/comment/${commentId}/reply`,
		method: 'GET'
	})

	return content
}
