import { useTranslation } from 'react-i18next'

import { Box, HStack, Switch, Text } from '@chakra-ui/react'

export const Individual = ({
	isAnonymous,
	isIndividual
}: {
	isAnonymous: boolean
	isIndividual: boolean
}) => {
	const { t } = useTranslation()

	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					isDisabled={isAnonymous}
					colorScheme='switch_scheme_color_checked'
					isChecked={isIndividual}
				/>
				<Text>{t('evaluationPoint:settings.individual.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.anonymous.description')}
			</Text>
		</Box>
	)
}

export const Anonymous = ({
	isAnonymous,
	isIndividual
}: {
	isAnonymous: boolean
	isIndividual: boolean
}) => {
	const { t } = useTranslation()
	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					isDisabled={isIndividual}
					colorScheme='switch_scheme_color_checked'
					isChecked={isAnonymous}
				/>
				<Text>{t('evaluationPoint:settings.anonymous.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.multipleResponse.description')}
			</Text>
		</Box>
	)
}

export const MultipleResponse = ({
	isMultipleResponse
}: {
	isMultipleResponse: boolean
}) => {
	const { t } = useTranslation()

	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					colorScheme='switch_scheme_color_checked'
					isChecked={isMultipleResponse}
				/>
				<Text>{t('evaluationPoint:settings.multipleResponse.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.multipleResponse.description')}
			</Text>
		</Box>
	)
}

export const UserEvaluation = ({
	isUserEvaluation
}: {
	isUserEvaluation: boolean
}) => {
	const { t } = useTranslation()

	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					colorScheme='switch_scheme_color_checked'
					isChecked={isUserEvaluation}
				/>
				<Text>{t('evaluationPoint:settings.useEvaluation.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.useEvaluation.description')}
			</Text>
		</Box>
	)
}
