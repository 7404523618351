import { fetch } from 'config'

import { MonitorPoint, QuantitativeMonitorPoint } from '../types/monitor-point'

export const getMonitorPoint = async (monitorPointId: string) => {
	const response = await fetch<MonitorPoint & QuantitativeMonitorPoint>({
		url: `projects/v1/monitor-point/${monitorPointId}`,
		method: 'GET'
	})

	return response.data
}
