import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiCaretRight } from 'react-icons/bi'

import { Flex, Text, Icon, Tab } from '@chakra-ui/react'

import Hyperlink from 'modules/common/components/hyperlink'

export const QuestionCard: React.FC<{
	isIntro?: boolean
	text: string
	qId: string
	index: number
	questionCommentsCount: number
	isOpened: boolean
	click: () => void
	hasIntro?: boolean
}> = ({
	text,
	index,
	click,
	questionCommentsCount,
	isOpened,
	isIntro = false,
	hasIntro = false
}) => {
	const { t } = useTranslation()
	const commentsCount = questionCommentsCount
	const replyMessage =
		commentsCount === 1
			? t('divergencePoint:answer')
			: t('divergencePoint:answers')

	const cardText = isIntro
		? t('startingPoint:view.intro')
		: `${t('divergencePoint:question')} ${hasIntro ? index - 1 : index}`

	return (
		<Tab
			w='full'
			justifyContent='flex-start'
			onClick={click}
			boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			backgroundColor={isOpened ? 'gray.100' : 'transparent'}
			_selected={{}}
		>
			<Flex
				w='full'
				background='transparent'
				alignItems='center'
				justifyContent='space-between'
			>
				<Flex flexDirection='column' p='4'>
					<Text textAlign='start' fontWeight='500' color='#8B90A0'>
						{cardText}
					</Text>
					<Text textAlign='start' fontWeight='500' py='2' variant='breakLines'>
						<Hyperlink>
							{hasIntro && index - 1 === 0
								? text.length > 100
									? `${text.substr(0, 70)}...`
									: text
								: text}
						</Hyperlink>
					</Text>
					{!isIntro && (
						<Text
							textAlign='start'
							fontSize='xs'
							fontWeight='500'
							color='#8B90A0'
						>
							{`${commentsCount} ${replyMessage}`}
						</Text>
					)}
				</Flex>
				<Icon as={BiCaretRight} fontSize='xl' />
			</Flex>
		</Tab>
	)
}
