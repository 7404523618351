import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheck } from 'react-icons/bi'
import { useQueryClient } from 'react-query'

import {
	ModalBody,
	useBreakpointValue,
	Text,
	Stack,
	ModalFooter,
	Button,
	Icon
} from '@chakra-ui/react'

import { ConvergencePoint, ConvergenceQuestion } from '../../../types'
import { BeliefQuestion } from '../../belief-question'
import { AllocationForm } from '../allocation-form'

export const Reply = ({
	point,
	myAnswer,
	setIsEditingAnswer
}: {
	point: ConvergencePoint
	myAnswer?: ConvergenceQuestion[]
	setIsEditingAnswer?: Dispatch<SetStateAction<boolean>>
}) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const isEditingAnswer = myAnswer && !!setIsEditingAnswer

	const queryClient = useQueryClient()

	const onSuccess = () => {
		queryClient.invalidateQueries(['answer', point.id])
	}

	return (
		<AllocationForm
			pointId={point.id}
			questions={point.questions}
			onComplete={onSuccess}
			myAnswer={myAnswer}
			setIsEditingAnswer={setIsEditingAnswer}
		>
			<ModalBody p={0}>
				<Text
					textAlign='center'
					my={3}
					fontSize='sm'
					fontWeight='medium'
					px={8}
				>
					{t('map:points.convergence.distributePoints')}
				</Text>
				<Stack background='purple.50' py={4} px={isMobile ? 4 : 8}>
					{point.questions.map((question, index) => (
						<BeliefQuestion
							key={question.id}
							question={question}
							index={index}
						/>
					))}
				</Stack>
			</ModalBody>

			<ModalFooter justifyContent='space-around' px={isMobile ? 4 : 8}>
				<Stack
					w='full'
					justify='flex-end'
					align='center'
					direction='row'
					gap={4}
					py={isMobile ? 0 : 4}
				>
					{isEditingAnswer && (
						<Button
							w='fit-content'
							color='gray.400'
							bgColor='gray.180'
							fontSize={['xs', 'md']}
							fontWeight='semibold'
							onClick={() => setIsEditingAnswer(false)}
						>
							{t('buttons:cancel')}
						</Button>
					)}
					<Button
						fontSize={['xs', 'md']}
						w={['full', 'fit-content']}
						type='submit'
						colorScheme='custom_scheme_color_button'
						leftIcon={
							!isMobile ? <Icon as={BiCheck} fontSize='2xl' /> : undefined
						}
					>
						{isEditingAnswer
							? t('buttons:saveEdition')
							: t('buttons:submitAnswer')}
					</Button>
				</Stack>
			</ModalFooter>
		</AllocationForm>
	)
}
