import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#E0181B',
		light: '#F89294'
	},

	secondary: {
		dark: '#232735',
		light: '#8B90A0'
	}
}

export default themeColors
