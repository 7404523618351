import { useQuery } from 'react-query'

import { getMonitorPointStatus } from '../usecases/get-monitor-point-status'

export const useMonitorPointStatus = (monitorPointId: string) => {
	return useQuery(
		['monitorPointStatus', monitorPointId],
		() => getMonitorPointStatus(monitorPointId),
		{
			enabled: !!monitorPointId
		}
	)
}
