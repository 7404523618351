import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import {
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Text,
	useTheme
} from '@chakra-ui/react'

interface RadioOption {
	value: string
	label: string
	description: string
}

interface FormRadioGroupProps {
	label: string
	name: string
	options: RadioOption[]
	register: UseFormRegister<any>
	isRequired?: boolean
}

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
	label,
	name,
	options,
	register,
	isRequired
}) => {
	const theme = useTheme() as { colors: any }

	return (
		<FormControl isRequired={isRequired}>
			<FormLabel mb={-3} fontWeight='semibold' color='gray.400'>
				{label}
			</FormLabel>
			<RadioGroup defaultValue={options[0].value}>
				<Stack>
					{options.map((option, index) => (
						<Radio
							key={option.value}
							mt={index !== 0 ? -4 : undefined}
							_focus={{
								borderColor: theme.colors.input_border,
								borderWidth: '1px',
								boxShadow:
									'0px 3px 4px 0px rgba(35, 39, 53, 0.00), 0px 3px 30px 0px rgba(35, 39, 53, 0.07)'
							}}
							{...register(name)}
							value={option.value}
						>
							<Stack pt={5} spacing={0}>
								<Text fontWeight='medium'>{option.label}</Text>
								<Text fontSize='sm' color='gray.300'>
									{option.description}
								</Text>
							</Stack>
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		</FormControl>
	)
}
