import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ChakraProvider } from '@chakra-ui/react'

import { QueryClient } from 'config'
import ConnectivityDiagnostics from 'config/connectivity-diagnostics'
import { SetupTrackers } from 'config/trackers'
import AppRouter from 'routes/router'

import GlobalTenantContext from 'modules/common/contexts/global-tenant-context'
import UserTypeContext from 'modules/common/contexts/user-type-context'
import { Fonts } from 'modules/common/styles/fonts'
import { createTheme } from 'modules/common/styles/theme'

import MetaTenant from './config/meta-tenant'
import SetupClarity from './config/SetupClarity/index'

import 'i18n/i18n'
import 'modules/common/styles/global.css'

const App = () => {
	useEffect(() => {
		SetupTrackers()
	}, [])

	return (
		<React.StrictMode>
			<QueryClientProvider client={QueryClient}>
				<GlobalTenantContext>
					{(tenant: string) => {
						const theme = createTheme(tenant)
						return (
							<ChakraProvider theme={theme}>
								<ConnectivityDiagnostics />
								<Fonts />
								<HelmetProvider>
									<MetaTenant tenant={tenant} theme={theme} />
									<SetupClarity />
									<UserTypeContext>
										<AppRouter />
									</UserTypeContext>
								</HelmetProvider>
							</ChakraProvider>
						)
					}}
				</GlobalTenantContext>
				<ReactQueryDevtools initialIsOpen={true} />
			</QueryClientProvider>
		</React.StrictMode>
	)
}

const root = ReactDOM.createRoot(document.getElementById('main')!)
root.render(<App />)

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then((registration) => {
		registration.unregister()

		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(async (names) => {
				await Promise.all(names.map((name) => caches.delete(name)))
			})
		}
	})
}
