import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button, Flex, HStack, Icon, Text, VStack, Textarea, useToast } from '@chakra-ui/react'
import { IconStStrateegiaBlue } from 'modules/common/components/icons'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { GoPencil } from 'react-icons/go'
import { EvaluationResponse } from 'modules/map/types'
import { useUpdateEssayPoint } from '../hooks/use-essay-response-mutation'
import { useJourney } from 'modules/journeys/hooks/useJourney'
import { Role } from 'modules/user/constants/roles'
import { useMe } from 'modules/user/hooks'

type Props = {
	content: EvaluationResponse
	userLanguage: string
}

export const EvaluationCard = ({ content, userLanguage }: Props) => {
	const { t } = useTranslation()
	const { user: currentUser } = useMe()
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const boxCommentRef = useRef<HTMLDivElement | null>(null)
	const toast = useToast()
	const baseGrade = Math.round(content.evaluation_result.base_grade)
	const [isEdit, setIsEdit] = useState(false)
	const [editedResult, setEditedResult] = useState(content.evaluation_result.result)
	const { mutate: editEvaluationResponse, isLoading } = useUpdateEssayPoint()

	const submitEvaluationResponse = () => {

		const params = {
			essayPointId: content.essay_point_id,
			essayPointResponseId: content.id,
			data: editedResult
		}

		editEvaluationResponse(params, {
			onSuccess: () => {
				content.evaluation_result.result = editedResult
				setIsEdit(false)
				toast({
					title: t('evaluationPoint:essay.evaluation.editedResponse'),
					status: 'success'
				})
			},
			onError: (error) => {
				console.error('error on editEvaluationResponse', error)
				toast({
					title: t('errors:request.error.title'),
					description: t('errors:request.error.description'),
					status: 'error'
				})
			}
		})
	}

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setEditedResult(event.target.value)
	}

	const handleClick = () => {
		if (isEdit) {
			submitEvaluationResponse()
		} else {
			setEditedResult(content.evaluation_result.result)
			setIsEdit(true)
		}
	}
	
	const { data: users = [] } = useJourney(journeyId as string, {
		select: (journey) => journey.users
	})

	const hasPrivileges = !!users
    .filter((user) => user.id === currentUser?.id)
    .find(({ project_roles }) =>
      Role(project_roles).can('evaluation_point.edit_evaluate')
    )

	return (
		<Flex
			height='fit-content'
			w='full'
			mt='2'
			px={5}
			py={5}
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor='blue.500'
			backgroundColor='#fff'
			ref={boxCommentRef}
		>
			<HStack justify='space-between'>
				<HStack>
					<Icon w={10} h={10} as={IconStStrateegiaBlue} />
					<Text fontWeight='semibold' fontSize='sm'>
						{t('evaluationPoint:essay.evaluation.title')}
					</Text>
				</HStack>

				{hasPrivileges && (
					<HStack>
						<Text color='#8B90A0' fontWeight='500' fontSize='xs' mr='2'>
							{handleDateFormat(content.created_at, userLanguage === 'PT_BR' ? 'ptBR' : '')}
						</Text>
						{isEdit ? (
							<Button onClick={submitEvaluationResponse} isLoading={isLoading} colorScheme='custom_scheme_color_button' size={'sm'}>
								{t('buttons:save')}
							</Button>
						) : (
							<GoPencil onClick={handleClick} cursor={'pointer'} color={'#515666'} />
						)}
					</HStack>
				)}
				
			</HStack>
			<VStack mt={4}>
				{isEdit ? (
					<Textarea
						variant='breakLines'
						value={editedResult}
						onChange={handleChange}
						border='1px solid'
						borderColor='blue.500'
						borderRadius='8'
						minHeight={72}
					/>
				) : (
					<Text variant='breakLines'>{content.evaluation_result.result}</Text>
				)}
				<VStack>
					<Text fontWeight='semibold' fontSize='sm'>
						{t('evaluationPoint:essay.evaluation.finalGrade')}
					</Text>
					<Text mt={0} fontWeight='bold' fontSize='3xl'>
						{baseGrade}
					</Text>
				</VStack>
			</VStack>
		</Flex>
	)
}