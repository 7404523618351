import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiComment, BiChat, BiChevronUp, BiChevronDown } from 'react-icons/bi'

import {
	Flex,
	Text,
	Button,
	Avatar,
	Icon,
	useToast,
	useBreakpointValue
} from '@chakra-ui/react'

import Hyperlink from 'modules/common/components/hyperlink'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'

import { useConversationChatStore } from '../../store/chat-store'
import { Comment } from '../../types'
import { CommentInputField } from './comment-input-field'
import { DeleteCommentButton } from './delete-comment-button'
import { ReplyCard } from './reply-card-list'

type Props = {
	conversationPointId: string | undefined
	comment: Comment
	hasDeletePrivilege: boolean
	userName: string
	userId: string
	userLanguage: string
}

export const CommentCard: React.FC<Props> = ({
	conversationPointId,
	comment,
	hasDeletePrivilege,
	userName,
	userId,
	userLanguage
}) => {
	const { t } = useTranslation()
	const toast = useToast()
	const useString = useStringToColor({ text: comment.author.name }, [500])[1]
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const bg = useStringToColor({ text: userName }, [500])[1]
	const { addReply, deleteComment } = useConversationChatStore()

	const commentsCount = comment?.reply_count | 0

	const [reply, setReply] = useState(false)
	const [expandedReplies, setExpandedReplies] = useState(false)
	const [showData, setShowData] = useState(true)

	const handleReplyClick = () => {
		setReply(!reply)
		setExpandedReplies(!expandedReplies)
	}

	const handleAddReply = (text: string) => {
		addReply(comment.id, text)
	}

	return (
		<Flex
			w='full'
			mt='2'
			padding='5'
			pb='0'
			flexDirection='column'
			borderRadius='8'
			border='1px solid'
			borderColor={useString}
			backgroundColor='white'
		>
			<Flex w='full' justifyContent='space-between'>
				<Flex alignItems='center'>
					<Avatar
						size='sm'
						backgroundColor={useString}
						name={comment.author.name}
						color='white'
					></Avatar>
					{showData || !mobileScreen ? (
						<Text color='gray.900' fontWeight='semibold' fontSize='sm' ml='2'>
							{comment.author.name}
						</Text>
					) : (
						<Text color='gray.900' fontWeight='semibold' fontSize='sm' ml='2'>
							{comment.author.name.split(' ')[0]}
						</Text>
					)}
				</Flex>
				<Flex alignItems='center' gridGap='2'>
					{showData && (
						<Text color='gray.300' fontWeight='medium' fontSize='xs'>
							{`${handleDateFormat(
								new Date(comment.created_at),
								userLanguage === 'PT_BR' ? 'ptBR' : ''
							)}`}
						</Text>
					)}
					{hasDeletePrivilege && (
						<DeleteCommentButton
							onConfirm={() => {
								deleteComment(comment.id)
								toast({
									title: t(
										'map:points.conversation.view.chat.deleteCommentSuccess'
									),
									isClosable: true,
									status: 'success'
								})
							}}
							iconButtonProps={{
								fontSize: 'xl',
								color: 'pink.500',
								background: 'transparent'
							}}
							handleOverlay={setShowData}
						/>
					)}
				</Flex>
			</Flex>
			<Flex mt='2'>
				<Text w='full' fontSize='sm' color='gray.600' fontWeight='medium'>
					<Hyperlink>{comment.text}</Hyperlink>
				</Text>
			</Flex>
			<Flex
				mt='4'
				alignItems='center'
				w='full'
				h='12'
				justifyContent='center'
				borderTop='1px solid #DFE0E3'
				borderBottom={expandedReplies ? '1px solid #DFE0E3' : 'unset'}
			>
				{commentsCount === 0 ? (
					<Button background='transparent' onClick={handleReplyClick}>
						<Icon as={BiComment} w='6' h='6' color='pink.500' />
						<Text ml='2' color='pink.500' fontWeight='semibold' fontSize='xs'>
							{t('divergencePoint:Comment')}
						</Text>
					</Button>
				) : (
					<Button background='transparent' onClick={handleReplyClick}>
						<Icon as={BiChat} w='6' h='6' color='pink.500' />
						<Text ml='2' color='pink.500' fontWeight='semibold' fontSize='xs'>
							{commentsCount > 1
								? `${commentsCount} ${t('divergencePoint:comments')}`
								: `1 ${t('divergencePoint:comment')}`}
						</Text>
						{reply && <BiChevronUp size='20' color='gray.400' />}
						{!reply && <BiChevronDown size='20' color='gray.400' />}
					</Button>
				)}
			</Flex>
			{reply && (
				<ReplyCard
					conversationPointId={conversationPointId}
					commentId={comment.id}
					userId={userId}
					userName={userName}
					userLanguage={userLanguage}
				/>
			)}
			{reply && (
				<Flex w='full' mt='4' mb='2' alignItems='baseline' gridGap='2'>
					<Avatar
						size='sm'
						backgroundColor={bg}
						name={userName}
						color='white'
					></Avatar>
					<CommentInputField
						cantComment={false}
						handleComment={handleAddReply}
						layoutProps={{
							px: 1,
							py: 1,
							bg: 'gray.100',
							borderRadius: '3xl'
						}}
					/>
				</Flex>
			)}
		</Flex>
	)
}
