import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStLightning: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 80 81' {...props}>
		<path d='M56.254 1.06633C56.7434 1.35002 57.1221 1.79128 57.3282 2.31802C57.5344 2.84476 57.5558 3.42584 57.389 3.96633L48.384 33.2263H64.999C65.4873 33.2261 65.965 33.3689 66.373 33.6371C66.7811 33.9052 67.1017 34.287 67.2952 34.7353C67.4888 35.1836 67.5468 35.6787 67.4622 36.1596C67.3776 36.6405 67.154 37.0861 66.819 37.4413L26.819 79.9413C26.432 80.3529 25.917 80.6212 25.3579 80.7026C24.7988 80.784 24.2287 80.6736 23.7404 80.3894C23.2521 80.1053 22.8744 79.6641 22.669 79.1378C22.4635 78.6115 22.4424 78.0312 22.609 77.4913L31.614 48.2263H14.999C14.5108 48.2265 14.0331 48.0837 13.6251 47.8156C13.217 47.5474 12.8964 47.1656 12.7029 46.7174C12.5093 46.2691 12.4513 45.774 12.5359 45.2931C12.6205 44.8122 12.8441 44.3666 13.179 44.0113L53.179 1.51133C53.5656 1.10033 54.0798 0.832173 54.6381 0.750473C55.1964 0.668772 55.7659 0.778329 56.254 1.06133V1.06633ZM20.784 43.2263H34.999C35.3903 43.2263 35.7761 43.318 36.1254 43.4942C36.4747 43.6704 36.7778 43.9261 37.0103 44.2408C37.2428 44.5555 37.3982 44.9203 37.4639 45.306C37.5297 45.6917 37.5041 46.0874 37.389 46.4613L30.549 68.6763L59.209 38.2263H44.999C44.6078 38.2264 44.222 38.1347 43.8727 37.9585C43.5234 37.7823 43.2203 37.5266 42.9878 37.2119C42.7553 36.8972 42.5999 36.5323 42.5341 36.1467C42.4684 35.761 42.494 35.3653 42.609 34.9913L49.449 12.7763L20.784 43.2263Z' />
	</IconBase>
)
