import create from 'zustand'

import { Comment, CommentsWithReplies } from '../types'
import {
	fetchConversationComments,
	fetchConversationCommentReplies,
	addConversationComment,
	addConversationCommentReply,
	deleteConversationComment
} from '../usecases'

interface ChatState {
	comments: CommentsWithReplies[]
	fetchComments: (conversationPointId: string) => void
	fetchCommentsWithReplies: (commentId: string) => void
	addComment: (conversationPointId: string, text: string) => void
	addReply: (commentId: string, text: string) => void
	deleteComment: (commentId: string, parentId?: string) => void
}

export const useConversationChatStore = create<ChatState>((set, get) => ({
	comments: [],

	fetchComments: async (conversationPointId: string) => {
		const content = await fetchConversationComments(conversationPointId)

		const contentWithReplies = content.map((comment) => ({
			...comment,
			replies: [] as Comment[]
		}))

		set(() => ({
			comments: [...(contentWithReplies as CommentsWithReplies[])]
		}))
	},

	fetchCommentsWithReplies: async (commentId: string) => {
		const { comments } = get()
		const commentIndex = comments.findIndex(
			(comment) => comment.id === commentId
		)
		if (commentIndex < 0 || comments[commentIndex]?.replies.length > 0) {
			return false
		}

		const replies = await fetchConversationCommentReplies(commentId)

		comments[commentIndex].replies = replies

		set(() => ({
			comments: [...comments]
		}))
	},

	addComment: async (conversationPointId: string, text: string) => {
		const comment = await addConversationComment(conversationPointId, text)

		set(({ comments }) => ({
			comments: [
				...comments,
				{ ...comment, replies: [] as Comment[] } as CommentsWithReplies
			]
		}))
	},

	addReply: async (commentId: string, text: string) => {
		const { comments } = get()
		const reply = await addConversationCommentReply(commentId, text)

		const commentIndex = comments.findIndex(
			(comment) => comment.id === commentId
		)
		if (commentIndex < 0) {
			return false
		}
		comments[commentIndex].replies.push(reply)
		comments[commentIndex].reply_count = comments[commentIndex].replies.length

		set(() => ({
			comments: [...comments]
		}))
	},

	deleteComment: async (commentId: string, parentId?: string) => {
		const { comments } = get()
		await deleteConversationComment(commentId)

		if (parentId) {
			const commentIndex = comments.findIndex(
				(comment) => comment.id === parentId
			)

			const replyIndex = comments[commentIndex].replies.findIndex(
				(reply) => reply.id === commentId
			)

			if (typeof replyIndex === 'number' && replyIndex > -1) {
				comments
					.find((comment) => comment.id === parentId)
					?.replies.splice(replyIndex, 1)

				comments[commentIndex].reply_count =
					comments[commentIndex].replies.length
			}
		} else {
			const commentIndex = comments.findIndex(
				(comment) => comment.id === commentId
			)

			if (typeof commentIndex === 'number' && commentIndex > -1) {
				comments.splice(commentIndex, 1)
			}
		}

		set(() => ({
			comments
		}))
	}
}))
