import { useTranslation } from 'react-i18next'

import { Flex, Text, Icon } from '@chakra-ui/react'

import { IconStAnonym } from 'modules/common/components/icons/st-anonym'

export function AnonymBar() {
	const { t } = useTranslation()
	return (
		<Flex bg='#505565' color='white' py={1} align='center' justify='center'>
			<Icon as={IconStAnonym} fontSize='xl' mr={1} />
			<Text fontSize='xs'>{t('anonym:incognito.divergence.bar')}</Text>
		</Flex>
	)
}
