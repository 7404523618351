import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#4B47F6',
		light: '#B1B0FF'
	},

	secondary: {
		dark: '#FDB400',
		light: '#FFEBDB'
	}
}

export default themeColors
