import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	List,
	ListItem,
	Text,
	VStack,
	useOutsideClick
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { AnimatePresence, motion } from 'framer-motion'

type OptionType = {
	value: string
	title: string
	description: string
	icon?: React.ReactNode
	checked?: boolean
}

type SelectEnhancedOptionProps<TFormValues extends FieldValues> = {
	placeholder: string
	label?: string
	control: Control<TFormValues>
	name: FieldPath<TFormValues>
	rules?: any
	defaultValue?: any
	isMobile: boolean
	options: OptionType[]
	isDisabled?: boolean
	useMode: 'journey' | 'lab'
	onSelectItem?: (value: string, checked: boolean) => void
}

export const SelectEnhancedOption = <TFormValues extends Record<string, any>>({
	placeholder,
	label,
	control,
	name,
	rules,
	defaultValue,
	isMobile,
	options,
	isDisabled,
	useMode,
	onSelectItem
}: SelectEnhancedOptionProps<TFormValues>) => {
	const {
		field: { value, onChange },
		fieldState: { error }
	} = useController({ name, control, rules, defaultValue })

	const [isOpen, setIsOpen] = useState(false)
	const containerRef = useRef(null)

	useOutsideClick({
		ref: containerRef,
		handler: () => setIsOpen(false)
	})

	const { t } = useTranslation()

	const selectedOptions = useMemo(() => {
		return options.filter((option) => {
			return option.checked !== undefined
				? option.checked
				: value?.includes(option.value)
		})
	}, [value, options])

	const handleSelectAll = useCallback(() => {
		if (selectedOptions.length === options.length) {
			onChange([])
		} else {
			onChange(options.map((option) => option.value))
		}
	}, [selectedOptions.length, options, onChange])

	const handleItemChange = (checked: boolean, option: OptionType) => {
		if (onSelectItem) {
			onSelectItem(option.value, checked)
		}

		const newValue = value.includes(option.value)
			? value.filter((val: string) => val !== option.value)
			: [...value, option.value]
		onChange(newValue)
	}

	return (
		<FormControl isInvalid={!!error} ref={containerRef}>
			{label && (
				<FormLabel textAlign={'start'} fontSize='md' fontWeight='medium'>
					{label}
				</FormLabel>
			)}
			<Button
				w={!isMobile ? 'full' : 'auto'}
				justifyContent='space-between'
				isDisabled={isDisabled}
				onClick={() => setIsOpen(!isOpen)}
			>
				<Text fontSize='sm'>
					{selectedOptions.length > 0
						? t(`common:placeholders:${useMode}_placeholder`, {
							count: selectedOptions.length
						})
						: placeholder}
				</Text>
				{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
			</Button>
			<AnimatePresence>
				{isOpen && (
					<Box position={'relative'}>
						<motion.div
							initial={{ opacity: 0, y: -40 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -20 }}
							transition={{ duration: 0.2 }}
						>
							<List
								maxH={['calc(100vh - 300px)', '350px']}
								overflowY='auto'
								bgColor={'gray.100'}
								position='absolute'
								top={'full'}
								left={0}
								right={0}
								zIndex={999}
								borderRadius='md'
								mt={1}
							>
								<ListItem key={'select-all'}>
									{useMode !== 'lab' && (
										<Checkbox
											w={'full'}
											isChecked={selectedOptions.length === options.length}
											onChange={handleSelectAll}
											px={4}
											py={2}
										>
											<VStack align='start' spacing={1}>
												<Text fontSize='sm' fontWeight={'medium'}>
													{t('credits:distribution.selectAll')}
												</Text>
											</VStack>
										</Checkbox>
									)}
								</ListItem>
								{options.map((option) => (
									<ListItem key={option.value}>
										<Checkbox
											w={'full'}
											isChecked={
												selectedOptions.includes(option) || option.checked
											}
											onChange={(e) =>
												handleItemChange(e.target.checked, option)
											}
											px={4}
											py={2}
										>
											<VStack align='start' spacing={1}>
												<Text fontSize='sm' fontWeight={'medium'}>
													{option.title}
												</Text>
												<HStack spacing={2}>
													{option.icon}
													<Text fontSize='sm' color={'gray.300'}>
														{option.description}
													</Text>
												</HStack>
											</VStack>
										</Checkbox>
									</ListItem>
								))}
							</List>
						</motion.div>
					</Box>
				)}
			</AnimatePresence>
			<FormErrorMessage>{error?.message}</FormErrorMessage>
		</FormControl>
	)
}
