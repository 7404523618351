import React from 'react'

import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useBreakpointValue,
	UseDisclosureReturn
} from '@chakra-ui/react'

import UserTermsText from './terms'

interface Props extends UseDisclosureReturn {
	renderFooter?: JSX.Element
	renderHeader: () => JSX.Element
	customTerm?: () => JSX.Element
}

function TermsAndConditions({
	isOpen,
	onOpen,
	onClose,
	renderFooter: RenderFooter,
	renderHeader: RenderHeader,
	customTerm
}: Props) {
	const modalSize = useBreakpointValue({ base: 'full', lg: '3xl' })

	const Term = customTerm || UserTermsText

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			scrollBehavior='inside'
			size={modalSize}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader ml={3} mt={10}>
					<RenderHeader />
				</ModalHeader>
				<ModalCloseButton mr={3} mt={3} />
				<ModalBody overflow='auto' h='31rem'>
					<Box
						backgroundColor='gray.100'
						overflow='hidden'
						h='100%'
						p={4}
						borderRadius='lg'
					>
						<Box h='inherit' overflow='auto' p={4}>
							<Term />
						</Box>
					</Box>
				</ModalBody>
				<ModalFooter>{RenderFooter}</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default TermsAndConditions
