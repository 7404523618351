import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'

import {
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Modal,
	UseDisclosureReturn,
	Textarea,
	InputGroup,
	InputRightElement,
	Text,
	Button,
	Flex,
	Icon,
	useToast,
	useTheme
} from '@chakra-ui/react'

import { Form, Formik } from 'formik'

import { FormField } from 'modules/common/components/forms'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { ReferenceValidation } from 'modules/tools/validators/tools'

import useReferenceStore from '../../store/use-references-store'

export const ModalAddLink: React.FC<{
	disclosure: UseDisclosureReturn
}> = ({ disclosure }) => {
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { t } = useTranslation()
	const [url, setUrl] = React.useState('')
	const [description, setDescription] = React.useState('')
	const [loading, setLoading] = React.useState(false)
	const { addAttachedLink } = useReferenceStore()
	const toast = useToast()
	const handleSubmit = () => {
		try {
			setLoading(true)
			addAttachedLink(selectedPoint?.id || '', description, url)
			setUrl('')
			setDescription('')
			disclosure.onClose()
			toast({
				title: t('divergencePoint:about_kit:link_added'),
				isClosable: true,
				status: 'success'
			})
			setLoading(false)
		} catch (error) {
			const { message } = error as Error
			setUrl('')
			setDescription('')
			setLoading(false)
			disclosure.onClose()
			toast({
				title: message as string,
				isClosable: true,
				status: 'error'
			})
		}
	}

	const handleDescription = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement
		if (target.value.length <= 1000) {
			setDescription(target.value)
		}
	}

	const handleUrl = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement
		setUrl(target.value)
	}

	const theme = useTheme()

	return (
		<Modal
			isOpen={disclosure.isOpen}
			onClose={disclosure.onClose}
			isCentered
			size='xl'
		>
			<ModalOverlay />
			<Formik
				onSubmit={handleSubmit}
				initialValues={{ url: '', description: '' }}
				validationSchema={ReferenceValidation}
			>
				{({ isValid, dirty }) => {
					return (
						<Form>
							<ModalContent px={8}>
								<ModalCloseButton />
								<Text
									fontWeight='700'
									color='gray.500'
									fontSize='1.3rem'
									marginTop='4.5rem'
									marginBottom='2'
								>
									{t('divergencePoint:about_kit:add_link_modal')}
								</Text>
								<FormField
									name='url'
									autoFocus
									label={t('tools:create.labels.url')}
									placeholder={t('tools:create.placeholder.link')}
									onKeyUp={handleUrl}
								/>
								<InputGroup mt='6'>
									<FormField
										name='description'
										maxLength={1000}
										padding={2}
										borderRadius='lg'
										as={Textarea}
										_focus={{
											borderColor: theme.colors.input_border
										}}
										onKeyUp={handleDescription}
										label={t('tools:create.labels.description')}
										placeholder={t('tools:create.placeholder.refDescription')}
										pr={20}
										labelProps={{
											mb: 2
										}}
									/>
									<InputRightElement mt={6} mr={6}>
										<Text fontSize='sm'>{description.length}/1000</Text>
									</InputRightElement>
								</InputGroup>
								<Flex justifyContent='flex-end' mb={6}>
									<Button
										mt={4}
										mb={3}
										mr={4}
										background='transparent'
										onClick={() => {
											setUrl('')
											setDescription('')
											disclosure.onClose()
										}}
									>
										{t('divergencePoint:cancel')}
									</Button>
									<Button
										isDisabled={!(isValid && dirty)}
										type='submit'
										mt={4}
										mb={3}
										colorScheme='custom_scheme_color_button'
										leftIcon={<Icon as={BiLink} fontSize='xl' color='#fff' />}
										isLoading={loading}
									>
										{t('divergencePoint:about_kit:add_link_button')}
									</Button>
								</Flex>
							</ModalContent>
						</Form>
					)
				}}
			</Formik>
		</Modal>
	)
}
