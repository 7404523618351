import { useQuery, UseQueryOptions } from 'react-query'

import { planKeys } from '../query/planKeys'
import { BillingAccount } from '../types'
import { getBillingAccount } from '../usecases'

export function useBillingAccount<T = BillingAccount>(
	options?: UseQueryOptions<BillingAccount, unknown, T>
) {
	return useQuery<BillingAccount, unknown, T>(
		planKeys.billing(),
		getBillingAccount,
		options
	)
}
