import { useMutation } from 'react-query'

import { createMonitorPointAnalysis } from '../usecases/create-monitor-point-analysis'

export const useCreateMonitorPointAnalysis = () => {
	return useMutation(
		async ({
			monitorPointId,
			data
		}: {
			monitorPointId: string
			data: { language: string }
		}) => {
			return await createMonitorPointAnalysis(monitorPointId, data)
		}
	)
}
