import React, { ReactNode } from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

type EvaluationStore = {
	isAnonymous: boolean
	isIndividual: boolean
	hasAnswers: boolean
	isMultipleResponse: boolean
	isUserEvaluation: boolean
	setIsIndividual: (isIndividual: boolean) => void
	setIsAnonymous: (isAnonymous: boolean) => void
	setIsMultipleResponse: (isMultipleResponse: boolean) => void
	setIsUserEvaluation: (isUserEvaluation: boolean) => void
	setHasAnswers: (isAnonymous: boolean) => void
}

const { Provider, useStore } = createContext<EvaluationStore>()

type EvaluationProviderProps = {
	children: ReactNode
	value: {
		isAnonymous: boolean
		isIndividual: boolean
		hasAnswers: boolean
		isMultipleResponse: boolean
		isUserEvaluation: boolean
	}
}

function EvaluationProvider({ children, value }: EvaluationProviderProps) {
	const createStore = () =>
		create<EvaluationStore>((set) => ({
			setIsAnonymous: (isAnonymous: boolean) => set({ isAnonymous }),
			setIsIndividual: (isIndividual: boolean) => set({ isIndividual }),
			setIsMultipleResponse: (isMultipleResponse: boolean) =>
				set({ isMultipleResponse }),
			setIsUserEvaluation: (isUserEvaluation: boolean) =>
				set({ isUserEvaluation }),
			setHasAnswers: (hasAnswers: boolean) => set({ hasAnswers }),
			...value
		}))

	return <Provider createStore={createStore}>{children}</Provider>
}

export { useStore as useEvaluationStore, EvaluationProvider }
