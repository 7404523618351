import React from 'react'
import { useTranslation } from 'react-i18next'

import {
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Box,
	Text,
	useToast
} from '@chakra-ui/react'

import Api from 'data/api'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { FormField } from 'modules/common/components'

const StepEmail = ({ onFinish }: { onFinish: (email: string) => void }) => {
	const { t } = useTranslation()
	const toast = useToast()
	const handleSubmit = async (values: { email: string }): Promise<void> => {
		try {
			Api.Users.passwordRecovery(values.email)
			onFinish(values.email)
		} catch (err) {
			toast({ title: t('forgotPassword:email.failed'), status: 'error' })
		}
	}

	return (
		<>
			<ModalHeader fontSize='2xl' mt={10}>
				{t('forgotPassword:email.title')}
			</ModalHeader>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{
					email: ''
				}}
				validationSchema={Yup.object({
					email: Yup.string()
						.required(t('errors:requiredField'))
						.email(t('login:validEmail'))
				})}
			>
				{({ isValid, dirty }) => {
					return (
						<Form>
							<ModalBody>
								<Box>
									<Text mb='1.7rem'>{t('forgotPassword:email.body')}</Text>

									<FormField
										placeholder={t('forgotPassword:email.placeHolder')}
										label={t('login:email')}
										name='email'
										variant='outline'
										labelProps={{
											mb: 2,
											fontSize: 'md'
										}}
									/>
								</Box>
							</ModalBody>
							<ModalFooter>
								<Button
									type='submit'
									isDisabled={!(isValid && dirty)}
									mt={4}
									mb={3}
									width='full'
									colorScheme='custom_scheme_color_button'
								>
									{t('buttons:receiveCode')}
								</Button>
							</ModalFooter>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default StepEmail
