import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Image,
	Input,
	Text,
	VStack,
	useTheme
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { Provider } from 'modules/login/types'
import { LoginFinalType } from 'modules/login/validators'

import ForgotPassword from '../forgot-password'

type FinalFormProps = {
	onSubmit: () => void
	register: UseFormRegister<LoginFinalType>
	errors: FieldErrors<LoginFinalType>
	providers?: Provider[] | Provider
	previousURL: string
	isLoading: boolean
}

export const FinalLoginForm = ({
	onSubmit,
	register,
	errors,
	providers,
	previousURL,
	isLoading
}: FinalFormProps) => {
	const { t } = useTranslation()

	const { pathname, search } = useLocation()
	const theme = useTheme()

	const current = `${pathname}${search}`
	const ignoredPaths = ['/signin', '/', '/signup', current]
	const redirectURL = !ignoredPaths.includes(previousURL)
		? previousURL
		: '/dashboard'
	const tenantUrl = `${window.location.origin}/auth/callback`

	const handleRedirectProvider = (providerData: Provider) => {
		sendGAEvent(`login-with-provider-${providerData.name}`)
		const stateObject = {
			providerId: providerData.id,
			urlCallback: tenantUrl,
			previousUrl: redirectURL
		}

		const encodedState = encodeURIComponent(JSON.stringify(stateObject))
		const providerAuthUrl = `${providerData.authorization_endpoint}?client_id=${providerData.client_id
			}&scope=${providerData.scope.join(
				' '
			)}&redirect_uri=${tenantUrl}&response_type=code&state=${encodedState}`

		window.location.href = providerAuthUrl
	}

	const providersArray = Array.isArray(providers) ? providers : [providers]

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing={4}>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel htmlFor='email'>{t('login:email')}</FormLabel>
					<Input
						{...register('email')}
						placeholder={t('login:placeholders.email')}
						autoComplete='username'
						_focus={{
							borderColor: theme.colors.input_border,
							boxShadow: `0 0 0 1px ${theme.colors.input_border}`
						}}
						_placeholder={{ fontWeight: 'medium', color: 'gray.300' }}
					/>
					{errors.email && (
						<FormErrorMessage>{errors.email.message}</FormErrorMessage>
					)}
				</FormControl>

				<FormControl isInvalid={!!errors.password}>
					<FormLabel htmlFor='password'>{t('login:password')}</FormLabel>
					<Input
						{...register('password')}
						type='password'
						autoComplete='current-password'
						_focus={{
							borderColor: theme.colors.input_border,
							boxShadow: `0 0 0 1px ${theme.colors.input_border}`
						}}
						placeholder={t('login:placeholders.password')}
						_placeholder={{ fontWeight: 'medium', color: 'gray.300' }}
					/>
					{errors.password && (
						<FormErrorMessage>{errors.password.message}</FormErrorMessage>
					)}
				</FormControl>
			</VStack>

			<HStack
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				mt={8}
				alignItems='flex-start'
			>
				<ForgotPassword />
			</HStack>

			<VStack spacing={3}>
				<CustomSchemeButton
					type='submit'
					data-testid='submit'
					colorScheme={theme.colors.login_button_schema}
					mt={8}
					width='full'
					isLoading={isLoading}
				>
					{t('login:title')}
				</CustomSchemeButton>

				<HStack spacing={2} w='full'>
					<Box w='full' h='1px' bgColor='gray.200' />
					<Text fontSize={['xs', 'md']} color='gray'>
						{t('login:or')}
					</Text>
					<Box w='full' h='1px' bgColor='gray.200' />
				</HStack>

				{providersArray?.map((provider) => {
					if (!provider) return null

					return (
						<Button
							key={provider?.id}
							variant='outline'
							w='full'
							leftIcon={<Image boxSize='24px' src={provider?.logo_url} />}
							onClick={() => handleRedirectProvider(provider as Provider)}
						>
							{t('signup:enterWith', { value: provider?.name })}
						</Button>
					)
				})}
			</VStack>
		</form>
	)
}
