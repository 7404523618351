import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiCheckCircle } from 'react-icons/bi'

import { Box, Text, Icon, useBreakpointValue } from '@chakra-ui/react'

type Props = {
	timeInfo: {
		date: string
		hour: string
	}
}

export const SubmissionMessage: React.FC<Props> = ({ timeInfo }) => {
	const { t } = useTranslation()
	const variant = useBreakpointValue({ base: 'xs', md: '' })

	return (
		<Box textAlign='center' py={4}>
			<Icon
				as={BiCheckCircle}
				display='block'
				mx='auto'
				fontSize='6xl'
				color='green.500'
			/>
			<Text as='strong' fontSize={variant}>
				{t('map:points.convergence.submittedAnswer', timeInfo)}
			</Text>
			<Box pl='3rem' pr='3rem'>
				<Text fontSize={variant}>
					<Trans
						i18nKey='map:points.convergence.waitingResult'
						values={timeInfo}
						components={[<strong />]}
					/>
				</Text>
			</Box>
		</Box>
	)
}
