import { fetch } from 'config'

import { UserAuthorization } from '../types'

type Params = {
	providerId: string
	code: string
	redirectUri: string
}

export const exchangeProviderAuthorizationCode = ({
	providerId,
	code,
	redirectUri
}: Params) => {
	return fetch<UserAuthorization>({
		url: '/users/v1/auth/code',
		method: 'GET',
		params: { providerId, code: decodeURIComponent(code), redirectUri }
	}).then(({ data }) => data)
}
