import { fetch } from 'config'

import { ChatRoom } from 'modules/assistants/types/chat-room'

export const createChatRoom = async (assistantId: string) => {
	return await fetch<ChatRoom>({
		url: 'projects/v1/chat-room',
		method: 'POST',
		data: {
			assistant_id: assistantId
		}
	})
}
