import { fetch } from 'config'

import { Comment } from '../types'

export async function addConversationCommentReply(
	commentId: string,
	text: string
) {
	const { data } = await fetch<Comment>({
		url: `/projects/v1/checkpoint/comment/${commentId}/reply`,
		method: 'POST',
		data: { text }
	})

	return data
}
