import { fetch } from 'config'

import { User } from 'modules/user/types'

export type CreateUserWithProvider = {
	access_token: string
	acting: string
	discover_strateegia: string
	email: string
	external_id: string
	language: string
	name: string
	newsletter_accepted: boolean
	provider_id: string
	strateegia_usage: string
	term_accepted: boolean
}

export const CreatWithProvider = (userParams: CreateUserWithProvider) => {
	return fetch<User>({
		url: 'users/v1/user/providersignup',
		method: 'POST',
		data: userParams
	}).then(({ data }) => data)
}
