import { fetch } from 'config'

type Response = {
	ids: string[]
}
export const deleteAllUserAnswers = (pointId: string) => {
	return fetch<Response>({
		url: `/projects/v1/essay-point/${pointId}/responses`,
		method: 'DELETE'
	}).then(({ data }) => data)
}
