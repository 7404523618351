import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const disableAssistantShareCommunity = async (assistantId: string) => {
	return await fetch<Assistant>({
		url: `projects/v1/assistants/${assistantId}/disable-share-community`,
		method: 'PATCH'
	})
}
