import defaultCustomColors from '../default/colors'

const portomarinhoCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#FD6A00',
		700: '#FD6A00'
	},

	secondary_color_scheme_button: {
		700: '#008AC3'
	},

	switch_scheme_color_checked: {
		500: '#008AC3'
	},

	radio_color: {
		500: '#008AC3'
	}
}

export default portomarinhoCustomColors
