import { lazy } from 'react'
import { Route, Navigate } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

const SelectTemplate = lazy(() => import('modules/tools/pages/select-template'))
const CreateTool = lazy(() => import('modules/tools/pages/create-tool'))
const EditTool = lazy(() => import('modules/tools/pages/edit-tool'))
const Kits = lazy(() => import('modules/tools/pages/kits'))
const MyKits = lazy(() => import('modules/tools/pages/my-kits'))
const Detail = lazy(() => import('modules/tools/pages/detail'))

const ToolsRoutes = () => {
	return (
		<ApmRoutes>
			<Route
				path=':id'
				element={
					<Loading>
						<Detail />
					</Loading>
				}
			/>
			<Route
				path='select-template'
				element={
					<Loading>
						<SelectTemplate />
					</Loading>
				}
			/>
			<Route
				path='create'
				element={
					<Loading>
						<CreateTool />
					</Loading>
				}
			/>
			<Route
				path='edit/:id'
				element={
					<Loading>
						<EditTool goToMyKits={true} />
					</Loading>
				}
			/>
			<Route
				path='my-kits'
				element={
					<Loading>
						<MyKits />
					</Loading>
				}
			/>
			<Route path='explore'>
				<Route index element={<Navigate to='./strateegia-kits' />} />
				<Route
					path=':tab'
					element={
						<Loading>
							<Kits />
						</Loading>
					}
				/>
			</Route>
		</ApmRoutes>
	)
}

export default ToolsRoutes
