export function removeEmptyValues<T extends { [key: string]: any }>(
	obj: T
): Partial<T> {
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, value]) => value != null)
			.map(([key, value]) => [
				key,
				typeof value === 'object' && value !== null
					? removeEmptyValues(value as { [key: string]: unknown })
					: value
			])
	) as Partial<T>
}
