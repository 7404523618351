import React from 'react'

import { useToken } from '@chakra-ui/react'
import Linkify from 'linkify-react'

const Hyperlink: React.FC<{ children?: string }> = ({ children = '' }) => {
	const [color] = useToken('colors', ['blue.500'])

	return (
		<Linkify
			options={{
				attributes: {
					target: '_blank',
					rel: 'noopener noreferrer',
					style: {
						color,
						textDecoration: 'underline'
					}
				}
			}}
		>
			{children}
		</Linkify>
	)
}

export default Hyperlink
