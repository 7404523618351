import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStProCrown = (props: IconProps) => (
	<IconBase
		viewBox='0 0 14 15'
		fill='currentcolor'
		stroke='currentcolor'
		strokeWidth='0.1'
		{...props}
	>
		<path
			d='M14 0.111328L10.1111 4.00022L7 0.111328L3.88889 4.00022L0 0.111328V10.2224H14V0.111328ZM1.55556 14.8891H12.4444C12.857 14.8891 13.2527 14.7252 13.5444 14.4335C13.8361 14.1418 14 13.7461 14 13.3336V11.778H0V13.3336C0 13.7461 0.163888 14.1418 0.455612 14.4335C0.747335 14.7252 1.143 14.8891 1.55556 14.8891Z'
			fill='#FDB400'
		/>
	</IconBase>
)
