import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChat } from 'react-icons/bi'

import { Box, Flex, Text, Icon, Stack } from '@chakra-ui/react'

import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { useMe } from 'modules/user/hooks'

import { useConversationChatStore } from '../../store/chat-store'
import { CommentCard } from './comment-card'

export const Chat: React.FC = () => {
	const { t } = useTranslation()
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { comments, fetchComments } = useConversationChatStore()

	useEffect(() => {
		if (selectedPoint) {
			fetchComments(selectedPoint.id)
		}
	}, [selectedPoint, fetchComments])

	const { user } = useMe()
	const hasDeletePrivilege = (authorId: string) =>
		user ? user.id === authorId : false

	return (
		<Stack w='full' justifyContent='space-between' maxH='50vh'>
			{comments?.length > 0 && (
				<Box px='9' py='4' mt='7' bg='gray.100' overflowY='auto'>
					<Flex alignItems='center' gridGap='3' color='gray.300'>
						<Icon as={BiChat} />
						<Text fontSize='sm' fontWeight='semibold'>
							{t('map:points.conversation.view.chat.comments')}
						</Text>
					</Flex>
					{comments?.map((comment) => (
						<CommentCard
							hasDeletePrivilege={hasDeletePrivilege(comment.author.id)}
							key={comment.id}
							conversationPointId={selectedPoint?.id}
							comment={comment}
							userName={user?.name || ''}
							userId={user?.id || ''}
							userLanguage={user?.language || 'ptBR'}
						/>
					))}
				</Box>
			)}
		</Stack>
	)
}
