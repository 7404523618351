import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiComment } from 'react-icons/bi'

import {
	Flex,
	Text,
	Button,
	Avatar,
	useToast,
	Icon,
	useBreakpointValue
} from '@chakra-ui/react'

import Hyperlink from 'modules/common/components/hyperlink'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'

import { useConversationChatStore } from '../../store/chat-store'
import { Comment } from '../../types'
import { CommentInputField } from './comment-input-field'
import { DeleteCommentButton } from './delete-comment-button'

type Props = {
	reply: Comment
	userId: string
	userName: string
	userLanguage: string
}

export const ReplyCardListItem: React.FC<Props> = ({
	reply,
	userId,
	userName,
	userLanguage
}) => {
	const { deleteComment, addReply } = useConversationChatStore()
	const toast = useToast()
	const stringColor = useStringToColor({ text: reply.author.name }, [500])[1]
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const [comment, setComment] = useState(false)
	const [showData, setShowData] = useState(true)
	const bg = useStringToColor({ text: userName }, [500])[1]
	const { t } = useTranslation()

	const handleAddReply = (text: string) => {
		addReply(reply.parent_id, text)
		setComment(false)
	}

	return (
		<Flex flexDirection='column'>
			<Flex flexDirection='row'>
				<Flex mt='4'>
					<Avatar
						size='sm'
						backgroundColor={stringColor}
						name={reply.author.name}
						color='white'
					></Avatar>
				</Flex>

				<Flex w='full' flexDirection='column' ml='2' mt='4'>
					<Flex
						w='100%'
						flexDirection='column'
						backgroundColor='gray.100'
						borderRadius='lg'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
					>
						<Flex
							alignItems='flex-end'
							pb='1'
							justifyContent='space-between'
							minH='10'
						>
							{showData || !mobileScreen ? (
								<Text
									color='gray.900'
									fontWeight='semibold'
									fontSize='sm'
									ml='4'
								>
									{reply.author.name}
								</Text>
							) : (
								<Text
									color='gray.900'
									fontWeight='semibold'
									fontSize='sm'
									ml='4'
								>
									{reply.author.name.split(' ')[0]}
								</Text>
							)}
							<Flex alignItems='center'>
								{showData && (
									<Text
										color='gray.300'
										fontWeight='medium'
										fontSize='xs'
										mr={3}
									>
										{`${handleDateFormat(
											new Date(reply.created_at),
											userLanguage === 'PT_BR' ? 'ptBR' : ''
										)}`}
									</Text>
								)}
								{userId && userId === reply.author.id && (
									<DeleteCommentButton
										onConfirm={() => {
											deleteComment(reply.id, reply.parent_id)
											toast({
												title: t('divergencePoint:reply_deleted'),
												isClosable: true,
												status: 'success'
											})
										}}
										iconButtonProps={{ fontSize: 'xl', color: 'pink.500' }}
										handleOverlay={setShowData}
									/>
								)}
							</Flex>
						</Flex>
						<Text
							ml='4'
							mb='3'
							fontSize='sm'
							color='gray.500'
							fontWeight='500'
							pr='2'
						>
							<Hyperlink>{reply.text}</Hyperlink>
						</Text>
					</Flex>
					<Flex mt='1'>
						<Button
							background='transparent'
							onClick={() => setComment(!comment)}
						>
							<Icon as={BiComment} w='6' h='6' color='pink.500' />
							<Text ml='2' color='pink.500' fontWeight='semibold' fontSize='xs'>
								{t('divergencePoint:Comment')}
							</Text>
						</Button>
					</Flex>
				</Flex>
			</Flex>
			{comment && (
				<Flex w='full' mt='2' pl='10' alignItems='baseline' gridGap='2'>
					<Avatar
						size='sm'
						backgroundColor={bg}
						name={userName}
						color='white'
					></Avatar>
					<CommentInputField
						cantComment={false}
						handleComment={handleAddReply}
						layoutProps={{
							px: 1,
							py: 1,
							bg: 'gray.100',
							borderRadius: '3xl'
						}}
					/>
				</Flex>
			)}
		</Flex>
	)
}
