import React, { PropsWithChildren, useState } from 'react'
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi'

import {
	Modal,
	ModalProps,
	useBreakpointValue,
	IconButton,
	ButtonProps
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

interface SubComponents {
	FullscreenButton: React.FC<ButtonProps>
	Fullscreen: React.FC<PropsWithChildren>
	RegularScreen: React.FC<PropsWithChildren>
}

interface Context {
	isFullscreen: boolean
	setIsFullscreen: (fullscreen: boolean) => void
}

const FullscreenContext = React.createContext<Context>({
	isFullscreen: false,
	setIsFullscreen: () => {
		return null
	}
})

const useFullscreenContext = () => {
	const context = React.useContext(FullscreenContext)
	if (!context) {
		throw new Error(
			'FullscreenButton compound components cannot be rendered outside the FullscreenBox component.'
		)
	}
	return context
}

interface FullscreenProps extends Omit<ModalProps, 'size'> {
	isOpen: boolean
	onClose: () => void
	size?: Record<string, string>
}

const FullscreenBox: React.FC<FullscreenProps> & SubComponents = (
	{ children, isOpen, onClose, size: modalSize = {} },
	...props
) => {
	const size = useBreakpointValue({ base: 'full', md: '4xl', ...modalSize })
	const [isFullscreen, setIsFullscreen] = useState(false)

	return (
		<FullscreenContext.Provider value={{ isFullscreen, setIsFullscreen }}>
			<FullscreenContext.Consumer>
				{({ isFullscreen }) => {
					return (
						<Modal
							closeOnOverlayClick={false}
							size={isFullscreen ? 'full' : size}
							isOpen={isOpen}
							onClose={onClose}
							isCentered
							{...props}
						>
							{children}
						</Modal>
					)
				}}
			</FullscreenContext.Consumer>
		</FullscreenContext.Provider>
	)
}

const FullscreenButton: React.FC<ButtonProps> = (props) => {
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { isFullscreen, setIsFullscreen } = useFullscreenContext()

	if (isMobile) {
		return null
	}

	const handleFullScreen = () => {
		setIsFullscreen(!isFullscreen)
		sendGAEvent('full_screen_open')
	}
	return (
		<IconButton
			aria-label='Fullscreen'
			size='sm'
			onClick={handleFullScreen}
			icon={isFullscreen ? <BiExitFullscreen /> : <BiFullscreen />}
			fontSize='xl'
			variant='ghost'
			{...props}
		/>
	)
}

FullscreenBox.FullscreenButton = FullscreenButton

const Fullscreen: React.FC<PropsWithChildren> = ({ children }) => {
	const { isFullscreen } = useFullscreenContext()
	if (isFullscreen) {
		return <>{children}</>
	}

	return null
}

FullscreenBox.Fullscreen = Fullscreen

const RegularScreen: React.FC<PropsWithChildren> = ({ children }) => {
	const { isFullscreen } = useFullscreenContext()
	if (!isFullscreen) {
		return <>{children}</>
	}

	return null
}

FullscreenBox.RegularScreen = RegularScreen

export { FullscreenBox }
