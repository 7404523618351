import { useEffect, useRef } from 'react'

import useConnectivityStore, {
	EVENT_SOCKET_FAIL
} from 'config/connectivity-diagnostics/connectivity-store'

import { createConnection } from './socket.io'

const client_insts = new Map<string, any>()

export interface SubscribeRecipient {
	action_type: string
	callback: Function
}

const useSocket = (channel: string) => {
	const subChannelsRef = useRef<SubscribeRecipient[]>([])
	useEffect(() => {
		if (!client_insts.has(channel)) {
			const client = createConnection(channel)
			client.on('disconnect', () => {
				const { setEventType } = useConnectivityStore.getState()
				setEventType(EVENT_SOCKET_FAIL)
			})
			client_insts.set(channel, client)
		} else {
			const client = client_insts.get(channel)
			if (client.disconnected) {
				client.connect()
			}
		}
		return () => {
			if (client_insts.has(channel)) {
				const client = client_insts.get(channel)
				if (client) {
					subChannelsRef.current.forEach((sub) => {
						client?.removeListener(sub.action_type, sub.callback)
					})
				}
			}
		}
	}, [])
	return {
		subscribe: <T>(type: string, callback: (data: T) => void) => {
			const client = client_insts.get(channel)
			const subIndex = subChannelsRef.current.findIndex(
				(sub) => sub.action_type === type
			)
			if (subIndex > -1) {
				const [sub] = subChannelsRef.current.splice(subIndex, 1)
				client?.removeListener(sub.action_type, sub.callback)
			}
			const callbackProxy = (data: { payload: T }) => {
				callback(data.payload)
			}
			subChannelsRef.current.push({
				action_type: type,
				callback: callbackProxy
			})
			client?.on(type, callbackProxy)
		},
		emit: <T>(type: string, payload: T) => {
			const client = client_insts.get(channel)
			client?.emit(type, payload)
		}
	}
}

export default useSocket
