import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { ApmRoutes } from '@elastic/apm-rum-react'

import { Loading } from 'routes/loading'

const MyProfile = lazy(() => import('modules/user/pages/profile'))
const PublicProfile = lazy(() => import('modules/user/pages/public-profile'))

const ProfileRoute = () => {
	return (
		<ApmRoutes>
			<Route
				path='my'
				element={
					<Loading>
						<MyProfile />
					</Loading>
				}
			/>
			<Route
				path=':id'
				element={
					<Loading>
						<PublicProfile />
					</Loading>
				}
			/>
		</ApmRoutes>
	)
}

export default ProfileRoute
