import * as React from 'react'
import { BiLinkExternal } from 'react-icons/bi'

import { Box, Link, Text, Icon, useBreakpointValue } from '@chakra-ui/react'

import Hyperlink from 'modules/common/components/hyperlink'
import { useMapStore } from 'modules/map/modules/map-canvas/store'

export const ReferenceCard: React.FC<{
	title: string
	description: string
	url: string
}> = ({ title, description, url }) => {
	const color = useMapStore(({ state }) => state.selectedPoint?.color)
	const newUrl = url.includes('http') ? url : `//${url}`
	const background = useBreakpointValue({ base: 'gray.100', lg: 'white' })
	return (
		<Box
			p={3}
			mx={6}
			marginTop={4}
			borderRadius='md'
			backgroundColor={background}
			border='1px solid black'
			borderColor={color}
			boxShadow='md'
		>
			<Text fontWeight='bold' color='gray.400' fontSize='sm'>
				{title}
			</Text>

			<Text marginTop={1} fontSize='sm' marginBottom={1} variant='breakLines'>
				<Hyperlink>{description}</Hyperlink>
			</Text>
			{url && (
				<Link as='a' color='gray.400' fontSize='xs' href={newUrl} isExternal>
					<Icon as={BiLinkExternal} fontSize='md' marginRight='0.5rem' />
					{url}
				</Link>
			)}
		</Box>
	)
}
