import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBody, Text, useToast } from '@chakra-ui/react'

import { AssistantList } from 'modules/assistants/components/assistant-list'
import AssistantsTabs from 'modules/assistants/components/assistants-tabs'
import { AssistantTab } from 'modules/assistants/types'

import { Status } from '.'
import { getAnswersCount } from './usecases/get-answers-count'

type ChooseAssistantProps = {
	pointId: string
	questionId: string
	setStatus: React.Dispatch<React.SetStateAction<Status>>
	setCommentsMissing: React.Dispatch<React.SetStateAction<number>>
	setTimestamp: React.Dispatch<React.SetStateAction<string>>
	setAssistantId: React.Dispatch<React.SetStateAction<string>>
	setTotalComments: React.Dispatch<React.SetStateAction<number>>
	setModalSize: React.Dispatch<React.SetStateAction<string>>
}

export const ChooseAssistant = ({
	setCommentsMissing,
	setStatus,
	setTimestamp,
	setTotalComments,
	setAssistantId,
	pointId,
	questionId,
	setModalSize
}: ChooseAssistantProps) => {
	const { t } = useTranslation()
	const toast = useToast()
	const [currentTab, setCurrentTab] = useState<AssistantTab>({
		title: t('assistants:tab.my-assistants'),
		type: 'private'
	})

	useEffect(() => {
		setModalSize('5xl')
	}, [])

	const handleSubmit = async (assistantId: string) => {
		setAssistantId(assistantId)

		const until = new Date().toISOString()

		try {
			const { missing, total } = await getAnswersCount({
				assistantId,
				pointId,
				questionId,
				until
			})
			setTimestamp(until)
			setCommentsMissing(missing)
			setTotalComments(total)
			setStatus('confirm')
			setModalSize('2xl')
		} catch (error) {
			console.error(error)
			toast({
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error'
			})
		}
	}

	return (
		<>
			<ModalBody
				overflowY='auto'
				maxH={['calc(100vh - 100px)', 'calc(100vh - 300px)']}
				minH='400px'
				pb={6}
			>
				<Text color='gray.400'>{t('assistants:commentAll.title')}</Text>
				<AssistantsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
				<AssistantList
					currentTab={currentTab}
					useMode='commentAll'
					commentAllData={{ handleSubmit }}
				/>
			</ModalBody>
		</>
	)
}
