import i18n from 'i18n/i18n'
import { object, string, ref, boolean } from 'yup'

import { hasNoUrl } from 'modules/common/validators'

export const signUpValidation = () =>
	object({
		name: string()
			.required(i18n.t('errors:requiredField'))
			.min(3, i18n.t('signup:shortName'))
			.max(50, i18n.t('signup:longName'))
			.test({ test: hasNoUrl, message: i18n.t('signup:validName') }),
		email: string()
			.required(i18n.t('errors:requiredField'))
			.email(i18n.t('signup:validEmail')),
		confirmEmail: string()
			.required(i18n.t('errors:requiredField'))
			.oneOf([ref('email'), null], i18n.t('signup:matchEmail'))
			.email(i18n.t('signup:validEmail')),
		password: string()
			.required(i18n.t('errors:requiredField'))
			.min(8, i18n.t('signup:minPassword')),
		confirmPassword: string()
			.required(i18n.t('errors:requiredField'))
			.oneOf([ref('password'), null], i18n.t('signup:matchPassword'))
			.min(8, i18n.t('signup:minPassword')),
		term_accepted: boolean()
			.oneOf([true], i18n.t('signup:acceptTermsMessage'))
			.required(i18n.t('signup:acceptTermsMessage'))
	})
