import { fetch } from 'config'

import { EvaluationResponse as EvaluationReplyResponse } from 'modules/map/types'

type Params = {
	pointId: string
	parentId: string
	text: string
}

export async function createEssayReply(params: Params) {
	const { pointId, parentId, ...data } = params
	const response = await fetch<EvaluationReplyResponse>({
		url: `/projects/v1/essay-point/${pointId}/responses/${parentId}/reply`,
		method: 'POST',
		data
	})
	return response.data
}
