import { useQuery } from 'react-query'

import { getMonitorPoint } from '../usecases/get-monitor-point'

export const useMonitorPoint = (monitorPointId: string) => {
	return useQuery(
		['monitorPoint', monitorPointId],
		() => getMonitorPoint(monitorPointId),
		{
			enabled: !!monitorPointId
		}
	)
}
