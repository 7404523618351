import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSpreadsheet } from 'react-icons/bi'

import {
	HStack,
	Text,
	Menu,
	MenuButton,
	Button,
	MenuList,
	MenuItem,
	useDisclosure,
	useBreakpointValue
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { EmptyCredits } from 'modules/common/components/credits/components/empty'
import { IconStCoin } from 'modules/common/components/icons'
import { QUICK_SUMMARY_CREATION_COST } from 'modules/constants'
import { User } from 'modules/user/types'

import { QuickSummary } from '.'

type Props = {
	user?: User
	pointId: string
	questionId: string
}

export const QuickSummaryButton = ({ user, pointId, questionId }: Props) => {
	const { t } = useTranslation()
	const [language, setLanguage] = useState('')
	const disclosure = useDisclosure()
	const emptyCreditsDisclosure = useDisclosure()
	const buyCreditsDisclosure = useDisclosure()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	const handleSelectLanguage = (option: string) => {
		sendGAEvent('quick_summary')
		if (user && user.credits < QUICK_SUMMARY_CREATION_COST) {
			emptyCreditsDisclosure.onOpen()
			return
		}
		setLanguage(option)
		disclosure.onOpen()
	}

	const handleOpenBuyCredits = () => {
		buyCreditsDisclosure.onOpen()
		emptyCreditsDisclosure.onClose()
	}

	return (
		<Menu>
			<MenuButton
				as={Button}
				leftIcon={<BiSpreadsheet size={18} />}
				size='sm'
				colorScheme='pink'
				rightIcon={<Price />}
			>
				{isMobile ? t('buttons:summaryDebate') : t('buttons:quickSummary')}
			</MenuButton>
			<MenuList minWidth='240px'>
				<Text fontSize='xs' color='gray.300' py={3} paddingInlineStart='0.8rem'>
					{t('divergencePoint:quickSummary.selectLanguage')}
				</Text>
				<MenuItem onClick={() => handleSelectLanguage('PT_BR')}>
					{t('startingPoint:create.languages.pt')}
				</MenuItem>
				<MenuItem onClick={() => handleSelectLanguage('EN_US')}>
					{t('startingPoint:create.languages.en')}
				</MenuItem>
				<MenuItem onClick={() => handleSelectLanguage('ZH_CN')}>
					{t('startingPoint:create.languages.man')}
				</MenuItem>
				<MenuItem onClick={() => handleSelectLanguage('ES_ES')}>
					{t('startingPoint:create.languages.es')}
				</MenuItem>
				<MenuItem onClick={() => handleSelectLanguage('FR_FR')}>
					{t('startingPoint:create.languages.fr')}
				</MenuItem>
				<MenuItem onClick={() => handleSelectLanguage('DE_DE')}>
					{t('startingPoint:create.languages.al')}
				</MenuItem>
			</MenuList>
			{disclosure.isOpen && user && (
				<QuickSummary
					{...disclosure}
					language={language}
					user={user}
					pointId={pointId}
					questionId={questionId}
				/>
			)}
			<EmptyCredits
				{...emptyCreditsDisclosure}
				amount={user?.credits}
				openBuyCredits={handleOpenBuyCredits}
			/>
			<BuyCreditsContent {...buyCreditsDisclosure} />
		</Menu>
	)
}

const Price = () => {
	return (
		<HStack>
			<IconStCoin />
			<Text fontWeight='600' fontSize='16'>
				{QUICK_SUMMARY_CREATION_COST}
			</Text>
		</HStack>
	)
}
