import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#152544',
		light: '#2DA0BD'
	},
	secondary: {
		dark: '#1B4460',
		light: '#2DA0BD'
	}
}

export default themeColors
