import { useMemo } from 'react'

import { useToast } from '@chakra-ui/react'

import useJourneyStore from 'modules/journeys/store/use-journey-store'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { makeMap } from 'modules/map/modules/map-canvas/utils/make-map'
import { loadMap } from 'modules/map/usecases/load-map'
import { ROLE, Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { ErrorResponse } from '../types'

export function usePositionHandler() {
	const toast = useToast()
	const { setMap } = useMapStore(({ actions }) => actions)

	const { getActiveUsers } = useJourneyStore()
	const members = getActiveUsers()
	const currentUserId = useMe().user?.id
	const currentUserRoles = useMemo(
		() => members.find((m) => m.id === currentUserId)?.project_roles,
		[currentUserId, members]
	) as ROLE[]
	const canAddPoint = Role(currentUserRoles).can('point.add')

	const positionErrorHandler = async (mapId: string, error: ErrorResponse) => {
		const { status, message } = error.data

		if (status === 409) {
			const { points = [] } = await loadMap(mapId)
			const timeAfterToast = 500

			toast({ title: message, status: 'error' })

			setTimeout(() => {
				setMap(makeMap(points, canAddPoint), !canAddPoint)
			}, timeAfterToast)
		}
	}

	return positionErrorHandler
}
