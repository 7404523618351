import { fetch } from 'config'

import { Point } from 'modules/map/modules/map-canvas/types'

export async function deleteConversationPoint(id: string) {
	await fetch<Point>({
		url: `/projects/v1/checkpoint/${id}`,
		method: 'DELETE'
	})
}
