import { useTranslation } from 'react-i18next'

import * as yup from 'yup'

import { MonitorType, QualitativePointStatus } from '../types/monitor-point'

export const getMonitorPointStatusSchema = () => {
	const { t } = useTranslation()

	return yup.object({
		monitor_type: yup
			.mixed<MonitorType>()
			.oneOf(Object.values(MonitorType))
			.required(t('errors:requiredField')),
		value: yup.mixed().when('monitor_type', {
			is: MonitorType.QUALITATIVE,
			then: yup
				.mixed<QualitativePointStatus>()
				.oneOf(
					Object.values(QualitativePointStatus),
					t('errors:requiredField')
				),
			otherwise: yup
				.number()
				.required(t('errors:requiredField'))
				.min(0, t('errors:point:error:monitor:goal'))
				.transform((_, val) => Number(val) || 0)
		}),
		message: yup.string()
	})
}
