import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStCoin: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 42 43' {...props}>
		<rect
			x='0.273438'
			y='0.773438'
			width='41.4536'
			height='41.4536'
			rx='20.7268'
			fill='#FDB400'
		/>
		<path
			d='M26.2299 9.75578C27.1898 9.75578 28.0767 10.2679 28.5567 11.0991L33.7862 20.1569C34.2661 20.9882 34.2661 22.0123 33.7862 22.8436L28.5567 31.9014C28.0767 32.7327 27.1898 33.2447 26.23 33.2447L15.7709 33.2447C14.811 33.2447 13.9241 32.7327 13.4442 31.9014L8.21464 22.8436C7.73471 22.0123 7.73471 20.9882 8.21464 20.1569L13.4442 11.0991C13.9241 10.2679 14.811 9.75578 15.7709 9.75578L26.2299 9.75578Z'
			fill='white'
		/>
		<circle cx='16.8414' cy='19.1324' r='1.34333' fill='#FDB400' />
		<circle cx='25.3492' cy='19.1324' r='1.34333' fill='#FDB400' />
		<path
			d='M23.7816 23.1623C24.2762 23.1623 24.6881 23.5693 24.5658 24.0486C24.5237 24.2134 24.4698 24.3753 24.4045 24.5332C24.2244 24.9678 23.9606 25.3627 23.6279 25.6953C23.2953 26.028 22.9004 26.2918 22.4658 26.4718C22.0312 26.6519 21.5653 26.7445 21.0949 26.7445C20.6245 26.7445 20.1587 26.6519 19.7241 26.4718C19.2894 26.2918 18.8945 26.028 18.5619 25.6953C18.2293 25.3627 17.9654 24.9678 17.7854 24.5332C17.72 24.3753 17.6661 24.2134 17.6241 24.0486C17.5017 23.5693 17.9136 23.1623 18.4083 23.1623L21.0949 23.1623H23.7816Z'
			fill='#FDB400'
		/>
	</IconBase>
)
