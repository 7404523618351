import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	VStack
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import queryClient from 'config/query-client'
import useSocket from 'config/socket/useSocket'

import useMonitorPointStatusStore from 'modules/map/components/points/monitor-point/store/use-monitor-point-status-store'

import { useAddMonitorPointStatus } from '../../../../hooks/use-add-monitor-point-status'
import { FormInput } from '../../../../shared/components/form-input'
import { FormSelect } from '../../../../shared/components/form-select'
import { FormTextarea } from '../../../../shared/components/form-textarea'
import { monitorPointChannel } from '../../../../socket/monitor-point-channel'
import {
	MonitorPointStatus,
	MonitorType,
	QualitativePointStatus
} from '../../../../types/monitor-point'
import { getMonitorPointStatusSchema } from '../../../../validators/monitor-point-status'

interface AddStatusFormModalProps {
	isOpen: boolean
	onClose: () => void
	monitorType: MonitorType
}

export const AddStatusFormModal = ({
	isOpen,
	onClose: closeStatusFormModal,
	monitorType
}: AddStatusFormModalProps) => {
	const monitorPointStatusSchema = getMonitorPointStatusSchema()

	const {
		register,
		handleSubmit,
		reset: resetStatusForm,
		formState: { errors }
	} = useForm<MonitorPointStatus>({
		defaultValues: {
			monitor_type: monitorType
		},
		resolver: yupResolver(monitorPointStatusSchema)
	})
	const { id: journeyId = '', pointId: monitorPointId = '' } = useParams()
	const { mutate: addMonitorPointStatus, isLoading } =
		useAddMonitorPointStatus()
	const toast = useToast()
	const { t } = useTranslation()
	const { addStatusState } = useMonitorPointStatusStore()
	const { emit } = useSocket(monitorPointChannel({ projectId: journeyId }))

	const onSubmit = (data: MonitorPointStatus) => {
		addMonitorPointStatus(
			{ monitorPointId, data },
			{
				onSuccess: ({ data }) => {
					queryClient.refetchQueries(['monitorPoint', monitorPointId])

					addStatusState(data)

					const socketPayload = {
						id: data.id,
						monitor_point_id: monitorPointId
					}
					emit('ADD', socketPayload)

					resetStatusForm()
					closeStatusFormModal()

					toast.closeAll()
					toast({
						title: t('map:points:monitor:status:toasts:add'),
						status: 'success'
					})
				},
				onError: (error) => {
					console.log('error on addMonitorPointStatus', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeStatusFormModal}
			isCentered
			size={['full', '4xl']}
		>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent>
				<ModalHeader color='gray.900' fontSize='18px'>
					{t('map:points:monitor:status:actions.add')}
				</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<ModalBody>
						<VStack spacing={4}>
							{monitorType === MonitorType.QUANTITATIVE ? (
								<FormInput
									label={t('map:points:monitor:status:name:label')}
									name='value'
									placeholder={t('map:points:monitor:status:name:placeholder')}
									type='number'
									register={register}
									errors={errors}
									isRequired
								/>
							) : (
								<FormSelect
									label={t('map:points:monitor:status:name:label')}
									name='value'
									placeholder={t(
										'map:points:monitor:status:name:qualitative:placeholder'
									)}
									w={['100%', '30%']}
									isRequired
									register={register}
									errors={errors}
									options={[
										{
											label: t(
												'map:points:monitor:status:name:options:progress'
											),
											value: QualitativePointStatus.IN_PROGRESS
										},
										{
											label: t(
												'map:points:monitor:status:name:options:suspended'
											),
											value: QualitativePointStatus.SUSPENDED
										},
										{
											label: t(
												'map:points:monitor:status:name:options:completed'
											),
											value: QualitativePointStatus.COMPLETED
										}
									]}
								/>
							)}
							<FormTextarea
								label={t('map:points:monitor:status:message:label')}
								name='message'
								maxLength={1000}
								placeholder={t('map:points:monitor:status:message:placeholder')}
								register={register}
								errors={errors}
							/>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme='pink'
							type='submit'
							fontSize='sm'
							fontWeight='semibold'
							isLoading={isLoading}
						>
							{t('map:points:monitor:status:actions.add')}
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	)
}
