import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { manageIndividualMode } from '../usecases/manage-individual-mode'

export function useIndividualMutation(pointId: string) {
	const channel = '/point/essay-point/individual-mode'

	const { emit } = useSocket(channel)
	const setIsIndividual = useEvaluationStore((state) => state.setIsIndividual)

	const mutation = useMutation(manageIndividualMode, {
		onSuccess: (_, { isIndividual }) => {
			setIsIndividual(isIndividual)
			emit('EDIT', pointId)
		}
	})

	return mutation
}
