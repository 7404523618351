import { fetch } from 'config'

type params = {
	pointId: string
	isAnonym: boolean
}

export async function changeIncognitoMode({ pointId, isAnonym }: params) {
	const response = await fetch({
		url: `/projects/v1/divergence-point/${pointId}/incognito-mode`,
		method: 'PATCH',
		data: {
			incognito_mode: isAnonym
		}
	})

	return response.data
}
