import { fetch } from 'config'

import { ConvergencePoint } from '../types'

type Params = {
	closing_date: string
}

export async function updateConvergencePoint(id: string, data: Params) {
	const response = await fetch<ConvergencePoint>({
		url: `/projects/v1/convergence-point/${id}`,
		method: 'PATCH',
		data
	})

	return response.data
}
