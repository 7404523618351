import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Stack,
	Button,
	Icon,
	IconButton,
	IconButtonProps,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'

interface Props {
	onConfirm: () => void
	iconButtonProps?: Partial<IconButtonProps>
	handleOverlay: React.Dispatch<React.SetStateAction<boolean>>
}

export const DeleteCommentButton: React.FC<Props> = ({
	onConfirm,
	iconButtonProps,
	handleOverlay
}) => {
	const { t } = useTranslation()
	const mobileScreen = useBreakpointValue({ base: true, md: false })
	const [deleteClick, setDeleteClick] = useState(false)

	if (deleteClick) {
		return (
			<Stack direction='row' alignItems='center' spacing='1'>
				<Button
					colorScheme='red'
					size={mobileScreen ? 'xs' : 'sm'}
					p={1}
					aria-label='Delete question'
					onClick={() => {
						onConfirm()
					}}
				>
					<Stack direction='row' spacing='0.5' alignItems='center'>
						<Icon as={BiTrashAlt} />
						<Text>{t('buttons:delete')}</Text>
					</Stack>
				</Button>
				<Button
					colorScheme='gray'
					size={mobileScreen ? 'xs' : 'sm'}
					onClick={() => {
						setDeleteClick(false)
						handleOverlay(true)
					}}
				>
					{t('buttons:cancel')}
				</Button>
			</Stack>
		)
	}
	return (
		<IconButton
			size={mobileScreen ? 'xs' : 'sm'}
			aria-label='Delete question'
			icon={<BiTrashAlt />}
			onClick={() => {
				setDeleteClick(true)
				handleOverlay(false)
			}}
			{...iconButtonProps}
		/>
	)
}
