import { fetch } from 'config'

import { Template } from '../types'

export async function removeFavorite(templateId: string) {
	const { data } = await fetch<Template>({
		url: `/templates/v1/template/like/${templateId}`,
		method: 'DELETE'
	})

	return data
}
