import { fetch } from 'config'

import { NoticePoint } from '../../map-canvas/types'

export const getNoticePoint = (pointId: string) => {
	return fetch<NoticePoint>({
		url: `/projects/v1/notice-point/${pointId}`,
		method: 'GET'
	}).then(({ data }) => {
		return data
	})
}
