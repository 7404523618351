import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast, Box, HStack, Button, Text } from '@chakra-ui/react'

import { CloseIcon } from '@chakra-ui/icons'
import useConnectivityStore, {
	EVENT_SESSION_EXPIRED
} from 'config/connectivity-diagnostics/connectivity-store'

const ConnectivityDiagnostics: React.FC<{ eventType: string }> = ({
	eventType
}) => {
	const { t } = useTranslation()
	const toast = useToast()
	const toastId = 'connectivity-diagnostics'
	const [overlayOn, setOverlayOn] = useState(false)

	const reconnectToast = useCallback(() => {
		if (!toast.isActive(toastId)) {
			toast({
				status: 'error',
				duration: null,
				isClosable: true,
				id: toastId,
				onCloseComplete: () => {
					setOverlayOn(false)
				},
				render: ({ onClose }) => {
					return (
						<HStack color='white' bgColor='orange.500' p={2} borderRadius='5px'>
							<Text>
								{t('connectivityDiagnostics:liveConnection.description')}
							</Text>
							<Button
								variant='outline'
								onClick={() => window.location.reload()}
								colorScheme='orange.500'
								bgColor='orange.500'
								size='sm'
							>
								{t('connectivityDiagnostics:genericDiagnosticFailure.proceed')}
							</Button>
							<CloseIcon onClick={onClose} fontSize='.5rem' />
						</HStack>
					)
				}
			})
		}
	}, [t, toast])

	const refreshTokenFail = useCallback(() => {
		if (!toast.isActive(toastId)) {
			toast({
				status: 'error',
				duration: null,
				isClosable: false,
				id: toastId,
				onCloseComplete: () => {
					setOverlayOn(false)
				},
				render: () => {
					return (
						<HStack color='white' bgColor='red.500' p={2} borderRadius='5px'>
							<Text>
								{t('connectivityDiagnostics:expiredSession.description')}
							</Text>
							<Button
								variant='outline'
								onClick={() => window.location.reload()}
								colorScheme='red.500'
								bgColor='red.500'
								size='sm'
							>
								{t('connectivityDiagnostics:expiredSession.proceed')}
							</Button>
						</HStack>
					)
				}
			})
		}
		setOverlayOn(true)
	}, [t, toast])

	useEffect(() => {
		if (eventType === EVENT_SESSION_EXPIRED) {
			refreshTokenFail()
		}
	}, [reconnectToast, refreshTokenFail, eventType])

	if (!overlayOn) {
		return null
	}

	return (
		<Box
			zIndex={1999}
			backgroundColor='rgba(5,5,5,.5)'
			position='fixed'
			width='full'
			height='full'
		></Box>
	)
}

const ConnectivityDiagnosticsWrapper: React.FC = () => {
	const eventType = useConnectivityStore((state) => state.eventType)
	if (!eventType) {
		return null
	}
	return <ConnectivityDiagnostics eventType={eventType} />
}

export default ConnectivityDiagnosticsWrapper
