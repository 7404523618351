import { useQuery } from 'react-query'

import { getConvergencePointAnswer } from '../usecases'

export const useConvergencePointAnswer = (pointId: string) => {
	return useQuery(
		['answer', pointId],
		() => getConvergencePointAnswer(pointId),
		{ retry: false }
	)
}
