import React from 'react'
import { Link } from 'react-router-dom'

import {
	Box,
	BoxProps,
	Flex,
	Heading,
	HStack,
	Icon,
	Spacer,
	Text,
	VStack,
	Avatar,
	LinkOverlay,
	As
} from '@chakra-ui/react'

import { UserAvatar, Card } from 'modules/common/components'
import { useStringToColor } from 'modules/common/hooks'
import { Author } from 'modules/tools/types/tool-model'

type Props = {
	title: string
	description?: string
	link?: string
	color?: string
	user?: Author
	icon: As
	renderMenu?: React.ReactNode
	renderControls?: React.ReactNode
	attachIcon?: As
	attachInfo?: string | number
	height?: string
	width?: string
} & BoxProps

export const CardBase: React.FC<Props> = (props) => {
	const { title, description, color } = props
	const [, fgColor, bgColor] = useStringToColor(
		{ text: title, forceColor: color },
		[500, 50, 50]
	)

	return (
		<Card height={props.height} width={props.width} color={color}>
			<VStack pt={4} spacing='none' h='full'>
				<HStack w='full' justify='space-between'>
					<Avatar
						w={8}
						h={8}
						color={fgColor}
						backgroundColor={bgColor}
						icon={<Icon as={props.icon} fontSize='sm' />}
					/>
					<Box as='span' mr={-2} zIndex={99} position='relative'>
						{props.renderMenu}
					</Box>
				</HStack>

				<Box w='full' flex='1'>
					<Heading fontSize='sm' mt={2} mb={2}>
						{props.link ? (
							<LinkOverlay as={Link} to={props.link}>
								{title}
							</LinkOverlay>
						) : (
							title
						)}
					</Heading>
					{description && (
						<Text
							wordBreak='break-all'
							display='-webkit-box'
							sx={{ WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}
							overflow='hidden'
							fontSize='xs'
							fontWeight='medium'
							color='gray.400'
						>
							{description}
						</Text>
					)}
				</Box>

				<HStack px={4} w='full' mt={1} pb={3} justify='space-between'>
					{props.user && <UserAvatar user={props.user} />}
					<Spacer />
					<CardAttachment info={props.attachInfo} icon={props.attachIcon} />
				</HStack>

				<Controls>{props.renderControls}</Controls>
			</VStack>
		</Card>
	)
}

const CardAttachment = React.memo(
	({ icon, info }: { icon?: As; info?: string | number }) => {
		if (!info) return null

		return (
			<Flex align='center' fontSize='xs' color='gray.300' lineHeight='1'>
				{icon && <Icon as={icon} mr={1} />}
				<Text ml={1} fontWeight='medium'>
					{info}
				</Text>
			</Flex>
		)
	}
)

const Controls = ({ children }: { children: React.ReactNode }) => {
	if (!children) return null
	return (
		<HStack
			p={2}
			w='full'
			justify='flex-end'
			borderTop='1px'
			borderColor='gray.200'
		>
			{children}
		</HStack>
	)
}
