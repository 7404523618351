import { fetch } from 'config'

import { EvaluationResponse } from 'modules/map/types'

export async function getEssaypointResponses(pointId: string) {
	const response = await fetch<EvaluationResponse[]>({
		url: `/projects/v1/essay-point/${pointId}/responses`,
		method: 'GET'
	})
	return response.data
}
