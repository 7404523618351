import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStHome: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 16 16' {...props}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.5 6.125C2.77614 6.125 3 6.34886 3 6.625V14H5.5V10.25C5.5 9.91848 5.6317 9.60054 5.86612 9.36612C6.10054 9.1317 6.41848 9 6.75 9H9.25C9.58152 9 9.89946 9.1317 10.1339 9.36612C10.3683 9.60054 10.5 9.91848 10.5 10.25V14H13V6.625C13 6.34886 13.2239 6.125 13.5 6.125C13.7761 6.125 14 6.34886 14 6.625V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H10C9.72386 15 9.5 14.7761 9.5 14.5V10.25C9.5 10.1837 9.47366 10.1201 9.42678 10.0732C9.37989 10.0263 9.3163 10 9.25 10H6.75C6.6837 10 6.62011 10.0263 6.57322 10.0732C6.52634 10.1201 6.5 10.1837 6.5 10.25V14.5C6.5 14.7761 6.27614 15 6 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V6.625C2 6.34886 2.22386 6.125 2.5 6.125Z'
			fill='currentcolor'
			stroke='currentcolor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.69285 1.27172L15.3457 7.64022C15.5452 7.83117 15.5521 8.14768 15.3612 8.34715C15.1702 8.54663 14.8537 8.55355 14.6542 8.36259L8.01002 2.00239C8.00697 2.00212 8.00366 2.00196 8.00016 2.00195C7.99654 2.00195 7.99312 2.00211 7.98999 2.00238L1.34575 8.3626C1.14627 8.55355 0.829764 8.54663 0.638812 8.34715C0.44786 8.14768 0.454772 7.83117 0.654252 7.64022L7.30756 1.27132C7.51151 1.06454 7.78947 1.00157 8.00195 1.00195C8.21355 1.00233 8.49051 1.06582 8.69285 1.27172Z'
			fill='currentcolor'
			stroke='currentcolor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.5 2C10.5 1.72386 10.7239 1.5 11 1.5H12.5C12.7761 1.5 13 1.72386 13 2V5.59375C13 5.86989 12.7761 6.09375 12.5 6.09375C12.2239 6.09375 12 5.86989 12 5.59375V2.5H11.5V4.15625C11.5 4.43239 11.2761 4.65625 11 4.65625C10.7239 4.65625 10.5 4.43239 10.5 4.15625V2Z'
			fill='currentcolor'
			stroke='currentcolor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</IconBase>
)
