import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'

import { deleteUserAssistant } from 'modules/assistants/usecases/delete-user-assistant'

// TODO: refactor to use delete modal common component
export const AssistantDeleteConfirmModal = ({
	isOpen,
	onClose,
	assistantName,
	userAssistantId
}: {
	isOpen: boolean
	onClose: () => void
	assistantName: string
	userAssistantId: string
}) => {
	const { t } = useTranslation()
	const toast = useToast()
	const [isDeleting, setIsDeleting] = useState(false)
	const isMobile = useBreakpointValue({ base: true, md: false })

	const handleDelete = async () => {
		setIsDeleting(true)

		try {
			await deleteUserAssistant(userAssistantId)

			queryClient.invalidateQueries('userAssistants')

			toast({
				title: t('assistants:toast.success.title'),
				status: 'success'
			})
		} catch (error) {
			toast({
				title: t('assistants:toast.error.title'),
				status: 'error'
			})
		} finally {
			setIsDeleting(false)
			onClose()
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent pt={2} borderRadius={'8px'}>
				<ModalHeader fontWeight='bold' fontSize='lg'>
					{t('assistants:delete.title')}
				</ModalHeader>
				<ModalCloseButton mt={4} mr={3} />
				<ModalBody>
					<Text mb={0.5}>
						{t('assistants:delete.message')}
						<Text as={'span'} fontWeight={'bold'}>
							{assistantName}
						</Text>
						? {t('assistants:delete.confirm')}
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button mr={3} onClick={onClose}>
						{t('assistants:modal.cancel')}
					</Button>
					<Button
						colorScheme='pink'
						onClick={handleDelete}
						isLoading={isDeleting}
						leftIcon={isMobile ? undefined : <BiTrashAlt />}
					>
						{t('assistants:modal.delete.confirm')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
