import { useTranslation, Trans } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Button,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { EvaluationResponse } from 'modules/map/types'

import { deleteAllUserAnswers } from '../../usecases/delete-all-user-answers'

type Props = {
	isOpen: boolean
	onClose: () => void
	pointId: string
	journeyId: string
}

export const ModalDeleteAllAnswers = ({
	isOpen,
	onClose,
	pointId,
	journeyId
}: Props) => {
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { t } = useTranslation()
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))
	const { deleteEssayState, essayPointResponses } = useEssaysStore()
	const { setHasAnswers } = useEvaluationStore()
	const toast = useToast()

	const handleDelete = async () => {
		if (essayPointResponses.length === 1) {
			setHasAnswers(false)
		}
		try {
			const { ids } = await deleteAllUserAnswers(pointId)
			ids.forEach((id) => {
				emit('DELETE', { id, essay_point_id: pointId })
				deleteEssayState({} as EvaluationResponse, id)
			})
		} catch (error) {
			toast({
				title: t('errors:default'),
				status: 'error',
				isClosable: true,
				position: 'bottom'
			})
		}

		onClose()
	}

	return (
		<Modal size='lg' isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton mt={isMobile ? 1 : 5} mr={isMobile ? 0 : 4} />
				<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
					<Text fontSize={['18', 'xl']} fontWeight='bold'>
						{t('evaluationPoint:warning.title')}
					</Text>
				</ModalHeader>
				<ModalBody mt={2} px={isMobile ? 4 : 8}>
					<Text mb={5}>{t('evaluationPoint:warning.message')}</Text>
					<Trans
						i18nKey='evaluationPoint:warning.confirmation'
						components={[<strong />]}
					/>
				</ModalBody>
				<ModalFooter px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<HStack>
						<Button
							fontSize={['xs', 'md']}
							colorScheme='custom_scheme_color_button'
							onClick={handleDelete}
							leftIcon={<Icon as={BiTrashAlt} />}
						>
							{t('buttons:deleteAnswer')}
						</Button>

						<Button
							fontWeight='semibold'
							color='gray.400'
							bgColor='gray.180'
							fontSize={['xs', 'md']}
							onClick={onClose}
						>
							{t('buttons:cancel')}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
