import { fetch } from 'config'

type Params = {
	pointId: string
	isIndividual: boolean
}

export async function manageIndividualMode({ isIndividual, pointId }: Params) {
	const action = isIndividual ? 'enable' : 'disable'

	const response = await fetch({
		url: `/projects/v1/essay-point/${pointId}/individual-mode/${action}`,
		method: 'PATCH'
	})
	return response.data
}
