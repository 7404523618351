import { useQuery } from 'react-query'

import Api from 'data/api'

import { journeyKeys } from '../query'

export const useLabs = () => {
	const { data, isLoading } = useQuery(
		journeyKeys.labs(),
		Api.Projects.getPlanUsageByLabs
	)

	return {
		labs: data || [],
		isLoading,
		isEmpty: !data?.length
	}
}
