import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { IconBase } from './_icon-base'

export const IconStToolsSolid: React.FC = (props: IconProps) => (
	<IconBase viewBox='0 0 14 16' fill='none' {...props}>
		<path
			d='M6 0.57735C6.6188 0.220085 7.3812 0.220085 8 0.57735L12.9282 3.42265C13.547 3.77992 13.9282 4.44017 13.9282 5.1547V10.8453C13.9282 11.5598 13.547 12.2201 12.9282 12.5774L8 15.4226C7.3812 15.7799 6.6188 15.7799 6 15.4226L1.0718 12.5774C0.452995 12.2201 0.0717969 11.5598 0.0717969 10.8453V5.1547C0.0717969 4.44017 0.452995 3.77992 1.0718 3.42265L6 0.57735Z'
			fill='currentcolor'
		/>
	</IconBase>
)
