import { useQuery, UseQueryOptions } from 'react-query'

import Api from 'data/api'

import { Journey } from 'modules/journeys/types'

import { journeyKeys } from '../query'

export function useJourney<T = Journey>(
	id: string,
	options?: UseQueryOptions<Journey, unknown, T>
) {
	return useQuery<Journey, unknown, T>(
		journeyKeys.detail(id),
		() => Api.Projects.getById(id),
		options
	)
}
