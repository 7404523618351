import { TenantColors } from '../types'
import fdcCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const fdcColors: TenantColors = {
	...fdcCustomColors,
	...themeColors,
	...componentColors,
	...screenColors
}

export default fdcColors
