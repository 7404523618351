import { Provider } from 'modules/login/types'

export function concatProviders(
	publicProviders: Provider[],
	privateProvider: Provider
) {
	if (privateProvider === undefined) {
		return publicProviders
	}
	return publicProviders.concat(privateProvider)
}
