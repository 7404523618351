import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	Button,
	HStack,
	Icon,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { useChatRoom } from 'modules/assistants/hooks/chat-room/use-chat-room'
import { useAssistantFreeChat } from 'modules/assistants/hooks/use-assistant-free-chat'
import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { IconStCoin } from 'modules/common/components/icons/st-coin'
import { ASSISTANT_CHAT } from 'modules/constants'
import { useMe } from 'modules/user/hooks'
import { userKeys } from 'modules/user/query'

export const AssistantStartConfirmButton = ({
	assistantId,
	setChatRoomId,
	callback
}: {
	assistantId: string | null
	setChatRoomId?: React.Dispatch<React.SetStateAction<string>>
	callback?: () => void
}) => {
	const { mutate: createChatRoom } = useChatRoom()
	const { hasFreeAssistantChat, isOpenAssistantChatFree } =
		useAssistantFreeChat()
	const { user } = useMe()
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const {
		isOpen: isBuyCreditsOpen,
		onOpen: onBuyCreditsOpen,
		onClose: onBuyCreditsClose
	} = useDisclosure()

	const handleCreateChatRoom = (assistantId: string) => {
		sendGAEvent('assistant_card_chat_button_clicked')

		if (user) {
			if (!hasFreeAssistantChat && user.credits < ASSISTANT_CHAT) {
				onBuyCreditsOpen()
				return
			}

			setIsLoading(true)

			createChatRoom(assistantId, {
				onSuccess: ({ data }) => {
					queryClient.invalidateQueries(userKeys.me())
					queryClient.invalidateQueries('free_first_chatroom_availability')
					setChatRoomId?.(data.id)
					setIsLoading(false)
					navigate('/dashboard', {
						state: {
							assistant: { ...location.state.assistant, chatRoomId: data.id }
						}
					})
					callback?.()
				},
				onError: (error) => {
					console.log('error on createChatRoom', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
					setIsLoading(false)
				}
			})
		}
	}

	return (
		<>
			<Button
				w='100%'
				fontWeight='semibold'
				colorScheme='pink'
				isLoading={isLoading}
				onClick={() => {
					handleCreateChatRoom(assistantId as string)
				}}
			>
				{isOpenAssistantChatFree ? (
					<Text>{t('assistants:modal.free-chat.view.experiment')}</Text>
				) : (
					<>
						<Text>{t('assistants:modal.free-chat.list.start-chat')}</Text>
						{!hasFreeAssistantChat && (
							<HStack px={2}>
								<Icon w={5} h={5} as={IconStCoin} />
								<Text fontSize='sm' fontWeight='bold'>
									{ASSISTANT_CHAT}
								</Text>
							</HStack>
						)}
					</>
				)}
			</Button>
			{isBuyCreditsOpen && (
				<BuyCreditsContent
					isOpen={isBuyCreditsOpen}
					onClose={onBuyCreditsClose}
				/>
			)}
		</>
	)
}
