import defaultCustomColors from '../default/colors'

const trescoracoesCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#482306',
		700: '#482306'
	},

	secondary_color_scheme_button: {
		700: '#A32341'
	},

	switch_scheme_color_checked: {
		500: '#A32341'
	},

	radio_color: {
		500: '#A32341'
	}
}

export default trescoracoesCustomColors
