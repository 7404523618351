import { HStack, useBreakpointValue } from '@chakra-ui/react'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'

import { useRemoveQuestionAnalysisMutation } from '../../hooks/use-remove-question-analysis-mutation'

type OptionsProps = {
	questionId: string
	pointId: string
	analysisId: string
}

export const Options = ({ questionId, pointId, analysisId }: OptionsProps) => {
	const removeSummaryMutation = useRemoveQuestionAnalysisMutation(questionId)
	const isMobile = useBreakpointValue({ base: true, md: false })
	const buttonSize = isMobile ? 'lg' : 'xl'

	const handleDelete = () => {
		removeSummaryMutation.mutate({ analysisId, pointId, questionId })
	}

	return (
		<HStack>
			<DeleteConfirmButton
				onConfirm={handleDelete}
				iconButtonProps={{
					fontSize: buttonSize,
					color: 'pink.500',
					background: 'transparent'
				}}
			/>
		</HStack>
	)
}
