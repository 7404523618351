import { fetch } from 'config'

export type Params = {
    essayPointId: string
    essayPointResponseId: string
    data: string
}

export async function editEssayPointEvaluation({ essayPointId, essayPointResponseId, data }: Params) {
    const response = await fetch({
        url: `/projects/v1/essay-point/${essayPointId}/responses/${essayPointResponseId}`,
        method: 'PUT',
        data: JSON.stringify({ result: data }),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    return response.data
}