import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RiRobot2Line } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'

import {
	Avatar,
	Grid,
	Heading,
	HStack,
	Icon,
	Spinner,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { AssistantChatFreeModal } from 'modules/assistants/components/assistant-chat-free'
import { AssistantChatModal } from 'modules/assistants/components/assistant-chat-modal'
import { useChatRoom } from 'modules/assistants/hooks/chat-room/use-chat-room'
import { useFreeChatStore } from 'modules/assistants/store/free-chat-store'
import { Card } from 'modules/common/components'
import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { IconStCoin } from 'modules/common/components/icons'
import { useStringToColor } from 'modules/common/hooks'
import { ASSISTANT_CHAT } from 'modules/constants'
import { Assistant } from 'modules/map/types/assistants'
import { userKeys } from 'modules/user/query'
import { User } from 'modules/user/types'

export const AssistantSimpleCard = ({
	user,
	assistant
}: {
	user?: User
	assistant: Assistant
}) => {
	const [color, bgColor] = useStringToColor({ text: assistant.name })
	const { t } = useTranslation()
	const chatRoomIdRef = useRef<string>('')
	const toast = useToast()
	const { mutate: createChatRoom } = useChatRoom()
	const { state } = useLocation()
	const hasFreeAssistantChat = state.hasFreeAssistantChat
	const { onExperimentNow, setIsLoading, isLoading } = useFreeChatStore()

	const {
		isOpen: isChatOpen,
		onClose: onChatClose,
		onOpen: onChatOpen
	} = useDisclosure()

	const {
		isOpen: isChatFreeAssistantOpen,
		onClose: onChatFreeAssistantClose,
		onOpen: onChatFreeAssistantOpen
	} = useDisclosure()

	const {
		isOpen: isBuyCreditsOpen,
		onClose: onBuyCreditsClose,
		onOpen: onBuyCreditsOpen
	} = useDisclosure()

	const handleCreateChatRoom = (assistantId: string) => {
		sendGAEvent(
			`assistant_card_chat_button_${hasFreeAssistantChat ? 'free' : 'home'}`
		)
		setIsLoading(assistantId, true)

		if (user) {
			if (!hasFreeAssistantChat && user.credits < ASSISTANT_CHAT) {
				onBuyCreditsOpen()
				setIsLoading(assistantId, false)
				return
			}

			createChatRoom(assistantId, {
				onSuccess: ({ data }) => {
					queryClient.invalidateQueries(userKeys.me())
					queryClient.invalidateQueries('free_first_chatroom_availability')
					chatRoomIdRef.current = data.id
					onChatOpen()
					setIsLoading(assistantId, false)
				},
				onError: (error) => {
					console.log('error on createChatRoom', error)
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
					setIsLoading(assistantId, false)
				}
			})
		}
	}

	const handleCreateChatFreeRoom = () => {
		onChatFreeAssistantOpen()
	}

	return (
		<>
			<Card
				width='100%'
				color={color}
				boxProps={{ padding: '3', bgColor: 'gray.100' }}
			>
				<HStack
					cursor='pointer'
					onClick={
						hasFreeAssistantChat && !onExperimentNow
							? handleCreateChatFreeRoom
							: () => handleCreateChatRoom(assistant.id)
					}
					justify='space-between'
				>
					<HStack py={1}>
						<Avatar
							w={6}
							h={6}
							bgColor={bgColor}
							icon={<Icon as={RiRobot2Line} fontSize='sm' color='white' />}
						/>
						<Grid alignItems='start' gap={0}>
							<Heading fontSize='sm'>{assistant.name}</Heading>
							<Text isTruncated fontSize='xs' color='gray.400'>
								{assistant.formation}
							</Text>
						</Grid>
					</HStack>
					{isLoading[assistant.id] ? (
						<Spinner size='sm' color='gray.400' mr={5} />
					) : (
						!hasFreeAssistantChat && (
							<HStack px={2}>
								<Icon w={5} h={5} as={IconStCoin} />
								<Text fontSize='sm' fontWeight='bold'>
									{ASSISTANT_CHAT}
								</Text>
							</HStack>
						)
					)}
				</HStack>
			</Card>
			{isChatOpen && (
				<AssistantChatModal
					isOpen={isChatOpen}
					onClose={onChatClose}
					assistantData={assistant}
					chatRoomId={chatRoomIdRef.current}
				/>
			)}
			{isChatFreeAssistantOpen && (
				<AssistantChatFreeModal
					isOpen={isChatFreeAssistantOpen}
					onClose={onChatFreeAssistantClose}
					assistantId={assistant.id}
					handleCreateChatRoom={handleCreateChatRoom}
				/>
			)}
			{isBuyCreditsOpen && (
				<BuyCreditsContent
					isOpen={isBuyCreditsOpen}
					onClose={onBuyCreditsClose}
				/>
			)}
		</>
	)
}
