import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'

import { Flex, Icon, Text, useBreakpointValue } from '@chakra-ui/react'

export const LinksEmpty: React.FC<{ isLink: boolean }> = ({ isLink }) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false })

	if (isLink) {
		return (
			<Flex
				flexDirection='column'
				h='80%'
				w='full'
				alignItems='center'
				justifyContent='center'
			>
				<Icon as={BiLink} fontSize='5rem' color='gray.400' />
				<Text fontSize='md' fontWeight='600' color='gray.400'>
					{t('divergencePoint:about_kit:about_empty_1')}
				</Text>

				<Text fontSize='md' fontWeight='600' color='gray.400'>
					{t('divergencePoint:about_kit:about_empty_2')}
				</Text>
				{!isMobile && (
					<Text fontSize='md' fontWeight='600' color='gray.400'>
						{t('divergencePoint:about_kit:about_empty_3')}
					</Text>
				)}
				{isMobile && (
					<>
						<Text fontSize='md' fontWeight='600' color='gray.400'>
							{t('divergencePoint:about_kit:about_empty_mobile_3')}
						</Text>
						<Text fontSize='md' fontWeight='600' color='gray.400'>
							{t('divergencePoint:about_kit:about_empty_mobile_4')}
						</Text>
					</>
				)}
			</Flex>
		)
	}
	return (
		<Flex
			flexDirection='column'
			h='xl'
			w='full'
			alignItems='center'
			justifyContent='center'
		>
			<Icon as={BiLink} fontSize='5rem' color='gray.400' />
			<Text fontSize='md' fontWeight='600' color='gray.400'>
				{t('divergencePoint:about_kit:reference_empty_1')}
			</Text>
			{!isMobile && (
				<Text fontSize='md' fontWeight='600' color='gray.400'>
					{t('divergencePoint:about_kit:reference_empty_2')}
				</Text>
			)}
			{isMobile && (
				<>
					<Text fontSize='md' fontWeight='600' color='gray.400'>
						{t('divergencePoint:about_kit:reference_empty_mobile_2')}
					</Text>
					<Text fontSize='md' fontWeight='600' color='gray.400'>
						{t('divergencePoint:about_kit:reference_empty_mobile_3')}
					</Text>
				</>
			)}
		</Flex>
	)
}
