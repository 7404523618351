import { fetch } from 'config'

type CodeResponse = {
	id: string
	qr_code: string
}

export const generateCode = (credit_amount: number) => {
	return fetch<CodeResponse>({
		url: 'billing/v1/purchase/credits/pix/generate',
		method: 'POST',
		data: { credit_amount }
	}).then(({ data }) => data)
}
