import { fetch } from 'config'

type ExternalInfo = {
	sub: string
	name: string
	email: string
	journey_id: string
	registered: boolean
	enrollment: boolean
}

export const validateExternalToken = ({
	accountId,
	payload
}: {
	accountId: string
	payload: string
}) => {
	return fetch<ExternalInfo>({
		url: 'users/v1/auth/validate-external-token',
		method: 'POST',
		params: { accountId, payload }
	}).then(({ data }) => data)
}
