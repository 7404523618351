import { fetch } from 'config'

import { ChatRoomMessage } from 'modules/assistants/types/chat-room'

export const patchChatRoomMessage = async (
	chatRoomId: string,
	message: string,
	openWindow?: boolean
) => {
	return await fetch<ChatRoomMessage>({
		url: `projects/v1/chat-room/${chatRoomId}/message`,
		method: 'PATCH',
		params: {
			open_window: openWindow
		},
		data: {
			message
		}
	})
}
