import { fetch } from 'config'

import { BaseMonitorPoint, MonitorPoint } from '../types/monitor-point'

export const createMonitorPoint = async (
	mapId: string,
	data: BaseMonitorPoint
) => {
	const response = await fetch<MonitorPoint>({
		url: `projects/v1/map/${mapId}/monitor-point`,
		method: 'POST',
		data
	})

	return response.data
}
