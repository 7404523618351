import { fetch } from 'config'

import { TemplateDivergencePoint } from '../types'

export async function getDivergencePoint(pointId: string) {
	const { data } = await fetch<TemplateDivergencePoint>({
		url: `/templates/v1/divergence-point/${pointId}`,
		method: 'GET'
	})

	return data
}
