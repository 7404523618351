import { Navigate, useLocation } from 'react-router-dom'

// Esse component é utilizado para direcionar as URLs do livro Silvio, não pode ser apagado

export const V1Redirect = () => {
	const path = useLocation()

	const query = new URLSearchParams(path.search)
	const question = query.get('question')

	let newLocation = path.pathname
		.replace('/dashboard/project', '/journey')
		.replace('mission', 'map')
		.replace('content', 'point')

	if (question) {
		newLocation = newLocation.concat('/question/', question)
	}

	return <Navigate to={newLocation} />
}
