import { ThemeColors } from '../types'
import institutoAnimaCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#369793',
		light: '#32CDAC'
	},

	secondary: {
		dark: institutoAnimaCustomColors.yellow[500],
		light: institutoAnimaCustomColors.orange[50]
	}
}

export default themeColors
