import defaultCustomColors from '../default/colors'

const sinaencoCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#F68E1E',
		700: '#F68E1E'
	},

	secondary_color_scheme_button: {
		700: '#006F3B'
	},

	switch_scheme_color_checked: {
		500: '#006F3B'
	},

	radio_color: {
		500: '#006F3B'
	}
}

export default sinaencoCustomColors
