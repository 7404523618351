import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#008AC3',
		light: '#8EC2ED'
	},

	secondary: {
		dark: '#FD6A00',
		light: '#FFB783'
	}
}

export default themeColors
