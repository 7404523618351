import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Flex,
	FormErrorMessage,
	Image,
	InputGroup,
	InputRightElement,
	Text,
	Textarea,
	useTheme,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'

import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import UseCommentsStore from 'modules/map/store/use-comments-store'
import { AnswerQuestionValitation } from 'modules/map/validators'

import { AnonymInfo } from '../../view-divergence-point/anonym-mode/anonym-Info'
import SendIcon from '../send.svg'

type FormValues = {
	answer: string
}

type Props = {
	divergencePointId: string
	questionId: string
	projectId: string
	closeModal: () => void
}

export const WriteTab = ({
	divergencePointId,
	questionId,
	projectId,
	closeModal
}: Props) => {
	const { t } = useTranslation()
	const validation = AnswerQuestionValitation()
	const { addComment, addCommentState } = UseCommentsStore()
	const { emit } = useSocket(divergencyPointChannel({ projectId }))
	const toast = useToast()
	const [text, setText] = useState('')

	const {
		register,
		handleSubmit,
		formState: { isValid, isSubmitting, errors },
		getValues
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: { answer: '' },
		resolver: yupResolver(validation)
	})

	const onSubmit = async () => {
		const savedComment = await addComment(
			divergencePointId,
			questionId,
			getValues().answer.trim()
		)
		addCommentState(savedComment)
		const commentInfo = {
			divergence_point_id: savedComment.divergence_point_id,
			question_id: savedComment.question_id,
			parent_id: savedComment.parent_id,
			id: savedComment.id
		}
		emit('ADD', commentInfo)
		closeModal()
		toast({
			title: t('divergencePoint:reply_created'),
			isClosable: true,
			status: 'success'
		})
	}

	const handleAnswer = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		const target = event.target as HTMLTextAreaElement
		const currentHeight = target.offsetHeight
		const currentScrollHeight = target.scrollHeight

		const maxScrollHeight = 0.5 * window.innerHeight

		if (target.value.length <= 3000) {
			setText(target.value)
			if (
				currentHeight < currentScrollHeight &&
				currentScrollHeight <= maxScrollHeight
			) {
				event.currentTarget.style.height = 'inherit'
				event.currentTarget.style.height = `${currentScrollHeight + 10}px`
			}
		}
	}

	const handleChangeAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
		setText(event.target.value)
	}

	const theme = useTheme()
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputGroup>
				<Textarea
					maxLength={3000}
					minHeight={[200, 180]}
					padding={2}
					borderRadius='lg'
					onKeyUp={handleAnswer}
					pr={20}
					_focus={{
						borderColor: theme.colors.input_border
					}}
					placeholder={t('divergencePoint:description_send_answer')}
					{...register('answer', {
						onChange: handleChangeAnswer
					})}
				/>
				<InputRightElement mr={3}>
					<Text fontSize='sm'>{text.length}/3000</Text>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage>{errors.answer?.message}</FormErrorMessage>
			<AnonymInfo />
			<Flex justifyContent='flex-end' pt={4}>
				<Button
					isDisabled={!isValid}
					type='submit'
					mt={4}
					colorScheme='custom_scheme_color_button'
					leftIcon={<Image src={SendIcon} />}
					isLoading={isSubmitting}
				>
					{t('divergencePoint:send_answer')}
				</Button>
			</Flex>
		</form>
	)
}
