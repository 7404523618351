import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const assistantUnshareLab = async (
	labId: string,
	assistantId: string
) => {
	return await fetch<Assistant>({
		url: `projects/v1/assistants/${assistantId}/unshare-lab`,
		method: 'PATCH',
		params: {
			labId
		}
	})
}
