import { fetch } from 'config'

type Params = {
	pointId: string
	isMultipleResponse: boolean
}

export async function manageMultipleResponseMode({
	isMultipleResponse,
	pointId
}: Params) {
	const action = isMultipleResponse ? 'enable' : 'disable'

	const response = await fetch({
		url: `/projects/v1/essay-point/${pointId}/multiple-response-mode/${action}`,
		method: 'PATCH'
	})
	return response.data
}
