import { fetch } from 'config'

export const createMonitorPointAnalysis = async (
	monitorPointId: string,
	data: { language: string }
) => {
	const response = await fetch<{ analysis: string; create_at: string }>({
		url: `projects/v1/monitor-point/${monitorPointId}/analyze`,
		method: 'POST',
		data
	})

	return response.data
}
