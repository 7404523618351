import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCaretRight, BiLink } from 'react-icons/bi'

import { Flex, Text, Button, Icon } from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import Hyperlink from 'modules/common/components/hyperlink'
import { PointInfos } from 'modules/map/store/use-comments-store'
import { Tool } from 'modules/tools/types/tool-model'

export const AboutTool: React.FC<{
	tool: Tool
	pointInfo: PointInfos
	setLinksOrRefs: Dispatch<SetStateAction<string>>
}> = ({ tool, setLinksOrRefs, pointInfo }) => {
	const { t } = useTranslation()
	const [refs, setRefs] = useState(true)

	const handleOpenReferences = () => {
		setLinksOrRefs('refs')
		setRefs(true)
		sendGAEvent('kit_references')
	}
	const handleOpenComplementaryLinks = () => {
		setLinksOrRefs('links')
		setRefs(false)
		sendGAEvent('kit_complementary_links')
	}
	return (
		<Flex flexDirection='column' h='100%' overflow='hidden'>
			<Flex flexDirection='column' overflowY='auto' maxH='70%' px={8} py={8}>
				<Text fontSize='md' color='gray.300' fontWeight='600'>
					{t('divergencePoint:about_kit:description')}
				</Text>
				<Text fontSize='md' mt={2} variant='breakLines'>
					<Hyperlink>{tool.description || pointInfo.introduction}</Hyperlink>
				</Text>
			</Flex>
			<Button
				onClick={handleOpenReferences}
				background={refs ? 'gray.100' : 'transparent'}
				minH='15%'
				maxH='15%'
				paddingX={10}
				paddingY={12}
				display='flex'
				justifyContent='space-between'
				boxShadow='xs'
			>
				<Flex alignItems='center'>
					<Icon as={BiLink} fontSize='2xl' />
					<Text fontWeight='500' color='gray.1' ml={2}>
						{t('divergencePoint:about_kit:references')}
					</Text>
				</Flex>
				<Icon as={BiCaretRight} fontSize='xl' />
			</Button>
			<Button
				onClick={handleOpenComplementaryLinks}
				background={!refs ? 'gray.100' : 'transparent'}
				paddingX={10}
				paddingY={12}
				display='flex'
				justifyContent='space-between'
				boxShadow='sm'
				minH='15%'
				maxH='15%'
			>
				<Flex alignItems='center'>
					<Icon as={BiLink} fontSize='2xl' />
					<Text fontWeight='500' color='gray.1' ml={2}>
						{t('divergencePoint:about_kit:links')}
					</Text>
				</Flex>
				<Icon as={BiCaretRight} fontSize='xl' />
			</Button>
		</Flex>
	)
}
