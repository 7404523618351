import React, { createContext } from 'react'

import { useBoolean, useBreakpointValue } from '@chakra-ui/react'

type UseBooleanReturn = {
	on: () => void
	off: () => void
	toggle: () => void
}

type DashContext = {
	showNav: boolean
	shrink: boolean
	setShowNav: UseBooleanReturn
	setShrink: UseBooleanReturn
	sidebar: {
		width: number | string
		logoMargin: number
	}
}

const makeUseBooleanReturn = () => ({
	on: () => null,
	off: () => null,
	toggle: () => null
})

export const DashboardContext = createContext<DashContext>({
	showNav: false,
	setShowNav: makeUseBooleanReturn(),
	shrink: true,
	setShrink: makeUseBooleanReturn(),
	sidebar: {
		width: '18rem',
		logoMargin: 8
	}
})

export const DashboardProvider: React.FC<{
	collapsible?: boolean
	children: React.ReactNode
}> = ({ children }) => {
	const [showNav, setShowNav] = useBoolean(false)
	const [shrinkState, setShrink] = useBoolean(true)

	const isMobile = useBreakpointValue({ base: true, lg: false })
	const shrink = isMobile ? false : shrinkState

	const sidebar = {
		width: shrink ? 20 : '18rem',
		logoMargin: shrink ? 3.5 : 8
	}

	return (
		<DashboardContext.Provider
			value={{ showNav, setShowNav, shrink, setShrink, sidebar }}
		>
			{children}
		</DashboardContext.Provider>
	)
}
