import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	useTheme
} from '@chakra-ui/react'

import { SmallCloseIcon } from '@chakra-ui/icons'

type Props = {
	name: string
	placeholder: string
	canDelete: boolean
	onRemove: () => void
	onEnter?: () => void
}

type keyHandle = React.KeyboardEventHandler<HTMLInputElement>

export const ConvergenceOption: React.FC<Props> = (props) => {
	const { t } = useTranslation()
	const { name, placeholder, onRemove, canDelete, onEnter } = props
	const { register } = useFormContext()

	const handleKeyDown: keyHandle = ({ key }) => {
		if (key === 'Enter') {
			onEnter?.()
		}
	}

	const theme = useTheme() as { colors: any }
	return (
		<InputGroup>
			<Input
				{...register(name)}
				placeholder={placeholder}
				variant='flushed'
				_focus={{
					borderColor: theme.colors.input_border,
					boxShadow: `0px 1px 0px 0px ${theme.colors.input_border}`
				}}
				onKeyPress={handleKeyDown}
			/>
			{canDelete && (
				<InputRightElement>
					<IconButton
						aria-label={t('map:points.convergence.create.removeOption')}
						variant='ghost'
						_hover={{ bg: 'transparent' }}
						color='gray.300'
						onClick={onRemove}
						icon={<SmallCloseIcon />}
					/>
				</InputRightElement>
			)}
		</InputGroup>
	)
}
