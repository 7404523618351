import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiCalendar } from 'react-icons/bi'

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Stack,
	Text,
	useTheme
} from '@chakra-ui/react'

interface FormDatePickerProps {
	label: string
	name: string
	minDate: string
	register: UseFormRegister<any>
	errors: Record<string, any>
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
	label,
	name,
	minDate,
	register,
	errors
}) => {
	const { t } = useTranslation()
	const theme = useTheme() as { colors: any }
	const errorMessage = errors[name]?.message

	return (
		<FormControl isRequired isInvalid={!!errorMessage}>
			<FormLabel fontWeight='semibold' color='gray.400'>
				{label}
			</FormLabel>
			<Stack mt={2} direction='row' alignItems='center'>
				<Icon color='gray.400' as={BiCalendar} />
				<Text color='gray.400' fontWeight='medium' pointerEvents='none'>
					{t('map:points.conversation.create.date.label')}
				</Text>
				<Input
					variant='filled'
					type='date'
					{...register(name)}
					maxW='140px'
					min={minDate}
					sx={{
						'&::-webkit-calendar-picker-indicator': {
							display: 'none'
						}
					}}
					_focus={{
						borderColor: theme.colors.input_border,
						borderWidth: '0px'
					}}
				/>
			</Stack>
			<FormErrorMessage>{errorMessage}</FormErrorMessage>
		</FormControl>
	)
}
