import { TenantColors } from '../types'
import googlecloudCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const googlecloudColors: TenantColors = {
	...googlecloudCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default googlecloudColors
