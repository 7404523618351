import { fetch } from 'config'

type Params = {
	pointId: string
	answerId: string
}

export async function deleteEssayAnswer({ answerId, pointId }: Params) {
	const response = await fetch({
		url: `/projects/v1/essay-point/${pointId}/responses/${answerId}`,
		method: 'DELETE'
	})
	return response.data
}
