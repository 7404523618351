import { useTranslation } from 'react-i18next'

import { Box, BoxProps, useToast } from '@chakra-ui/react'

import { ShareButton } from 'modules/common/components/share-button'
import { makePointUrl } from 'modules/map/utils/makePointUrl'

interface Props extends BoxProps {
	pointType: string
}

export const ButtonSharePoint = ({ pointType, ...props }: Props) => {
	const { t } = useTranslation()
	const toast = useToast()

	const handleSuccess = () => {
		toast({
			status: 'success',
			title: t('inviteUsers:byPublicLink.copySuccess')
		})
	}

	return (
		<Box pl={2} {...props}>
			<ShareButton
				params={{
					url: makePointUrl()
				}}
				onSuccess={handleSuccess}
				pointType={pointType}
			/>
		</Box>
	)
}
