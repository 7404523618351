import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { VStack } from '@chakra-ui/react'

import { InputField } from 'modules/common/components/forms/hook-form-fields'

import { AcceptTermsField } from '../accept-terms-field'

type Props = {
	email?: string
	name?: string
}

export const SignupWithExternalToken = ({ email, name }: Props) => {
	const { t } = useTranslation()

	const { setValue } = useFormContext()

	useEffect(() => {
		setValue('name', name)
		setValue('email', email)
	}, [setValue])

	return (
		<>
			<VStack>
				<InputField
					isDisabled={true}
					fieldName='name'
					label={t('signup:name')}
					placeholder={t('signup:placeholders.name')}
				/>
				<InputField
					isDisabled={true}
					fieldName='email'
					label='email'
					placeholder={t('login:placeholders.email')}
				/>
				<InputField
					fieldName='password'
					label={t('signup:password')}
					placeholder={t('login:placeholders.password')}
					type='password'
				/>
				<InputField
					fieldName='confirmPassword'
					label={t('signup:confirmPassword')}
					placeholder={t('login:placeholders.password')}
					type='password'
					onPaste={(e) => {
						e.preventDefault()
					}}
				/>
			</VStack>
			<AcceptTermsField />
		</>
	)
}
