import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
	title: string
}

export const DocHeader: React.FC<Props> = ({ title }) => {
	return (
		<Helmet>
			<title>{title} - strateegia.digital</title>
		</Helmet>
	)
}
