import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'

import queryClient from 'config/query-client'
import useSocket from 'config/socket/useSocket'

import { useStringToColor } from 'modules/common/hooks'
import { useColorWithOpacity } from 'modules/common/hooks/use-color-with-opacity'
import useMonitorPointStatusStore from 'modules/map/components/points/monitor-point/store/use-monitor-point-status-store'
import {
	MonitorPointStatusCommentResponse,
	MonitorPointStatusResponse,
	QualitativePointStatus
} from 'modules/map/components/points/monitor-point/types/monitor-point'

import { monitorPointChannel } from '../../../socket/monitor-point-channel'

interface UseStatusCardLogicReturn {
	state: {
		isCommentSectionOpen: boolean
		isLoading: { [key: string]: boolean }
	}
	data: {
		qualitativeStatus: string | undefined
	}
	utils: {
		transparentBorderColor: string
		useString: string
		qualitativeBadgeBgStatusColor: string
		hasLiked: boolean
	}
	actions: {
		handleDelete: (comment?: MonitorPointStatusCommentResponse) => Promise<void>
		handleLike: (
			mode: 'add' | 'delete',
			monitorPointId?: string | undefined,
			statusId?: string | undefined,
			commentId?: string | undefined
		) => any
		setIsCommentSectionOpen: Dispatch<SetStateAction<boolean>>
	}
}

export const useStatusCardLogic = (
	status: MonitorPointStatusResponse,
	userId: string
): UseStatusCardLogicReturn => {
	const isAuthor = status.author.id === userId
	const hasNoReplies = status.replies.length < 1

	const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(
		hasNoReplies && !isAuthor
	)
	const toast = useToast()
	const { t } = useTranslation()
	const {
		deleteStatus,
		deleteStatusState,
		deleteComment,
		deleteCommentState,
		isLoading,
		manageLike
	} = useMonitorPointStatusStore()
	const { id: journeyId = '', pointId: monitorPointId = '' } = useParams()
	const { emit } = useSocket(monitorPointChannel({ projectId: journeyId }))

	const hasLiked = useMemo(() => {
		return status.agreements?.some((agreement) => agreement.user_id === userId)
	}, [status.agreements, userId])

	const handleLike = async (
		mode: 'add' | 'delete',
		monitorPointId?: string | undefined,
		statusId?: string | undefined,
		commentId?: string | undefined
	) => {
		const data = await manageLike(mode, monitorPointId, statusId, commentId)

		if (data) {
			const socketPayload = {
				id: data.id,
				monitor_point_id: monitorPointId,
				monitor_point_status_id: ''
			}

			if ('monitor_point_status_id' in data) {
				socketPayload.monitor_point_status_id = data.monitor_point_status_id
			}

			hasLiked
				? emit('DELETE_AGREEMENT', socketPayload)
				: emit('ADD_AGREEMENT', socketPayload)
		}
	}

	const handleDelete = async (comment?: MonitorPointStatusCommentResponse) => {
		const socketPayload = {
			monitor_point_id: monitorPointId
		}

		if (comment) {
			await deleteComment(comment.id)
			deleteCommentState(status.id, comment.id)

			emit('DELETE', {
				...socketPayload,
				id: comment.id,
				monitor_point_status_id: comment.monitor_point_status_id
			})
		} else {
			await deleteStatus(monitorPointId, status.id)
			deleteStatusState(status)

			emit('DELETE', { ...socketPayload, id: status.id })
		}

		queryClient.refetchQueries(['monitorPoint', monitorPointId])

		toast.closeAll()
		const title = comment
			? t('map:points:monitor:status:toasts:delete-comment')
			: t('map:points:monitor:status:toasts:delete')
		toast({
			title,
			status: 'success',
			duration: 3000,
			isClosable: true
		})
	}

	const useString = useStringToColor({ text: status.author.name }, [500])[1]
	const transparentBorderColor = useColorWithOpacity(useString, 0.2)

	const qualitativeBadgeBgStatusColor = useMemo(() => {
		const qualitativeStatusColors = {
			IN_PROGRESS: 'blue.500',
			SUSPENDED: 'red.500',
			COMPLETED: 'green.500'
		}
		return (
			qualitativeStatusColors[
				status.value as keyof typeof qualitativeStatusColors
			] || ''
		)
	}, [status.value])

	const qualitativeStatusMapper = {
		[QualitativePointStatus.STARTED]: t(
			'map:points:monitor:status:name:options:started'
		),
		[QualitativePointStatus.IN_PROGRESS]: t(
			'map:points:monitor:status:name:options:progress'
		),
		[QualitativePointStatus.COMPLETED]: t(
			'map:points:monitor:status:name:options:completed'
		),
		[QualitativePointStatus.SUSPENDED]: t(
			'map:points:monitor:status:name:options:suspended'
		)
	}

	const qualitativeStatus =
		qualitativeStatusMapper[status.value as keyof typeof QualitativePointStatus]

	return {
		state: { isCommentSectionOpen, isLoading },
		data: {
			qualitativeStatus
		},
		utils: {
			transparentBorderColor,
			useString,
			qualitativeBadgeBgStatusColor,
			hasLiked
		},
		actions: {
			handleDelete,
			handleLike,
			setIsCommentSectionOpen
		}
	}
}
