import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#1D4080',
		light: '#81A2C7'
	},

	secondary: {
		dark: '#E20A39',
		light: '#F39DB0'
	}
}

export default themeColors
