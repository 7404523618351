import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	UseDisclosureReturn
} from '@chakra-ui/react'

type Props = UseDisclosureReturn & {
	onDelete: () => void
	deleteText: string
	title: string
	children: React.ReactNode
}

const DeleteModal: React.FC<Props> = (props) => {
	const { isOpen, onClose, onDelete, children, deleteText, title } = props
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, ld: false })

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton mt={3.5} mr={[0, 4]} />
				<ModalHeader px={[4, 6]} pt={[6, 6]}>
					<Text fontSize='lg' fontWeight='bold'>
						{title}
					</Text>
				</ModalHeader>
				<ModalBody px={[4, 6]}>
					<Text fontSize='md'>{children}</Text>
				</ModalBody>

				<ModalFooter mt={3} px={[4, 6]} pb={4}>
					<HStack>
						<Button
							fontWeight='semibold'
							color='gray.400'
							bgColor='gray.180'
							onClick={onClose}
						>
							{t('buttons:cancel')}
						</Button>
						<Button
							colorScheme='custom_scheme_color_button'
							onClick={() => {
								onClose()
								onDelete()
							}}
							leftIcon={isMobile ? undefined : <BiTrashAlt />}
						>
							{deleteText}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default DeleteModal
