import React from 'react'

import { Button, ButtonProps, useTheme } from '@chakra-ui/react'

import { useTenant } from 'modules/common/contexts/global-tenant-context'

// Defina as props específicas do seu botão personalizado, se houver
interface CustomButtonProps {
	isDisabled?: boolean
	to?: string
	colorSchemeDefault?: string
	target?: string
}

// Combine as props do botão Chakra UI com as props personalizadas
type CombinedProps = CustomButtonProps & ButtonProps

// Crie o componente CustomButton
export const CustomSchemeButton: React.FC<CombinedProps> = ({
	isDisabled = false,
	children,
	colorScheme = 'primary',
	colorSchemeDefault = 'pink',
	to,
	target,
	...rest
}) => {
	const theme = useTheme()
	const { tenant, isTenantDefault } = useTenant()

	const isHex = colorScheme.includes('#')

	const hover = isDisabled ? {} : { bg: colorScheme + '_button_hover' }
	const active = isDisabled ? {} : { bg: colorScheme + '_button_hover' }
	const focus = isDisabled
		? {}
		: { boxShadow: `0 0 0 1px ${theme.colors[colorScheme + '_button_focus']}` }

	const tenantProps = {
		backgroundColor: isHex ? colorScheme : colorScheme + '_button_bg',
		_hover: hover,
		_focus: focus,
		_active: active,
		_disabled: {
			bg: colorScheme + '_button_disabled_bg',
			color: 'white',
			cursor: 'auto',
			opacity: 0.5
		}
	}

	return (
		<Button
			color='white'
			{...(!isTenantDefault(tenant) ? tenantProps : {})}
			isDisabled={isDisabled}
			to={to}
			target={target}
			{...rest}
			colorScheme={colorSchemeDefault}
		>
			{children}
		</Button>
	)
}
