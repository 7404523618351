import { fetch } from 'config'

import { PrivateProvider, Provider } from '../types'

export const getPrivateProvider = async ({ domain }: PrivateProvider) => {
	return await fetch<Provider>({
		url: `/users/v1/auth/provider/by-domain?domain=${domain}`,
		method: 'GET'
	}).then((res) => res.data)
}
