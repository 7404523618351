import enAnonym from './en/anonym.json'
import enApiKeys from './en/apiKeys.json'
import enAssistants from './en/assistants.json'
import enButtons from './en/buttons.json'
import enCommon from './en/common.json'
import enConnectivityDiagnostics from './en/connectivityDiagnostics.json'
import enCredits from './en/credits.json'
import enDivergencePoint from './en/divergencePoint.json'
import enEmpty from './en/empty.json'
import enErrors from './en/errors.json'
import enEvaluationPoint from './en/evaluationPoint.json'
import enFeedback from './en/feedback.json'
import enFlow from './en/flow.json'
import enForgotPassword from './en/forgotPassword.json'
import enHome from './en/home.json'
import enInviteUsers from './en/inviteUsers.json'
import enJourneys from './en/journeys.json'
import enLab from './en/lab.json'
import enLabPlans from './en/labPlans.json'
import enLogin from './en/login.json'
import enMap from './en/map.json'
import enMaturity from './en/maturity.json'
import enNDA from './en/nda.json'
import enProfile from './en/profile.json'
import enRoles from './en/roles.json'
import enSettings from './en/settings.json'
import enSignup from './en/signup.json'
import enStartingPoint from './en/startingPoint.json'
import enSummary from './en/summary.json'
import enTemplates from './en/templates.json'
import enTools from './en/tools.json'
import enUserTerms from './en/userTerms.json'
import esAnonym from './es/anonym.json'
import esApiKeys from './es/apiKeys.json'
import esAssistants from './es/assistants.json'
import esButtons from './es/buttons.json'
import esCommon from './es/common.json'
import esConnectivityDiagnostics from './es/connectivityDiagnostics.json'
import esCredits from './es/credits.json'
import esDivergencePoint from './es/divergencePoint.json'
import esEmpty from './es/empty.json'
import esErrors from './es/errors.json'
import esEvaluationPoint from './es/evaluationPoint.json'
import esFeedback from './es/feedback.json'
import esFlow from './es/flow.json'
import esForgotPassword from './es/forgotPassword.json'
import esHome from './es/home.json'
import esInviteUsers from './es/inviteUsers.json'
import esJourneys from './es/journeys.json'
import esLab from './es/lab.json'
import esLabPlans from './es/labPlans.json'
import esLogin from './es/login.json'
import esMap from './es/map.json'
import esMaturity from './es/maturity.json'
import esNDA from './es/nda.json'
import esProfile from './es/profile.json'
import esRoles from './es/roles.json'
import esSettings from './es/settings.json'
import esSignup from './es/signup.json'
import esStartingPoint from './es/startingPoint.json'
import esSummary from './es/summary.json'
import esTemplates from './es/templates.json'
import esTools from './es/tools.json'
import esUserTerms from './es/userTerms.json'
import ptAnonym from './pt/anonym.json'
import ptApiKeys from './pt/apiKeys.json'
import ptAssistants from './pt/assistants.json'
import ptButtons from './pt/buttons.json'
import ptCommon from './pt/common.json'
import ptConnectivityDiagnostics from './pt/connectivityDiagnostics.json'
import ptCredits from './pt/credits.json'
import ptDivergencePoint from './pt/divergencePoint.json'
import ptEmpty from './pt/empty.json'
import ptErrors from './pt/errors.json'
import ptEvaluationPoint from './pt/evaluationPoint.json'
import ptFeedback from './pt/feedback.json'
import ptFlow from './pt/flow.json'
import ptForgotPassword from './pt/forgotPassword.json'
import ptHome from './pt/home.json'
import ptInviteUsers from './pt/inviteUsers.json'
import ptJourneys from './pt/journeys.json'
import ptLab from './pt/lab.json'
import ptLabPlans from './pt/labPlans.json'
import ptLogin from './pt/login.json'
import ptMap from './pt/map.json'
import ptMaturity from './pt/maturity.json'
import ptNDA from './pt/nda.json'
import ptProfile from './pt/profile.json'
import ptRoles from './pt/roles.json'
import ptSettings from './pt/settings.json'
import ptSignup from './pt/signup.json'
import ptStartingPoint from './pt/startingPoint.json'
import ptSummary from './pt/summary.json'
import ptTemplates from './pt/templates.json'
import ptTools from './pt/tools.json'
import ptUserTerms from './pt/userTerms.json'

export const resources = {
	pt: {
		common: ptCommon,
		login: ptLogin,
		signup: ptSignup,
		errors: ptErrors,
		profile: ptProfile,
		empty: ptEmpty,
		buttons: ptButtons,
		assistants: ptAssistants,
		flow: ptFlow,
		home: ptHome,
		tools: ptTools,
		journeys: ptJourneys,
		userTerms: ptUserTerms,
		settings: ptSettings,
		forgotPassword: ptForgotPassword,
		map: ptMap,
		inviteUsers: ptInviteUsers,
		divergencePoint: ptDivergencePoint,
		lab: ptLab,
		nda: ptNDA,
		roles: ptRoles,
		templates: ptTemplates,
		connectivityDiagnostics: ptConnectivityDiagnostics,
		labPlans: ptLabPlans,
		feedback: ptFeedback,
		anonym: ptAnonym,
		apiKeys: ptApiKeys,
		summary: ptSummary,
		startingPoint: ptStartingPoint,
		credits: ptCredits,
		evaluationPoint: ptEvaluationPoint,
		maturity: ptMaturity
	},
	en: {
		common: enCommon,
		login: enLogin,
		signup: enSignup,
		errors: enErrors,
		profile: enProfile,
		empty: enEmpty,
		buttons: enButtons,
		assistants: enAssistants,
		flow: enFlow,
		home: enHome,
		tools: enTools,
		journeys: enJourneys,
		userTerms: enUserTerms,
		settings: enSettings,
		forgotPassword: enForgotPassword,
		map: enMap,
		inviteUsers: enInviteUsers,
		divergencePoint: enDivergencePoint,
		lab: enLab,
		nda: enNDA,
		roles: enRoles,
		templates: enTemplates,
		connectivityDiagnostics: enConnectivityDiagnostics,
		labPlans: enLabPlans,
		feedback: enFeedback,
		anonym: enAnonym,
		apiKeys: enApiKeys,
		summary: enSummary,
		startingPoint: enStartingPoint,
		credits: enCredits,
		evaluationPoint: enEvaluationPoint,
		maturity: enMaturity
	},
	es: {
		common: esCommon,
		login: esLogin,
		signup: esSignup,
		errors: esErrors,
		profile: esProfile,
		empty: esEmpty,
		buttons: esButtons,
		assistants: esAssistants,
		flow: esFlow,
		home: esHome,
		tools: esTools,
		journeys: esJourneys,
		userTerms: esUserTerms,
		settings: esSettings,
		forgotPassword: esForgotPassword,
		map: esMap,
		inviteUsers: esInviteUsers,
		divergencePoint: esDivergencePoint,
		lab: esLab,
		nda: esNDA,
		roles: esRoles,
		templates: esTemplates,
		connectivityDiagnostics: esConnectivityDiagnostics,
		labPlans: esLabPlans,
		feedback: esFeedback,
		anonym: esAnonym,
		apiKeys: esApiKeys,
		summary: esSummary,
		startingPoint: esStartingPoint,
		credits: esCredits,
		evaluationPoint: esEvaluationPoint,
		maturity: esMaturity
	}
} as const

export const ns = Object.keys(resources.pt)
