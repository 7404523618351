import defaultCustomColors from '../default/colors'
const fdcCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#152544'
	},

	secondary_color_scheme_button: {
		500: '#152544'
	},

	radio_color: {
		500: '#152544'
	},

	switch_scheme_color_checked: {
		500: '#152544'
	}
}

export default fdcCustomColors
