import { KeyValuePermissions } from 'config/permissions/type'

const defaultPermissions = {
	microsoft_provider: false,
	subscription_button: false,
	configuration_api_keys: false,
	invite_people_button: true,
	go_to_blog: false
}

const myLabPermissions = {
	plan_tab: false
}

const profilePermissions = {
	journey_participation: false,
	tabs: false,
	social_network: false
}

const componentPermissions = {
	coin_enable_routing_to_credits: false
}

const defaultKeyValuePermissions: KeyValuePermissions = {
	...defaultPermissions,
	...myLabPermissions,
	...profilePermissions,
	...componentPermissions
}

export default defaultKeyValuePermissions
