import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Text,
	HStack,
	useToast,
	Image,
	useBreakpointValue
} from '@chakra-ui/react'

import chrome from 'modules/common/assets/img/google-chrome.svg'
import { useBrowser } from 'modules/common/hooks'

export const BrowserRecommendation: React.FC = () => {
	const { t } = useTranslation()
	const toast = useToast()
	const userScreen = useBreakpointValue({ base: true, lg: false })
	const paramsRef = useRef({ toast, userScreen, t })
	const currentBrowser = useBrowser()

	const isChromeBase = ['Edge (Chromium)', 'Chrome'].some((browser) =>
		currentBrowser.includes(browser)
	)

	useEffect(() => {
		if (isChromeBase) return

		const currentParams = paramsRef.current

		setTimeout(() => {
			const toastId = 'chrome-toast'
			if (!currentParams.toast.isActive(toastId)) {
				currentParams.toast({
					position: currentParams.userScreen ? 'top' : 'top-right',
					isClosable: true,
					duration: null,
					id: toastId,
					render: ({ onClose }) => (
						<HStack
							boxShadow='base'
							borderRadius='lg'
							color='gray.500'
							pb={3}
							pt={3}
							pl={3}
							bg='gray.100'
							onClick={onClose}
						>
							<Image boxSize='8' src={chrome} />
							<Text fontSize={{ base: 'xs', lg: 'sm' }}>
								{currentParams.t('common:chromeRecomendation')}
							</Text>
						</HStack>
					)
				})
			}
		}, 1000)
		return () => {
			currentParams.toast.closeAll()
		}
	}, [isChromeBase])

	return null
}
