import { fetch } from 'config'

import {
	MonitorPointStatus,
	MonitorPointStatusResponse
} from '../types/monitor-point'

export const addMonitorPointStatus = async (
	monitorPointId: string,
	data: MonitorPointStatus
) => {
	return await fetch<MonitorPointStatusResponse>({
		url: `projects/v1/monitor-point/${monitorPointId}/status`,
		method: 'PATCH',
		data
	})
}
