import { ReactNode } from 'react'

import { fetch } from 'config'
import create from 'zustand'
import createContext from 'zustand/context'

import { Tool } from 'modules/tools/types/tool-model'

export type QuestionWithComments = {
	id: string
	comments: Comments[]
}

export type Reply = {
	author: Author
	id: string
	parent_id: string
	text: string
	created_at: Date
	agreements: Agreement[]
	question_id: string
	created_by: string
}

export interface DislikeComment {
	comment_id: string
	question_id: string
	user_id: string
	parent_id?: string
}

export interface LikeComment extends DislikeComment {
	user_name?: string
}

interface QuestionFull extends QuestionWithComments {
	count: number
}

interface QuestionCount {
	id: string
	question: string
	total_comments_count: number
	parent_comments_count: number
	reply_comments_count: number
}

export type Comments = {
	author: Author
	divergence_point_id: string
	id: string
	parent_id: string
	question_id: string
	reply_count: number
	text: string
	created_at: Date
	agreed: boolean
	agreements: Agreement[]
	replies: Reply[]
	created_by: string
}

export type Author = {
	avatar_url: string
	avatarUrl: string
	id: string
	name: string
	username: string
	is_assistant: boolean
}

export type Agreement = {
	created_at: Date
	name: string
	user_id: string
}

type Links = {
	author: Author
	id: string
	title: string
	url: string
}

export type PointInfos = {
	attached_links: Links[]
	created_at: string
	id: string
	introduction: string
	tool: Tool
}

interface CommentsStoreState {
	isLoadingComments: boolean
	isLoadingSendReply: boolean
	questions: QuestionFull[]
	questionOpenedIndex: number
	summaryQuestionIndex: number
	point: PointInfos | null
	hasOpenedQuestion: () => boolean
	clearQuestions: () => void
	addComment: (
		divergencePointId: string,
		questionId: string,
		text: string
	) => Promise<Comments>
	addCommentState: (data: Comments) => void
	addReplyState: (data: Comments) => void
	addReply: (
		questionCommentId: string,
		text: string,
		commentId: string
	) => Promise<Comments>
	deleteComment: (commentId: string) => Promise<void>
	deleteCommentState: (data: Comments) => void
	fetchQuestionsInfo: (divergencePointId: string) => void
	fetchCommentsAndRepliesAndSetQuestionById: (
		divergencePointId: string,
		questionId: string
	) => void
	setQuestionOpenedIndex: (index: number) => void
	setQuestionSummaryIndex: (index: number) => void
	setQuestionOpenedIndexById: (questionId: string) => void
	likeComment: (like: LikeComment) => Promise<Comments>
	dislikeComment: (dislike: DislikeComment) => Promise<Comments>
	updateCommentState: (data: Comments) => void
	fetchedQuestionsIds: string[]
	markQuestionAsFetched: (questionId: string) => void
}

const { Provider, useStore } = createContext<CommentsStoreState>()

const CommentsStore = () =>
	create<CommentsStoreState>((set, get) => ({
		isLoadingComments: false,
		isLoadingSendReply: false,
		questions: [],
		questionOpenedIndex: 0,
		summaryQuestionIndex: 0,
		point: null,
		fetchedQuestionsIds: [],
		hasOpenedQuestion: () => {
			const { questionOpenedIndex } = get()
			return questionOpenedIndex > 0
		},
		clearQuestions: () => {
			set(() => ({
				questions: []
			}))
		},
		setQuestionOpenedIndexById: (questionId: string) => {
			const { questions } = get()
			const index = questions.findIndex(
				(question) => question.id === questionId
			)
			set(() => ({
				questionOpenedIndex: index
			}))
		},
		addCommentState: async (data: Comments) => {
			if (data.parent_id) {
				get().addReplyState(data)
				return
			}
			data.replies = []
			const { questions } = get()
			const comments = questions.find(
				(question) => question.id === data.question_id
			)?.comments
			const hasComment = comments?.find(({ id }) => id === data.id)
			if (hasComment) {
				return
			}
			comments?.push(data)
			set(() => ({
				questions
			}))
		},
		addReplyState: async (data: Comments) => {
			data.replies = []
			const { questions } = get()
			const replies = questions
				.find((question) => question.id === data.question_id)
				?.comments.find((comment) => comment.id === data.parent_id)?.replies

			const hasReply = replies?.find(({ id }) => id === data.id)

			if (hasReply) {
				return
			}

			replies?.push(data)

			set(() => ({
				questions
			}))
		},
		addComment: async (
			divergencePointId: string,
			questionId: string,
			text: string
		) => {
			const { data } = await fetch<Comments>({
				url: `/projects/v1/divergence-point/${divergencePointId}/question/${questionId}/comment`,
				method: 'POST',
				data: { text }
			})
			data.replies = []
			return data
		},
		addReply: async (_questionId: string, text: string, commentId: string) => {
			set(() => ({ isLoadingSendReply: true }))
			try {
				const { data } = await fetch<Comments>({
					url: `/projects/v1/question/comment/${commentId}/reply`,
					method: 'POST',
					data: { text }
				})
				return data
			} finally {
				set(() => ({ isLoadingSendReply: false }))
			}
		},
		deleteCommentState: ({
			question_id,
			parent_id,
			id: commentId
		}: Comments) => {
			const { questions } = get()
			const isReply = !!parent_id
			if (isReply) {
				const replyIndex = questions
					.find((question) => question.id === question_id)
					?.comments.find((comment) => comment.id === parent_id)
					?.replies.findIndex((reply) => reply.id === commentId)
				if (typeof replyIndex === 'number' && replyIndex > -1) {
					questions
						.find((question) => question.id === question_id)
						?.comments.find((comment) => comment.id === parent_id)
						?.replies.splice(replyIndex, 1)
				}
			} else {
				const commentIndex = questions
					.find((question) => question.id === question_id)
					?.comments.findIndex((comment) => comment.id === commentId)
				if (typeof commentIndex === 'number' && commentIndex > -1) {
					questions
						.find((question) => question.id === question_id)
						?.comments.splice(commentIndex, 1)
				}
			}

			set(() => ({
				questions
			}))
		},
		deleteComment: async (commentId: string) => {
			await fetch({
				url: `/projects/v1/question/comment/${commentId}`,
				method: 'DELETE'
			})
		},
		fetchQuestionsInfo: async (divergencePointId: string) => {
			const { data: partialQuestions } = await fetch<PointInfos>({
				url: `/projects/v1/divergence-point/${divergencePointId}`,
				method: 'GET'
			})

			const { data: questionCount } = await fetch<QuestionCount[]>({
				url: `/projects/v1/divergence-point/${divergencePointId}/question/comment/count`,
				method: 'GET'
			})

			const fullQuestions = partialQuestions.tool.questions.map((question) => ({
				...question,
				comments: [],
				count:
					questionCount.find(({ id }) => id === question.id)
						?.parent_comments_count ?? 0
			}))

			set(() => ({
				questions: fullQuestions,
				point: partialQuestions
			}))
		},
		setQuestionOpenedIndex: (index: number) => {
			set(() => ({
				questionOpenedIndex: index
			}))
		},
		setQuestionSummaryIndex: (index: number) => {
			set(() => ({
				summaryQuestionIndex: index
			}))
		},
		fetchCommentsAndRepliesAndSetQuestionById: async (
			divergencePointId: string,
			questionId: string
		) => {
			set({ isLoadingComments: true })

			try {
				const { data } = await fetch<QuestionWithComments>({
					url: `/projects/v1/divergence-point/${divergencePointId}/question/${questionId}/comment/report`,
					method: 'GET'
				})

				set((state) => {
					const updatedQuestions = state.questions.map((question) => {
						if (question.id === questionId) {
							return {
								...question,
								comments: data.comments
							}
						}
						return question
					})

					return {
						...state,
						questions: updatedQuestions,
						isLoadingComments: false
					}
				})
			} catch (error) {
				set({ isLoadingComments: false })
				console.error(error)
			}
		},
		updateCommentState: (comment: Comments) => {
			const { question_id, id: comment_id, parent_id, agreements } = comment
			const { questions } = get()
			const isReply = !!parent_id
			const questionIndex = questions.findIndex(
				(question) => question.id === question_id
			)
			const commentIndex = questions[questionIndex].comments.findIndex(
				(comment) => [comment_id, parent_id].includes(comment.id)
			)
			if (!isReply) {
				questions[questionIndex].comments[commentIndex].agreements = [
					...agreements
				]
			} else {
				const replyIndex = questions[questionIndex].comments[
					commentIndex
				].replies.findIndex((reply) => reply.id === comment_id)
				questions[questionIndex].comments[commentIndex].replies[
					replyIndex
				].agreements = [...agreements]
			}
			set(() => ({
				questions
			}))
		},
		likeComment: async (likeComment: LikeComment) => {
			const { data: agreementSaved } = await fetch<Comments>({
				url: `/projects/v1/question/comment/${likeComment.comment_id}/agreement`,
				method: 'POST'
			})
			return agreementSaved
		},
		dislikeComment: async (dislikeComment: DislikeComment) => {
			const { data: agreementSaved } = await fetch<Comments>({
				url: `/projects/v1/question/comment/${dislikeComment.comment_id}/agreement`,
				method: 'DELETE'
			})
			return agreementSaved
		},
		markQuestionAsFetched: (questionId: string) => {
			const { fetchedQuestionsIds } = get()

			if (!fetchedQuestionsIds.includes(questionId)) {
				set((state) => ({
					fetchedQuestionsIds: [...state.fetchedQuestionsIds, questionId]
				}))
			}
		}
	}))

export function CommentsStoreProvider({ children }: { children: ReactNode }) {
	return <Provider createStore={CommentsStore}>{children}</Provider>
}

export default useStore
