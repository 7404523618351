import { useMutation } from 'react-query'

import { updatePointVisibility } from '../usecases/update-point-visibility'

type Params = {
	pointType:
		| 'notice-point'
		| 'divergence-point'
		| 'convergence-point'
		| 'check-point'
		| 'essay-point'
		| 'monitor-point'
	pointId: string
}

export const useUpdatePointVisibility = ({ pointType, pointId }: Params) => {
	return useMutation((visibility: boolean) =>
		updatePointVisibility({ pointType, pointId, visibility })
	)
}
