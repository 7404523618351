import { fetch } from 'config'

import { Point } from '../../map-canvas/types'
import { ConvergencePointAnswer } from '../components/belief-question'

export const editAnswer = (pointId: string, data: ConvergencePointAnswer) => {
	return fetch<Point>({
		url: `/projects/v1/convergence-point/${pointId}/answer`,
		method: 'PUT',
		data
	}).then(({ data }) => data)
}
