import { fetch } from 'config'

import { WritingStyle } from '../types/index'

export const getWritingStyles = async () => {
	const response = await fetch<WritingStyle[]>({
		url: 'projects/v1/assistants/writing-styles',
		method: 'GET'
	})

	return response.data
}
