import { fetch } from 'config'

import { MonitorPointStatusResponse } from '../types/monitor-point'

export const statusAgreement = async (
	monitorPointId: string,
	statusId: string,
	mode: 'add' | 'delete'
) => {
	const response = await fetch<MonitorPointStatusResponse>({
		url: `projects/v1/monitor-point/${monitorPointId}/status/${statusId}/agreement`,
		method: mode === 'add' ? 'POST' : 'DELETE'
	})

	return response.data
}
