import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { manageUserEvaluationMode } from '../usecases/manage-user-evaluation-mode'

export function useUserEvaluationMutation(pointId: string) {
	const channel = '/point/essay-point/user-evaluation-mode'

	const { emit } = useSocket(channel)
	const setIsUserEvaluation = useEvaluationStore(
		(state) => state.setIsUserEvaluation
	)

	const mutation = useMutation(manageUserEvaluationMode, {
		onSuccess: (_, { isUserEvaluation }) => {
			setIsUserEvaluation(isUserEvaluation)
			emit('EDIT', pointId)
		}
	})

	return mutation
}
