import { useMutation } from 'react-query'

import { MonitorPointStatus } from '../types/monitor-point'
import { addMonitorPointStatus } from '../usecases/add-monitor-point-status'

export const useAddMonitorPointStatus = () => {
	return useMutation(
		async ({
			monitorPointId,
			data
		}: {
			monitorPointId: string
			data: MonitorPointStatus
		}) => {
			return await addMonitorPointStatus(monitorPointId, data)
		}
	)
}
