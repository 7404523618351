import i18n from 'i18n/i18n'
import { number, object, string, InferType, boolean, array } from 'yup'

import { validateCNPJ } from '../utils/validate-cnpj'
import { validateCPF } from '../utils/validate-cpf'
import { hasNoUrl } from './global'

export const creditsValidator = object({
	creditsAmount: number()
		.required(i18n.t('errors:requiredField'))
		.integer(i18n.t('errors:buyCredits.integerNumber'))
		.min(50, i18n.t('errors:buyCredits.min'))
		.typeError(i18n.t('errors:buyCredits.insertValue')),
	creditsCost: number().required(i18n.t('errors:requiredField')),
	name: string()
		.test({
			test: hasNoUrl,
			message: i18n.t('profile:toastUrlErrorMessage')
		})
		.required(i18n.t('errors:requiredField')),
	fiscal_id: string().when('account_type', {
		is: 'PERSONAL',
		then: string()
			.test({
				test: (value = '') => validateCPF(value),
				message: i18n.t('errors:buyCredits.invalidCPF')
			})
			.required(i18n.t('errors:requiredField')),
		otherwise: string()
			.test({
				test: (value = '') => validateCNPJ(value),
				message: i18n.t('errors:buyCredits.invalidCNPJ')
			})
			.required(i18n.t('errors:requiredField'))
	}),
	email: string()
		.email(i18n.t('errors:validEmail'))
		.required(i18n.t('errors:requiredField')),
	address: object({
		country: string().required(i18n.t('errors:requiredField')),
		postal_code: string().required(i18n.t('errors:requiredField'))
	})
})

export type CreditsType = InferType<typeof creditsValidator>

export const creditsDistributionLabSchema = object({
	creditsAmount: number()
		.required(i18n.t('errors:requiredField'))
		.integer(i18n.t('errors:buyCredits.integerNumber'))
		.min(5, i18n.t('credits:distribution.min'))
		.typeError(i18n.t('errors:buyCredits.insertValue')),
	journeyIds: array(string().required(i18n.t('errors:requiredField'))).required(
		i18n.t('errors:requiredField')
	),
	membersAmount: number().required(i18n.t('errors:requiredField')),
	memberIds: array(string().required(i18n.t('errors:requiredField')))
})

export type CreditsDistributionLabType = InferType<
	typeof creditsDistributionLabSchema
>

const projectRoleSchema = string()
	.oneOf(['ADMIN', 'MENTOR', 'INVESTOR', 'INNOVATOR'])
	.required(i18n.t('errors:requiredField'))

export const userSchema = object({
	id: string().required(i18n.t('errors:requiredField')),
	name: string().required(i18n.t('errors:requiredField')),
	email: string().email(i18n.t('errors:validEmail')).notRequired(),
	username: string().required(i18n.t('errors:requiredField')),
	roles: array(string().required(i18n.t('errors:requiredField'))),
	project_roles: array(projectRoleSchema).required(
		i18n.t('errors:requiredField')
	),
	bio: string().notRequired(),
	avatar_url: string()
		.url(i18n.t('errors:invalidUrl'))
		.nullable()
		.notRequired(),
	status: string().required(i18n.t('errors:requiredField')),
	language: string().required(i18n.t('errors:requiredField')),
	active: boolean().notRequired(),
	lab_name: string().notRequired()
})

export const creditsDistributionJourneySchema = object({
	step: string(),
	creditsAmount: number()
		.required(i18n.t('errors:requiredField'))
		.integer(i18n.t('errors:buyCredits.integerNumber'))
		.min(5, i18n.t('credits:distribution.min'))
		.typeError(i18n.t('errors:buyCredits.insertValue')),
	members: array().of(userSchema).required(i18n.t('errors:requiredField')),
	selectedMemberIds: array()
		.of(string().required(i18n.t('errors:requiredField')))
		.when('step', {
			is: (val: string) => val !== 'distribute', // If step is not 'distribute'
			then: array()
				.required(i18n.t('errors:requiredField'))
				.min(1, 'ao menos um membro deve ser selecionado'),
			otherwise: array().notRequired() // Otherwise, no specific requirements
		})
})

type InferredUser = InferType<typeof userSchema>
type RefinedUser = Omit<
	InferredUser,
	'bio' | 'avatar_url' | 'lab_name' | 'email' | 'active'
> & {
	bio?: string
	avatar_url?: string
	lab_name?: string
	email?: string
	active?: boolean
}

type InferredCreditsDistribution = InferType<
	typeof creditsDistributionJourneySchema
>
export type CreditsDistributionJourneyType = Omit<
	InferredCreditsDistribution,
	'members'
> & {
	members?: RefinedUser[] // <-- Note the "?", which makes the members array optional.
}
