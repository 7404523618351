import {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useRef,
	useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { BiPlusCircle } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import {
	UseDisclosureReturn,
	Stack,
	Text,
	VStack,
	HStack,
	Checkbox,
	useToast,
	Textarea,
	useTheme
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'
import { sendGAEvent } from 'config/trackers/google-analytics'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { pointChannel } from 'modules/map/modules/point/point_channel'

import { useMapStore } from '../../../map-canvas/store'
import { ErrorResponse, Point, Position } from '../../../map-canvas/types'
import { makeHexagon } from '../../../map-canvas/utils/hexagon-utils'
import { usePositionHandler } from '../../../map-canvas/utils/usePositionHandler'
import { NoticePointModal } from '../../notice-point-modal'
import { createNoticePoint } from '../../usecases'

type Props = {
	disclosure: UseDisclosureReturn
}

export const CreateNoticePoint: FC<Props> = ({ disclosure }) => {
	const { t } = useTranslation()
	const { mapId = '', id: projectId = '' } = useParams<{
		mapId: string
		id: string
	}>()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const toast = useToast()
	const [value, setValue] = useState('')
	const [sendEmail, setSendEmail] = useState(true)
	const positionErrorHandler = usePositionHandler()
	const toastMessage = sendEmail
		? t('map:points:notice:create:sendEmailSucess')
		: t('map:points.conversation.create.success')

	const position = useMapStore(
		({ state }) => state.selectedPoint?.position
	) as Position
	const { addPoint, clearSelected } = useMapStore(({ actions }) => actions)

	const insertPointInTheMap = (data: Point) => {
		const hexagon = makeHexagon(data.position.row, data.position.col, {
			...data,
			point_type: 'NOTICE'
		})
		addPoint(hexagon)
		clearSelected()
	}

	const handleCreatePoint = async () => {
		const payload = {
			description: value,
			position,
			send_email: sendEmail,
			map_id: mapId
		}
		try {
			const noticePoint = await createNoticePoint(payload)
			insertPointInTheMap(noticePoint)
			const pointType = 'NOTICE'
			toast({
				isClosable: true,
				title: toastMessage,
				status: 'success'
			})
			sendGAEvent('create_notice_point')

			const pointInfo = {
				id: noticePoint.id,
				position: noticePoint.position,
				point_type: pointType
			}
			emit('ADD', pointInfo)
		} catch (error) {
			const { response } = error as any
			positionErrorHandler(mapId, response as ErrorResponse)
			clearSelected()
		}
	}

	return (
		<NoticePointModal
			disclosure={disclosure}
			body={<CreateNoticePointBody message={value} setValue={setValue} />}
			footer={
				<CreateNoticePointFooter
					setSendEmail={setSendEmail}
					sendEmail={sendEmail}
					handleCreatePoint={handleCreatePoint}
					message={value}
				/>
			}
		/>
	)
}

type BodyProps = {
	setValue: Dispatch<SetStateAction<string>>
	message?: string
}

export const CreateNoticePointBody: FC<BodyProps> = (props) => {
	const { t } = useTranslation()
	const { message, setValue } = props

	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
		}
	}, [])

	function handleKeyDown(e: any) {
		e.target.style.height = 'inherit'
		e.target.style.height = `${e.target.scrollHeight}px`
		setValue(e.target.value)
	}

	const theme = useTheme() as { colors: any }
	return (
		<>
			<VStack h='100%' align='inherit'>
				<Text color='gray.500' fontWeight='semibold'>
					{t('map:points:notice:create:bodyTitle')}
				</Text>
				<Textarea
					p={8}
					ref={textareaRef}
					minH={240}
					_focus={{
						borderColor: theme.colors.input_border
					}}
					onChange={handleKeyDown}
					defaultValue={message}
				/>
			</VStack>
		</>
	)
}

type FooterProps = {
	setSendEmail: Dispatch<SetStateAction<boolean>>
	sendEmail: boolean
	message: string
	handleCreatePoint: () => Promise<void>
}

const CreateNoticePointFooter: FC<FooterProps> = (props) => {
	const { t } = useTranslation()
	const { handleCreatePoint, sendEmail, setSendEmail, message } = props
	const isEmpty = message.length === 0
	const theme = useTheme() as { colors: any }

	return (
		<>
			<Stack direction={['column', 'row']} w='full' justify='space-between'>
				<HStack>
					<Checkbox defaultChecked onChange={() => setSendEmail(!sendEmail)} />
					<Text fontSize='sm' color='black'>
						{t('map:points:notice:create:sendEmail')}
					</Text>
				</HStack>
				<CustomSchemeButton
					isDisabled={isEmpty}
					onClick={handleCreatePoint}
					colorScheme={theme.colors.starting_point_button}
					leftIcon={<BiPlusCircle />}
				>
					{t('buttons:createPoint')}
				</CustomSchemeButton>
			</Stack>
		</>
	)
}
