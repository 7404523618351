import React, { useMemo } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiPlusCircle, BiTrashAlt } from 'react-icons/bi'

import {
	Input,
	Box,
	Button,
	Divider,
	Icon,
	IconButton,
	Stack,
	Text,
	useTheme
} from '@chakra-ui/react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { ConvergenceOption } from '../option'

type Props = {
	index: number
	onRemove: () => void
	canDelete: boolean
}

export const ConvergenceQuestion: React.FC<Props> = (props) => {
	const { t } = useTranslation()
	const { index, onRemove, canDelete } = props
	const { control, register } = useFormContext()
	const { fields, remove, append } = useFieldArray({
		control,
		name: `questions.${index}.options`
	})

	const [parent] = useAutoAnimate()

	const canRemoveOptions = useMemo(() => fields.length > 1, [fields])
	const blockAddOptions = useMemo(() => fields.length >= 30, [fields])
	const isLast = (index: number) => fields.length === index + 1

	const theme = useTheme() as { colors: any }
	return (
		<Box background='gray.100' p={6} mt={2} rounded='md'>
			<Input
				placeholder={t('map:points.convergence.create.makeAsking')}
				variant='flushed'
				_focus={{
					borderColor: theme.colors.input_border,
					boxShadow: `0px 1px 0px 0px ${theme.colors.input_border}`
				}}
				{...register(`questions.${index}.text`)}
			/>

			<Box pl={5} mt={5}>
				<>
					<ul ref={parent}>
						{fields.map((option, optionIndex) => (
							<ConvergenceOption
								name={`questions.${index}.options.${optionIndex}.text`}
								placeholder={t('map:points.convergence.create.option', {
									index: optionIndex + 1
								})}
								key={option.id}
								canDelete={canRemoveOptions}
								onRemove={() => remove(optionIndex)}
								onEnter={() =>
									isLast(optionIndex) &&
									!blockAddOptions &&
									append({ text: '' })
								}
							/>
						))}
					</ul>

					<Box mt={5}>
						<Button
							color={theme.colors.convergence_points_button_outlined_text_color}
							variant='ghost'
							colorScheme='blue'
							fontWeight='semibold'
							leftIcon={<Icon as={BiPlusCircle} />}
							onClick={() => append({ text: '' })}
							isDisabled={blockAddOptions}
						>
							{t('buttons:addOption')}
						</Button>
					</Box>
					<Divider mt={5} mb={1} />

					<Stack
						direction='row'
						justify='flex-end'
						w='full'
						alignItems='center'
						py={2}
					>
						<Stack direction='row' alignItems='center' w='full'>
							<Icon />
							<Text fontSize='xs' maxW='100%'>
								{t('map:points.convergence.create.beliefExplanation')}
							</Text>
						</Stack>
						{canDelete && (
							<IconButton
								aria-label={t('map:points.convergence.create.removeOption')}
								variant='ghost'
								_hover={{ bg: 'transparent' }}
								color='gray.300'
								onClick={onRemove}
								icon={<Icon as={BiTrashAlt} />}
							/>
						)}
					</Stack>
				</>
			</Box>
		</Box>
	)
}
