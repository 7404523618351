import { fetch } from 'config'

import { Template } from '../types'

export async function markAsFavorite(templateId: string) {
	const { data } = await fetch<Template>({
		url: `/templates/v1/template/like/${templateId}`,
		method: 'POST'
	})

	return data
}
