import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
	Button,
	Flex,
	Image,
	Text,
	Textarea,
	useBreakpointValue
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { useStringToColor } from 'modules/common/hooks'
import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { User } from 'modules/user/types'

import SendIcon from '../../../components/chat/send.svg'
import { AnonymAvatar } from '../../view-divergence-point/anonym-mode/anonym-avatar'
import { createEssayReply } from '../usecases/create-essay-reply'

type Props = {
	isAnonym: boolean
	author: User
	pointId: string
	parentId: string
}

export const InputComment = ({
	isAnonym,
	author,
	parentId,
	pointId
}: Props) => {
	const { t } = useTranslation()
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const isMobile = useBreakpointValue({ base: true, md: false })
	const tone = isAnonym ? 800 : 500
	const useString = useStringToColor({ text: author.name }, [tone])[1]
	const { addReplyState } = useEssaysStore()
	const textAreaRef = useRef<HTMLTextAreaElement>(null)
	const [inputComment, setInputComment] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const isValid = inputComment.length > 2
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))

	const sendComment = async () => {
		setIsLoading(true)
		const reply = await createEssayReply({
			parentId,
			pointId,
			text: inputComment.trim()
		})
		addReplyState(reply)
		const replyInfo = {
			essay_point_id: reply.essay_point_id,
			parent_id: reply.parent_id,
			id: reply.id
		}
		emit('ADD', replyInfo)
		setInputComment('')
		setIsLoading(false)
		resetTextAreaHeight()
	}

	const onChangeHandler = ({
		target
	}: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (target.value.length > 3000) {
			return
		}
		setInputComment(target.value)
	}

	const resetTextAreaHeight = () => {
		// Reset the textarea height.
		if (textAreaRef && textAreaRef.current) {
			textAreaRef.current.style.height = 'auto' // Adjust this as necessary for your original textarea size.
		}
	}

	const handleKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (
		event
	) => {
		const textarea = event.currentTarget
		const maxScrollHeight = 0.2 * window.innerHeight

		// Reset the height to auto to allow the textarea to shrink.
		textarea.style.height = 'auto'

		const currentScrollHeight = textarea.scrollHeight

		if (currentScrollHeight > maxScrollHeight) {
			textarea.style.overflowY = 'scroll'
			textarea.style.height = `${maxScrollHeight}px`
		} else {
			textarea.style.height = `${currentScrollHeight}px`
		}
	}

	return (
		<Flex w='full' mt='4' justifyContent='space-around'>
			<AnonymAvatar
				isAnonym={isAnonym}
				name={author.name}
				avatarUrl={author.avatar_url}
				backgroundColor={useString}
			/>
			<Textarea
				ref={textAreaRef}
				onChange={onChangeHandler}
				onKeyUp={handleKeyUp}
				flex={1}
				border='none'
				outline='none'
				sx={{ '&:empty::before': { content: 'attr(data-placeholder)' } }}
				placeholder={t('divergencePoint:write_comment')}
				mr={2}
				fontSize='md'
				ml={2}
				backgroundColor='gray.100'
				borderRadius='0.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				p='3'
				rows={1}
				value={inputComment}
			/>
			{!isMobile && (
				<Button
					isDisabled={!isValid}
					isLoading={isLoading}
					onClick={sendComment}
					borderRadius='18rem'
					backgroundColor='evaluation_answer_send_button'
					size='md'
					w='6rem'
					_hover={{ backgroundColor: 'none' }}
					alignItems='center'
					justifyContent='center'
				>
					<Text color='#fff' fontWeight='600' fontSize='sm'>
						{t('divergencePoint:send')}
					</Text>
					<Image ml={1} src={SendIcon} />
				</Button>
			)}
			{isMobile && (
				<Button
					isDisabled={!isValid}
					isLoading={isLoading}
					onClick={sendComment}
					borderRadius='50%'
					size='md'
					backgroundColor='evaluation_answer_send_button'
					w='2rem'
					alignItems='center'
					justifyContent='center'
				>
					<Image src={SendIcon} maxW='none' p={0} />
				</Button>
			)}
		</Flex>
	)
}
