import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Text,
	Box,
	Collapse,
	Grid,
	useBreakpointValue,
	Divider
} from '@chakra-ui/react'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import { EvaluationResponse } from 'modules/map/types'
import { useMe } from 'modules/user/hooks'

import { EssayCard } from './essay-card'
import { ReplyEssayAnswer } from './essay-card-reply'
import { EvaluationCard } from './essay-evaluation-card'
import { InputComment } from './input-comment'

export type Props = {
	content: EvaluationResponse
	pointId: string
	isLastOne: boolean
}

export const EssayComponent = ({ content, pointId, isLastOne }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()
	const hasEvaluation = !!content.evaluation_result
	const { user } = useMe()
	const { isAnonymous } = useEvaluationStore()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const hasDeletePrivilege = user?.id === content.author.id

	const makeDuration: () => number = () => {
		if (content.reply_count === 0) return 0.2
		if (content.reply_count < 4) return 0.4
		if (content.reply_count < 10) return 0.8
		return 1
	}

	const duration = makeDuration()

	if (isMobile) {
		return (
			<>
				<Grid gridTemplateColumns='1fr' gap={4} w='full' pb={4} pt={2}>
					<Box>
						{user && (
							<EssayCard
								content={content}
								hasDeletePrivilege={hasDeletePrivilege}
								hasEvaluation={hasEvaluation}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
								userLanguage={user?.language}
								currentUser={user}
							/>
						)}
						{content.responses && user && user.language && (
							<Collapse
								transition={{ enter: { duration } }}
								in={isOpen}
								animateOpacity
							>
								<Text
									ml={2}
									mt={4}
									color='gray.400'
									fontWeight='semibold'
									fontSize='md'
								>
									{t('evaluationPoint:view.comments')}
								</Text>
								<Box pb='2' width='100%'>
									{content.responses.map((reply) => (
										<ReplyEssayAnswer
											author={reply.author}
											content={reply}
											userId={user.id}
											userLanguage={user.language}
											key={reply.id}
											isAnonym={isAnonymous}
										/>
									))}
									<InputComment
										isAnonym={isAnonymous}
										author={user}
										parentId={content.id}
										pointId={pointId}
									/>
								</Box>
							</Collapse>
						)}
					</Box>
				</Grid>
				{!isLastOne && <Divider />}
			</>
		)
	}

	return (
		<>
			<Grid
				gridTemplateColumns={hasEvaluation ? '2fr 1.5fr' : '1fr'}
				gap={4}
				w='full'
				pb={4}
				pt={2}
			>
				<Box>
					{user && (
						<EssayCard
							content={content}
							hasDeletePrivilege={hasDeletePrivilege}
							hasEvaluation={hasEvaluation}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							userLanguage={user?.language}
							currentUser={user}
						/>
					)}
					{user && (
						<Collapse
							transition={{ enter: { duration } }}
							in={isOpen}
							animateOpacity
						>
							<Text
								ml={2}
								mt={4}
								color='gray.400'
								fontWeight='semibold'
								fontSize='md'
							>
								{t('evaluationPoint:view.comments')}
							</Text>
							{content.responses && user.language && (
								<Box pb='2' width='100%'>
									{content.responses.map((reply) => (
										<ReplyEssayAnswer
											author={reply.author}
											content={reply}
											userId={user.id}
											userLanguage={user.language}
											key={reply.id}
											isAnonym={isAnonymous}
										/>
									))}
								</Box>
							)}
							<InputComment
								isAnonym={isAnonymous}
								author={user}
								parentId={content.id}
								pointId={pointId}
							/>
						</Collapse>
					)}
				</Box>
				{content.evaluation_result && user?.language && (
					<EvaluationCard userLanguage={user?.language} content={content} />
				)}
			</Grid>
			{!isLastOne && <Divider />}
		</>
	)
}
