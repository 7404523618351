export const analysesKeys = {
	all: ['analyses'],
	toolEngagements: () => [...analysesKeys.all, 'toolEngagements'],
	mapEngagements: () => [...analysesKeys.all, 'mapEngagements'],
	generalEngagement: () => [...analysesKeys.all, 'generalEngagement']
} as const

export const questionKeys = {
	all: ['question'],
	questionSummary: (id: string) => [...questionKeys.all, 'questionSummary', id]
}

export const assistantsKeys = {
	all: ['assistants'],
	allAssistants: () => [...assistantsKeys.all, 'allAssistants']
}
