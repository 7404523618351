import { fetch } from 'config'

import { ConvergencePoint } from '../types'

export async function deleteConvergencePoint(id: string) {
	const response = await fetch<ConvergencePoint>({
		url: `/projects/v1/convergence-point/${id}`,
		method: 'DELETE'
	})

	return response.data
}
