import { useTranslation } from 'react-i18next'

import { format } from 'date-fns'
import * as yup from 'yup'

import { MonitorType } from '../types/monitor-point'

export const getMonitorPointSchema = () => {
	const { t } = useTranslation()

	return yup.object({
		position: yup
			.object({
				row: yup.number().required(t('errors:requiredField')),
				col: yup.number().required(t('errors:requiredField'))
			})
			.required(t('errors:requiredField')),
		name: yup
			.string()
			.required(t('errors:requiredField'))
			.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 })),
		description: yup
			.string()
			.required(t('errors:requiredField'))
			.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 })),
		conclusion_date: yup
			.string()
			.required(t('errors:requiredField'))
			.test('is-future-date', t('errors:date:mustBeFuture'), function (value) {
				const currentDate = new Date()
				currentDate.setDate(currentDate.getDate() + 1)
				currentDate.setHours(0, 0, 0, 0)

				currentDate.setHours(0, 0, 0, 0)
				const inputDate = new Date(value || '')
				return inputDate > currentDate
			})
			.transform((_, val) => {
				const finalDate = new Date(val)
				const date = finalDate.getDate() + 1
				finalDate.setDate(date)

				return format(new Date(finalDate), "yyyy-MM-dd'T'HH:mm:ss")
			}),
		monitor_type: yup
			.mixed()
			.oneOf(Object.values(MonitorType))
			.required(t('errors:requiredField')),
		goal: yup.number().when(['monitor_type'], {
			is: MonitorType.QUANTITATIVE,
			then: (schema) =>
				schema
					.required(t('errors:requiredField'))
					.moreThan(0, t('errors:point:error:monitor:goal'))
					.transform((_, val) => Number(val) || 0),
			otherwise: (schema) => schema.strip()
		}),
		flow: yup.string().when(['monitor_type'], {
			is: MonitorType.QUANTITATIVE,
			then: (schema) => schema.required(t('errors:requiredField')),
			otherwise: (schema) => schema.strip()
		}),
		type: yup.string().when(['monitor_type'], {
			is: MonitorType.QUANTITATIVE,
			then: (schema) => schema.required(t('errors:requiredField')),
			otherwise: (schema) => schema.strip()
		})
	})
}
