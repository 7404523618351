import i18n from 'i18n/i18n'
import { string, object, TestFunction } from 'yup'

import { hasNoUrl } from 'modules/common/validators'

export const MapValidation = () =>
	object({
		title: string()
			.required(i18n.t('errors:requiredField'))
			.min(1, ({ min }) => i18n.t('errors:fields.title.min', { min }))
			.max(35, ({ max }) => i18n.t('errors:fields.title.max', { max }))
			.test({ test: hasNoUrl, message: i18n.t('errors:fields.title.hasUrl') })
	})

export const ConversationPointValidation = () =>
	object({
		date: string().required(i18n.t('errors:requiredField')),
		description: string().required(i18n.t('errors:requiredField')),
		place: string().required(i18n.t('errors:requiredField')),
		hour: string()
			.required(i18n.t('errors:requiredField'))
			.test({
				test: testHourFromNow,
				message: i18n.t('errors:hourFromNow')
			})
	})

export const testHourFromNow: TestFunction = function (hour) {
	const date = this.parent.date
	const testDate = new Date(`${date}T${hour}`)
	const isAfterNow = testDate > new Date()

	return isAfterNow
}
