import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#461BFE',
		light: '#8EC2ED'
	},

	secondary: {
		dark: '#FFBE01',
		light: '#FFF3D7'
	}
}

export default themeColors
