import i18n from 'i18n/i18n'
import { object, string, boolean } from 'yup'

import { hasNoUrl } from 'modules/common/validators'

export const signUpProviderValidation = () =>
	object({
		name: string()
			.required(i18n.t('errors:requiredField'))
			.min(3, i18n.t('signup:shortName'))
			.max(50, i18n.t('signup:longName'))
			.test({ test: hasNoUrl, message: i18n.t('signup:validName') }),
		term_accepted: boolean()
			.oneOf([true], i18n.t('signup:acceptTermsMessage'))
			.required(i18n.t('signup:acceptTermsMessage'))
	})
