import { ThemeColors } from '../types'

const themeColors: ThemeColors = {
	primary: {
		dark: '#EB1700',
		light: '#FFA096'
	},

	secondary: {
		dark: '#322C2A',
		light: '#9D9B9A'
	}
}

export default themeColors
