import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const createAssistant = async (data: Assistant) => {
	return await fetch<Assistant>({
		url: 'projects/v1/assistants',
		method: 'POST',
		data
	})
}
