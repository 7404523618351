import { TenantColors } from '../types'
import childhoodCustomColors from './colors'
import componentColors from './componentColors'
import screenColors from './screenColors'
import themeColors from './themeColors'

const childhoodColors: TenantColors = {
	...childhoodCustomColors,
	...themeColors,
	...screenColors,
	...componentColors
}

export default childhoodColors
