import { fetch } from 'config'

import { Template } from '../types'

interface TemplateData {
	templateId: string
	title?: string
	description?: string | null
	color?: string
	visibility?: string
}

export const updateTemplate = async ({
	templateId,
	...templateData
}: TemplateData) => {
	const response = await fetch<Template>({
		url: `/templates/v1/template/${templateId}`,
		method: 'PUT',
		data: templateData
	})

	return response.data
}
