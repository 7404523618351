import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { manageMultipleResponseMode } from '../usecases/manage-multiple-response-mode'

export function useMultipleResponseMutation(pointId: string) {
	const channel = '/point/essay-point/multiple-response-mode'

	const { emit } = useSocket(channel)
	const setIsMultipleResponse = useEvaluationStore(
		(state) => state.setIsMultipleResponse
	)

	const mutation = useMutation(manageMultipleResponseMode, {
		onSuccess: (_, { isMultipleResponse }) => {
			setIsMultipleResponse(isMultipleResponse)
			emit('EDIT', pointId)
		}
	})

	return mutation
}
