import { fetch } from 'config'

import { Template } from '../types'

export async function updateVisibility(templateId: string, visibility: string) {
	const response = await fetch<Template>({
		url: `/templates/v1/template/${templateId}/visibility`,
		method: 'PATCH',
		data: { visibility }
	})

	return response.data
}
