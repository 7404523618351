import { isBefore } from 'date-fns'
import i18n from 'i18n/i18n'
import { string, object } from 'yup'

export const CreateConversationPointValidation = () =>
	object({
		date: string().required(i18n.t('errors:requiredField')),
		description: string().required(i18n.t('errors:requiredField')),
		place: string(),
		hour: string()
			.required(i18n.t('errors:requiredField'))
			.test({
				test(hour) {
					const date = this.parent.date
					const testDate = new Date(`${date}T${hour}`)
					const isValid = !isBefore(testDate, new Date())

					return isValid
				},
				message: i18n.t('errors:hourFromNow')
			})
	})
