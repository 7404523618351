import { fetch } from 'config'

import { Point } from '../../map-canvas/types'
import { ConvergencePointAnswer } from '../components/belief-question'

export async function answerConvergencePoint(
	pointId: string,
	data: ConvergencePointAnswer
) {
	const url = `/projects/v1/convergence-point/${pointId}/answer`

	return fetch<Point>({ url, method: 'POST', data })
		.then((res) => res.data)
		.catch(function (err) {
			const status = err.response.status

			switch (status) {
				case 409: {
					throw new Error(err.response.data.message)
				}

				default: {
					throw err
				}
			}
		})
}
