import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useBreakpointValue, useToast } from '@chakra-ui/react'

import { useMapStore } from 'modules/map/modules/map-canvas/store'

export const useClosePoint = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { clearSelected } = useMapStore(({ actions }) => actions)
	const toast = useToast()
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false })

	const closePoint = (pointId: string, hasError?: boolean) => {
		if (hasError) {
			toast.closeAll()
			toast({
				title: t('errors:point.error.title'),
				description: t('errors:point.error.description'),
				status: 'error',
				isClosable: true,
				duration: 7000,
				containerStyle: {
					minWidth: isMobile ? 'auto' : '800px'
				}
			})
		}

		const hasPointParam = location.pathname.includes('point')

		if (hasPointParam) {
			const backRouter = location.pathname.replace(`/point/${pointId}`, '')
			clearSelected()
			navigate(backRouter)
		} else {
			clearSelected()
		}
	}

	return closePoint
}
