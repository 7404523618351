import { fetch } from 'config'

type Response = {
	total: number
}

export const getUserResponsesCount = (pointId: string) => {
	return fetch<Response>({
		url: `/projects/v1/essay-point/${pointId}/responses/count`,
		method: 'GET'
	}).then(({ data }) => data.total)
}
