import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'

type Params = {
	initialValues: Record<string, any> | undefined
	schema?: AnyObjectSchema
}

export const useAssistantCustomForm = ({ initialValues, schema }: Params) => {
	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: initialValues ?? {},
		resolver: schema && yupResolver(schema)
	})

	const formValue = {
		control,
		errors
	}

	return { handleSubmit, formValue, control, errors }
}
