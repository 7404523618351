interface GPT_CREDIT_COST_TYPE {
	'3.5-Turbo': number
	4: number
}

// interface LLaMa_CREDIT_COST_TYPE {
// 	'13B': number
// }

const GPT_CREDIT_COST: GPT_CREDIT_COST_TYPE = {
	'3.5-Turbo': 2,
	4: 4
	// other versions...
}

// const LLaMa_CREDIT_COST: LLaMa_CREDIT_COST_TYPE = {
// 	'13B': 1, // Assuming a cost of 3 credits for the LLaMa 13B model
// 	// other versions...
// }

export const STARTING_POINT_CREATION_COST = GPT_CREDIT_COST['3.5-Turbo'] * 2
export const EVALUATION_POINT_CREATION_COST = GPT_CREDIT_COST['3.5-Turbo']
export const ANALYSIS_CREATION_COST = GPT_CREDIT_COST['4'] * 2
export const QUICK_SUMMARY_CREATION_COST = GPT_CREDIT_COST['4']
export const ENCOURAGE_DEBATE_RESPONSE_CREATION_COST =
	GPT_CREDIT_COST['3.5-Turbo']
export const EVALUATION_POINT_EVALUATE_ESSAY = GPT_CREDIT_COST['3.5-Turbo']
export const DOWNLOAD_CSV = 1
export const ASSISTANT_BASIC = GPT_CREDIT_COST['3.5-Turbo']
export const ASSISTANT_ADVANCED = GPT_CREDIT_COST['4'] * 2
export const ASSISTANT = GPT_CREDIT_COST['4']
export const ASSISTANT_CHAT = GPT_CREDIT_COST['4'] * 2.5
