import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Flex, Skeleton, Text, useToast } from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import Hyperlink from 'modules/common/components/hyperlink'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { useDivergenceStore } from 'modules/map/store/divergence-store'

import useCommentsStore, {
	Comments,
	Reply
} from '../../store/use-comments-store'
import { AnonymAvatar } from '../view-divergence-point/anonym-mode/anonym-avatar'
import { InputComment } from './input-comment'
import { LikeButton } from './like-button'

type Props = {
	reply: Reply
	userId: string
	userName: string
	userLanguage: string
	journeyId: string
	username: string
	divergencePointId: string
}

export const ReplyCard: React.FC<Props> = ({
	reply,
	userId,
	userName,
	userLanguage,
	journeyId,
	username,
	divergencePointId
}) => {
	const { deleteComment, deleteCommentState } = useCommentsStore()
	const toast = useToast()
	const { isAnonym } = useDivergenceStore()
	const anonymName = useRef('anonym'.padStart(6 + Math.random() * 10, '0'))
	const stringColor = useStringToColor({ text: reply.author.name }, [500])[1]
	const [isComment, setIsComment] = useState(false)
	const { t } = useTranslation()
	const [dataVisible, setDataVisible] = useState(true)
	const { emit } = useSocket(divergencyPointChannel({ projectId: journeyId }))
	const profilePath = `/dashboard/profile/${username}`
	const authorAvatarUrl = reply.author.avatar_url || reply.author.avatarUrl
	const canDelete = userId === reply.author.id || reply.created_by === userId
	const isAssistant = reply.author.is_assistant

	const deleteReplayHandler = async () => {
		await deleteComment(reply.id)
		const commentInfo = {
			divergence_point_id: divergencePointId,
			question_id: reply.question_id,
			parent_id: reply.parent_id,
			id: reply.id
		}
		deleteCommentState(commentInfo as Comments)
		emit('DELETE', commentInfo)
		toast({
			title: t('divergencePoint:comment_deleted'),
			isClosable: true,
			status: 'success'
		})
		setDataVisible(false)
	}

	const ActorName = () => {
		if (isAnonym) {
			return (
				<Skeleton isLoaded={false} animation='none' endColor='#DDDEE9' ml='4'>
					<Flex
						_hover={{ textDecoration: 'underline' }}
						color='gray.1'
						fontWeight='600'
						fontSize='sm'
						h='auto'
					>
						{anonymName.current}
					</Flex>
				</Skeleton>
			)
		} else {
			return (
				<Skeleton
					isLoaded={true}
					animation='none'
					endColor='#DDDEE9'
					ml={4}
					mb={2}
				>
					<Text
						as={isAssistant ? 'span' : Link}
						color='gray.1'
						fontWeight='600'
						fontSize='sm'
						lineHeight={1.2}
						{...(!isAssistant && { to: profilePath })}
					>
						{reply.author.name}
					</Text>
					{isAssistant && !isAnonym && (
						<Text color='gray.300' fontWeight='semibold' fontSize='xs' ml='2px'>
							{t('assistants:commentAll.badge')}
						</Text>
					)}
				</Skeleton>
			)
		}
	}

	return (
		<Flex flexDirection='column'>
			<Flex flexDirection='row'>
				{isAnonym ? (
					<Flex mt='4'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={authorAvatarUrl}
							name={reply.author.name}
							backgroundColor={stringColor}
						/>
					</Flex>
				) : (
					<Text as={Link} to={profilePath} mt='4'>
						<AnonymAvatar
							isAnonym={isAnonym}
							avatarUrl={authorAvatarUrl}
							name={reply.author.name}
							backgroundColor={stringColor}
						/>
					</Text>
				)}

				<Flex w='full' flexDirection='column' ml='2' mt='4'>
					<Flex
						flexDirection='column'
						backgroundColor='gray.100'
						borderRadius='0.5rem'
						boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
						w='100%'
					>
						<Flex alignItems='flex-start' justifyContent='space-between' mt={3}>
							<ActorName />
							<Flex alignItems='center'>
								{dataVisible && (
									<Text
										color='#8B90A0'
										fontWeight='500'
										mr='2'
										fontSize='0.625rem'
										mb='1'
									>
										{handleDateFormat(
											reply.created_at,
											userLanguage === 'PT_BR' ? 'ptBR' : ''
										)}
									</Text>
								)}
								{canDelete && (
									<DeleteConfirmButton
										onConfirm={deleteReplayHandler}
										iconButtonProps={{ fontSize: 'xl', color: 'pink.500' }}
										setDataVisible={() => setDataVisible(true)}
										setNotDataVisible={() => setDataVisible(false)}
									/>
								)}
							</Flex>
						</Flex>
						<Text
							ml='4'
							mb='3'
							fontSize='sm'
							color='#232735'
							fontWeight='500'
							pr='2'
							variant='breakLines'
						>
							<Hyperlink>{reply.text.trim()}</Hyperlink>
						</Text>
					</Flex>
					<Flex marginLeft='15' mt='1'>
						<LikeButton
							commentsAgreements={reply.agreements}
							commentsId={reply.parent_id}
							commentsQuestionId={reply.question_id}
							userId={userId}
							userName={userName}
							journeyId={journeyId}
							isReply
							replyId={reply.id}
						/>
					</Flex>
				</Flex>
			</Flex>
			{isComment && (
				<Box ml='8'>
					<InputComment
						commentsId={reply.parent_id}
						commentsQuestionId={reply.question_id}
						userName={userName}
						userId={userId}
						changeVisibility={() => setIsComment(false)}
						journeyId={journeyId}
					/>
				</Box>
			)}
		</Flex>
	)
}
