import { fetch } from 'config'

import { TemplateKit } from '../types'

export async function getTemplateKits(templateId: string) {
	const { data } = await fetch<TemplateKit[]>({
		url: `/templates/v1/template/${templateId}/tools`,
		method: 'GET'
	})

	return data
}
