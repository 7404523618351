import { formatToLocalDateTime } from './format-to-local-date-time'
const path = 'https://www.google.com/calendar/render?action=TEMPLATE'

interface Params {
	text: string
	details: string
	location: string
	dates: string
}

export const GCalendar = {
	getGCalendarPath(params: Params) {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		const paramsString = new URLSearchParams({
			...params,
			ctz: timezone
		}).toString()

		return `${path}&${paramsString}`
	},
	parseDate(date: string): string {
		const startDate = date ? new Date(formatToLocalDateTime(date)) : new Date()
		const endDate = new Date(startDate)
		endDate.setHours(startDate.getHours() + 1)

		const isoStart = formatIso(startDate)
		const isoEnd = formatIso(endDate)

		return `${isoStart}/${isoEnd}`
	}
}
const formatIso = (date: Date) => date.toISOString().replaceAll(/[.,:,-]/g, '')
