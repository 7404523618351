import i18next from 'i18n/i18n'

const get = (): string => {
	return i18next.language || window.localStorage.i18nextLng || ''
}

const set = (language: string): void => {
	i18next.changeLanguage(language)
}

export const internationalization = {
	get,
	set
}
