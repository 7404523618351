import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'

import { CreditsType, creditsValidator } from 'modules/common/validators'
import { useBillingAccount } from 'modules/labs/hooks'
import { BillingAccount } from 'modules/labs/types'
import { removeEmptyValues } from 'modules/labs/utils/remove-empty-values'

import { ChooseAmount } from './choose-amount'
import { ChoosePayment } from './components/choose-payment'
import { CreditsForm } from './components/credits-form'
import { PayPix } from './pay-pix'

export type Status = 'amount' | 'type' | 'pix' | 'paypal' | 'pix-qrcode'

export type ModalSize =
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| '4xl'
	| '5xl'
	| '6xl'
	| 'full'
	| 'xs'
	| 'sm'

type CreditsProps = {
	isOpen: boolean
	onClose: () => void
	children: (
		status: Status,
		setStatus: React.Dispatch<React.SetStateAction<Status>>,
		setModalSize: React.Dispatch<React.SetStateAction<ModalSize>>
	) => React.ReactNode
}

const BuyCreditsModal = ({ isOpen, onClose, children }: CreditsProps) => {
	const [status, setStatus] = useState<Status>('amount')
	const [modalSize, setModalSize] = useState<ModalSize>('md')

	const handleClose = () => {
		onClose()
		setStatus('amount')
		setModalSize('md')
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			isCentered={modalSize === 'md'}
			size={modalSize}
		>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent p={modalSize === 'md' ? 2 : 8}>
				<ModalCloseButton mt={3.5} mr={4} />
				{children(status, setStatus, setModalSize)}
			</ModalContent>
		</Modal>
	)
}

type BuyCreditsProps = {
	isOpen: boolean
	onClose: () => void
	account?: BillingAccount
}

const BuyCredits = ({ isOpen, onClose, account }: BuyCreditsProps) => {
	const methods = useForm<CreditsType>({
		mode: 'onChange',
		defaultValues: removeEmptyValues({
			creditsAmount: 50,
			creditsCost: 5,
			...account
		}),
		resolver: yupResolver(creditsValidator)
	})

	return (
		<FormProvider {...methods}>
			<BuyCreditsModal isOpen={isOpen} onClose={onClose}>
				{(status, setStatus, setModalSize) => (
					<>
						{status === 'amount' && (
							<ChooseAmount
								onClose={onClose}
								onNext={() => {
									setStatus('type')
								}}
							/>
						)}
						{status === 'type' && (
							<ChoosePayment
								onNext={(paymentOption: Status) => {
									setStatus(paymentOption as Status)
								}}
								setModalSize={setModalSize}
								setStatus={setStatus}
							/>
						)}
						{status === 'pix' && (
							<CreditsForm
								userHasAccount={!!account}
								formType='pix'
								onNext={() => {
									setStatus('pix-qrcode')
									setModalSize('md')
								}}
								onBack={() => {
									setStatus('type')
									setModalSize('md')
								}}
							/>
						)}
						{status === 'paypal' && (
							<CreditsForm
								userHasAccount={!!account}
								formType='paypal'
								onBack={() => {
									setStatus('type')
									setModalSize('md')
								}}
							/>
						)}
						{status === 'pix-qrcode' && <PayPix />}
					</>
				)}
			</BuyCreditsModal>
		</FormProvider>
	)
}

export const BuyCreditsContent = ({
	isOpen,
	onClose
}: {
	isOpen: boolean
	onClose: () => void
}) => {
	const { data: account, isLoading } = useBillingAccount()

	return !isLoading ? (
		<BuyCredits isOpen={isOpen} onClose={onClose} account={account} />
	) : null
}
