import { Suspense, ReactNode } from 'react'

import LoadingStrateegia from 'modules/common/components/loading'

type Props = {
	children: ReactNode
}

export const Loading = ({ children }: Props) => (
	<Suspense fallback={<LoadingStrateegia />}>{children}</Suspense>
)
