import { fetch } from 'config'

import { EvaluationResponse as EvaluationAnswerResponse } from 'modules/map/types'

type likeActions = 'agree' | 'disagree'

type Params = {
	pointId: string
	action: likeActions
	responseId: string
}

export async function likeEssayResponse({
	action,
	pointId,
	responseId
}: Params) {
	const response = await fetch<EvaluationAnswerResponse>({
		url: `/projects/v1/essay-point/${pointId}/responses/${responseId}/${action}`,
		method: 'PATCH'
	})
	return response.data
}
