import { fetch } from 'config'

import { EssayCreateResponse as EssayDataResponse } from 'modules/map/modules/evaluation-point/types'

export async function getEssaypoint(pointId: string) {
	const response = await fetch<EssayDataResponse>({
		url: `/projects/v1/essay-point/${pointId}`,
		method: 'GET'
	})
	return response.data
}
