import { useMutation } from 'react-query'

import { BaseMonitorPoint } from '../types/monitor-point'
import { createMonitorPoint } from '../usecases/create-monitor-point'

export const useCreateMonitorPoint = () => {
	return useMutation(
		async ({ mapId, data }: { mapId: string; data: BaseMonitorPoint }) => {
			return await createMonitorPoint(mapId, data)
		}
	)
}
