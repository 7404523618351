import tenantPermissions from 'config/permissions'

export const hasPermissionInList = (
	permission: string,
	option: string,
	userType: string,
	tenant: string
): boolean => {
	const permissions = tenantPermissions[tenant]
	if (!permissions.list[permission]) {
		return true
	}

	if (!permissions.list[permission][userType]) {
		return true
	}

	return !permissions.list[permission][userType].includes(option)
}
