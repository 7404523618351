import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useBreakpointValue,
	UseDisclosureReturn
} from '@chakra-ui/react'

import { useAssistants } from 'modules/assistants/hooks/use-assistants'
import Hyperlink from 'modules/common/components/hyperlink'
import { useJourney } from 'modules/journeys/hooks'
import { PointInfos } from 'modules/map/store/use-comments-store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { AssistantsTab } from './assistants-tab/assistants-tab'
import { WriteTab } from './write-tab'

interface Props extends UseDisclosureReturn {
	isOpen: boolean
	onOpen: () => void
	onClose: () => void
	questionId: string
	divergencePointId: string
	textQuestion: string | undefined
	index: number
	journeyId: string
	point: PointInfos
}

export const AnswerQuestion: React.FC<Props> = ({
	isOpen,
	onClose,
	questionId,
	divergencePointId,
	textQuestion,
	journeyId,
	point
}) => {
	const { t } = useTranslation()
	const firstField = React.useRef(null)
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { data } = useAssistants({ type: 'all' })
	const isStartingPoint = !!point.introduction
	const { user: currUser } = useMe()
	const [tabSelected, setTabSelected] = useState<'write' | 'assistants'>(
		'write'
	)

	const { data: journeyData } = useJourney(journeyId as string, {
		select: (journey) => journey
	})

	const assistantsData = data?.pages[0].data.content

	const handleCloseModal = () => {
		onClose()
		setTabSelected('write')
	}

	const hasPrivileges = !!journeyData?.users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('point.comment_all'))

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleCloseModal}
			size={['full', tabSelected === 'assistants' ? '5xl' : '2xl']}
			initialFocusRef={firstField}
			closeOnOverlayClick={false}
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<ModalContent pt={8} transition='max-width 0.3s ease' overflow='hidden'>
				<ModalCloseButton />

				<Tabs variant='soft-rounded' colorScheme='gray' overflowY='auto'>
					<TabList
						mt={isMobile ? 4 : 0}
						ml={isMobile ? 4 : 8}
						p={1}
						bgColor='gray.200'
						w='fit-content'
						rounded='full'
					>
						<Tab
							onClick={() => setTabSelected('write')}
							fontSize={isMobile ? 'sm' : 'md'}
						>
							{t('divergencePoint:tabWrite')}
						</Tab>
						{isStartingPoint && (journeyData?.ai_enabled || hasPrivileges) && (
							<Tab
								onClick={() => setTabSelected('assistants')}
								fontSize={isMobile ? 'sm' : 'md'}
							>
								{t('divergencePoint:tabAssistants')}
							</Tab>
						)}
					</TabList>

					<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
						{isMobile && (
							<Flex flexDirection='column' overflowY='auto'>
								<Text fontSize='md' fontWeight='600' color='gray.300'>
									{t('divergencePoint:question')}
								</Text>
								<Text fontSize='sm' fontWeight='500'>
									{textQuestion}
								</Text>
							</Flex>
						)}
						{!isMobile && (
							<Flex flexDirection='column'>
								<Text fontSize='md' fontWeight='600' color='gray.300'>
									{t('divergencePoint:question')}
								</Text>
								<Text fontSize='sm' fontWeight='500' variant='breakLines'>
									<Hyperlink>{textQuestion}</Hyperlink>
								</Text>
							</Flex>
						)}
					</ModalHeader>

					<ModalBody
						px={isMobile ? 4 : 8}
						w='full'
						maxH={['calc(100vh - 200px)', 'calc(100vh - 300px)']}
						overflow='unset'
						pb={[10, 6]}
					>
						<TabPanels pb={isMobile ? 4 : 8}>
							<TabPanel p={0}>
								<WriteTab
									closeModal={handleCloseModal}
									divergencePointId={divergencePointId}
									projectId={journeyId}
									questionId={questionId}
								/>
							</TabPanel>

							<TabPanel p={0}>
								<AssistantsTab
									assistants={assistantsData || []}
									divergencePointId={divergencePointId}
									questionId={questionId}
									closeModal={handleCloseModal}
									projectId={journeyId}
								/>
							</TabPanel>
						</TabPanels>
					</ModalBody>
				</Tabs>
			</ModalContent>
		</Modal>
	)
}

export const validText = (text: string) => {
	if (text.length <= 3000 && text.trim().length > 0) {
		return true
	}
	return false
}
