import { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	useToast,
	VStack
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'

import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { EvaluationResponse } from 'modules/map/types'

import InputResponse from './components/input-essay-text'
import InputTitle from './components/input-essay-title'
import { createEssayAnswer } from './usecases/create-essay-answer'
import { ResponsAanswerValidation } from './validators'

type Props = {
	onClose: () => void
	isOpen: boolean
	topic: string
	pointId: string
}

const AnswerPoint = (props: Props) => {
	const { isOpen, onClose, topic, pointId } = props
	const { t } = useTranslation()
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const toast = useToast()
	const { addEssayState } = useEssaysStore()
	const [isLoading, setIsLoading] = useState(false)
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { emit } = useSocket(evaluationPointChannel({ projectId: journeyId }))
	const { hasAnswers, setHasAnswers } = useEvaluationStore()
	const validation = ResponsAanswerValidation()

	const {
		handleSubmit,
		register,
		formState: { isValid, errors },
		watch,
		reset
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(validation),
		defaultValues: {
			title: '',
			response: ''
		}
	})

	const onSubmit = async (values: FieldValues) => {
		if (!hasAnswers) {
			setHasAnswers(!hasAnswers)
		}
		setIsLoading(true)
		const savedEssay = await createEssayAnswer({
			pointId,
			text: values.response.trim(),
			title: values.title
		})
		const responseWithComments = {
			...savedEssay,
			responses: []
		} as EvaluationResponse
		addEssayState(responseWithComments)
		const commentInfo = {
			essay_point_id: pointId,
			id: savedEssay.id
		}
		emit('ADD', commentInfo)
		onClose()
		toast({
			title: t('divergencePoint:reply_created'),
			isClosable: true,
			status: 'success'
		})
		onClose()
		setIsLoading(false)
		reset({
			title: '',
			response: ''
		})
	}

	const handleCloseModal = () => {
		onClose(),
			reset({
				title: '',
				response: ''
			})
	}

	return (
		<Modal
			closeOnOverlayClick={false}
			size={isMobile ? 'full' : '6xl'}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalContent
					bgColor='gray.100'
					h='90vh'
					py={isMobile ? 0 : 4}
					px={isMobile ? 0 : 7}
				>
					<ModalCloseButton mt={isMobile ? 2 : 8} mr={2} />
					<ModalHeader>
						<Text color='gray.300' fontSize='sm'>
							{t('evaluationPoint:view.theme')}
						</Text>
						<Text
							mt={isMobile ? 4 : 0}
							color='gray.400'
							fontWeight='bold'
							fontSize={['sm', 'lg']}
						>
							{topic}
						</Text>
					</ModalHeader>
					<ModalBody px={isMobile ? 3 : 2}>
						<VStack h='full' shadow='md' p={isMobile ? 5 : 9} bgColor='white'>
							<InputTitle
								register={register}
								errors={errors.title}
								text={watch('title')}
							/>
							<InputResponse
								register={register}
								errors={errors.response}
								text={watch('response')}
							/>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<HStack justifyContent='flex-end'>
							<Button onClick={handleCloseModal}>{t('buttons:cancel')}</Button>
							<Button
								isDisabled={!isValid}
								isLoading={isLoading}
								type='submit'
								colorScheme='custom_scheme_color_button'
							>
								{t('buttons:sendEssay')}
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	)
}

export default AnswerPoint
