import ReactGA from 'react-ga4'

import { history } from 'routes/history'

export function GoogleAnalyticsSetup() {
	const gaId = import.meta.env.VITE_GA_TAG

	if (!gaId) return

	ReactGA.initialize(gaId)
	trackAndSendPageViews()
}

function trackAndSendPageViews() {
	if (!ReactGA.ga()) return
	history.listen(({ location }) => {
		const path = location.pathname
		ReactGA.set({ page: path })
		ReactGA.send({ hitType: 'pageview', path })
	})
}

export function sendPageView(path: string) {
	if (!ReactGA.ga()) return
	ReactGA.set({ page: path })
	ReactGA.send({ hitType: 'pageview', path })
}

export function sendGAEvent(eventName: string) {
	ReactGA.event(eventName)
}
