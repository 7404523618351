import React, { useState, useEffect, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Icon,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Text
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

import { IconStJourneys } from 'modules/common/components/icons'
import { getLabName } from 'modules/common/utils/get-lab-name'
import { PlanByLabs } from 'modules/journeys/types'

type Props = {
	defaultValue: string | null
	onChange: (labId?: string | null) => void
	labsList: PlanByLabs[]
}

const SelectLab: React.FC<Props> = ({ onChange, defaultValue, labsList }) => {
	const [labId, setLabId] = useState<string | null>(defaultValue)
	const { t } = useTranslation()

	const currentLab = useMemo(
		() => labsList.find((lab) => lab.id === labId),
		[labsList, labId]
	)

	const buttonText = currentLab
		? getLabName(currentLab)
		: getLabName(labsList[0])

	const handleChange = (id: string | string[]) => {
		if (typeof id === 'string') {
			setLabId(id)
		}
	}

	useEffect(() => {
		if (!labId) {
			setLabId(labsList[0].id)
		}
		onChange(labId)
	}, [labId, setLabId])

	return (
		<>
			<Menu>
				<MenuButton
					as={Button}
					size='lg'
					_active={{ bg: 'gray.100' }}
					rightIcon={<Icon as={ChevronDownIcon} />}
				>
					{buttonText}
				</MenuButton>

				<MenuList overflow='hidden' minW={60}>
					<MenuOptionGroup type='radio' onChange={handleChange}>
						{labsList.map((lab) => (
							<MenuItemOption key={lab.id} value={lab.id} py={4}>
								<Text fontWeight='medium'>{getLabName(lab)}</Text>
								<Text color='gray.300' fontSize='xs'>
									@{lab.username}
								</Text>
								<Text color='gray.300' fontSize='xs'>
									<Icon
										as={IconStJourneys}
										mr={1}
										fontSize='sm'
										color='gray.200'
									/>
									{t('journeys:create.form.lab.proJourneys', {
										number: lab.usage.pro_projects_created
									})}
								</Text>
								<Text color='gray.300' fontSize='xs'>
									<Icon
										as={IconStJourneys}
										mr={1}
										fontSize='sm'
										color='gray.200'
									/>
									{t('journeys:create.form.lab.basicJourneys', {
										number: lab.usage.basic_projects_created
									})}
								</Text>
							</MenuItemOption>
						))}
					</MenuOptionGroup>
				</MenuList>
			</Menu>
			<Text fontSize='xs' color='gray.300' mt={4}>
				{t('journeys:create.form.lab.afterCreated')}
			</Text>
		</>
	)
}

export default memo(SelectLab)
