import defaultCustomColors from '../default/colors'

const hgsCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#5FC2E4',
		700: '#5FC2E4'
	},

	secondary_color_scheme_button: {
		700: '#131D4C'
	},

	switch_scheme_color_checked: {
		500: '#131D4C'
	},

	radio_color: {
		500: '#131D4C'
	}
}

export default hgsCustomColors
