import { fetch } from 'config'

import { Assistant } from 'modules/map/types/assistants'

export const assistantShareLab = async (labId: string, assistantId: string) => {
	return await fetch<Assistant>({
		url: `projects/v1/assistants/${assistantId}/share-lab`,
		method: 'PATCH',
		params: {
			labId
		}
	})
}
