import i18n from 'i18n/i18n'

import { PlanByLabs } from 'modules/journeys/types'
import { Lab } from 'modules/labs/types'

type LabData = Partial<PlanByLabs & Lab>

export const getLabName = (lab: LabData) => {
	const { name = '', username, owner_name, lab_name = '' } = lab
	return (
		lab_name ||
		name ||
		i18n.t('journeys:create.form.lab.owner', {
			owner: username || owner_name
		})
	)
}
