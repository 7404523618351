export function getCountries(lang: string) {
	const enCountries = [
		['AF', 'Afghanistan'],
		['AX', 'Åland'],
		['AL', 'Albania'],
		['DZ', 'Algeria'],
		['AS', 'American Samoa'],
		['AD', 'Andorra'],
		['AO', 'Angola'],
		['AI', 'Anguilla'],
		['AQ', 'Antarctica'],
		['AG', 'Antigua and Barbuda'],
		['AR', 'Argentina'],
		['AM', 'Armenia'],
		['AW', 'Aruba'],
		['AU', 'Australia'],
		['AT', 'Austria'],
		['AZ', 'Azerbaijan'],
		['BS', 'Bahamas'],
		['BH', 'Bahrain'],
		['BD', 'Bangladesh'],
		['BB', 'Barbados'],
		['BY', 'Belarus'],
		['BE', 'Belgium'],
		['BZ', 'Belize'],
		['BJ', 'Benin'],
		['BM', 'Bermuda'],
		['BT', 'Bhutan'],
		['BO', 'Bolivia'],
		['BQ', 'Bonaire, Sint Eustatius and Saba'],
		['BA', 'Bosnia and Herzegovina'],
		['BW', 'Botswana'],
		['BV', 'Bouvet Island'],
		['BR', 'Brazil'],
		['IO', 'British Indian Ocean Territory'],
		['BN', 'Brunei Darussalam'],
		['BG', 'Bulgaria'],
		['BF', 'Burkina Faso'],
		['BI', 'Burundi'],
		['KH', 'Cambodia'],
		['CM', 'Cameroon'],
		['CA', 'Canada'],
		['CV', 'Cape Verde'],
		['KY', 'Cayman Islands'],
		['CF', 'Central African Republic'],
		['TD', 'Chad'],
		['CL', 'Chile'],
		['CN', 'China'],
		['CX', 'Christmas Island'],
		['CC', 'Cocos (Keeling) Islands'],
		['CO', 'Colombia'],
		['KM', 'Comoros'],
		['CG', 'Congo (Brazzaville'],
		['CD', 'Congo (Kinshasa'],
		['CK', 'Cook Islands'],
		['CR', 'Costa Rica'],
		['CI', "Côte d'Ivoire"],
		['HR', 'Croatia'],
		['CW', 'Curaçao'],
		['CY', 'Cyprus'],
		['CZ', 'Czech Republic'],
		['DK', 'Denmark'],
		['DJ', 'Djibouti'],
		['DM', 'Dominica'],
		['DO', 'Dominican Republic'],
		['EC', 'Ecuador'],
		['EG', 'Egypt'],
		['SV', 'El Salvador'],
		['GQ', 'Equatorial Guinea'],
		['ER', 'Eritrea'],
		['EE', 'Estonia'],
		['ET', 'Ethiopia'],
		['FK', 'Falkland Islands'],
		['FO', 'Faroe Islands'],
		['FJ', 'Fiji'],
		['FI', 'Finland'],
		['FR', 'France'],
		['GF', 'French Guiana'],
		['PF', 'French Polynesia'],
		['TF', 'French Southern Lands'],
		['GA', 'Gabon'],
		['GM', 'Gambia'],
		['GE', 'Georgia'],
		['DE', 'Germany'],
		['GH', 'Ghana'],
		['GI', 'Gibraltar'],
		['GR', 'Greece'],
		['GL', 'Greenland'],
		['GD', 'Grenada'],
		['GP', 'Guadeloupe'],
		['GU', 'Guam'],
		['GT', 'Guatemala'],
		['GG', 'Guernsey'],
		['GN', 'Guinea'],
		['GW', 'Guinea-Bissau'],
		['GY', 'Guyana'],
		['HT', 'Haiti'],
		['HM', 'Heard and McDonald Islands'],
		['HN', 'Honduras'],
		['HK', 'Hong Kong'],
		['HU', 'Hungary'],
		['IS', 'Iceland'],
		['IN', 'India'],
		['ID', 'Indonesia'],
		['IQ', 'Iraq'],
		['IE', 'Ireland'],
		['IM', 'Isle of Man'],
		['IL', 'Israel'],
		['IT', 'Italy'],
		['JM', 'Jamaica'],
		['JP', 'Japan'],
		['JE', 'Jersey'],
		['JO', 'Jordan'],
		['KZ', 'Kazakhstan'],
		['KE', 'Kenya'],
		['KI', 'Kiribati'],
		['KR', 'Korea, South'],
		['KW', 'Kuwait'],
		['KG', 'Kyrgyzstan'],
		['LA', 'Laos'],
		['LV', 'Latvia'],
		['LB', 'Lebanon'],
		['LS', 'Lesotho'],
		['LR', 'Liberia'],
		['LY', 'Libya'],
		['LI', 'Liechtenstein'],
		['LT', 'Lithuania'],
		['LU', 'Luxembourg'],
		['MO', 'Macau'],
		['MK', 'Macedonia'],
		['MG', 'Madagascar'],
		['MW', 'Malawi'],
		['MY', 'Malaysia'],
		['MV', 'Maldives'],
		['ML', 'Mali'],
		['MT', 'Malta'],
		['MH', 'Marshall Islands'],
		['MQ', 'Martinique'],
		['MR', 'Mauritania'],
		['MU', 'Mauritius'],
		['YT', 'Mayotte'],
		['MX', 'Mexico'],
		['FM', 'Micronesia'],
		['MD', 'Moldova'],
		['MC', 'Monaco'],
		['MN', 'Mongolia'],
		['ME', 'Montenegro'],
		['MS', 'Montserrat'],
		['MA', 'Morocco'],
		['MZ', 'Mozambique'],
		['MM', 'Myanmar'],
		['NA', 'Namibia'],
		['NR', 'Nauru'],
		['NP', 'Nepal'],
		['NL', 'Netherlands'],
		['NC', 'New Caledonia'],
		['NZ', 'New Zealand'],
		['NI', 'Nicaragua'],
		['NE', 'Niger'],
		['NG', 'Nigeria'],
		['NU', 'Niue'],
		['NF', 'Norfolk Island'],
		['MP', 'Northern Mariana Islands'],
		['NO', 'Norway'],
		['OM', 'Oman'],
		['PK', 'Pakistan'],
		['PW', 'Palau'],
		['PS', 'Palestine'],
		['PA', 'Panama'],
		['PG', 'Papua New Guinea'],
		['PY', 'Paraguay'],
		['PE', 'Peru'],
		['PH', 'Philippines'],
		['PN', 'Pitcairn'],
		['PL', 'Poland'],
		['PT', 'Portugal'],
		['PR', 'Puerto Rico'],
		['QA', 'Qatar'],
		['RE', 'Reunion'],
		['RO', 'Romania'],
		['RU', 'Russian Federation'],
		['RW', 'Rwanda'],
		['BL', 'Saint Barthélemy'],
		['SH', 'Saint Helena'],
		['KN', 'Saint Kitts and Nevis'],
		['LC', 'Saint Lucia'],
		['MF', 'Saint Martin (French part'],
		['PM', 'Saint Pierre and Miquelon'],
		['VC', 'Saint Vincent and the Grenadines'],
		['WS', 'Samoa'],
		['SM', 'San Marino'],
		['ST', 'Sao Tome and Principe'],
		['SA', 'Saudi Arabia'],
		['SN', 'Senegal'],
		['RS', 'Serbia'],
		['SC', 'Seychelles'],
		['SL', 'Sierra Leone'],
		['SG', 'Singapore'],
		['SX', 'Sint Maarten (Dutch part'],
		['SK', 'Slovakia'],
		['SI', 'Slovenia'],
		['SB', 'Solomon Islands'],
		['SO', 'Somalia'],
		['ZA', 'South Africa'],
		['GS', 'South Georgia and South Sandwich Islands'],
		['SS', 'South Sudan'],
		['ES', 'Spain'],
		['LK', 'Sri Lanka'],
		['SD', 'Sudan'],
		['SR', 'Suriname'],
		['SJ', 'Svalbard and Jan Mayen Islands'],
		['SZ', 'Swaziland'],
		['SE', 'Sweden'],
		['CH', 'Switzerland'],
		['TW', 'Taiwan'],
		['TJ', 'Tajikistan'],
		['TZ', 'Tanzania'],
		['TH', 'Thailand'],
		['TL', 'Timor-Leste'],
		['TG', 'Togo'],
		['TK', 'Tokelau'],
		['TO', 'Tonga'],
		['TT', 'Trinidad and Tobago'],
		['TN', 'Tunisia'],
		['TR', 'Turkey'],
		['TM', 'Turkmenistan'],
		['TC', 'Turks and Caicos Islands'],
		['TV', 'Tuvalu'],
		['UG', 'Uganda'],
		['UA', 'Ukraine'],
		['AE', 'United Arab Emirates'],
		['GB', 'United Kingdom'],
		['UM', 'United States Minor Outlying Islands'],
		['US', 'United States of America'],
		['UY', 'Uruguay'],
		['UZ', 'Uzbekistan'],
		['VU', 'Vanuatu'],
		['VA', 'Vatican City'],
		['VE', 'Venezuela'],
		['VN', 'Vietnam'],
		['VG', 'Virgin Islands, British'],
		['VI', 'Virgin Islands, U.S'],
		['WF', 'Wallis and Futuna Islands'],
		['EH', 'Western Sahara'],
		['YE', 'Yemen'],
		['ZM', 'Zambia'],
		['ZW', 'Zimbabwe']
	]

	const ptCountries = [
		['AF', 'Afeganistão'],
		['ZA', 'África do Sul'],
		['AL', 'Albânia'],
		['DE', 'Alemanha'],
		['AD', 'Andorra'],
		['AO', 'Angola'],
		['AG', 'Antiga e Barbuda'],
		['SA', 'Arábia Saudita'],
		['DZ', 'Argélia'],
		['AR', 'Argentina'],
		['AM', 'Arménia'],
		['AU', 'Austrália'],
		['AT', 'Áustria'],
		['AZ', 'Azerbaijão'],
		['BS', 'Bahamas'],
		['BD', 'Bangladexe'],
		['BB', 'Barbados'],
		['BH', 'Barém'],
		['BE', 'Bélgica'],
		['BZ', 'Belize'],
		['BJ', 'Benim'],
		['BY', 'Bielorrússia'],
		['BO', 'Bolívia'],
		['BA', 'Bósnia e Herzegovina'],
		['BW', 'Botsuana'],
		['BR', 'Brasil'],
		['BN', 'Brunei'],
		['BG', 'Bulgária'],
		['BF', 'Burquina Faso'],
		['BI', 'Burúndi'],
		['BT', 'Butão'],
		['CV', 'Cabo Verde'],
		['CM', 'Camarões'],
		['KH', 'Camboja'],
		['CA', 'Canadá'],
		['QA', 'Catar'],
		['KZ', 'Cazaquistão'],
		['TD', 'Chade'],
		['CL', 'Chile'],
		['CN', 'China'],
		['CY', 'Chipre'],
		['CO', 'Colômbia'],
		['KM', 'Comores'],
		['CG', 'Congo-Brazzaville'],
		['KP', 'Coreia do Norte'],
		['KR', 'Coreia do Sul'],
		['CI', 'Costa do Marfim'],
		['CR', 'Costa Rica'],
		['HR', 'Croácia'],
		['KW', 'Cuaite'],
		['CU', 'Cuba'],
		['DK', 'Dinamarca'],
		['DM', 'Dominica'],
		['EG', 'Egito'],
		['AE', 'Emirados Árabes Unidos'],
		['EC', 'Equador'],
		['ER', 'Eritreia'],
		['SK', 'Eslováquia'],
		['SI', 'Eslovénia'],
		['ES', 'Espanha'],
		['PS', 'Estado da Palestina'],
		['US', 'Estados Unidos'],
		['EE', 'Estónia'],
		['ET', 'Etiópia'],
		['FJ', 'Fiji'],
		['PH', 'Filipinas'],
		['FI', 'Finlândia'],
		['FR', 'França'],
		['GA', 'Gabão'],
		['GM', 'Gâmbia'],
		['GH', 'Gana'],
		['GE', 'Geórgia'],
		['GD', 'Granada'],
		['GR', 'Grécia'],
		['GT', 'Guatemala'],
		['GY', 'Guiana'],
		['GN', 'Guiné'],
		['GQ', 'Guiné Equatorial'],
		['GW', 'Guiné-Bissau'],
		['HT', 'Haiti'],
		['HN', 'Honduras'],
		['HU', 'Hungria'],
		['YE', 'Iémen'],
		['MH', 'Ilhas Marechal'],
		['IN', 'Índia'],
		['ID', 'Indonésia'],
		['IR', 'Irão'],
		['IQ', 'Iraque'],
		['IE', 'Irlanda'],
		['IS', 'Islândia'],
		['IL', 'Israel'],
		['IT', 'Itália'],
		['JM', 'Jamaica'],
		['JP', 'Japão'],
		['DJ', 'Jibuti'],
		['JO', 'Jordânia'],
		['LA', 'Laus'],
		['LS', 'Lesoto'],
		['LV', 'Letónia'],
		['LB', 'Líbano'],
		['LR', 'Libéria'],
		['LY', 'Líbia'],
		['LI', 'Listenstaine'],
		['LT', 'Lituânia'],
		['LU', 'Luxemburgo'],
		['MK', 'Macedónia'],
		['MG', 'Madagáscar'],
		['MY', 'Malásia'],
		['MW', 'Maláui'],
		['MV', 'Maldivas'],
		['ML', 'Mali'],
		['MT', 'Malta'],
		['MA', 'Marrocos'],
		['MU', 'Maurícia'],
		['MR', 'Mauritânia'],
		['MX', 'México'],
		['MM', 'Mianmar'],
		['FM', 'Micronésia'],
		['MZ', 'Moçambique'],
		['MD', 'Moldávia'],
		['MC', 'Mónaco'],
		['MN', 'Mongólia'],
		['ME', 'Montenegro'],
		['NA', 'Namíbia'],
		['NR', 'Nauru'],
		['NP', 'Nepal'],
		['NI', 'Nicarágua'],
		['NE', 'Níger'],
		['NG', 'Nigéria'],
		['NO', 'Noruega'],
		['NZ', 'Nova Zelândia'],
		['OM', 'Omã'],
		['NL', 'Países Baixos'],
		['PW', 'Palau'],
		['PA', 'Panamá'],
		['PG', 'Papua Nova Guiné'],
		['PK', 'Paquistão'],
		['PY', 'Paraguai'],
		['PE', 'Peru'],
		['PL', 'Polónia'],
		['PT', 'Portugal'],
		['KE', 'Quénia'],
		['KG', 'Quirguistão'],
		['KI', 'Quiribáti'],
		['GB', 'Reino Unido'],
		['CF', 'República Centro-Africana'],
		['CZ', 'República Checa'],
		['CD', 'República Democrática do Congo'],
		['DO', 'República Dominicana'],
		['RO', 'Roménia'],
		['RW', 'Ruanda'],
		['RU', 'Rússia'],
		['SB', 'Salomão'],
		['SV', 'Salvador'],
		['WS', 'Samoa'],
		['LC', 'Santa Lúcia'],
		['KN', 'São Cristóvão e Neves'],
		['SM', 'São Marinho'],
		['ST', 'São Tomé e Príncipe'],
		['VC', 'São Vicente e Granadinas'],
		['SC', 'Seicheles'],
		['SN', 'Senegal'],
		['SL', 'Serra Leoa'],
		['RS', 'Sérvia'],
		['SG', 'Singapura'],
		['SY', 'Síria'],
		['SO', 'Somália'],
		['LK', 'Sri Lanca'],
		['SZ', 'Suazilândia'],
		['SD', 'Sudão'],
		['SS', 'Sudão do Sul'],
		['SE', 'Suécia'],
		['CH', 'Suíça'],
		['SR', 'Suriname'],
		['TH', 'Tailândia'],
		['TW', 'Taiuã'],
		['TJ', 'Tajiquistão'],
		['TZ', 'Tanzânia'],
		['TL', 'Timor-Leste'],
		['TG', 'Togo'],
		['TO', 'Tonga'],
		['TT', 'Trindade e Tobago'],
		['TN', 'Tunísia'],
		['TM', 'Turcomenistão'],
		['TR', 'Turquia'],
		['TV', 'Tuvalu'],
		['UA', 'Ucrânia'],
		['UG', 'Uganda'],
		['UY', 'Uruguai'],
		['UZ', 'Usbequistão'],
		['VU', 'Vanuatu'],
		['VA', 'Vaticano'],
		['VE', 'Venezuela'],
		['VN', 'Vietname'],
		['ZM', 'Zâmbia'],
		['ZW', 'Zimbábue']
	]

	return lang === 'pt' ? ptCountries : enCountries
}

export function europeanUnion() {
	return [
		'BE',
		'GR',
		'LT',
		'PT',
		'BG',
		'ES',
		'LU',
		'RO',
		'CZ',
		'FR',
		'HU',
		'SI',
		'DK',
		'HR',
		'MT',
		'SK',
		'DE',
		'IT',
		'NL',
		'FI',
		'EE',
		'CY',
		'AT',
		'SE',
		'IE',
		'LV',
		'PL'
	]
}
