import { fetch } from 'config'

import { ConvergencePointAnswer } from '../types'

export async function getConvergencePointAnswer(pointId: string) {
	const url = `/projects/v1/convergence-point/${pointId}/answer/me`
	const response = await fetch<ConvergencePointAnswer>({
		url,
		method: 'GET'
	})

	return response.data
}
