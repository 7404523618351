import { useTranslation } from 'react-i18next'

import {
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useBreakpointValue,
	FormControl,
	FormErrorMessage
} from '@chakra-ui/react'
import { FieldError, UseFormRegister } from 'react-hook-form'

type Props =  {
	register: UseFormRegister<{
		title: string,
		response: string
	}>
	errors?: FieldError,
	text:string
}

const InputTitle = ({register, errors, text}: Props) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	return (
		<FormControl isInvalid={!!errors}>
			<InputGroup mb={2}>
				<Input
					{...register('title')}
					_placeholder={{ color: 'gray.300', fontSize: isMobile ? 'sm' : 'md' }}
					variant='flushed'
					placeholder={t('evaluationPoint:essay.title')}
					maxLength={100}
					pr={75}
				/>
				<InputRightElement>
					<Text fontSize={['sm', 'md']} color='gray.300'>
						{text?.length}/{100}
					</Text>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage>{errors?.message}</FormErrorMessage>
		</FormControl>
	)
}

export default InputTitle
