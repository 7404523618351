import { fetch } from 'config'

import { Comment } from '../types'

export async function deleteConversationComment(commentId: string) {
	await fetch<Comment>({
		url: `/projects/v1/checkpoint/comment/${commentId}`,
		method: 'DELETE'
	})
}
