import { fetch } from 'config'

import { TemplateCreation, Template } from '../types'

export async function createTemplate(data: TemplateCreation) {
	const response = await fetch<Template>({
		url: '/templates/v1/template',
		method: 'POST',
		data
	})

	return response.data
}
