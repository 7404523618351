import React, { useMemo } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiPlusCircle } from 'react-icons/bi'

import { Box, Button, Icon, useTheme } from '@chakra-ui/react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { makeConvergenceQuestion } from 'modules/map/modules/convergence-point/helpers'

import { ConvergenceQuestion } from '../question'

export const CreateConvergenceQuestions = () => {
	const { t } = useTranslation()
	const { control } = useFormContext()
	const { fields, remove, append } = useFieldArray({
		control,
		name: 'questions'
	})

	const [parent] = useAutoAnimate()

	const blockAdd = useMemo(() => fields.length >= 10, [fields])
	const canDelete = useMemo(() => fields.length > 1, [fields])

	const theme = useTheme() as { colors: any }
	return (
		<>
			<ul ref={parent}>
				{fields.map((question, index) => (
					<ConvergenceQuestion
						key={question.id}
						index={index}
						onRemove={() => remove(index)}
						canDelete={canDelete}
					/>
				))}
			</ul>

			<Box mt={5}>
				<Button
					variant='ghost'
					color={theme.colors.convergence_points_button_outlined_text_color}
					colorScheme='blue'
					fontWeight='semibold'
					leftIcon={<Icon as={BiPlusCircle} />}
					onClick={() => append(makeConvergenceQuestion())}
					isDisabled={blockAdd}
				>
					{t('buttons:addAsking')}
				</Button>
			</Box>
		</>
	)
}
