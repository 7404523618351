import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AssistantList } from 'modules/assistants/components/assistant-list'
import AssistantsTabs from 'modules/assistants/components/assistants-tabs'
import { AssistantTab } from 'modules/assistants/types'
import { Assistant } from 'modules/map/types/assistants'

import { AssistantQuestionCommentReview } from './assistant-question-comment-review'
import { AssistantQuestionCommentSize } from './assistant-question-comment-size'

type Props = {
	assistants?: Assistant[]
	divergencePointId: string
	questionId: string
	projectId: string
	closeModal: () => void
}

export const AssistantsTab = ({
	divergencePointId,
	questionId,
	projectId,
	closeModal
}: Props) => {
	const { t } = useTranslation()
	const [assistantResponse, setAssistantResponse] = useState<any>(null)
	const [assistantResponseSize, setAssistantResponseSize] = useState('MEDIUM')
	const [currentTab, setCurrentTab] = useState<AssistantTab>({
		title: t('assistants:tab.my-assistants'),
		type: 'private'
	})

	return !assistantResponse ? (
		<>
			<AssistantQuestionCommentSize
				assistantResponseSize={assistantResponseSize}
				setAssistantResponseSize={setAssistantResponseSize}
			/>

			<AssistantsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

			<AssistantList
				useMode='answer'
				currentTab={currentTab}
				createAnswerData={{
					divergencePointId,
					questionId,
					onResponse: setAssistantResponse,
					assistantResponseSize
				}}
			/>
		</>
	) : (
		<AssistantQuestionCommentReview
			comment={assistantResponse.text}
			assistantId={assistantResponse.author.id}
			divergencePointId={divergencePointId}
			questionId={questionId}
			projectId={projectId}
			closeModal={closeModal}
			assistantName={assistantResponse.author.name}
		/>
	)
}
