import defaultCustomColors from '../default/colors'

const portodigitalCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#333FE1',
		700: '#333FE1'
	},

	secondary_color_scheme_button: {
		700: '#232324'
	},

	switch_scheme_color_checked: {
		500: '#232324'
	},

	radio_color: {
		500: '#232324'
	}
}

export default portodigitalCustomColors
