import { useTranslation } from 'react-i18next'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { useTenant } from 'modules/common/contexts/global-tenant-context'

interface ContinueButtonProps {
	onClick: () => void
	isDisabled: boolean
}

export const ContinueButton = ({
	onClick,
	isDisabled
}: ContinueButtonProps) => {
	const { t } = useTranslation()
	const { tenant, isTenantDefault } = useTenant()

	return (
		<CustomSchemeButton
			type='submit'
			width='full'
			colorScheme={isTenantDefault(tenant) ? 'secondary' : 'primary'}
			onClick={onClick}
			isDisabled={isDisabled}
			mt={8}
		>
			{t('signup:continue')}
		</CustomSchemeButton>
	)
}
