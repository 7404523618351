import Api from 'data/api'
import create from 'zustand'

import {
	InitialCreateJourney,
	JourneySummary,
	JourneySummaryMap
} from 'modules/journeys/types'
import ProjectsApi, { NotificationCount } from 'modules/journeys/usecases'
import { ROLE } from 'modules/user/constants'

interface RoleNotificationCount extends NotificationCount {
	project_role: ROLE
}
interface JourneysState {
	notificationCount: RoleNotificationCount[]
	loadNotificationCount: () => void
	createJourney: (journey: InitialCreateJourney) => Promise<JourneySummary>
}

const JourneysStore = create<JourneysState>((set, get) => ({
	notificationCount: [],
	loadNotificationCount: async () => {
		const notificationCount = await ProjectsApi.getNotificationsCount()
		const notifications = notificationCount.map(({ project_role, count }) => {
			return {
				...count,
				project_role
			}
		})
		set(() => ({
			notificationCount: [...notifications]
		}))
	},
	createJourney: async (journey: InitialCreateJourney) => {
		const idTemplate =
			journey.template_id === 'blank' ? null : journey.template_id
		const labs = await ProjectsApi.getPlanUsageByLabs()
		if (labs.length > 1 || labs.length === 0) {
			throw new Error('user has more than 1 lab')
		}
		let templateData = {}
		if (journey.template_id) {
			templateData = await Api.Templates.getTemplateById(journey.template_id)
		}

		const [labDefault] = labs

		const payload = {
			title: '',
			lab_owner_id: labDefault.id,
			color: '',
			description: '',
			template_id: idTemplate,
			...templateData
		}
		const journeySummary = await ProjectsApi.create(payload)
		if (journeySummary.maps.length === 0) {
			const [mapDefault] = journey.maps
			const map = await ProjectsApi.createMap({
				projectId: journeySummary.id,
				title: mapDefault.title
			})
			journeySummary.maps.push(map as JourneySummaryMap)
		}
		return journeySummary
	}
}))

export default JourneysStore
