import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLinkExternal } from 'react-icons/bi'

import {
	Avatar,
	Box,
	Flex,
	Icon,
	Link,
	Spacer,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import { useStringToColor } from 'modules/common/hooks'

import useReferences from '../../store/use-references-store'

type Props = {
	userName: string
	description: string
	url: string
	hasDeletePrivilege: boolean
	divergencePointId: string
	idLink: string
}

export const LinkCard: React.FC<Props> = ({
	userName,
	description,
	url,
	hasDeletePrivilege,
	divergencePointId,
	idLink
}) => {
	const { removeAttachedLink } = useReferences()
	const useString = useStringToColor({ text: userName }, [500])[1]
	const toast = useToast()
	const { t } = useTranslation()
	const background = useBreakpointValue({ base: 'gray.100', lg: 'white' })
	return (
		<Box
			backgroundColor={background}
			marginTop={4}
			mx={6}
			p={3}
			borderRadius='0.5rem'
			border='1px solid'
			borderColor={useString}
			overflowY='auto'
			boxShadow='md'
		>
			<Flex align='center' wrap='wrap'>
				<Avatar
					name={userName}
					size='sm'
					backgroundColor={useString}
					color='#fff'
				></Avatar>
				<Text fontWeight='600' fontSize='14px' marginLeft='8px'>
					{userName}
				</Text>
				<Spacer />
				{hasDeletePrivilege && (
					<DeleteConfirmButton
						onConfirm={() => {
							try {
								removeAttachedLink(divergencePointId, idLink)
								toast({
									title: t('divergencePoint:about_kit:link_deleted'),
									isClosable: true,
									status: 'success'
								})
							} catch (error) {
								const { message } = error as Error
								toast({
									title: message as string,
									isClosable: true,
									status: 'error'
								})
							}
						}}
						iconButtonProps={{
							fontSize: 'xl',
							color: 'pink.500',
							background: 'transparent'
						}}
					/>
				)}
			</Flex>

			<Text fontSize='14px' my='0.9rem'>
				{description}
			</Text>

			<Link href={url} fontSize='12px' color='gray.400' isExternal>
				<Icon as={BiLinkExternal} fontSize='md' marginRight='0.5rem' />
				{url}
			</Link>
		</Box>
	)
}
