import { useState } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { DeleteConfirmButton } from 'modules/common/components/delete-confirm-button'
import { useStringToColor } from 'modules/common/hooks'
import { handleDateFormat } from 'modules/common/utils/change-date-format'
import { MonitorPointStatusCommentResponse } from 'modules/map/components/points/monitor-point/types/monitor-point'
import { AnonymAvatar } from 'modules/map/components/view-divergence-point/anonym-mode/anonym-avatar'

interface StatusCommentCardProps {
	comment: MonitorPointStatusCommentResponse
	userId: string
	userName: string
	userLanguage: string
	onDelete: () => void
	isDeleteCommentLoading: boolean
}

export const StatusCommentCard = ({
	comment,
	userId,
	userLanguage,
	onDelete,
	isDeleteCommentLoading
}: StatusCommentCardProps) => {
	const stringColor = useStringToColor({ text: comment.author.name }, [500])[1]
	const [isDateVisible, setIsDateVisible] = useState(true)

	const isAnonym = false // TODO: implement anonym mode

	const canDelete = userId === comment.author.id

	return (
		<Flex mt={4}>
			<AnonymAvatar
				isAnonym={isAnonym}
				avatarUrl={comment.author.avatar_url}
				name={comment.author.name}
				backgroundColor={stringColor}
			/>
			<Flex w='full' flexDirection='column' ml={2}>
				<Flex
					flexDirection='column'
					backgroundColor='gray.100'
					borderRadius='0.5rem'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
					w='100%'
					p={3}
				>
					<Flex alignItems='center' justifyContent='space-between'>
						<Text color='gray.1' fontWeight='600' fontSize='sm'>
							{isAnonym ? 'Anônimo' : comment.author.name}
						</Text>
						<Flex alignItems='center'>
							{isDateVisible && (
								<Text color='#8B90A0' fontWeight='500' fontSize='xs' mr={2}>
									{handleDateFormat(
										new Date(comment.created_at),
										userLanguage === 'PT_BR' ? 'ptBR' : ''
									)}
								</Text>
							)}
							{canDelete && (
								<DeleteConfirmButton
									isLoading={isDeleteCommentLoading}
									onConfirm={onDelete}
									iconButtonProps={{ fontSize: 'xl', color: 'pink.500' }}
									setDataVisible={() => setIsDateVisible(true)}
									setNotDataVisible={() => setIsDateVisible(false)}
								/>
							)}
						</Flex>
					</Flex>
					<Text fontSize='sm' color='#232735' fontWeight='500' mt={2}>
						{comment.text}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	)
}
